export const interpreterStreamIds = [
  655,
  656,
  657,
  658,
  659,
  777,
  2027,
  2028,
  2029,
  2030,
  2031,
  1000430,
  1000429,
  1000428,
  1000427,
  1000426,
  1080050,
  1080049,
  1080048,
  1080047,
  1080046,
  1192971,
  1192972,
  1192973,
  1192974,
  1192975,
  1197275,
  1197274,
  1197273,
  1197272,
  1197271,
  1197280,
  1197279,
  1197278,
  1197277,
  1197276,
  1708,
  1709,
  1710,
  1711,
  1712,
  3843233,
  3843234,
  3843235,
  3843236,
  3843237,
  3843238,
  3843239,
  3843240,
  3843241,
  3843242,
  3846335,
  3846334,
  3846333,
  3846332,
  3846331,
  3846263,
  3846262,
  3846261,
  3846260,
  3846259,
  3846251,
  3846250,
  3846249,
  3846248,
  3846247,
  4286261,
  4286262,
  4286263,
  4286264,
  4286265,
  4370261,
  4370260,
  4370259,
  4370258,
  4370257,
  4482343,
  4482344,
  4482345,
  4482346,
  4482347,
  4512350,
  4512351,
  4512352,
  4512353,
  4512354,
  4512355,
  4512356,
  4512357,
  4512358,
  4512359,
  4512360,
  4512361,
  4512362,
  4512363,
  4512364,
  4512365,
  4512366,
  4512367,
  4512368,
  4512369,
  4512370,
  4512371,
  4512372,
  4512373,
  4512374,
  4682307,
  4682308,
  4682309,
  4682310,
  4682311,
  4682312,
  4682313,
  4682314,
  4682315,
  4682316,
  4704749,
  4704750,
  4704751,
  4704752,
  4704753,
  4704754,
  4704755,
  4704756,
  4704757,
  4704758,
  4919010,
  4919011,
  4919012,
  4919013,
  4919014,
  4919020,
  4919021,
  4919022,
  4919023,
  4919024,
  5146463,
  5146464,
  5146465,
  5146466,
  5146467,
  5146468,
  5146469,
  5146470,
  5146471,
  5146472,
  5146473,
  5146474,
  5146475,
  5146476,
  5146477,
  5503202,
  5503203,
  5503204,
  5503205,
  5503206,
];
