import React from 'react';
import Text, { ITextColors, ITextStyles } from '@/components/ui/content/Text';
import UserTalking from '@/components/ui/new-icons/UserTalking';
import { IContentColors, IContentTextSizeTypes } from '@/types';
// styles + types
import styles from './styles.module.scss';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { makeSelectBackstageTalkingUserByStageId } from '@/models/event';

const StreamTalkingUserInfo = ({ stageId }) => {
  const currentTalkingUserName = useMemoizedSelector(
    makeSelectBackstageTalkingUserByStageId,
    stageId,
  );

  if (!currentTalkingUserName) {
    return null;
  }

  return (
    <div className={styles.mainContainer}>
      <UserTalking size={13} color={IContentColors.WHITE} />
      <Text
        text={`${currentTalkingUserName}`}
        textStyle={ITextStyles.SEMI_BOLDED}
        textColor={ITextColors.WHITE}
        textSize={IContentTextSizeTypes.SMALL}
        className={styles.name}
      />
    </div>
  );
};

export default StreamTalkingUserInfo;
