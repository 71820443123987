import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { X } from 'react-feather';
import styles from '@/components/ui/modals/PromptsModal/styles.module.scss';

const SwitchSessionPromptsModal = ({
  show,
  setShow = () => {},
  onSwitch,
  title,
  disableClose = false,
  subTitle,
  rootId,
}: any) => {
  const ref = useRef();

  const modalRoot = rootId
    ? document.getElementById(rootId)
    : document.getElementById('modal') || document.getElementById('app-modal');

  return (
    <>
      {show &&
        modalRoot &&
        createPortal(
          <div id="switch-session-prompts-modal" className={styles.overlay}>
            <div className={styles.modal} ref={ref}>
              <div className={styles.container}>
                {!disableClose && (
                  <button
                    className={styles.modalClose}
                    type="button"
                    onClick={() => setShow(false)}
                  >
                    <X size={24} color="#cdcdcd" />
                  </button>
                )}
                <header className={styles.modalHeader}>
                  {title}
                  <div className={styles.subTitle}>
                    {subTitle.split('$n').map((item: string, key: number) => (
                      <div key={key.toString()}>{item}</div>
                    ))}
                  </div>
                </header>
                <div className={styles.buttonBody}>
                  <Button
                    label="Stay here"
                    styleClass={IButtonTypes.NO_BG_CONTRAST}
                    type="button"
                    onClick={() => setShow(false)}
                  />

                  <Button
                    styleClass={IButtonTypes.PRIMARY}
                    label="Yes, Switch"
                    onClick={onSwitch}
                    type="button"
                  />
                </div>
              </div>
            </div>
          </div>,
          modalRoot,
        )}
    </>
  );
};

export default SwitchSessionPromptsModal;
