import React from 'react';
import { useDispatch } from 'react-redux';

import styles from './styles.module.scss';

import api from '@/api';
import Text, { ITextColors, ITextSizes } from '@/components/ui/content/Text';
import ToggleField from '@/components/ui/NewFormComponents/ToggleField';
import ScreenShareOff from '@/components/ui/new-icons/ScreenShareOff';
import { useMediaQuery } from 'react-responsive';

interface IScreenSharePermission {
  channelSettings?: any;
}

const ScreenSharePermission = (props: IScreenSharePermission) => {
  const { channelSettings } = props;

  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const { hasScreenSharePerm } = channelSettings;

  const handleToggle = value => {
    api.channel
      .updateStageSettingsWithChannelId(channelSettings?.channelId, {
        hasScreenSharePerm: value,
        channelId: channelSettings?.channelId,
      })
      .catch(() => {
        dispatch({
          type: 'global/addDangerToast',
          payload: { description: 'Error saving changes' },
        });
      });
  };

  return (
    <div className={styles.optionWrapper}>
      <ScreenShareOff size={24} />
      <Text
        text="Everyone can share screen"
        textSize={isMobile ? ITextSizes.SMALL : ITextSizes.MEDIUM}
        textColor={ITextColors.WHITE}
      />
      <div className={styles.toggle}>
        <ToggleField
          value={hasScreenSharePerm}
          onChange={value => handleToggle(value)}
          widthInPx={42}
          customToggleContainerStyles="boxShadowUnsetToggle"
          label=""
        />
      </div>
    </div>
  );
};

export default ScreenSharePermission;
