import React, { useState, useEffect } from 'react';
// styles + types
import styles from './styles.module.scss';
import RecordingIcon from '@/components/ui/new-icons/Recording';
import api from '@/api';
import ReactTooltip from 'react-tooltip';
import { useParams } from 'react-router-dom';

const RecordingIndicatorBar = ({
  currentSession,
  showRecordingIndicator,
}) => {
  const { eventId = '' } = useParams<{
    eventId: string;
  }>();
  const [sessionLiveDuration, setSessionLiveDuration] = useState(null) as any;
  const [sessionTimer, setSessionTimer] = useState('');

  useEffect(() => {
    if (!currentSession) {
      return;
    }
    api.segment.getSegmentLiveDuration(eventId, currentSession.segmentId).then(res => {
      setSessionLiveDuration(res.data);
    });
  }, [currentSession]);

  useEffect(() => {
    if(sessionLiveDuration === null){
      return;
    }
    let iterationCount = 1;
    const intervalId = setInterval(() => {
      const diffInSeconds = sessionLiveDuration + iterationCount;
      const days = Math.floor(diffInSeconds / (3600*24));
      const hours = Math.floor((diffInSeconds / 60 / 60) % 24);
      const minutes = Math.floor((diffInSeconds / 60) % 60);
      const seconds = Math.floor(diffInSeconds % 60);
      let timeFormat = days > 0 ? `${`0${days}`.slice(-2)}:` : '';
      timeFormat += hours > 0 ? `${`0${hours}`.slice(-2)}:` : '';
      timeFormat += `${`0${minutes}`.slice(-2)}:`;
      timeFormat += `${`0${seconds}`.slice(-2)}`;
      setSessionTimer(timeFormat);
      iterationCount += 1;
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [sessionLiveDuration]);

  if (!sessionTimer) {
    return null;
  }

  return (
    <div className={styles.mainContainer}>
      {showRecordingIndicator && (
        <div data-tip data-for="tooltip_stage_recording">
          <RecordingIcon size={10} />
          <ReactTooltip
            id="tooltip_stage_recording"
            place="right"
            effect="solid"
          >
            This session is being recorded
          </ReactTooltip>
        </div>
      )}
      <div className={styles.timerLabel}>{sessionTimer}</div>
    </div>
  );
};

export default RecordingIndicatorBar;
