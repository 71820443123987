import { IStreamContainerDims } from '../../utils';
import {
  MAX_STREAMS_PER_PAGE as MAX_STREAMS_PER_PAGE_WHILE_SHARING,
  MAX_STREAMS_PER_PAGE_FOR_MOB as MAX_STREAMS_PER_PAGE_FOR_MOB_WHILE_SHARING,
} from './SharingView/config';

export const MAX_STREAMS_PER_PAGE = 6;

export const MAX_STREAMS_PER_PAGE_ON_TAB = 6;

export const MAX_STREAMS_PER_PAGE_ON_MOB = 4;

export const getMaxStreamsPerPage = (
  isMobile: boolean,
  isSharingView: boolean,
) => {
  if (isMobile) {
    return isSharingView
      ? MAX_STREAMS_PER_PAGE_FOR_MOB_WHILE_SHARING
      : MAX_STREAMS_PER_PAGE_ON_MOB;
  }

  return isSharingView
    ? MAX_STREAMS_PER_PAGE_WHILE_SHARING
    : MAX_STREAMS_PER_PAGE;
};

export const getLayoutDims = (
  config: IStreamContainerDims,
  isMobile: boolean,
) => {
  const { width, height, numRows: rows = 1, numCols: cols = 1 } = config;

  // Add margins to maintain ratios, remove later if leave button is moved
  const singleStreamMargins = 8;
  const baseWidth = isMobile
    ? width
    : width - (cols === 1 ? 0.2 * width : 0) - cols * singleStreamMargins;
  const baseHeight = isMobile
    ? 0.7 * height
    : height - (rows === 1 ? 0.2 * height : 0) - rows * singleStreamMargins;

  const newWidth = Math.floor(baseWidth) / cols;
  const newHeight = Math.floor(baseHeight) / rows;

  return {
    width: newWidth,
    height: newHeight,
  };
};
