import {
  IRaisedHandRequestStatus,
  IStageParent,
} from '@/components/Stage/types';
import { IUseChannelPresentationResponse } from '@/services/messaging/useChannelPresentation.types';

export interface IStreamOptionsBarProps {
  broadcastPermissions: boolean;
  channelMode: any;
  completeMyRaisedHandRequest: any;
  isStreamsEmpty: boolean;
  localClient: any;
  localStream: any;
  onBroadcastButtonClick: (bool: boolean) => void;
  onEmojiClick: (type: string) => void;
  onScreenShareButtonClick: () => void;
  onToggleRaiseHand: () => void;
  parent: IStageParent;
  raisedHandStatus?: IRaisedHandRequestStatus;
  setBroadcastPermissions: (bool: boolean) => void;
  setChatPanelOpen: (bool: boolean) => void;
  setShowTabIndex: (icon: any) => void;
  timeLeftMs: number;
  pendingScreen: boolean;
  hideStreamOptions: boolean;
  currentlyPlayingVideo: any;
  currentLiveStream: any;
  selectedTable?: any;
  hasGreenRoomStreams?: any;
  channelConfig?: any;
  moveUserToGreenRoom: any;
  showGreenRoomActionPanel?: boolean;
  channelSidePanelState: boolean;
  avControlsConfig: any;
  volumeOn: boolean;
  toggleVolume: any;
  showVolumeToggle: boolean;
  presentationConfig: IUseChannelPresentationResponse;
  openMediaTabInGreenroomPanel: () => void;
  toggleBoothLiveView: () => void;
  audienceGalleryRequestStatus: any;
  stageSessionSettings: any;
  completeMyAudienceGalleryRequest: any;
  contributorViewRequestStatus: any;
  completeMyContributorViewRequest: any;
  showDiscussionLeftPanel?: boolean;
  setToggleLeftPanel?: any;
  toggleLeftPanel?: boolean;
}

export enum IStreamOptionTypes {
  BROADCAST_BUTTON = 'broadcastButton',
  BROADCAST_PANEL_BUTTON = 'broadcastPanelButton',
  EMOJIS_BAR = 'emojisBar',
  EMOJIS_BAR_BUTTON = 'emojisBarButton',
  EXIT_BACKSTAGE_BUTTON = 'exitStageButton',
  EXIT_STREAM_BUTTON = 'exitStreamButton',
  RETURN_TO_BACKSTAGE_BUTTON = 'returnToBackstageButton',
  EXIT_ROOM_BUTTON = 'exitRoomButton',
  JOIN_BACKSTAGE_BUTTON = 'joinBackstageButton',
  JOIN_STAGE_BUTTON = 'joinStageButton',
  JOIN_STREAM_BUTTON = 'joinStreamButton',
  JOIN_STREAM_MODAL_BUTTON = 'joinStreamModalButton',
  MIC_TOGGLE = 'micToggle',
  SCREEN_OR_FILE_SHARE_TOGGLE = 'screenOrFileShareToggle',
  SPEAKER_TIMER = 'speakerTimer',
  VIDEO_TOGGLE = 'videoToggle',
  CIPLA_BUTTON1 = 'ciplaButton1',
  CIPLA_BUTTON2 = 'ciplaButton2',
  STREAM_SETTINGS_BAR = 'streamSettingsBar',
  VOLUME_TOGGLE = 'volumeToggle',
  STREAM_TITLE = 'streamTitle',
  STREAM_INFO_ROOM_NAME = 'streamInfoRoomName',
  STREAM_INFO_ROOM_DESC = 'streamInfoRoomDesc',
  STREAM_INFO_ROOM_NUM = 'streamRoomInfoNum',
  STREAM_INFO_ROOM_LOGO = 'streamRoomInfoLogo',
  SESSION_CHANGE_DROPDOWN = 'sessionChangeDropdown',
  SESSION_NAME_LABEL = 'sessionNameLabel',
  BROADCAST_RECORDING_BUTTON = 'broadcastRecordingButton',
  BROADCAST_PAUSE_RESUME_BUTTON = 'broadcastPauseResumeButton',
  NEW_STREAM_LEAVE = 'newStreamLeave',
  BROADCAST_DRY_RUN_PANEL_BUTTON = 'broadcastDryRunPanelButton',
  RECORDING_INDICATOR_BAR = 'recordingIndicatorBar',
  RETURN_TO_EXPO_BOOTH = 'returnToExpoBooth',
  EXIT_EXPO_BOOTH = 'exitExpoBooth',
  STREAM_USER_TALKING_INFO_VIEW = 'streamUserTalkingInfoView',
  VIRTUAL_BACKGROUND_BUTTON = 'virtualBackgroundButton',
  RAISED_HAND_BUTTON = 'raisedHandButton',
  MORE_OPTIONS_BTN = 'moreOptionsBtn',
  TRACK_SELECTION = 'trackSelection',
}
