// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__objectFitContain___4OZdP video{object-fit:contain !important}.styles-module__centerStageContainer___1umvl{position:absolute;top:20%;left:19.2%}.styles-module__centerPrimaryContainer___2D9W1{position:absolute;top:20%;left:20%}.styles-module__centerTwoContainerStreamOne___2g7um{position:absolute;top:27.7%;left:6.19%}.styles-module__centerTwoContainerStreamTwo___2WM_v{position:absolute;top:27.7%;left:51.04%}.styles-module__centerThreeContainerStreamOne___1qrzZ{position:absolute;top:27.7%;left:6.19%}.styles-module__centerThreeContainerStreamTwo___15PGh{position:absolute;top:27.7%;left:35.72%}.styles-module__centerThreeContainerStreamThree___2RNdr{position:absolute;top:27.7%;left:65.26%}.styles-module__centerSecondaryStreamsContainer___2Btxn{position:absolute;top:81%;left:0;width:100%;display:flex;flex-direction:row;justify-content:center;align-items:center}.styles-module__secondaryStream___2kk9B{margin-right:2px}\n", ""]);
// Exports
exports.locals = {
	"objectFitContain": "styles-module__objectFitContain___4OZdP",
	"centerStageContainer": "styles-module__centerStageContainer___1umvl",
	"centerPrimaryContainer": "styles-module__centerPrimaryContainer___2D9W1",
	"centerTwoContainerStreamOne": "styles-module__centerTwoContainerStreamOne___2g7um",
	"centerTwoContainerStreamTwo": "styles-module__centerTwoContainerStreamTwo___2WM_v",
	"centerThreeContainerStreamOne": "styles-module__centerThreeContainerStreamOne___1qrzZ",
	"centerThreeContainerStreamTwo": "styles-module__centerThreeContainerStreamTwo___15PGh",
	"centerThreeContainerStreamThree": "styles-module__centerThreeContainerStreamThree___2RNdr",
	"centerSecondaryStreamsContainer": "styles-module__centerSecondaryStreamsContainer___2Btxn",
	"secondaryStream": "styles-module__secondaryStream___2kk9B"
};
module.exports = exports;
