import { IStreamContainerDims } from "../../utils";

export const getRazorpayGridLayoutDims = (config: IStreamContainerDims, numStreams: number) => {
  const { height, width } = config;

  if (numStreams === 1) {
    return [{
      height: height * 426 / 720,
      width: width * 689 / 1280
    }];
  }

  if (numStreams === 2) {
    return [{
      height: height * 426 / 720,
      width: width * 330 / 1280
    }, {
      height: height * 426 / 720,
      width: width * 330 / 1280
    }];
  }

  if (numStreams === 3) {
    return [{
      height: height * 258 / 720,
      width: width * 387 / 1280
    }, {
      height: height * 258 / 720,
      width: width * 387 / 1280
    }, {
      height: height * 258 / 720,
      width: width * 491 / 1280
    }];
  }

  if (numStreams === 4) {
    return [{
      height: height * 265 / 720,
      width: width * 426 / 1280
    }, {
      height: height * 265 / 720,
      width: width * 426 / 1280
    }, {
      height: height * 265 / 720,
      width: width * 426 / 1280
    }, {
      height: height * 265 / 720,
      width: width * 426 / 1280
    }];
  }

  if (numStreams === 5) {
    return [{
      height: height * 258 / 720,
      width: width * 321 / 1280
    }, {
      height: height * 258 / 720,
      width: width * 321 / 1280
    }, {
      height: height * 258 / 720,
      width: width * 321 / 1280
    }, {
      height: height * 248 / 720,
      width: width * 396 / 1280
    }, {
      height: height * 248 / 720,
      width: width * 396 / 1280
    }];
  }

  if (numStreams >= 6) {
    return new Array(numStreams).fill({
      height: height * 258 / 720,
      width: width * 321 / 1280
    })
  }
};
