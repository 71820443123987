import { EPresentationLayoutVariant, IDims } from '../types';

export const getContainerHeight = ({
  parentHeight,
  isTablet,
  isMobile,
  isSidePanelOpen,
  layoutVariant,
}) => {
  // Account for address bar, floating A/V controls on phones
  const mobileParentHeight = parentHeight * 0.8;
  if (isMobile) {
    return layoutVariant === EPresentationLayoutVariant.SLIDER
      ? 0.575 * mobileParentHeight
      : 0.5 * mobileParentHeight;
  }

  if (isTablet) {
    return isSidePanelOpen ? 0.7 * parentHeight : 0.75 * parentHeight;
  }

  return 0.8 * parentHeight;
};

export const getContainerWidth = (
  parentWidth: number,
  isTablet: boolean,
  isMobile: boolean,
) => {
  const parentWidthExclMargins = parentWidth - 8;
  if (isMobile) {
    // Exclude margin
    return parentWidthExclMargins;
  }

  if (isTablet) {
    return 0.71 * parentWidthExclMargins;
  }

  // ~75% of available space for shared stream
  return 0.715 * parentWidthExclMargins;
};

export const getSharedFileLayoutDims = ({
  dims,
  isTablet,
  isMobile,
  isSidePanelOpen,
}) => {
  const { width, height } = dims;

  // Presentation dims gets calculated from height
  if (isMobile) {
    return {
      width,
      height: height * 0.7,
    };
  }
  if (isTablet) {
    return {
      width,
      height: isSidePanelOpen ? height * 0.7 : height * 0.87,
    };
  }
  return {
    width,
    height: isSidePanelOpen ? height * 0.8 : height * 0.975,
  };
};

export const getSharedPluginLayoutDims = (
  dims: IDims,
  isTablet: boolean,
  isMobile: boolean,
  isSidePanelOpen: boolean,
  layoutVariant: EPresentationLayoutVariant,
) => {
  const { width, height } = dims;

  if (isMobile) {
    return {
      width: width * 0.92,
      height:
        layoutVariant === EPresentationLayoutVariant.SLIDER
          ? height * 0.55
          : height * 0.5,
    };
  }
  if (isTablet) {
    return {
      width: isSidePanelOpen ? width * 0.68 : width * 0.65,
      height: height * 0.925,
    };
  }
  return {
    width: isSidePanelOpen ? width * 0.68 : width * 0.65,
    height: height * 0.9,
  };
};
