export interface IStreamLabelProps {
  companyName?: string;
  greenRoom: boolean;
  nasscomScreenShare?: boolean;
  headline?: string;
  bio?: string;
  hideNameBadge?: boolean;
  isOrganizerStream: boolean;
  name: string;
  showCondensed: boolean;
  showNasscomLabel?: boolean;
  showGradientLabel?: boolean;
  showMutedStatus: boolean;
  volumeLevel: EVolumeLevels;
  showVolumeIndicator: boolean;
  hideHeadline: boolean;
}

export enum EVolumeLevels {
  NONE = 0,
  LOW = 1,
  HIGH = 2,
}
