import React, { useState, useEffect } from 'react';
import KeynoteLayout from './KeynoteLayout';
import SingleStreamLayout from './SingleStreamLayout';
import GridLayout from './GridLayout';
import SpotlightLayout from './SpotlightLayout';
import produce from 'immer';
import styles from './styles.module.scss';
import { StreamType } from '@/components/ChannelStreamsContainer/types';

const BottomSponsorLayout = props => {
  const {
    primaryStream,
    secondaryStreams,
    streams,
    parentHeight: originalParentHeight,
    parentWidth,
    parentLeftPosition,
    parentTopPosition,
    streamDetailsMap,
    volumeOn,
    greenRoom,
    userMoveFns,
    isStageFull,
    parent,
    streamNamesMap,
    localClientAVStatus,
  } = props;
  const containerHeight = originalParentHeight * (1 - 0.214);
  const [layoutConfig, setLayoutConfig] = useState({
    hasScreenShare: false,
    isGridMode: true,
    pinnedUid: null,
  });
  const { hasScreenShare, isGridMode } = layoutConfig;

  useEffect(() => {
    const hasAnyScreenStream =
      primaryStream &&
      streamDetailsMap[primaryStream.uid] &&
      [StreamType.SCREEN, StreamType.SCREEN_RELAY].includes(
        streamDetailsMap[primaryStream.uid].type,
      );

    setLayoutConfig(
      produce(draft => {
        draft.hasScreenShare = hasAnyScreenStream;
        draft.isGridMode = streams.length <= 3;
      }),
    );
  }, [streams, primaryStream, secondaryStreams, streamDetailsMap]);

  const innerLayoutProps = {
    ...props,
    parentHeight: containerHeight,
  };
  const StreamElement = (() => {
    if (hasScreenShare) {
      console.log('debugLayout > KeynoteLayout');
      return <KeynoteLayout {...innerLayoutProps} />;
    }

    if (streams.length === 1) {
      console.log('debugLayout > SingleStreamLayout');
      return <SingleStreamLayout {...innerLayoutProps} />;
    }

    if (isGridMode) {
      console.log('debugLayout > GridLayout');
      return <GridLayout {...innerLayoutProps} />;
    }

    console.log('debugLayout > SpotlightLayout');
    return <SpotlightLayout {...innerLayoutProps} />;
    // (is anyone pinned, if not then active speaker detection)
  })();

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: parentTopPosition,
          left: parentLeftPosition,
          width: parentWidth,
          height: containerHeight,
        }}
        className={styles.bottomSponsorContainer}
      >
        {StreamElement}
      </div>
    </>
  );
};

export default BottomSponsorLayout;
