import React, { useMemo, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import screenfull from 'screenfull';
// components
import StreamLabel from './StreamLabel';
// styles + types
import styles from './styles.module.scss';
import { ISingleStreamPlayerProps } from './types';
// helpers
import {
  handleCustomVideoStreamPlay,
  handleAudioStreamPlay,
  isLiveEventOrganizer,
  getBackstageToStageAVStatus,
  getFirstNameLastName,
} from '@/utils/helpers';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import {
  makeSelectIsOrganizer,
  makeSelectRoomsOwnerAccIds,
} from '@/models/event';
import IconButton, { IUIIcons } from '@/components/ui/buttons/IconButton';
import {
  IIconButtonTypes,
  TooltipTypes,
} from '@/components/ui/buttons/IconButton/types';
import { IStageParent, StreamType } from '@/components/Stage/types';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAudioPlaybackDeviceId,
  selectIsStageFull,
  selectRecordingMode,
  selectStageVideoQuality,
} from '@/models/global';
import { VJ_15FPS, VJ_STREAM_IDS } from '@/config/vj';
import {
  BACKSTAGE_TO_LIVE_STAGE_FLOW,
  getBackStageToLiveStageIntro,
  RAISE_HAND_TO_BACKSTAGE_FLOW,
  getRaiseHandToBackStageIntro,
  RAISE_HAND_TO_BACKSTAGE_STEPS,
} from '@/components/onboarding/stageFlows';
import { makeSelectShowFlow, makeSelectUserAccountId } from '@/models/account';
import ConfirmExitModal from '@/components/PublishedStreamDisplay/StreamOptionsBar/ConfirmExitModal';
import ConfirmExitPromptsModal from '@/components/PublishedStreamDisplay/StreamOptionsBar/ConfirmExitPromptsModal';
import api from '@/api';
import StreamActiveUser from './StreamActiveUser';
import useAudioVolume from '@/services/video/useAudioVolume';
import { VideoQualityTypes } from '@/components/PublishedStreamDisplay/StreamSettingsBar/utils';
import {
  isChrome,
  isChromium,
  isIOS,
  isIOS13,
  isIPad13,
  isMobile,
  isMobileOnly,
  isMobileSafari,
  isSafari,
} from 'react-device-detect';
import GenericErrorBoundary from '@/components/GenericErrorBoundary';
import { Maximize } from 'react-feather';
import { useRemoteStreamDispatch } from '@/services/video/use-remote-stream-control';
import { useChannelContext } from '@/services/video/channel-context/channel-context';
import IconTextButton, {
  IIconTextButtonTypes,
} from '@/components/ui/buttons/IconTextButton';
import { IContentColors, IContentTextSizeTypes } from '@/types';
import { EMOJI_ICON_SIZE } from '@/styles/iconSizes';
import { INewLabelStyles } from '@/components/ui/content/NewLabel';
import Text, { ITextColors } from '@/components/ui/content/Text';
import RemoteUserControls from './RemoteUserControls/index';
import RaisedHandRequestIndicator from './RaisedHandRequestIndicator';
import useIsOrganizerOrRoomOwner from '@/hooks/events/useIsOrganizerOrRoomOwner';
import useOutsideClick from '@/hooks/use-outside-click';

const SingleStreamPlayer = React.memo(
  (props: ISingleStreamPlayerProps) => {
    const {
      // onDoubleClick,
      volumeOn,
      className,
      containerStyles,
      greenRoom,
      nasscomScreenShare,
      hideNameBadgeBase,
      name = '',
      noBorder = false,
      onClick,
      parent,
      showNasscomLabel,
      stream,
      streamDetails,
      userMoveFns,
      noMargin,
      showGradientLabel,
      localClientAVStatus = { audio: false, video: false },
      localClient,
      liveRaisedHandRequestList,
      onScreenMount,
    } = props;
    const { floatingToast, channelConfig } = useChannelContext();
    const {
      stopRemoteUserScreenShare,
      muteRemoteUser,
      unmuteRequestRemoteUser,
      videoOffRemoteUser,
      videoOnRequestRemoteUser,
    } = useRemoteStreamDispatch({
      channelConfig,
      showFloatingToast: floatingToast?.showFloatingToast,
    });

    const singleStreamHasVjAccess: boolean =
      // Check if stream ID is present in the vj.ts or configured in the db.
      VJ_STREAM_IDS.includes(stream.uid) ||
      VJ_15FPS.includes(stream.uid) ||
      streamDetails?.hasVjAccess === true;

    const containerRef = useRef();
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
      if (!isMobile) {
        return;
      }
      setShowMenu(prev => !prev);
    };

    const dispatch = useDispatch();

    const hideNameBadge =
      parent === IStageParent.CONTRIBUTOR_SLOT ||
      singleStreamHasVjAccess ||
      hideNameBadgeBase;
    // Show name badge regardless of the container dimensions [BUG-521, 522]
    // || containerStyles.height < 120 ||
    // containerStyles.width < 120;

    const {
      handleUserKickout,
      moveUserToStage,
      moveUserToGreenRoom,
      completeRaisedHandRequest,
    } = userMoveFns;

    const isBorderless =
      parent === IStageParent.STAGE_PREVIEW ||
      parent === IStageParent.CONTRIBUTOR_SLOT ||
      ([IStageParent.BOOTH, IStageParent.BOOTH_OWNER].includes(parent) &&
        (containerStyles.height < 170 || containerStyles.width < 310));

    const videoContainerRef = useRef();
    const isScreenShareStream =
      name && name.toLowerCase().includes('screenshare');

    const handleStreamDoubleClick = () => {
      if(isScreenShareStream && stream.isLocal) return;
      try {
        const videoContainer = document.getElementById(
          `single-stream-${stream.uid}`,
        );
        const videoList = videoContainer?.getElementsByTagName('video');
  
        const video = videoList && videoList[0];
  
        if ((isSafari || isMobileSafari) && isIOS && isMobileOnly) {
          if (video?.webkitEnterFullScreen) {
            video.webkitEnterFullScreen();
            return;
          }
        }
        if (!videoContainerRef || !videoContainerRef.current) {
          return;
        }
        if (screenfull.isEnabled) {
          screenfull.toggle(videoContainerRef.current);
        }
      } catch (e) {
        console.error('debugScreenfull > ', e);
      }
    };

    const isStageFull = useSelector(selectIsStageFull);
    const audioPlaybackDeviceId = useSelector(selectAudioPlaybackDeviceId);
    const isRecordingMode = useSelector(selectRecordingMode);

    const {
      eventId = '',
      stageId = '',
      discussionTableId = '',
    } = useParams() as any;

    const { uid, audioTrack, videoTrack } = stream;
    const { height, width } = containerStyles;
    let newContainerStyles;

    // hack to add spacing between streams
    // the additional 16px will be 8 px will be split margin of 4px on all sides
    if (greenRoom || parent === IStageParent.LIVE_DISCUSSION) {
      newContainerStyles = {
        height: noBorder ? height : height - 16,
        width: noBorder ? width : width - 16,
      };
    } else {
      newContainerStyles = {
        height: noBorder ? height : height - 8,
        width: noBorder ? width : width - 8,
      };
    }

    if (hideNameBadge || isBorderless) {
      newContainerStyles.border = 'none';
    }

    const isOrganizer = useMemoizedSelector(makeSelectIsOrganizer, eventId);
    const selectedVideoQuality = useSelector(selectStageVideoQuality);
    let hideVideoStreams =
      (parent === IStageParent.LIVE_STAGE ||
        parent === IStageParent.LIVE_BACKSTAGE) &&
      selectedVideoQuality === VideoQualityTypes.AUDIO_ONLY &&
      !stream.isLocal &&
      !isScreenShareStream;

    const isLocalStreamVideoDisabled =
      stream.isLocal && !localClientAVStatus.video;

    hideVideoStreams = hideVideoStreams || isLocalStreamVideoDisabled;

    useOutsideClick(containerRef, () => setShowMenu(false));

    useEffect(() => {
      if (isScreenShareStream) {
        // TODO: handle this more robustly
        return handleCustomVideoStreamPlay(videoTrack, `single-stream-${uid}`, {
          fit: 'contain',
        });
      }
      return handleCustomVideoStreamPlay(
        videoTrack,
        `single-stream-${uid}`,
        {},
      );
    }, [videoTrack, isScreenShareStream]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
      if (parent === IStageParent.AUDIENCE_GALLERY) {
        return;
      }

      if (!stream.isLocal) {
        // eslint-disable-next-line consistent-return
        return handleAudioStreamPlay(
          audioTrack,
          volumeOn,
          audioPlaybackDeviceId,
        );
      }
    }, [audioTrack, volumeOn, audioPlaybackDeviceId]);

    const stageRaiseHandShowFlow = useSelector(
      useMemo(() => makeSelectShowFlow(BACKSTAGE_TO_LIVE_STAGE_FLOW), []),
    );
    const [introInProgress, setIntroInProgress] = useState(false);
    const stageRaiseHandOnboardingObject = useMemo(
      () =>
        getBackStageToLiveStageIntro(updatedFlowState => {
          dispatch({
            type: 'account/updateUserFlowProgress',
            payload: updatedFlowState,
          });
          setIntroInProgress(false);
        }),
      [],
    );

    useEffect(() => {
      if (
        !(
          !introInProgress &&
          stageRaiseHandShowFlow &&
          isOrganizer &&
          parent === IStageParent.LIVE_BACKSTAGE &&
          isMobileOnly
        )
      )
        return;
      const introTimer = setTimeout(() => {
        setIntroInProgress(true);
        stageRaiseHandOnboardingObject.start();
      }, 1000);
      return () => clearTimeout(introTimer);
    }, [isOrganizer, stageRaiseHandShowFlow, parent]);

    const selectShowBackStageFlow = useMemo(
      () => makeSelectShowFlow(RAISE_HAND_TO_BACKSTAGE_FLOW),
      [],
    );
    const showbackStageFlow = useSelector(selectShowBackStageFlow);
    const [introBackStageInProgress, setIntroBackStageInProgress] =
      useState(false);
    const introBackStageObject = useMemo(
      () =>
        getRaiseHandToBackStageIntro(updatedFlowState => {
          dispatch({
            type: 'account/updateUserFlowProgress',
            payload: updatedFlowState,
          });
          setIntroBackStageInProgress(false);
        }),
      [],
    );

    useEffect(() => {
      setTimeout(() => {
        if (
          !introBackStageInProgress &&
          showbackStageFlow &&
          isOrganizer &&
          !greenRoom &&
          parent !== IStageParent.LIVE_DISCUSSION &&
          !isMobileOnly
        ) {
          const backToSatgeButton = document.getElementById(
            RAISE_HAND_TO_BACKSTAGE_STEPS.HAND,
          );
          if (backToSatgeButton) {
            setIntroBackStageInProgress(true);
            introBackStageObject.start();
          }
        }
      }, 1000);
    }, [showbackStageFlow, isOrganizer, greenRoom]);

    useEffect(
      () => () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        stageRaiseHandOnboardingObject &&
          parent === IStageParent.LIVE_BACKSTAGE &&
          stageRaiseHandOnboardingObject.exit(true);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        introBackStageObject && introBackStageObject.exit(true);
      },
      [],
    );

    useEffect(() => {
      // clearing current user talking object while stream is out
      return () => {
        dispatch({
          type: 'event/setBackstageTalkingUserInfo',
          payload: {
            stageId: stageId,
            name: null,
          },
        });
      };
    }, []);

    const role = (streamDetails && streamDetails.role) || '';
    const headline = (streamDetails && streamDetails.headline) || '';
    const bio = (streamDetails && streamDetails.bio) || '';

    const isOrganizerStream = isLiveEventOrganizer(role);
    const streamAccountId = (streamDetails && streamDetails.accountId) || '';
    const currentUserAccountId = useMemoizedSelector(makeSelectUserAccountId);
    const isLiveRaisedHandStream = liveRaisedHandRequestList?.find(
      (req: any) => req.accountId === streamAccountId,
    );
    const isOrganizerOrRoomOwner = useIsOrganizerOrRoomOwner(
      eventId,
      discussionTableId,
    );

    const roomOwners = useMemoizedSelector(
      makeSelectRoomsOwnerAccIds,
      discussionTableId,
    );
    const isRoomOwner = useMemo(() => {
      if (roomOwners) {
        return roomOwners?.some(o => o === currentUserAccountId);
      }

      return false;
    }, [roomOwners, currentUserAccountId]);

    const handleAvReminder = () => {
      if (currentUserAccountId === streamAccountId) {
        dispatch({
          type: 'global/setMessageModal',
          payload: {
            title: 'Please turn on your audio/video to go to the stage',
          },
        });
        return;
      }
      api.stage.remindSpeakerToTurnOnAv(stageId, streamAccountId);
      dispatch({
        type: 'global/setMessageModal',
        payload: {
          title: `You cannot push ${name} to stage as their audio and video is turned off.`,
          subtitle: 'Please ask them to turn their audio and video on.',
        },
      });
    };

    const handleToggleMuteRemoteUser = () => {
      if (!streamDetails) return;
      if (isAudioEnabled) {
        muteRemoteUser(streamDetails.accountId, streamDetails.name);
      } else {
        unmuteRequestRemoteUser(streamDetails.accountId);
      }
    };

    const handleToggleVideoRemoteUser = () => {
      if (!streamDetails) return;
      if (isVideoEnabled) {
        videoOffRemoteUser(streamDetails.accountId, streamDetails.name);
      } else {
        videoOnRequestRemoteUser(streamDetails.accountId);
      }
    };

    const kickUserOut = () => {
      if (isLiveRaisedHandStream) {
        completeRaisedHandRequest(streamAccountId);
        return;
      }
      handleUserKickout(stream.uid);
    };

    const handleMoveUserToStage = () => {
      moveUserToStage(stream.uid);
    };

    const handleMoveUserToGreenRoom = () => {
      moveUserToGreenRoom(stream.uid);
    };

    const handleStopRemoteUserScreenShare = () => {
      if (streamDetails?.accountId) {
        stopRemoteUserScreenShare(streamDetails.accountId);
      }
    };

    const showUserControls = parent === IStageParent.LIVE_BACKSTAGE;
    // Please check usages of this const before changing the word "yourself"
    const confirmationNameText = stream.isLocal ? 'yourself' : name;

    // Check if video and audio is enabled in local or remote streams
    const isVideoEnabled = useMemo(() => {
      if (stream.isLocal) {
        return localClientAVStatus.video;
      }
      return Boolean(videoTrack);
    }, [stream.isLocal, localClientAVStatus, videoTrack]);

    const isVideoTrackPublished = () => {
      if (stream.isLocal) {
        return localClientAVStatus.video;
      }
      if (!localClient) return isVideoEnabled;
      const channelUser = localClient
        .getMainChannelUsers()
        ?.find(item => item?.uid === stream.uid);
      return Boolean(channelUser?.hasVideo);
    };
    const isAudioEnabled = useMemo(() => {
      if (stream.isLocal) {
        return localClientAVStatus.audio;
      }
      return Boolean(audioTrack);
    }, [stream.isLocal, localClientAVStatus, audioTrack]);

    // Check if it's a screen share stream
    const isScreenSharedStream =
      streamDetails?.type === StreamType.SCREEN ||
      streamDetails?.type === StreamType.SCREEN_RELAY;

    const showStopScreenShareOption =
      !stream.isLocal &&
      isOrganizerOrRoomOwner &&
      isScreenSharedStream &&
      parent === IStageParent.LIVE_DISCUSSION;

    useEffect(() => {
      let timeout: NodeJS.Timeout;
      if (isScreenSharedStream) {
        timeout = setTimeout(() => {
          const activeScreenShareUserDiv = document.getElementById(
            `activeScreenSharingUser-${uid}`,
          );
          if (activeScreenShareUserDiv) {
            activeScreenShareUserDiv.style.visibility = 'hidden';
          }
        }, 10 * 1000);

        return () => {
          clearTimeout(timeout);
        };
      }

      return undefined;
    }, []);

    useEffect(() => {
      if (!onScreenMount) {
        return;
      }

      isScreenSharedStream && !isMobile && onScreenMount?.();
    }, []);

    useEffect(() => {
      if (!onScreenMount) {
        return;
      }

      isScreenShareStream && !isMobile && onScreenMount?.();
    }, []);

    // Show active user indicator
    const { currentVolumeLevel, hasActiveVolume } = useAudioVolume({
      audioTrack,
      isLocalStream: stream.isLocal,
      isLocalAudioEnabled: localClientAVStatus.audio,
    });

    const backstageToStageModalText = useMemo(
      () =>
        getBackstageToStageAVStatus(
          isAudioEnabled,
          isVideoEnabled,
          confirmationNameText,
        ),
      [isAudioEnabled, isVideoEnabled, confirmationNameText],
    );

    const showRemoteUserControls =
      isOrganizerOrRoomOwner &&
      !stream.isLocal &&
      !isScreenSharedStream &&
      parent === IStageParent.LIVE_DISCUSSION;
    const bothAvIsOff = !isVideoEnabled && !isAudioEnabled;
    // Show active volume border when the attendee is on stage and expo
    const showVolumeIndicator =
      !videoTrack ||
      (parent !== IStageParent.LIVE_STAGE &&
        parent !== IStageParent.CONTRIBUTOR_SLOT &&
        parent !== IStageParent.STAGE_PREVIEW);

    if (!videoTrack && name.length > 0) {
      return (
        <div
          className={classnames(
            className,
            styles.blackBackground,
            styles.singleStreamPlayer,
            {
              [styles.streamContainerNoMargin]: noMargin,
              [styles.streamContainer]: !noBorder,
              [styles.streamContainerNoBorder]: noBorder,
              [styles.stagePreview]: isBorderless,
            },
          )}
          style={newContainerStyles}
          ref={containerRef}
          onClick={toggleMenu}
        >
          {!isScreenSharedStream && (
            <RaisedHandRequestIndicator accountId={streamDetails?.accountId} />
          )}
          <div
            className={classnames({
              [styles.videoElementFull]: isStageFull && !greenRoom,
              // Show active volume
              [styles.videoActiveSpeakerElement]:
                hasActiveVolume && showVolumeIndicator,
              // Fallback
              [styles.videoElement]:
                !hasActiveVolume || (hasActiveVolume && !showVolumeIndicator),
            })}
          ></div>
          {isOrganizer &&
            !isBorderless &&
            parent !== IStageParent.CONTRIBUTOR_SLOT && (
              <>
                <div
                  className={classnames(styles.kickout, {
                    [styles.greenRoomKickout]: greenRoom,
                  })}
                >
                  {!stream.isLocal && showUserControls && (
                    <ConfirmExitModal
                      title='Remove this user?'
                      trigger={({ setShow }) => (
                        <IconButton
                          key="kickoutUser"
                          activeIcon={IUIIcons.STREAM_LEAVE}
                          defaultIcon={IUIIcons.STREAM_LEAVE}
                          onClick={() => setShow(true)}
                          showDefault={true}
                          type={IIconButtonTypes.ROUND_DARK_GREY_WHITE}
                          tooltip={TooltipTypes.right}
                        />
                      )}
                      handleConfirm={({ setShow }) => {
                        kickUserOut();
                        setShow(false);
                      }}
                      exitText={`${confirmationNameText} will be removed from this room.`}
                      confirmButtonText="Remove"
                    />
                  )}
                  {greenRoom && showUserControls && (
                    <ConfirmExitPromptsModal
                      trigger={({ setShow }) => (
                        <IconButton
                          key="toStageButton"
                          activeIcon={IUIIcons.HAND_RAISE}
                          defaultIcon={IUIIcons.HAND_RAISE}
                          onClick={() => setShow(true)}
                          showDefault={true}
                          type={IIconButtonTypes.ROUND_DARK_GREY_WHITE}
                          tooltip={TooltipTypes.right}
                        />
                      )}
                      handleConfirm={({ setShow }) => {
                        handleMoveUserToStage();
                        setShow(false);
                      }}
                      title={backstageToStageModalText.title}
                      exitText={backstageToStageModalText.description}
                      confirmButtonText="Yes"
                    />
                  )}
                  {!greenRoom && showUserControls && (
                    <ConfirmExitModal
                      trigger={({ setShow }) => (
                        <IconButton
                          key="toGreenRoomButton"
                          activeIcon={IUIIcons.HAND_LOWER}
                          defaultIcon={IUIIcons.HAND_LOWER}
                          onClick={() => setShow(true)}
                          showDefault={true}
                          type={IIconButtonTypes.ROUND_DARK_GREY_WHITE}
                          tooltip={TooltipTypes.right}
                        />
                      )}
                      handleConfirm={({ setShow }) => {
                        // TODO check hasVideo here
                        if (!isVideoTrackPublished()) {
                          kickUserOut();
                        } else {
                          handleMoveUserToGreenRoom();
                        }
                        setShow(false);
                      }}
                      exitText={`Are you sure you want to remove ${confirmationNameText} from stage?`}
                      confirmButtonText="Yes"
                    />
                  )}
                </div>
                <div className={styles.muteUser}>
                  {!stream.isLocal && isAudioEnabled && showUserControls && (
                    <ConfirmExitModal
                      trigger={({ setShow }) => (
                        <IconButton
                          key="muteRemoteUserButton"
                          tooltip="Mute User"
                          activeIcon={IUIIcons.NEW_MIC_OFF}
                          defaultIcon={IUIIcons.NEW_MIC_OFF}
                          onClick={() => setShow(true)}
                          showDefault={true}
                          type={IIconButtonTypes.ROUND_PRIMARY}
                        />
                      )}
                      handleConfirm={({ setShow }) => {
                        handleToggleMuteRemoteUser();
                        setShow(false);
                      }}
                      exitText={`Are you sure you want to mute ${confirmationNameText}?`}
                      confirmButtonText="Yes"
                    />
                  )}
                </div>
              </>
            )}
          {showRemoteUserControls && (
            <RemoteUserControls
              isAudioEnabled={isAudioEnabled}
              isVideoEnabled={isVideoEnabled}
              isAScreenShare={false}
              toggleMuteRemoteUser={handleToggleMuteRemoteUser}
              toggleVideoRemoteUser={handleToggleVideoRemoteUser}
              kickUserOut={kickUserOut}
              name={streamDetails?.name ?? ''}
              showMenu={showMenu}
            />
          )}
          {!isVideoEnabled && (
            <StreamActiveUser
              user={streamDetails}
              hasActiveVolume={hasActiveVolume}
              containerStyles={containerStyles}
              showVolumeIndicator={showVolumeIndicator}
            />
          )}
          <GenericErrorBoundary>
            <StreamLabel
              headline={headline}
              bio={bio}
              hideNameBadge={hideNameBadge || isBorderless}
              isOrganizerStream={isOrganizerStream}
              name={name}
              showCondensed={width < 400 || height < 200}
              showNasscomLabel={showNasscomLabel}
              showGradientLabel={showGradientLabel}
              greenRoom={greenRoom}
              nasscomScreenShare={nasscomScreenShare}
              showMutedStatus={isAudioEnabled}
              showActiveVolume={hasActiveVolume}
              volumeLevel={currentVolumeLevel}
              showVolumeIndicator={showVolumeIndicator}
              hideHeadline={height < 100}
            />
          </GenericErrorBoundary>
          {/* <Heading
            text={`${firstInitial}${lastInitial}`}
            headingSize={isBorderless ? IHeadingSizes.XXSMALL : IHeadingSizes.LARGE}
            headingColor={IHeadingColors.WHITE}
          /> */}
        </div>
      );
    }

    const isMobileIOSChrome =
      isMobile && (isIOS || isIOS13 || isIPad13) && (isChrome || isChromium);
    const showScreenShareFullscreenIcon =
      !isRecordingMode &&
      isScreenShareStream &&
      !stream.isLocal &&
      !isMobileIOSChrome;

    return (
      <div
        className={classnames(className, styles.singleStreamPlayer, {
          [styles.streamContainerNoMargin]: noMargin,
          [styles.streamContainer]: !noBorder,
          [styles.streamContainerNoBorder]: noBorder,
          [styles.stagePreview]: isBorderless,
        })}
        style={newContainerStyles}
        ref={containerRef}
        onClick={toggleMenu}
      >
        {!isScreenSharedStream && (
          <RaisedHandRequestIndicator accountId={streamDetails?.accountId} />
        )}
        {isOrganizer &&
          !isBorderless &&
          parent !== IStageParent.CONTRIBUTOR_SLOT && (
            <>
              <div
                className={classnames(styles.kickout, {
                  [styles.greenRoomKickout]: greenRoom,
                })}
              >
                {!stream.isLocal && showUserControls && (
                  <ConfirmExitModal
                    title='Remove this user?'
                    trigger={({ setShow }) => (
                      <IconButton
                        key="kickoutUser"
                        activeIcon={IUIIcons.STREAM_LEAVE}
                        defaultIcon={IUIIcons.STREAM_LEAVE}
                        onClick={() => setShow(true)}
                        showDefault={true}
                        type={IIconButtonTypes.ROUND_DARK_GREY_WHITE}
                        tooltip={TooltipTypes.right}
                      />
                    )}
                    handleConfirm={({ setShow }) => {
                      kickUserOut();
                      setShow(false);
                    }}
                    exitText={`${confirmationNameText} will be removed from this room.`}
                    confirmButtonText="Remove"
                  />
                )}
                {greenRoom && showUserControls && (
                  <ConfirmExitPromptsModal
                    cancelButtonText='Cancel'
                    trigger={({ setShow }) => (
                      <IconButton
                        id="hand-raise-to-stage"
                        key="toStageButton"
                        activeIcon={IUIIcons.HAND_RAISE}
                        defaultIcon={IUIIcons.HAND_RAISE}
                        onClick={() => setShow(true)}
                        showDefault={true}
                        type={IIconButtonTypes.ROUND_DARK_GREY_WHITE}
                        tooltip={TooltipTypes.right}
                      />
                    )}
                    handleConfirm={({ setShow }) => {
                      handleMoveUserToStage();
                      setShow(false);
                    }}
                    handleCancel={(e, { setShow }) => {
                      setShow(false);
                    }}
                    title={backstageToStageModalText.title}
                    exitText={backstageToStageModalText.description}
                    confirmButtonText="Yes"
                  />
                )}
                {!greenRoom && showUserControls && !isLiveRaisedHandStream && (
                  <ConfirmExitModal
                    trigger={({ setShow }) => (
                      <IconButton
                        id="hand-raise-to-backstage"
                        key="toGreenRoomButton"
                        activeIcon={IUIIcons.HAND_LOWER}
                        defaultIcon={IUIIcons.HAND_LOWER}
                        onClick={() => setShow(true)}
                        showDefault={true}
                        type={IIconButtonTypes.ROUND_DARK_GREY_WHITE}
                        tooltip={TooltipTypes.right}
                      />
                    )}
                    handleConfirm={({ setShow }) => {
                      if (!isVideoTrackPublished()) {
                        kickUserOut();
                      } else {
                        handleMoveUserToGreenRoom();
                      }
                      setShow(false);
                    }}
                    exitText={`Are you sure you want to remove ${confirmationNameText} from stage?`}
                    confirmButtonText="Yes"
                  />
                )}
              </div>
              <div className={styles.muteUser}>
                {!stream.isLocal && isAudioEnabled && showUserControls && (
                  <ConfirmExitModal
                    trigger={({ setShow }) => (
                      <IconButton
                        key="muteRemoteUserButton"
                        tooltip="Mute User"
                        activeIcon={IUIIcons.NEW_MIC_OFF}
                        defaultIcon={IUIIcons.NEW_MIC_OFF}
                        onClick={() => setShow(true)}
                        showDefault={true}
                        type={IIconButtonTypes.ROUND_PRIMARY}
                      />
                    )}
                    handleConfirm={({ setShow }) => {
                      handleToggleMuteRemoteUser();
                      setShow(false);
                    }}
                    exitText={`Are you sure you want to mute ${confirmationNameText}?`}
                    confirmButtonText="Yes"
                  />
                )}
              </div>
            </>
          )}
          {showRemoteUserControls && (
            <RemoteUserControls
              isAudioEnabled={isAudioEnabled}
              isVideoEnabled={isVideoEnabled}
              isAScreenShare={false}
              toggleMuteRemoteUser={handleToggleMuteRemoteUser}
              toggleVideoRemoteUser={handleToggleVideoRemoteUser}
              kickUserOut={kickUserOut}
              name={streamDetails?.name ?? ''}
              showMenu={showMenu}
            />
          )}
        {/* Show border around the active user's stream */}
        <div
          className={classnames({
            [styles.videoTrackWrapper]: isScreenShareStream,
            [styles.videoElementFull]: isStageFull && !greenRoom,
            // Show active volume
            [styles.videoActiveSpeakerElement]:
              hasActiveVolume && showVolumeIndicator,
            // Show stop screen share gradient
            [styles.screenShareBackground]: showStopScreenShareOption,
            // Fallback
            [styles.videoElement]:
              !hasActiveVolume || (hasActiveVolume && !showVolumeIndicator),
          })}
          id={`single-stream-${stream.uid}`}
          onClick={onClick}
          ref={videoContainerRef}
          onDoubleClick={handleStreamDoubleClick}
          aria-hidden="true"
        />
        {!isVideoEnabled && !isScreenSharedStream && (
          <StreamActiveUser
            user={streamDetails}
            hasActiveVolume={hasActiveVolume}
            containerStyles={containerStyles}
            showVolumeIndicator={showVolumeIndicator}
          />
        )}
        {showScreenShareFullscreenIcon && (
          <div className={styles.fullscreenIcon}>
            <Maximize onClick={handleStreamDoubleClick} />
          </div>
        )}
        {showStopScreenShareOption ? (!isMobile ? (
          <>
            <div
              id={`activeScreenSharingUser-${uid}`}
              className={styles.activeScreenSharingUserWrapper}
            >
              <Text
                textSize={IContentTextSizeTypes.SMALL}
                textColor={ITextColors.MONOCHROME_CONTRAST}
                text={`${
                  getFirstNameLastName(streamDetails.name)[0]
                } is presenting`}
              />
            </div>
            <div className={styles.stopScreenShareWrapper}>
              <IconTextButton
                showDefault
                buttonType={IIconTextButtonTypes.COLOURS_NEUTRAL_BASE_3}
                iconSize={EMOJI_ICON_SIZE}
                defaultIcon={IUIIcons.SCREEN_SHARE_ON}
                onClick={handleStopRemoteUserScreenShare}
                defaultLabel="End presentation"
                labelSize={IContentTextSizeTypes.SMALL}
                defaultLabelStyle={INewLabelStyles.REGULAR}
                buttonIconColor={IContentColors.WHITE}
                style={{
                  borderRadius: '24px',
                  background: 'rgba(0, 0, 0, 0.5)',
                  backdropFilter: 'blur(2.18824px)',
                }}
              />
            </div>
            </>
          ) : (
            <RemoteUserControls
              isAudioEnabled={false}
              isVideoEnabled={false}
              isAScreenShare
              kickUserOut={kickUserOut}
              stopScreenShare={handleStopRemoteUserScreenShare}
              name={streamDetails?.name ?? ''}
              showMenu={showMenu}
            />
          )
        ) : null}
        <GenericErrorBoundary>
          <StreamLabel
            headline={headline}
            bio={bio}
            hideNameBadge={hideNameBadge || isBorderless}
            isOrganizerStream={isOrganizerStream}
            name={name}
            showCondensed={width < 400 || height < 200}
            showNasscomLabel={showNasscomLabel}
            showGradientLabel={showGradientLabel}
            greenRoom={greenRoom}
            nasscomScreenShare={nasscomScreenShare}
            showMutedStatus={isAudioEnabled}
            volumeLevel={currentVolumeLevel}
            showActiveVolume={hasActiveVolume}
            showVolumeIndicator={showVolumeIndicator}
            hideHeadline={height < 100}
          />
        </GenericErrorBoundary>
      </div>
    );
  },
  (prevProps, nextProps) => {
    const {
      name: prevName,
      stream: prevStream,
      containerStyles: { height: prevHeight, width: prevWidth },
      volumeOn: prevVolume,
      hideNameBadge: prevHideNameBadge,
      streamDetails: prevStreamDetails,
      localClientAVStatus: { audio: prevLocalAudio, video: prevLocalVideo },
      liveRaisedHandRequestList: prevLiveRaisedHandRequest,
      greenRoom: prevGreenRoom,
    } = prevProps;
    const {
      name: nextName,
      stream: nextStream = [],
      containerStyles: { height: nextHeight, width: nextWidth },
      volumeOn: nextVolume,
      hideNameBadge: nextHideNameBadge,
      streamDetails: nextStreamDetails,
      localClientAVStatus: { audio: nextLocalAudio, video: nextLocalVideo },
      liveRaisedHandRequestList: nextLiveRaisedHandRequest,
      greenRoom: nextGreenRoom,
    } = nextProps;

    const sameStreamId = prevStream.uid === nextStream.uid;
    const sameAudioTrack = prevStream.audioTrack === nextStream.audioTrack;
    const sameVideoTrack = prevStream.videoTrack === nextStream.videoTrack;
    const sameStream = sameStreamId && sameAudioTrack && sameVideoTrack;

    const sameDims = prevHeight === nextHeight && prevWidth === nextWidth;
    const sameName = prevName === nextName;
    const sameVolume = prevVolume === nextVolume;
    const sameHideNameBadge = prevHideNameBadge === nextHideNameBadge;
    const sameStreamDetails = prevStreamDetails === nextStreamDetails;
    const sameLocalClientAVStatus =
      prevLocalAudio === nextLocalAudio && prevLocalVideo === nextLocalVideo;

    const sameLiveRaisedHandRequestList =
      prevLiveRaisedHandRequest?.length === nextLiveRaisedHandRequest?.length;
    const sameGreenRoom = prevGreenRoom === nextGreenRoom;

    if (
      sameStream &&
      sameDims &&
      sameName &&
      sameVolume &&
      sameHideNameBadge &&
      sameStreamDetails &&
      sameLocalClientAVStatus &&
      sameLiveRaisedHandRequestList &&
      sameGreenRoom
    ) {
      return true;
    }
    return false;
  },
);

export default SingleStreamPlayer;