// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__mainContainer___2GgRt{display:flex;align-items:center;grid-gap:5px;margin-left:5px}.styles-module__name___3dZ5G{display:inline-block;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:15ch}\n", ""]);
// Exports
exports.locals = {
	"mainContainer": "styles-module__mainContainer___2GgRt",
	"name": "styles-module__name___3dZ5G"
};
module.exports = exports;
