import BottomSheet from '@/components/ui/BottomSheet';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import Text, { ITextColors, ITextStyles } from '@/components/ui/content/Text';
import { IContentColors, IContentTextSizeTypes } from '@/types';
import { getCssVar } from '@/utils/cssVars';
import { getFirstNameLastName } from '@/utils/helpers';
import {
  Microphone,
  MicrophoneOff,
  Video,
  VideoOff,
} from '@styled-icons/boxicons-solid';
import { LogOut } from "@styled-icons/boxicons-regular"
import classnames from 'classnames';
import React, { useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { MoreVertical } from 'react-feather';
import { SheetRef } from 'react-modal-sheet';
import ConfirmExitModal from '../../StreamOptionsBar/ConfirmExitModal';
import parentStyles from '../styles.module.scss';
import {
  getConfirmation,
  getMobileOptions,
  REMOTE_USER_ACTION,
} from './config';
import styles from './styles.module.scss';
import { Props } from './types';

const RemoteUserControlMobile = (props: Props) => {
  const {
    isAudioEnabled,
    isVideoEnabled,
    isAScreenShare,
    name,
    kickUserOut,
    showMenu,
  } = props;
  const [firstName] = getFirstNameLastName(name);
  const bottomSheetRef = useRef<SheetRef>();
  const [snapPoint, setSnapPoint] = useState(0);
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [confirmationType, setConfirmationType] = useState<REMOTE_USER_ACTION>(
    'NONE',
  );

  const closeBottomSheet = () => {
    setShowBottomSheet(false);
    setConfirmationType('NONE');
  };

  const handleConfirmClick = () => {
    if (isAScreenShare) return;
    const { toggleMuteRemoteUser, toggleVideoRemoteUser } = props;
    if (confirmationType === 'AUDIO_OFF' || confirmationType === 'AUDIO_ON') {
      toggleMuteRemoteUser();
    }
    if (confirmationType === 'VIDEO_OFF' || confirmationType === 'VIDEO_ON') {
      toggleVideoRemoteUser();
    }
    if (confirmationType === 'KICKOUT_USER') {
      kickUserOut();
    }
    closeBottomSheet();
  };

  const handleOptionClick = (type: REMOTE_USER_ACTION) => {
    if (type === 'SCREEN_SHARE_OFF' && isAScreenShare) {
      props.stopScreenShare();
      closeBottomSheet();
    } else {
      setConfirmationType(type);
    }
  };

  const renderConfirmation = () => {
    const confirmation = getConfirmation(confirmationType, firstName);
    if (!confirmation) {
      return null;
    }
    const { title, description, actionText } = confirmation;
    return (
      <>
        <div className={styles.confirmationTextContainer}>
          <Text
            textAlign="center"
            className={styles.remoteUserName}
            text={title}
            textColor={ITextColors.MONOCHROME_CONTRAST}
            textSize={IContentTextSizeTypes.LARGE}
            block
          />
          <Text
            textAlign="center"
            text={description}
            textColor={ITextColors.NEUTRAL_DARK}
            textSize={IContentTextSizeTypes.SMALL}
            textStyle={ITextStyles.SEMI_BOLDED}
            block
          />
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            type="button"
            className={styles.confirmButton}
            label="Cancel"
            onClick={closeBottomSheet}
            styleClass={IButtonTypes.COLOURS_NEUTRAL_BASE_3}
          />
          <Button
            className={styles.confirmButton}
            label={actionText}
            onClick={handleConfirmClick}
            styleClass={IButtonTypes.PRIMARY}
            type="button"
          />
        </div>
      </>
    );
  };

  const showConfirmationPopup = confirmationType !== 'NONE';
  return (
    <>
      <div className={styles.mobileOptionsContainer}>
        {showMenu && (
          <div
            className={styles.moreIcon}
            onClick={e => {
              e.stopPropagation();
              setShowBottomSheet(true);
            }}
          >
            <MoreVertical
              size={20}
              color={getCssVar(IContentColors.MONOCHROME_CONTRAST)}
            />
          </div>
        )}
      </div>
      {showBottomSheet && (
        <BottomSheet
          id="remoteUserControlsBottomSheet"
          ref={bottomSheetRef}
          isOpen={showBottomSheet}
          showCloseButton
          onSnap={snapPoints => setSnapPoint(snapPoints)}
          initialSnap={0}
          snapPoints={[200, 0]}
          currentSnapPoint={snapPoint}
          onBackdropTouch={closeBottomSheet}
          showDragIndicator={false}
          onCloseClick={closeBottomSheet}
          onClose={closeBottomSheet}
          disableDrag
        >
          <div
            className={classnames(styles.sheetContentContainer, {
              [styles.tabletContainerStyles]: showConfirmationPopup,
            })}
          >
            {!showConfirmationPopup ? (
              <>
                <Text
                  className={styles.remoteUserName}
                  text={name}
                  textColor={ITextColors.MONOCHROME_CONTRAST}
                  textSize={IContentTextSizeTypes.MEDIUM}
                />
                {getMobileOptions(
                  isAudioEnabled,
                  isVideoEnabled,
                  isAScreenShare,
                ).map(({ icon: Icon, text, type }) => (
                  <div
                    key={type}
                    className={styles.optionsContainer}
                    onClick={() => handleOptionClick(type)}
                  >
                    {type === 'KICKOUT_USER' 
                      ? <Icon size={24} transform="rotate(180)" />
                      : <Icon size={24}  />
                    }
                    <Text
                      text={text}
                      textStyle={ITextStyles.SEMI_BOLDED}
                      textColor={ITextColors.MONOCHROME_CONTRAST}
                      textSize={IContentTextSizeTypes.SMALL}
                    />
                  </div>
                ))}
              </>
            ) : (
              renderConfirmation()
            )}
          </div>
        </BottomSheet>
      )}
    </>
  );
};

const RemoteUserControlDesktop = (props: Props) => {
  const { isAudioEnabled, isVideoEnabled, isAScreenShare, name } = props;
  const [firstName] = getFirstNameLastName(name);
  const videoConfirmation = getConfirmation(
    isVideoEnabled ? 'VIDEO_OFF' : 'VIDEO_ON',
    firstName,
  );
  const audioConfirmation = getConfirmation(
    isAudioEnabled ? 'AUDIO_OFF' : 'AUDIO_ON',
    firstName,
  );
  const kickoutConfirmation = getConfirmation('KICKOUT_USER', firstName);

  if (isAScreenShare) {
    return null;
  }

  const { toggleMuteRemoteUser, toggleVideoRemoteUser, kickUserOut } = props;
  
  return (
    <div className={parentStyles.remoteUserControls}>
      <ConfirmExitModal
        trigger={({ setShow }) => (
          <div
            className={parentStyles.remoteUserIconButton}
            onClick={() => setShow(true)}
          >
            {isVideoEnabled ? <Video size={20} /> : <VideoOff size={20} />}
          </div>
        )}
        handleCancel={(e, { setShow }) => {
          toggleVideoRemoteUser();
          setShow(false);
        }}
        handleConfirm={({ setShow }) => {
          setShow(false);
        }}
        exitText={videoConfirmation?.description}
        title={videoConfirmation?.title}
        cancelButtonText={videoConfirmation?.actionText}
        confirmButtonText="Cancel"
      />
      <ConfirmExitModal
        trigger={({ setShow }) => (
          <div
            className={parentStyles.remoteUserIconButton}
            onClick={() => setShow(true)}
          >
            {isAudioEnabled ? (
              <Microphone size={20} />
            ) : (
              <MicrophoneOff size={20} />
            )}
          </div>
        )}
        handleConfirm={({ setShow }) => {
          setShow(false);
        }}
        handleCancel={(e, { setShow }) => {
          toggleMuteRemoteUser();
          setShow(false);
        }}
        exitText={audioConfirmation?.description}
        title={audioConfirmation?.title}
        cancelButtonText={audioConfirmation?.actionText}
        confirmButtonText="Cancel"
      />
      <ConfirmExitModal
        trigger={({ setShow }) => (
          <div
            className={parentStyles.remoteUserIconButton}
            onClick={() => setShow(true)}
          >
            <LogOut size={20} transform="rotate(180)" />
          </div>
        )}
        handleCancel={(e, { setShow }) => {
          kickUserOut();
          setShow(false);
        }}
        handleConfirm={({ setShow }) => {
          setShow(false);
        }}
        exitText={kickoutConfirmation?.description}
        title={kickoutConfirmation?.title}
        cancelButtonText={kickoutConfirmation?.actionText}
        confirmButtonText="Cancel"
      />
    </div>
  );
};

const RemoteUserControls = (props: Props) =>
  isMobile ? (
    <RemoteUserControlMobile {...props} />
  ) : (
    <RemoteUserControlDesktop {...props} />
  );

export default RemoteUserControls;
