import Text, { ITextStyles } from '@/components/ui/content/Text';
import RaisedHandV2 from '@/components/ui/new-icons/RaisedHandV2';
import { useChannelContext } from '@/services/video/channel-context/channel-context';
import { IContentTextColorTypes } from '@/types';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { useHover } from 'react-use';

const RaisedHandRequestIndicator = ({ accountId }: { accountId?: string }) => {
  const { channelRequest } = useChannelContext();
  const { raisedHandRequestList } = channelRequest;
  const [showText, setShowText] = useState(false);

  const hasRaisedHand = useMemo(
    () => raisedHandRequestList.some(x => x.requestBy === accountId),
    [accountId, raisedHandRequestList],
  );

  useEffect(() => {
    if (!hasRaisedHand) {
      return undefined;
    }
    setShowText(true);
    const timeout = setTimeout(() => {
      setShowText(false);
    }, 3 * 1000);
    return () => clearTimeout(timeout);
  }, [hasRaisedHand]);

  const [Component] = useHover(isHovering => {
    if (!hasRaisedHand) {
      return <></>;
    }
    return (
      <div className={styles.raisedHand}>
        <RaisedHandV2 size={20} />
        {isHovering || showText ? (
          <Text
            text="Raised hand"
            textColor={IContentTextColorTypes.MONOCHROME_CONTRAST}
            textStyle={ITextStyles.SEMI_BOLDED}
            className={styles.text}
          />
        ) : null}
      </div>
    );
  });

  return Component;
};

export default RaisedHandRequestIndicator;
