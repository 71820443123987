import React from 'react';

import styles from './styles.module.scss';
import ScreenSharePermission from './ScreenSharePermission';
import { useChannelContext } from '@/services/video/channel-context/channel-context';

interface IMoreOptionsBtnView {
  isComponentVisible: boolean;
}

const MoreOptionsBtnView = React.forwardRef<
  HTMLDivElement,
  IMoreOptionsBtnView
>((props, ref) => {
  const { isComponentVisible } = props;
  const { channelSettings } = useChannelContext();

  return (
    <div ref={ref}>
      {isComponentVisible && (
        <div className={styles.optionsWrapper}>
          <ScreenSharePermission channelSettings={channelSettings} />
        </div>
      )}
    </div>
  );
});

export default MoreOptionsBtnView;
