import IconButton, {
  IIconButtonBGColors,
  IIconButtonTypes,
} from '@/components/ui/buttons/IconButton';
import { useChannelContext } from '@/services/video/channel-context/channel-context';
import { HOVER_NAV_ICON_SIZE } from '@/styles/iconSizes';
import { IUIIcons } from '@/types';
import React from 'react';
import styles from './styles.module.scss';

const RaisedHandRequestButton = () => {
  const { channelRequest } = useChannelContext();
  const { hasRaisedHand, raiseHandRequest, lowerHandRequest } = channelRequest;

  const onClick = () => {
    if (hasRaisedHand) {
      lowerHandRequest();
    } else {
      raiseHandRequest();
    }
  };

  return (
    <IconButton
      activeIcon={IUIIcons.RAISED_HAND_V2}
      defaultIcon={IUIIcons.RAISED_HAND_V2}
      onClick={onClick}
      showDefault
      iconBG={
        hasRaisedHand
          ? IIconButtonBGColors.PRIMARY
          : IIconButtonBGColors.NEUTRAL_LIGHT_3
      }
      type={IIconButtonTypes.ROUND_PRIMARY}
      iconSize={HOVER_NAV_ICON_SIZE}
      tooltipText={hasRaisedHand ? 'Lower hand' : 'Raise hand'}
      className={styles.raisedHandButton}
    />
  );
};

export default RaisedHandRequestButton;
