import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { IStageParent } from '@/components/Stage/types';

const Overlay = ({ isOrganizer, parent, url }) => (
  <img
    className={classnames(styles.overlayImage, {
      [styles.organizerOverlay]:
        isOrganizer && parent === IStageParent.LIVE_BACKSTAGE,
    })}
    src={url}
    alt=""
  />
);

export default Overlay;
