// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__interpreterBox___1EzC_{display:flex;flex-flow:row wrap;align-items:center;justify-content:center;position:relative;overflow:hidden;z-index:10}\n", ""]);
// Exports
exports.locals = {
	"interpreterBox": "styles-module__interpreterBox___1EzC_"
};
module.exports = exports;
