import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import queryString from 'query-string';
// components
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import EmojiBar from '../EmojiBar';
import SessionSelectBar from '../SessionSelectBar';
import IconButton, {
  IIconButtonBGColors,
} from '@/components/ui/buttons/IconButton';
import IconTextButton, {
  IIconTextButtonTypes,
} from '@/components/ui/buttons/IconTextButton';
import StreamPreviewModal from '@/components/ui/modals/StreamPreviewModal';
import Text, {
  ITextColors,
  ITextSizes,
  ITextStyles,
} from '@/components/ui/content/Text';
import StreamSettingsBar from '@/components/PublishedStreamDisplay/StreamSettingsBar';
// styles + types
import styles from './styles.module.scss';
import { IStreamOptionsBarProps, IStreamOptionTypes } from './types';
import {
  IContentColors,
  IContentTextColorTypes,
  IContentTextSizeTypes,
  IContentTextStyleTypes,
  IUIIcons,
} from '@/types';
import {
  IRaisedHandRequestStatus,
  IStageParent,
} from '@/components/Stage/types';
import { IIconButtonTypes } from '@/components/ui/buttons/IconButton/types';
// models + hooks
import {
  makeSelectIsOrganizer,
  makeSelectIsSpeaker,
  makeSelectEventStageIsBroadcasting,
  makeSelectEventRoleForEvent,
  makeStageSettingsByChannelId,
  makeSelectRecordingSessionObj,
  makeSelectEventById,
  makeSelectCurrentOverlayForChannel,
  makeSelectRoomByRoomId,
} from '@/models/event';
import useComputeBackstageAccess from '@/hooks/use-compute-backstage-access';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { setOnboardingTour, timeLeftMsToMins } from '@/utils/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsStageFull } from '@/models/global';
// models
import { STREAM_FLOW_STEPS } from '@/components/onboarding/tableFlows';
import { EXPO_BOOTH_LIVE_STREAM_STEPS } from '@/components/onboarding/expoFlows';
import ConfirmExitModal from './ConfirmExitModal';
import ConfirmExitPromptsModal from './ConfirmExitPromptsModal';
import {
  EventSidePanels,
  ZoneSidePanels,
} from '@/components/ChannelSidePanel/types';
import LogoNew from '@/components/LogoNew';
import {
  STANDARD_ICON_BUTTON_SIZE,
  MEDIUM_ICON_SIZE,
  EMOJI_ICON_SIZE,
} from '@/styles/iconSizes';
import Image from '@/components/ui/media/Image';
import { useRecording } from '@/hooks/use-recording';
import StreamRecordingToggle from '../StreamRecordingToggle';
import { SessionBroadcastStatus } from '../SessionSelectBar/types';
import { useStageSettings } from '@/hooks/use-stage-settings';
import RecordingIndicatorBar from '../RecordingIndicatorBar';
import {
  STAGE_STREAMS_VOLUME_TOGGLE_FLOW,
  getStageStreamVolumeToggleIntro,
} from '@/components/onboarding/stageFlows';
import { makeSelectShowFlow, makeSelectUserAccountId } from '@/models/account';
import api from '@/api';
import MicOffCurved from '@/components/ui/new-icons/MicOffCurved';
import SessionConfirmModal from './SessionConfirmModal';
import ScreenOrFileShareToggle from './ScreenOrFileShareToggle';
import { ChannlRefType as ChannelRefType } from '@/types/channel';
import { useMediaQuery } from 'react-responsive';
import {
  isChrome,
  isDesktop,
  isEdgeChromium,
  isMobileOnly,
  isSafari,
} from 'react-device-detect';
import { IScreen } from '@/types/screen';
import { SheetRef } from 'react-modal-sheet';
import BottomSheet from '@/components/ui/BottomSheet';
import GenericErrorBoundary from '@/components/GenericErrorBoundary';
import { selectActiveIntegrations } from '@/models/integration';
import Parser from 'html-react-parser';
import ReactTooltip from 'react-tooltip';
import { Info } from 'react-feather';
import { getCssVar } from '@/utils/cssVars';
import { ITicketTagTypes } from '@/types/tickets';
import useGetAllTicketTypes from '@/hooks/use-all-event-ticket-types-list';
import { ITicketTypeBasicInfo } from '@/models/event/types';
import { _isEmpty } from '@/utils/jsHelpers/array';
import StreamTalkingUserInfo from '../StreamTalkingUserInfo';
import { useIsPreviewView } from '@/hooks/use-is-preview-view';
import { useChannelContext } from '@/services/video/channel-context/channel-context';
import EmojiBarButton from '../EmojiBar/EmojiBarButton';
import RaisedHandRequestButton from '../RaisedHand';
import MoreOptionsBtnView from './MoreOptionsBtnView';
import useComponentVisible from '@/hooks/use-component-visible';
import useIsOrganizerOrRoomOwner from '@/hooks/events/useIsOrganizerOrRoomOwner';
import { useVideoState } from '@/services/video/video-context';
import { STUDIO_URL } from '@/config';
import { useShowVirtualBgSetting } from '@/hooks/use-virtual-background';
import TrackSelection from '../../Stage/TrackSelection';

const {
  KEYNOTE,
  NEW_MIC_OFF,
  NEW_MIC_ON,
  RAISED_HANDS,
  STREAM_JOIN,
  NEW_VOLUME_OFF,
  NEW_VOLUME_ON,
  NEW_VIDEO_OFF,
  NEW_VIDEO_ON,
  NEW_BROADCAST_START,
  NEW_BROADCAST_STOP,
  NEW_BROADCAST_PAUSE,
  NEW_BROADCAST_RESUME,
  STREAM_LEAVE,
  NEW_STREAM_LEAVE,
  BOOTH_VOLUME_OFF,
  BOOTH_VOLUME_ON,
  VIRTUAL_BACKGROUND_OFF,
  VIRTUAL_BACKGROUND_ON,
  MORE,
} = IUIIcons;

const {
  BROADCAST_BUTTON,
  BROADCAST_PANEL_BUTTON,
  EMOJIS_BAR,
  EMOJIS_BAR_BUTTON,
  EXIT_BACKSTAGE_BUTTON,
  EXIT_STREAM_BUTTON,
  RETURN_TO_BACKSTAGE_BUTTON,
  EXIT_ROOM_BUTTON,
  JOIN_BACKSTAGE_BUTTON,
  JOIN_STAGE_BUTTON,
  JOIN_STREAM_BUTTON,
  JOIN_STREAM_MODAL_BUTTON,
  MIC_TOGGLE,
  SCREEN_OR_FILE_SHARE_TOGGLE,
  SPEAKER_TIMER,
  VIDEO_TOGGLE,
  CIPLA_BUTTON1,
  CIPLA_BUTTON2,
  STREAM_SETTINGS_BAR,
  VOLUME_TOGGLE,
  STREAM_TITLE,
  STREAM_INFO_ROOM_NAME,
  STREAM_INFO_ROOM_NUM,
  STREAM_INFO_ROOM_LOGO,
  SESSION_CHANGE_DROPDOWN,
  SESSION_NAME_LABEL,
  BROADCAST_RECORDING_BUTTON,
  BROADCAST_PAUSE_RESUME_BUTTON,
  RETURN_TO_EXPO_BOOTH,
  EXIT_EXPO_BOOTH,
  BROADCAST_DRY_RUN_PANEL_BUTTON,
  RECORDING_INDICATOR_BAR,
  STREAM_USER_TALKING_INFO_VIEW,
  VIRTUAL_BACKGROUND_BUTTON,
  RAISED_HAND_BUTTON,
  MORE_OPTIONS_BTN,
  TRACK_SELECTION,
} = IStreamOptionTypes;

const StreamOptionsBar = (props: IStreamOptionsBarProps) => {
  const {
    broadcastPermissions,
    raisedHandStatus,
    completeMyRaisedHandRequest,

    audienceGalleryRequestStatus,
    completeMyAudienceGalleryRequest,

    contributorViewRequestStatus,
    completeMyContributorViewRequest,

    isStreamsEmpty,
    localClient,
    localStream,
    onBroadcastButtonClick,
    onEmojiClick,
    onScreenShareButtonClick,
    onToggleRaiseHand,
    parent,
    setBroadcastPermissions,
    setChatPanelOpen,
    setShowTabIndex,
    timeLeftMs,
    pendingScreen,
    hideStreamOptions,
    currentlyPlayingVideo,
    currentLiveStream,
    selectedTable,
    hasGreenRoomStreams,
    showGreenRoomActionPanel,
    channelConfig,
    moveUserToGreenRoom,
    channelSidePanelState,
    avControlsConfig,
    volumeOn,
    toggleVolume,
    showVolumeToggle,
    presentationConfig,
    pluginConfig,
    openMediaTabInGreenroomPanel,
    toggleBoothLiveView,
    stageSessionSettings: stageSettingsObj,
    setToggleLeftPanel,
    toggleLeftPanel,
  } = props;

  const { virtualBackground, attendeeChannel } = useChannelContext();
  const dispatch = useDispatch();
  const { eventId = '', stageId = '', discussionTableId = '' } = useParams<{
    eventId: string;
    stageId: string;
    discussionTableId: string;
  }>();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const currentUserAccountId = useMemoizedSelector(makeSelectUserAccountId);
  const [isVisible, setIsVisible] = useState(true);
  const isBroadcasting = useMemoizedSelector(
    makeSelectEventStageIsBroadcasting,
    { eventId, stageId },
  );

  const event = useMemoizedSelector(makeSelectEventById, eventId);
  const activeIntegrations = useSelector(selectActiveIntegrations);
  const showSidePanel = showGreenRoomActionPanel;
  const eventRole = useMemoizedSelector(makeSelectEventRoleForEvent, eventId);
  const hasBackstageAccess = useComputeBackstageAccess(
    eventId,
    stageId,
    eventRole,
  );
  const isOrganizerOrRoomOwner = useIsOrganizerOrRoomOwner(
    eventId,
    discussionTableId,
  );
  const { showVirtualBgSetting } = useShowVirtualBgSetting();

  const [showStageAudioTooltip, setShowStageAudioTooltip] = useState(false);
  const isStage = parent === IStageParent.LIVE_STAGE;
  const isBackstage = parent === IStageParent.LIVE_BACKSTAGE;
  const isDiscussion = parent === IStageParent.LIVE_DISCUSSION;

  const { devicesList } = useVideoState();
  const [isCameraListEmpty, isMicrophoneListEmpty] = useMemo(
    () => [
      devicesList.filter(item => item.kind === 'videoinput').length === 0,
      devicesList.filter(item => item.kind === 'audioinput').length === 0,
    ],
    [devicesList],
  );

  const {
    ref: moreOptionsViewRef,
    isComponentVisible: isMoreOptionsViewVisible,
    setIsComponentVisible: setIsMoreOptionsViewVisible,
  } = useComponentVisible(false);

  const isOrganizer = useMemoizedSelector(makeSelectIsOrganizer, eventId);
  const isSpeaker = useMemoizedSelector(makeSelectIsSpeaker, eventId);
  const isAttendee = isStage || (!isOrganizer && !isSpeaker);

  const isBoothOwner = parent === IStageParent.BOOTH_OWNER;
  const isBooth = parent === IStageParent.BOOTH;
  const isStagePreview = parent === IStageParent.STAGE_PREVIEW;
  const isStageFromBackstage = isBackstage && !hasGreenRoomStreams;
  const currentOverlay = useMemoizedSelector(
    makeSelectCurrentOverlayForChannel,
    channelConfig && channelConfig.channelId,
  );

  const showJoinBackStageButtonWhenOverlay =
    currentOverlay &&
    ![
      IStageParent.CONTRIBUTOR_SLOT,
      IStageParent.CONTRIBUTOR_SLOT_AUDIO,
      IStageParent.LIVE_BACKSTAGE,
    ].includes(parent) &&
    (isOrganizer || isSpeaker);

  const showJoinBackStageButton =
    hasBackstageAccess &&
    ((isStreamsEmpty &&
      !currentLiveStream &&
      !currentlyPlayingVideo &&
      showJoinBackStageButtonWhenOverlay) ||
      (isStage && (isBroadcasting || showJoinBackStageButtonWhenOverlay)));

  const showScreenOrFileToggle =
    (parent === IStageParent.LIVE_BACKSTAGE ||
      parent === IStageParent.LIVE_DISCUSSION) &&
    (isSpeaker || isOrganizerOrRoomOwner);
  const miroPluginActive = activeIntegrations.find(i => i.name === 'Miro');

  const { search } = useLocation();
  const searchParams = queryString.parse(search);
  const meetingRoomId = searchParams.cur;
  const { isPreview } = useIsPreviewView();

  const selectedDiscussionTable = useMemoizedSelector(makeSelectRoomByRoomId, {
    eventId,
    discussionTableId: discussionTableId || meetingRoomId,
  });

  useEffect(() => {
    let screen = IScreen.UNDEFINED;
    if (isStageFromBackstage && broadcastPermissions) {
      screen = IScreen.ONSTAGE;
    } else if (isBackstage && broadcastPermissions) {
      screen = IScreen.GREENROOM;
    }
    dispatch({
      type: 'global/setUserCurrentScreen',
      payload: screen,
    });
  }, [isStageFromBackstage, isBackstage, broadcastPermissions]);

  useEffect(() => {
    if (!event || !event.organizationId) {
      return;
    }
    dispatch({
      type: 'integration/getActiveIntegrations',
      payload: { organizationId: event.organizationId },
    });
  }, [event]);

  const currentSession = useMemoizedSelector(
    makeSelectRecordingSessionObj,
    stageId,
  );
  const { updateDryRunToggle, updateStageRecordingToggle } = useStageSettings({
    stageId,
  });

  useEffect(() => {
    if (!eventId) return;
    if (isOrganizer) {
      dispatch({
        type: 'event/getEventPendingSegmentList',
        payload: {
          eventId,
        },
      });
    }
  }, [eventId, isOrganizer]);
  const [selectedSnapPoint, setSnapPoint] = useState(0);

  const {
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    startRecordingWithDummySession,
    startDryRun,
    stopDryRun,
  } = useRecording({
    channelId: (channelConfig && channelConfig.channelId) || '',
  });

  const [isBreakoutRoom, setIsBreakoutRoom] = useState(false);
  const [breakoutConfig, setBreakoutConfig] = useState(null) as any;

  useEffect(() => {
    if (!discussionTableId) return;
    api.discussion.getDiscussionTable(discussionTableId).then(({ data }) => {
      if (!data) {
        window.open(`/l/event/${eventId}/discussions`, '_self');
        return;
      }
      if (data.type === 'BREAKOUT') {
        api.breakout
          .getStageBreakoutConfig(
            eventId,
            discussionTableId,
            ChannelRefType.SESSION,
          )
          .then(({ data: breakoutRoomConfig }) => {
            if (breakoutRoomConfig) {
              setBreakoutConfig(breakoutRoomConfig);
              setIsBreakoutRoom(true);
            } else {
              window.open(`/l/event/${eventId}/discussions`, '_self');
            }
          });
      } else {
        setIsBreakoutRoom(false);
      }
    });
  }, [discussionTableId]);

  const boothAccessEventRoles = [
    'e6503b5d-f122-4d0b-8540-7c56175fd6d5',
    '72bb48ae-66c7-4976-9e2b-4e5b6bec2333',
    '50652b10-dc03-4bfe-9baa-dad1edfb56dd',
    '91204bc8-135c-4319-8791-0044662d51e9',
    '36daaad2-4dd5-4f3b-86f5-068b6b42bd35',
    '29317ffe-4d33-4c4c-bb37-fdd0e943de9f',
    'ae1cb60d-7a75-4d0e-892f-5ea03516a18f',
    '82e0071b-4d1c-40d1-a0b6-15037947776f',
    '5b8099bc-7884-4c68-84a9-a84f5831448a',
    'eceee359-8e77-496b-bd2e-9d3d9fbff5ee',
    'be156a98-76ee-4487-8a6f-fffa465d608d',
    'f3635b99-1f5c-401f-a8e7-b235f2901e04',
    'd75ecc5a-eb57-408a-8bde-468e0a4c930b',
    '954eb20a-f8bd-45e1-93f1-2983d997f112',
    '701de660-bda0-4f5f-b4f8-9299b97caa02',
    '78f540fa-5253-43d9-9910-09e2212eb71d',
  ];
  const isBoothOwnerAccess =
    eventRole && boothAccessEventRoles.includes(eventRole.eventRoleId);
  const isStageFull = useSelector(selectIsStageFull);

  const channelSettingsByChannelId = useSelector(makeStageSettingsByChannelId);

  const handleJoinStageButtonClick = () => {
    onToggleRaiseHand();
    setShowTabIndex(ZoneSidePanels.RAISED_HANDS);
    setChatPanelOpen(true);
  };

  const handleJoinStreamButtonClick = () => {
    setShowTabIndex(ZoneSidePanels.RAISED_HANDS);
    setChatPanelOpen(true);
  };

  const leave = async () => {
    await localClient.leaveScreenAndUnpublishMain();
    if (raisedHandStatus) {
      await completeMyRaisedHandRequest();
    }
    if (audienceGalleryRequestStatus) {
      await completeMyAudienceGalleryRequest();
    }
    if (contributorViewRequestStatus) {
      await completeMyContributorViewRequest();
    }
    if (parent === IStageParent.LIVE_DISCUSSION) {
      await localClient.leave();
      const { parentRef = 'discussions' } = searchParams;
      if (parentRef?.includes('studio/')) {
        window.location.assign(`${STUDIO_URL}/${parentRef}`);
        return;
      }
      if (isBreakoutRoom && breakoutConfig) {
        window.open(
          `/l/event/${eventId}/stages/${breakoutConfig.stageId}`,
          '_self',
        );
      } else {
        window.open(`/l/event/${eventId}/${parentRef}`, '_self');
      }
      return;
    }
    if (parent === IStageParent.LIVE_BACKSTAGE) {
      window.open(`/l/event/${eventId}/stages/${stageId}`, '_self');
      return;
    }
    if (parent === IStageParent.BOOTH_OWNER || parent === IStageParent.BOOTH) {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
      return;
    }
    dispatch({
      type: 'global/updateUseVirtualBackdrop',
      payload: {
        useVirtualBackdrop: false,
      },
    });
    setBroadcastPermissions(false);
  };

  const showStageVolumeFlow = useSelector(
    useMemo(() => makeSelectShowFlow(STAGE_STREAMS_VOLUME_TOGGLE_FLOW), []),
  );
  const [volumeOnIntroInProgress, setVolumeIntroInProgress] = useState(false);
  const introObject = useMemo(
    () =>
      getStageStreamVolumeToggleIntro(updatedFlowState => {
        dispatch({
          type: 'account/updateUserFlowProgress',
          payload: updatedFlowState,
        });
        setVolumeIntroInProgress(false);
      }),
    [],
  );
  useEffect(() => {
    if (
      showStageVolumeFlow &&
      !volumeOnIntroInProgress &&
      showStageAudioTooltip &&
      introObject &&
      !isMobileOnly
    ) {
      introObject.start();
      setVolumeIntroInProgress(true);
      setOnboardingTour(introObject);
    }
  }, [showStageAudioTooltip]);

  useEffect(
    () => () => {
      if (introObject) {
        introObject.exit(true);
      }
    },
    [],
  );

  const stageHoverTimeoutRef: any = useRef();
  const allTicketTypeList: ITicketTypeBasicInfo[] = useGetAllTicketTypes(
    eventId,
  );

  useEffect(() => {
    if (_isEmpty(allTicketTypeList)) {
      return;
    }
    const currentTicket = allTicketTypeList.filter(
      t => t.ticketTypeId === eventRole.ticketTypeId,
    )[0];
    if (currentTicket?.ticketTagType === ITicketTagTypes.IN_PERSON) {
      dispatch({
        type: 'global/setStageVolume',
        payload: false,
      });
    }
  }, [allTicketTypeList]);

  useEffect(() => {
    if (!isAttendee || parent !== IStageParent.LIVE_STAGE) return;
    const parentContainer = document.getElementById(
      'publishedStreamDisplayContainer',
    );
    const streamTopBar = document.getElementById('topStreamOptionsContainer');
    if (streamTopBar && parentContainer) {
      const trackMouseMove = () => {
        if (stageHoverTimeoutRef.current !== undefined) {
          window.clearTimeout(stageHoverTimeoutRef.current);
        }
        stageHoverTimeoutRef.current = window.setTimeout(() => {
          streamTopBar.style.display = 'none';
          streamTopBar.style.opacity = '0';
          streamTopBar.style.transition = '0.75s';
        }, 3000);
      };

      const trackMouseLeave = () => {
        streamTopBar.style.display = 'none';
        streamTopBar.style.opacity = '0';
      };

      const trackMouseOver = () => {
        streamTopBar.style.display = 'block';
        streamTopBar.style.opacity = '1';
        streamTopBar.style.transition = 'opacity 0.3s';
      };

      parentContainer.addEventListener('mouseover', trackMouseOver);
      parentContainer.addEventListener('mousemove', trackMouseMove);
      parentContainer.addEventListener('mouseleave', trackMouseLeave);
      streamTopBar.addEventListener('mouseover', trackMouseOver);
      streamTopBar.addEventListener('mouseleave', trackMouseLeave);
      return () => {
        parentContainer.removeEventListener('mousemove', trackMouseMove);
        parentContainer.removeEventListener('mouseleave', trackMouseLeave);
        parentContainer.removeEventListener('mouseover', trackMouseOver);
        streamTopBar.removeEventListener('mouseover', trackMouseOver);
        streamTopBar.removeEventListener('mouseleave', trackMouseLeave);
        if (stageHoverTimeoutRef.current) {
          window.clearTimeout(stageHoverTimeoutRef.current);
        }
      };
    }
  }, [isAttendee, parent]);

  const handleMouseEnter = () => {
    if (!isDiscussion) {
      setIsVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isDiscussion) {
      setIsVisible(true);
    }
  };
  const handleRoomDesc = text => {
    if (!text) return '';
    // strip html out of text.
    const strippedText = text.replace(/<[^>]*>?/gm, '');
    if (isSafari && strippedText.length > 48) {
      return strippedText.slice(0, 48).concat('...');
    }
    return Parser(text);
  };
  const {
    audioEnabled,
    videoEnabled,
    togglingAudio,
    onAudioToggle,
    togglingVideo,
    onVideoToggle,
  } = avControlsConfig;

  const isScreenShared = useMemo(() => localClient.isScreenSharing(), [
    localClient.screenClient,
    localClient.pendingScreen,
    localClient.leavingScreenShare,
  ]);

  // get emoji settings [Sometimes emojis don't come]
  useEffect(() => {
    if (!eventId || !stageId) {
      return;
    }
    api.channel.getChannelByRef(stageId).then(res => {
      const { channelId } = res.data;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      dispatch({
        type: 'event/setChannelSettingsByChannelId',
        payload: channelId,
      });
    });
  }, [eventId, stageId]);

  if (isStagePreview || hideStreamOptions) {
    return null;
  }

  const handleBroadcastButtonClick = async (isArchiveData: boolean) => {
    // this condition for start and stop broadcast for dry run
    if (
      stageSettingsObj &&
      stageSettingsObj.isDryRun &&
      !stageSettingsObj.isDryRunRunning
    ) {
      // start dry run here
      startDryRun(stageId);
      return;
    }
    if (
      stageSettingsObj &&
      stageSettingsObj.isDryRun &&
      stageSettingsObj.isDryRunRunning
    ) {
      // stop dry run here
      stopDryRun(stageId);
      return;
    }

    // if no sessions added for stage, below one will create dummy session and start session.
    if (!currentSession) {
      startRecordingWithDummySession();
      return;
    }
    // archive event chat, polls and Q&A data.
    if (
      currentSession.broadcastStatus !== SessionBroadcastStatus.PENDING &&
      isArchiveData
    ) {
      const archiveResponse = await api.event.archiveEventData(eventId);
      if (archiveResponse.status === 200) {
        dispatch({
          type: 'global/addSuccessToast',
          payload: { description: 'Archived Data Successfully' },
        });
      } else {
        dispatch({
          type: 'global/addDangerToast',
          payload: {
            description: 'Failed to archive event data, please try again',
          },
        });
        return;
      }
    }
    if (currentSession.broadcastStatus === SessionBroadcastStatus.STARTED) {
      stopRecording(currentSession.segmentId);
      return;
    }
    if (currentSession.broadcastStatus === SessionBroadcastStatus.PENDING) {
      startRecording(currentSession.segmentId);
      return;
    }
    if (currentSession.broadcastStatus === SessionBroadcastStatus.PAUSED) {
      stopRecording(currentSession.segmentId);
    }
  };

  const handleBroadcastPauseResumeButtonClick = () => {
    if (
      currentSession &&
      currentSession.broadcastStatus === SessionBroadcastStatus.STARTED
    ) {
      pauseRecording(currentSession.segmentId);
      return;
    }
    if (
      currentSession &&
      currentSession.broadcastStatus === SessionBroadcastStatus.PAUSED
    ) {
      resumeRecording(currentSession.segmentId);
    }
  };

  const getBroadcastButtonStyle = () => {
    if (
      currentSession &&
      currentSession.broadcastStatus !== SessionBroadcastStatus.PENDING
    ) {
      return IIconTextButtonTypes.BCKSTG_DANGER_SQUARE_RADIUS;
    }
    return IIconTextButtonTypes.BCKSTG_SUCCESS_SQUARE_RADIUS;
  };

  const ICON_MAP = {
    [BROADCAST_BUTTON]: (
      <ConfirmExitModal
        trigger={({ setShow }) => {
          const onClick = () => setShow(true);
          return (
            <IconTextButton
              key="broadcastButton"
              activeIcon={NEW_BROADCAST_STOP}
              activeLabel="END BROADCAST"
              defaultIcon={NEW_BROADCAST_START}
              defaultLabel="START PUBLIC BROADCAST"
              onClick={onClick}
              showDefault={!isBroadcasting}
              buttonType={
                isBroadcasting
                  ? IIconTextButtonTypes.DANGER_PRIMARY
                  : IIconTextButtonTypes.SUCCESS_PRIMARY
              }
            />
          );
        }}
        handleConfirm={({ setShow }) => {
          onBroadcastButtonClick(!isBroadcasting);
          setShow(false);
        }}
        exitText={`Are you sure you want to ${
          isBroadcasting ? 'end' : 'start'
        } this session?`}
        confirmButtonText={isBroadcasting ? 'End' : 'Start'}
      />
    ),
    [BROADCAST_PANEL_BUTTON]: (
      <SessionConfirmModal
        currentSession={currentSession}
        trigger={({ setShow }) => {
          const onClick = () => setShow(true);
          return (
            <div>
              <IconTextButton
                key="broadcastButton"
                activeIcon={NEW_BROADCAST_STOP}
                activeLabel="END SESSION"
                defaultIcon={NEW_BROADCAST_START}
                defaultLabel={isMobile ? 'START' : 'START SESSION'}
                onClick={onClick}
                showDefault={
                  !(
                    currentSession &&
                    currentSession.broadcastStatus !==
                      SessionBroadcastStatus.PENDING
                  )
                }
                buttonType={getBroadcastButtonStyle()}
              />
            </div>
          );
        }}
        handleConfirm={({ setShow }, isArchiveData) => {
          handleBroadcastButtonClick(isArchiveData);
          setShow(false);
        }}
      />
    ),
    [BROADCAST_PAUSE_RESUME_BUTTON]: (
      <ConfirmExitModal
        title={
          currentSession.broadcastStatus === SessionBroadcastStatus.STARTED
            ? 'Pause session?'
            : 'Resume session?'
        }
        headerClassName="backStageModalWarningHeaderNew"
        className={styles.confirmModal}
        cancelButtonClassName={styles.cancelButton}
        confirmButtonClassName={styles.warningButton}
        trigger={({ setShow }) => {
          const onClick = () => setShow(true);
          return (
            <div>
              <IconTextButton
                key="broadcastButton"
                activeIcon={NEW_BROADCAST_RESUME}
                activeLabel="RESUME SESSION"
                defaultIcon={NEW_BROADCAST_PAUSE}
                defaultLabel="PAUSE SESSION"
                onClick={onClick}
                showDefault={
                  (currentSession &&
                    currentSession.broadcastStatus ===
                      SessionBroadcastStatus.STARTED) ||
                  false
                }
                buttonType={
                  (currentSession &&
                    currentSession.broadcastStatus ===
                      SessionBroadcastStatus.STARTED) ||
                  false
                    ? IIconTextButtonTypes.BCKSTG_PAUSE_SESSION
                    : IIconTextButtonTypes.BCKSTG_PAUSE_SESSION
                }
              />
            </div>
          );
        }}
        handleConfirm={({ setShow }) => {
          handleBroadcastPauseResumeButtonClick();
          setShow(false);
        }}
        exitText={`Are you sure you want to ${
          currentSession.broadcastStatus === SessionBroadcastStatus.STARTED
            ? 'pause'
            : 'resume'
        } this session?`}
        confirmButtonText={
          currentSession.broadcastStatus === SessionBroadcastStatus.STARTED
            ? 'Yes, Pause'
            : 'Yes, Resume'
        }
      />
    ),
    [BROADCAST_DRY_RUN_PANEL_BUTTON]: (
      <ConfirmExitModal
        confirmButtonType={IButtonTypes.NO_BG_CONTRAST}
        cancelButtonType={IButtonTypes.NO_BG_CONTRAST}
        title={
          stageSettingsObj?.isDryRunRunning ? 'End Dry Run?' : 'Start Dry Run?'
        }
        headerClassName={
          stageSettingsObj?.isDryRunRunning
            ? 'backStageModalDangerHeaderNew'
            : 'backStageModalHeaderNew'
        }
        className={styles.confirmModal}
        cancelButtonClassName={styles.cancelButton}
        confirmButtonClassName={
          stageSettingsObj?.isDryRunRunning
            ? styles.destructiveButton
            : styles.constructiveButton
        }
        trigger={({ setShow }) => {
          const onClick = () => setShow(true);
          return (
            <div data-testid="dry_run_start_end">
              <IconTextButton
                key="broadcastButton"
                activeIcon={NEW_BROADCAST_STOP}
                activeLabel="END DRY RUN"
                defaultIcon={NEW_BROADCAST_START}
                defaultLabel="START DRY RUN"
                onClick={onClick}
                showDefault={!stageSettingsObj.isDryRunRunning}
                buttonType={
                  !stageSettingsObj?.isDryRunRunning
                    ? IIconTextButtonTypes.BCKSTG_SUCCESS_SQUARE_RADIUS
                    : IIconTextButtonTypes.BCKSTG_DANGER_SQUARE_RADIUS
                }
              />
            </div>
          );
        }}
        handleConfirm={({ setShow }) => {
          handleBroadcastButtonClick(false);
          setShow(false);
        }}
        description={
          stageSettingsObj?.isDryRunRunning
            ? ''
            : 'Starting the dry run will make the stage live for the attendees'
        }
        exitText={`Are you sure you want to ${
          stageSettingsObj?.isDryRunRunning
            ? ' end dry run?'
            : ' start broadcasting?'
        }`}
        confirmButtonText={
          stageSettingsObj?.isDryRunRunning ? 'Yes, End' : 'Yes, Broadcast'
        }
      />
    ),
    [EMOJIS_BAR]: channelSettingsByChannelId &&
      channelSettingsByChannelId.hasEmoji && (
        <EmojiBar onEmojiClick={onEmojiClick} />
      ),
    [EMOJIS_BAR_BUTTON]: <EmojiBarButton onEmojiClick={onEmojiClick} />,
    [BROADCAST_RECORDING_BUTTON]: (
      <StreamRecordingToggle
        isBroadcasting={isBroadcasting}
        stageSettingsObj={stageSettingsObj}
        updateStageRecordingToggle={updateStageRecordingToggle}
      />
    ),
    [RAISED_HAND_BUTTON]: <RaisedHandRequestButton />,
    [STREAM_SETTINGS_BAR]: (
      <GenericErrorBoundary showFallbackUI>
        <StreamSettingsBar
          settingsData={stageSettingsObj}
          setShowStageAudioTooltip={setShowStageAudioTooltip}
          isOrganizer={isOrganizer}
          refId={(() => {
            if (parent === IStageParent.LIVE_DISCUSSION)
              return discussionTableId;
            return stageId;
          })()}
          parent={parent}
          handleEmojiClick={undefined}
          handleToggleAudio={undefined}
          isMuted={undefined}
          isWebinar={undefined}
          isBroadcasting={isBroadcasting}
          setIsMenuVisible={undefined}
        />
      </GenericErrorBoundary>
    ),
    [EXIT_STREAM_BUTTON]: (
      <div id={EXPO_BOOTH_LIVE_STREAM_STEPS.BACK_TO_CONTENT}>
        <ConfirmExitPromptsModal
          exitText="Are you sure you want to end the video stream and move out of stage?"
          cancelButtonText="Stay here"
          confirmButtonType={IButtonTypes.NO_BG_CONTRAST}
          cancelButtonType={IButtonTypes.PRIMARY}
          title="Exit Stage"
          headerClassName={styles['exitModalHeader']}
          trigger={({ setShow }) => (
            <IconTextButton
              key="leaveStream"
              activeIcon={NEW_STREAM_LEAVE}
              defaultIcon={STREAM_JOIN}
              activeLabel="EXIT STAGE"
              defaultLabel="join"
              onClick={() => {
                setShow(true);
              }}
              buttonType={IIconTextButtonTypes.BCKSTG_DANGER_SQUARE_RADIUS}
            />
          )}
          handleConfirm={async ({ setShow }) => {
            leave();
            setShow(false);
          }}
        />
      </div>
    ),
    [EXIT_BACKSTAGE_BUTTON]: (
      <ConfirmExitPromptsModal
        exitText="Are you sure you want to exit backstage?"
        cancelButtonText="Stay here"
        confirmButtonType={IButtonTypes.NO_BG_CONTRAST}
        cancelButtonType={IButtonTypes.PRIMARY}
        title="Exit Backstage"
        headerClassName={styles['exitModalHeader']}
        trigger={({ setShow }) => (
          <IconTextButton
            key="leaveStream"
            activeIcon={NEW_STREAM_LEAVE}
            defaultIcon={STREAM_JOIN}
            activeLabel={isMobile ? 'EXIT' : 'EXIT BACKSTAGE'}
            defaultLabel="join"
            onClick={() => {
              setShow(true);
            }}
            buttonType={IIconTextButtonTypes.BCKSTG_DANGER_SQUARE_RADIUS}
          />
        )}
        handleConfirm={async ({ setShow }) => {
          await leave();
          // Remove the history.push because the leave method is already taking care of it.
          setShow(false);
        }}
      />
    ),
    [RETURN_TO_BACKSTAGE_BUTTON]: (
      <ConfirmExitModal
        headerClassName={styles['exitModalHeader']}
        confirmButtonType={IButtonTypes.NO_BG_CONTRAST}
        cancelButtonType={IButtonTypes.PRIMARY}
        title="Return To Backstage?"
        trigger={({ setShow }) => (
          <IconTextButton
            key="leaveStream"
            activeIcon={NEW_STREAM_LEAVE}
            defaultIcon={STREAM_JOIN}
            activeLabel={isMobile ? 'EXIT' : 'EXIT BACKSTAGE'}
            defaultLabel="join"
            onClick={() => {
              setShow(true);
            }}
            buttonType={IIconTextButtonTypes.BCKSTG_DANGER_SQUARE_RADIUS}
          />
        )}
        handleConfirm={async ({ setShow }) => {
          if (!videoEnabled) {
            window.location.reload();
            return;
          }
          moveUserToGreenRoom(localStream.uid);
          setShow(false);
        }}
        exitText={`Are you sure you want to leave the stage?${
          videoEnabled ? '' : ' (The page will be reloaded)'
        }`}
        confirmButtonText="Yes"
      />
    ),
    [EXIT_ROOM_BUTTON]: (
      <ConfirmExitPromptsModal
        title="Leave Room"
        exitText="You're about to exit this room.$nAre you sure you want to leave ?"
        cancelButtonText="Stay here"
        disableClose={false}
        confirmButtonType={IButtonTypes.NO_BG_CONTRAST}
        cancelButtonType={IButtonTypes.PRIMARY}
        trigger={({ setShow }) => (
          <Button
            label="Exit Room"
            styleClass={IButtonTypes.ROOMS_DANGER_SQUARE_RADIUS}
            type="button"
            onClick={() => {
              setShow(true);
            }}
            dataTestId="exit_room"
          />
        )}
        handleConfirm={async ({ setShow }) => {
          await leave();
          setShow(false);
        }}
      />
    ),
    [RETURN_TO_EXPO_BOOTH]: (
      <IconTextButton
        id={EXPO_BOOTH_LIVE_STREAM_STEPS.BACK_TO_CONTENT}
        key="leaveExpoStream"
        activeIcon={NEW_STREAM_LEAVE}
        defaultIcon={STREAM_JOIN}
        activeLabel="RETURN TO EXPO BOOTH"
        defaultLabel="join"
        onClick={e => {
          toggleBoothLiveView();
          e.stopPropagation();
        }}
        buttonType={IIconTextButtonTypes.BCKSTG_DANGER_SQUARE_RADIUS}
      />
    ),
    [JOIN_BACKSTAGE_BUTTON]: (
      <Link to={`/l/event/${eventId}/stages/${stageId}/backstage`}>
        <Button
          type="button"
          styleClass={IButtonTypes.PRIMARY}
          label="JOIN BACKSTAGE"
        />
      </Link>
    ),
    [CIPLA_BUTTON1]: (
      <a
        style={{ marginRight: '10px' }}
        href="https://drive.google.com/file/d/1ZdKoP6VeAgAM8bqqGoPEaWUzobmhKhNR/view?usp=sharing"
        target="_blank"
      >
        <Button
          type="button"
          styleClass={IButtonTypes.PRIMARY_SQUARE_FULL}
          label="Survey consent"
        />
      </a>
    ),
    [CIPLA_BUTTON2]: (
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSc855iuoy8PpSekyPG_jVg4PzWSR6apcF3S0bgDsrX0xqyHog/viewform?usp=sf_link"
        target="_blank"
      >
        <Button
          type="button"
          styleClass={IButtonTypes.PRIMARY_SQUARE_FULL}
          label="Questionnaire"
        />
      </a>
    ),
    [JOIN_STAGE_BUTTON]: (
      <IconTextButton
        activeIcon={KEYNOTE}
        activeLabel={`${
          raisedHandStatus === IRaisedHandRequestStatus.REQUESTED
            ? 'Cancel Join Stage'
            : 'Join Stage'
        }`}
        buttonType={IButtonTypes.NEW_DANGER}
        defaultIcon={KEYNOTE}
        defaultLabel={`${
          raisedHandStatus === IRaisedHandRequestStatus.REQUESTED
            ? 'Cancel Join Stage'
            : 'Join Stage'
        }`}
        onClick={handleJoinStageButtonClick}
      />
    ),
    [JOIN_STREAM_BUTTON]: (
      <Button
        type="button"
        styleClass={IButtonTypes.NEW_DANGER}
        label="Join Stream"
        onClick={handleJoinStreamButtonClick}
      />
    ),
    [JOIN_STREAM_MODAL_BUTTON]: (
      <StreamPreviewModal
        localClient={localClient}
        localStream={localStream}
        setBroadcastPermissions={setBroadcastPermissions}
        showIconTextButton={true}
        defaultOpen={true}
        parent={parent}
        virtualBackground={virtualBackground}
      />
    ),
    [MIC_TOGGLE]: (
      <div id={STREAM_FLOW_STEPS.AUDIO}>
        <IconButton
          key="micButton"
          activeIcon={NEW_MIC_OFF}
          defaultIcon={NEW_MIC_ON}
          onClick={onAudioToggle}
          disabled={togglingAudio || isMicrophoneListEmpty}
          showDefault={audioEnabled}
          type={
            isMicrophoneListEmpty
              ? IIconButtonTypes.ROUND_DISABLED
              : IIconButtonTypes.ROUND_THEME
          }
          iconBG={IIconButtonBGColors.NEUTRAL_LIGHT_3}
          iconSize={EMOJI_ICON_SIZE}
          tooltip={isMobile ? 'left' : 'top'}
        />
      </div>
    ),
    [SCREEN_OR_FILE_SHARE_TOGGLE]: (
      <ScreenOrFileShareToggle
        isOrganizer={isOrganizer}
        openMediaTabInGreenroomPanel={openMediaTabInGreenroomPanel}
        pendingScreen={pendingScreen}
        onScreenShareButtonClick={onScreenShareButtonClick}
        isScreenShared={isScreenShared}
        isPresentationShared={
          presentationConfig.currentPresentation?.presentationId &&
          presentationConfig.currentPresentation?.presentedBy ===
            currentUserAccountId
        }
        addPresentation={presentationConfig.addPresentation}
        stopPresentation={presentationConfig.stopPresentation}
        refreshCurrentPresentationState={
          presentationConfig.refreshCurrentPresentationState
        }
        setLoadingPresentation={presentationConfig.setLoadingPresentation}
        refreshPresentationList={presentationConfig.refreshPresentationList}
        pluginConfig={pluginConfig}
        showScreenOrFileToggle={showScreenOrFileToggle}
        isMiroPluginActive={!!miroPluginActive}
        activePluginDetails={miroPluginActive}
        parent={parent}
      />
    ),
    [SPEAKER_TIMER]: (
      <div
        className={classnames(styles.speakerTimerCountdown, {
          [styles.speakerTimerAlmostDone]: timeLeftMs < 60 * 2 * 1000,
        })}
      >
        <Text
          text={timeLeftMs ? 'Session ending in' : 'Session time up!'}
          textColor={ITextColors.INHERIT}
          textSize={ITextSizes.SMALL}
        />
        <Text
          text={timeLeftMsToMins(timeLeftMs)}
          textColor={ITextColors.INHERIT}
          textSize={ITextSizes.MEDIUM}
        />
      </div>
    ),
    [VIDEO_TOGGLE]: (
      <div id={STREAM_FLOW_STEPS.VIDEO}>
        <IconButton
          key="videoButton"
          activeIcon={NEW_VIDEO_OFF}
          defaultIcon={NEW_VIDEO_ON}
          onClick={onVideoToggle}
          showDefault={videoEnabled}
          disabled={togglingVideo || isCameraListEmpty}
          type={
            isCameraListEmpty
              ? IIconButtonTypes.ROUND_DISABLED
              : IIconButtonTypes.ROUND_THEME
          }
          iconBG={IIconButtonBGColors.NEUTRAL_LIGHT_3}
          iconSize={EMOJI_ICON_SIZE}
        />
      </div>
    ),
    [VOLUME_TOGGLE]: (
      <div
        className={classnames(styles.volumeToggle, {
          [styles.volumeToggleForMobile]: isMobile,
        })}
        id="volume-toggle-button"
      >
        <IconButton
          key="micButton"
          activeIcon={
            parent === IStageParent.BOOTH ? BOOTH_VOLUME_OFF : NEW_VOLUME_OFF
          }
          defaultIcon={
            parent === IStageParent.BOOTH ? BOOTH_VOLUME_ON : NEW_VOLUME_ON
          }
          onClick={toggleVolume}
          disabled={false}
          showDefault={isPreview ? !volumeOn : volumeOn}
          type={IIconButtonTypes.ROUND_WHITE_DARK_GREY}
        />
      </div>
    ),
    [STREAM_TITLE]: (
    // TODO: Add stream title from greenRoom Config
      <Text
        key="infoStreamName"
        text="Stream Title"
        textAlign="center"
        textColor={IContentTextColorTypes.WHITE}
        textSize={IContentTextSizeTypes.XLARGE}
        textStyle={IContentTextStyleTypes.BOLDED}
      />
    ),
    [STREAM_INFO_ROOM_NAME]: (
      <>
        <div
          className={styles.roomInfoContainer}
          data-tip
          data-for="room_info_tooltip"
        >
          <div className={styles.roomTitleContainer}>
            <Text
              key="infoRoomName"
              text={selectedTable?.name}
              textAlign="left"
              textColor={IContentTextColorTypes.WHITE}
              textSize={IContentTextSizeTypes.LARGE}
              textStyle={IContentTextStyleTypes.BOLDED}
              textLineHeight="normal"
            />
            <Info color={getCssVar(IContentColors.WHITE)} size={14} />
          </div>
          <Text
            key="infoRoomDesc"
            text={handleRoomDesc(selectedTable?.description)}
            textAlign="left"
            textColor={IContentTextColorTypes.NEUTRAL_DARK}
            textSize={IContentTextSizeTypes.XSMALL}
            textStyle={IContentTextStyleTypes.DEFAULT}
            textLineHeight="normal"
          />
        </div>
        <ReactTooltip
          className={styles.roomInfoToolTipContainer}
          id="room_info_tooltip"
          place="top"
          effect="solid"
          backgroundColor="#1F1F1F"
          clickable
          textColor="#B8B8B8"
        >
          <Text
            key="infoRoomNameToolTip"
            text={selectedTable?.name}
            textAlign="left"
            textColor={IContentTextColorTypes.WHITE}
            textSize={IContentTextSizeTypes.LARGE}
            textStyle={IContentTextStyleTypes.BOLDED}
          />
          {selectedTable?.description ? Parser(selectedTable?.description) : ''}
        </ReactTooltip>
      </>
    ),
    [STREAM_INFO_ROOM_NUM]: (
      <Text
        key="infoRoomNum"
        text={`Room ${selectedTable?.tableNo}`}
        textAlign="center"
        textColor={IContentTextColorTypes.NEUTRAL_DARK}
        textSize={IContentTextSizeTypes.XSMALL}
        textStyle={IContentTextStyleTypes.BOLDED}
      />
    ),
    [STREAM_INFO_ROOM_LOGO]: (
      <Image
        key="infoRoomLogo"
        styleClass="streamInfoRoomLogo"
        src={
          selectedTable ? (
            selectedTable.logo
          ) : (
            <LogoNew className={styles.logo} />
          )
        }
      />
    ),
    [SESSION_NAME_LABEL]: (
      <Text
        key="infoSessionName"
        text={
          currentSession.isDummy
            ? currentSession.stageName
            : currentSession.title
        }
        textAlign="center"
        textColor={IContentTextColorTypes.WHITE}
        textSize={IContentTextSizeTypes.MEDIUM}
        textStyle={IContentTextStyleTypes.BOLDED}
      />
    ),
    [TRACK_SELECTION]: <TrackSelection attendeeChannel={attendeeChannel} />,
    [SESSION_CHANGE_DROPDOWN]: (
      <SessionSelectBar
        isBroadcasting={isBroadcasting}
        stageSettingsObj={stageSettingsObj}
        updateDryRunToggle={updateDryRunToggle}
      />
    ),
    [EXIT_EXPO_BOOTH]: (
      <div id={EXPO_BOOTH_LIVE_STREAM_STEPS.LIVE_STREAM}>
        <ConfirmExitPromptsModal
          exitText="Are you sure you want to exit the booth?"
          cancelButtonText="Stay here"
          confirmButtonType={IButtonTypes.NO_BG_CONTRAST}
          cancelButtonType={IButtonTypes.PRIMARY}
          title="Exit Booth"
          disableClose={false}
          trigger={({ setShow }) => (
            <IconTextButton
              activeIcon={STREAM_LEAVE}
              defaultIcon={STREAM_LEAVE}
              activeLabel="Exit Live Expo"
              defaultLabel="Exit Live Expo"
              showDefault={true}
              buttonType={IIconTextButtonTypes.FUNCTIONAL_DANGER_EXIT_EXPO}
              onClick={() => {
                setShow(true);
              }}
            />
          )}
          handleConfirm={async ({ setShow }) => {
            leave();
            setShow(false);
          }}
        />
      </div>
    ),
    [RECORDING_INDICATOR_BAR]: (
      <RecordingIndicatorBar
        currentSession={currentSession}
        showRecordingIndicator={
          broadcastPermissions && stageSettingsObj?.isRecordingSupport
        }
      />
    ),
    [STREAM_USER_TALKING_INFO_VIEW]: (
      <StreamTalkingUserInfo stageId={stageId} />
    ),
    [VIRTUAL_BACKGROUND_BUTTON]: (
      <IconButton
        key="virtualBgBtn"
        defaultIcon={VIRTUAL_BACKGROUND_ON}
        disabled={!localStream}
        activeIcon={VIRTUAL_BACKGROUND_OFF}
        showDefault={virtualBackground?.isEnabled}
        onClick={virtualBackground?.toggle}
        type={IIconButtonTypes.ROUND_THEME}
        iconBG={IIconButtonBGColors.NEUTRAL_LIGHT_3}
        iconSize={MEDIUM_ICON_SIZE}
      />
    ),
    [MORE_OPTIONS_BTN]: (
      <IconButton
        key="moreOptions"
        showDefault
        defaultIcon={MORE}
        activeIcon={MORE}
        onClick={() => setIsMoreOptionsViewVisible(!isMoreOptionsViewVisible)}
        type={IIconButtonTypes.ROUND_THEME}
        iconBG={IIconButtonBGColors.NEUTRAL_LIGHT_3}
        iconSize={STANDARD_ICON_BUTTON_SIZE}
      />
    ),
  };

  const TopCenterStreamOptions = (() => {
    if (isBackstage && isOrganizer) {
      if (broadcastPermissions) {
        return [SESSION_CHANGE_DROPDOWN];
      }
    }
    if (currentSession) {
      return [SESSION_NAME_LABEL];
    }
    return [];
  })();

  const BottomCenterStreamOptions = (() => {
    if (!broadcastPermissions) {
      return [];
    }
    const options = [MIC_TOGGLE, VIDEO_TOGGLE, SCREEN_OR_FILE_SHARE_TOGGLE];

    if (showVirtualBgSetting) {
      options.push(VIRTUAL_BACKGROUND_BUTTON);
    }

    if (isDiscussion) {
      if (!selectedDiscussionTable?.isMeetingRoom) {
        options.push(EMOJIS_BAR_BUTTON);
      }
      options.push(RAISED_HAND_BUTTON);
      if (isOrganizerOrRoomOwner) {
        options.push(MORE_OPTIONS_BTN);
      }
    }
    return options;
  })();

  const TopLeftStreamOptions = (() => {
    if (isStreamsEmpty && !currentLiveStream && !currentlyPlayingVideo) {
      if (broadcastPermissions && !isBoothOwner && isBackstage) {
        return [EXIT_BACKSTAGE_BUTTON];
      }
      return [];
    }

    if (isStage && (isBroadcasting || showJoinBackStageButtonWhenOverlay)) {
      if (
        raisedHandStatus === IRaisedHandRequestStatus.LIVE &&
        broadcastPermissions
      ) {
        return [EXIT_BACKSTAGE_BUTTON];
      }
    } else if (!isStage && isOrganizer && isBoothOwner && !isDiscussion) {
      // for the booth owner to return to expo content
      return [EXIT_EXPO_BOOTH, RETURN_TO_EXPO_BOOTH];
    } else if (parent === IStageParent.BOOTH) {
      return [RETURN_TO_EXPO_BOOTH];
    } else if (isStageFromBackstage && isOrganizer) {
      // for the organiser to return to backstage
      if (
        broadcastPermissions &&
        BottomCenterStreamOptions.includes(JOIN_STREAM_MODAL_BUTTON)
      ) {
        return [RETURN_TO_BACKSTAGE_BUTTON];
      }
      return [];
    } else if (isBackstage || isBoothOwner) {
      if (isSpeaker || isBoothOwner) {
        if (!broadcastPermissions) {
          return [JOIN_STREAM_MODAL_BUTTON];
        }
        // for the speaker to return to backstage
        if (isSpeaker) {
          return [EXIT_STREAM_BUTTON];
        }

        return [];
      }
      if (isOrganizer) {
        return [EXIT_BACKSTAGE_BUTTON];
      }
    } else if (isDiscussion) {
      if (broadcastPermissions) {
        return [EXIT_ROOM_BUTTON];
      }
      return [JOIN_STREAM_MODAL_BUTTON];
    }

    return [];
  })();

  const BottomLeftStreamOptions = (() => {
    const bottomLeftComponentsResult: any = [];

    if (showJoinBackStageButton) {
      bottomLeftComponentsResult.push(JOIN_BACKSTAGE_BUTTON);
    }

    if (isAttendee) {
      bottomLeftComponentsResult.push(TRACK_SELECTION);
    }

    // Show room info in rooms
    if (broadcastPermissions && isDiscussion) {
      bottomLeftComponentsResult.push(STREAM_INFO_ROOM_NAME);
    }

    // default for specific event
    if (isAttendee && eventId === '078858b8-0f2c-4b25-a304-47ba7ea27a20') {
      bottomLeftComponentsResult.push(CIPLA_BUTTON1, CIPLA_BUTTON2);
    }

    if (
      currentSession &&
      currentSession.broadcastStatus === SessionBroadcastStatus.STARTED &&
      !isMobile
    ) {
      bottomLeftComponentsResult.push(RECORDING_INDICATOR_BAR);
    }

    if (showVolumeToggle) {
      bottomLeftComponentsResult.push(VOLUME_TOGGLE);
    }

    if (!isMobile && !isAttendee && hasGreenRoomStreams && isBackstage) {
      bottomLeftComponentsResult.push(STREAM_USER_TALKING_INFO_VIEW);
    }

    if (isMobile && isStage && isBroadcasting) {
      bottomLeftComponentsResult.push(STREAM_SETTINGS_BAR);
    }

    return bottomLeftComponentsResult;
  })();

  const TopRightStreamOptions = (() => {
    if (isBackstage) {
      // if it's an organizer, add the option to broadcast
      if (isOrganizer && broadcastPermissions) {
        const list = [] as any;
        // pause and end session button disabled but don't delete this code
        // TODO: need to fix streams disappearing and enable below buttons.
        // if (
        //   currentSession &&
        //   (currentSession.broadcastStatus === SessionBroadcastStatus.PAUSED ||
        //     currentSession.broadcastStatus === SessionBroadcastStatus.STARTED)
        // ) {
        //   list.push(BROADCAST_PAUSE_RESUME_BUTTON);
        // }
        if (stageSettingsObj && stageSettingsObj.isDryRun) {
          list.push(BROADCAST_DRY_RUN_PANEL_BUTTON);
        } else {
          list.push(BROADCAST_PANEL_BUTTON);
        }
        return list;
      }
    }
    return [];
  })();

  const { messageIcon, messageText } = (() => {
    if (!isBackstage || hasGreenRoomStreams || !broadcastPermissions) {
      return {};
    }
    if (!audioEnabled && !isMobile) {
      return {
        messageIcon: (
          <MicOffCurved size={20} color={IContentColors.MONOCHROME_CONTRAST} />
        ),
        messageText: 'Your mic is on mute',
      };
    }
    return {};
  })();

  const BottomRightStreamOptions = (() => {
    if (isBackstage) {
      // if it's an organiser, add the option to broadcast
      if (isOrganizer) {
        let isEventCheckInBefore = false;
        if (event) {
          const eventStartDate = new Date(Date.parse(event.startDateTime));
          eventStartDate.setHours(0, 0, 0, 0);
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          if (eventStartDate.getTime() > today.getTime()) {
            isEventCheckInBefore = true;
          }
        }
        if (
          broadcastPermissions &&
          stageSettingsObj &&
          !stageSettingsObj.isDryRun &&
          !isEventCheckInBefore
        ) {
          return [BROADCAST_RECORDING_BUTTON, STREAM_SETTINGS_BAR];
        }
        if (broadcastPermissions) {
          return [STREAM_SETTINGS_BAR];
        }
      } else if (isSpeaker && broadcastPermissions) {
        return [STREAM_SETTINGS_BAR];
      }
    }
    if (isStreamsEmpty && !currentLiveStream && !currentlyPlayingVideo) {
      return [];
    }
    // Emoji bar
    if (isStage || isBooth) {
      if (
        isAttendee &&
        raisedHandStatus === IRaisedHandRequestStatus.LIVE &&
        broadcastPermissions &&
        isStage
      ) {
        return [STREAM_SETTINGS_BAR];
      }
      if (
        (isAttendee || !hasBackstageAccess || isBoothOwnerAccess) &&
        isBroadcasting
      ) {
        return [EMOJIS_BAR, STREAM_SETTINGS_BAR];
      }

      if (hasBackstageAccess && isBroadcasting) {
        return [EMOJIS_BAR];
      }
    }
    if (isBackstage || isBoothOwner) {
      // there's already people in the stream but the organizer isn't there
      if (
        isOrganizer &&
        !broadcastPermissions &&
        !BottomCenterStreamOptions.includes(JOIN_STREAM_MODAL_BUTTON)
      ) {
        return [JOIN_STREAM_MODAL_BUTTON];
      }
    }
    if (isDiscussion && broadcastPermissions) {
      return [STREAM_SETTINGS_BAR, STREAM_INFO_ROOM_LOGO];
    }
    if (isBroadcasting) {
      return [STREAM_SETTINGS_BAR];
    }
    return [];
  })();

  if (
    eventId === '651413b5-187f-45ef-bf62-c27bffed09d4' &&
    parent === IStageParent.LIVE_STAGE
  ) {
    return <></>;
  }

  const ref = React.useRef<SheetRef>();
  const snapTo = (i: number) => {
    setSnapPoint(i);
    ref.current?.snapTo(i);
  };

  const InteractionIcon = () => (
    <svg
      width="20"
      height="6"
      viewBox="0 0 20 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.interactionButtonsvg}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.80002 0.600098C1.47454 0.600098 0.400024 1.67461 0.400024 3.0001C0.400024 4.32558 1.47454 5.4001 2.80002 5.4001C4.12551 5.4001 5.20002 4.32558 5.20002 3.0001C5.20002 1.67461 4.12551 0.600098 2.80002 0.600098ZM10 0.600098C8.67454 0.600098 7.60002 1.67461 7.60002 3.0001C7.60002 4.32558 8.67454 5.4001 10 5.4001C11.3255 5.4001 12.4 4.32558 12.4 3.0001C12.4 1.67461 11.3255 0.600098 10 0.600098ZM14.8 3.0001C14.8 1.67461 15.8745 0.600098 17.2 0.600098C18.5255 0.600098 19.6 1.67461 19.6 3.0001C19.6 4.32558 18.5255 5.4001 17.2 5.4001C15.8745 5.4001 14.8 4.32558 14.8 3.0001Z"
        fill="white"
      />
    </svg>
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const interactionButton = (
    <button
      onClick={() => setIsExpanded(prevValue => !prevValue)}
      className={classnames(styles.interactionButton, {
        [styles.interactionButtonOpen]: isExpanded,
      })}
      title="interaction"
    >
      <InteractionIcon />
    </button>
  );
  const isBoothPage = location.pathname.includes('booth');

  return (
    <>
      <div className={styles.streamOptionsBottomBarContainerHeight}>
        <div
          id="streamOptionsTopContainer"
          className={classnames({
            [styles.streamOptionsTopBarContainerSidePanelIsOpen]:
              showSidePanel && channelSidePanelState,
            [styles.streamOptionsTopBarContainer]:
              showSidePanel && !channelSidePanelState,
            [styles.streamOptionsTopBarFullWidthContainerSidePanelIsOpen]:
              !showSidePanel && channelSidePanelState,
            [styles.streamOptionsTopBarFullWidthContainer]:
              !showSidePanel && !channelSidePanelState,
          })}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div
            id="topStreamOptionsContainer"
            className={styles.topStreamOptionsContainer}
          >
            <div
              className={classnames(styles.topLeftStreamOptions, {
                [styles.visible]: isVisible,
                [styles.hidden]: !isVisible,
              })}
            >
              {TopLeftStreamOptions.map(option => ICON_MAP[option])}
            </div>
            <div
              className={classnames(styles.topCenterStreamOptions, {
                [styles.visible]: isVisible,
                [styles.hidden]: !isVisible,
              })}
            >
              {TopCenterStreamOptions.map(option => ICON_MAP[option])}
            </div>
            <div
              className={classnames(styles.topRightStreamOptions, {
                [styles.visible]: isVisible,
                [styles.hidden]: !isVisible,
              })}
            >
              {TopRightStreamOptions.map(option => ICON_MAP[option])}
            </div>
          </div>
        </div>
        {channelSettingsByChannelId &&
          channelSettingsByChannelId.hasEmoji &&
          isBroadcasting &&
          isStage &&
          isMobile &&
          isAttendee &&
          raisedHandStatus !== IRaisedHandRequestStatus.LIVE &&
          !isStreamsEmpty &&
          !broadcastPermissions && <EmojiBar onEmojiClick={onEmojiClick} />}
        {isMobile && showVolumeToggle && !broadcastPermissions && (
          <div className={styles.bottomLeftContainer}>
            {BottomLeftStreamOptions.map(option => ICON_MAP[option])}
          </div>
        )}
        {isMobile && !isDiscussion && !toggleLeftPanel && (
          <BottomSheet
            ref={ref}
            snapPoints={isBoothPage ? [0.12, 0] : [0.24, 0.12, 0]}
            className={styles.bottomSheetOverflow}
            initialSnap={0}
            onClose={() => {
              snapTo(0);
            }}
            onSnap={snapPoints => setSnapPoint(snapPoints)}
            isOpen={broadcastPermissions}
            onBackdropTouch={() => snapTo(1)}
            backdropStyle={{
              left: selectedSnapPoint == 0 && !isBoothPage ? 0 : '-5000px',
            }}
            id="streamOptionsBarBottomSheet"
            disableDrag={!(parent === IStageParent.LIVE_BACKSTAGE)}
          >
            <div
              className={classnames(styles.bottomSheetContent, {
                [styles.attendeeOnStage]: isAttendee && isStage,
              })}
            >
              {messageText && (
                <div
                  className={classnames(styles.messageContainer, {
                    [styles.messageContainerWithGreenRoomPanel]: showGreenRoomActionPanel,
                  })}
                >
                  <div className={styles.messageWrapper}>
                    {messageIcon}
                    <Text
                      text={messageText}
                      textColor={ITextColors.MONOCHROME_CONTRAST}
                      textSize={ITextSizes.LARGE}
                      textStyle={ITextStyles.BOLDED}
                    />
                  </div>
                </div>
              )}
              <div
                className={classnames(styles.streamOptionsBottomBarContainer, {
                  [styles.streamOptionsBottomBarContainerWithGreenRoomActionPanel]: showGreenRoomActionPanel,
                  [styles.streamOptionsBottomBarContainerForAttendee]:
                    isAttendee && isStage,
                  [styles.streamOptionsBarContainerForMobileExpo]:
                    isBoothPage && isMobile,
                })}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div
                  className={classnames(styles.bottomLeftStreamOptions, {
                    [styles.visible]: isVisible,
                    [styles.hidden]: !isVisible,
                  })}
                >
                  {BottomLeftStreamOptions.map(option => ICON_MAP[option])}
                </div>
                <div
                  className={classnames(styles.bottomCenterStreamOptions, {
                    [styles.visible]: isVisible,
                    [styles.hidden]: !isVisible,
                  })}
                >
                  {BottomCenterStreamOptions.map(option => ICON_MAP[option])}
                </div>
                <div
                  className={classnames(styles.bottomRightStreamOptions, {
                    [styles.visible]: isVisible,
                    [styles.hidden]: !isVisible,
                  })}
                >
                  {BottomRightStreamOptions.map(option => ICON_MAP[option])}
                </div>
              </div>
              {!isBoothPage && (
                <div id="buttonBox" className={styles.buttonBox}>
                  {isOrganizer && !isAttendee && (
                    <button
                      type="button"
                      onClick={() => {
                        () => snapTo(2);
                        setToggleLeftPanel(1);
                      }}
                      title="stage controls"
                    >
                      Stage controls
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() => {
                      snapTo(2);
                      setShowTabIndex(ZoneSidePanels.CHAT);
                      setChatPanelOpen(true);
                    }}
                    title="interactions"
                  >
                    Interactions
                  </button>
                </div>
              )}
            </div>
          </BottomSheet>
        )}
        {!isMobile && (
          <div
            className={classnames(
              styles.streamOptionsBottomBarContainerWrapper,
            )}
          >
            <div
              className={classnames(styles.streamOptionsBottomBarContainer, {
                // Rooms
                [styles.streamOptionsBottomBarContainerInRoom]: isDiscussion,
                [styles.streamOptionsBottomBarContainerWithGreenRoomActionPanel]: showGreenRoomActionPanel,
                [styles.streamOptionsBottomBarContainerForAttendee]:
                  isAttendee && isStage,
              })}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div
                className={classnames(
                  styles.streamOptionsBottomBarContainerInner,
                )}
              >
                <div
                  className={classnames(styles.bottomLeftStreamOptions, {
                    [styles.bottomLeftStreamOptionsInRooms]:
                      parent === IStageParent.LIVE_DISCUSSION,
                    [styles.visible]: isVisible,
                    [styles.hidden]: !isVisible,
                  })}
                >
                  {BottomLeftStreamOptions.map(option => ICON_MAP[option])}
                </div>
                <div
                  className={classnames(styles.bottomCenterStreamOptions, {
                    [styles.visible]: isVisible,
                    [styles.hidden]: !isVisible,
                  })}
                >
                  {BottomCenterStreamOptions.map(option => ICON_MAP[option])}
                </div>
                <div
                  className={classnames(styles.bottomRightStreamOptions, {
                    [styles.visible]: isVisible,
                    [styles.hidden]: !isVisible,
                  })}
                >
                  {BottomRightStreamOptions.map(option => ICON_MAP[option])}
                </div>
                {!isDiscussion && (
                  <div id="buttonBox" className={styles.buttonBox}>
                    {isOrganizer && (
                      <button
                        type="button"
                        onClick={() => {
                          () => snapTo(2);
                          setToggleLeftPanel(1);
                        }}
                        title="stage controls"
                      >
                        Stage controls
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() => {
                        snapTo(2);
                        setShowTabIndex(EventSidePanels.CHAT);
                        setChatPanelOpen(true);
                      }}
                      title="interactions"
                    >
                      Interactions
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {isMobile && isDiscussion && BottomCenterStreamOptions?.length > 0 && (
          <div
            className={classnames({
              [styles.videoActionsWrapperOpen]: isExpanded,
              [styles.videoActionsWrapper]: !isExpanded,
              [styles.videoActionsWrapperForRoom]: isDiscussion,
            })}
          >
            <div className={styles.videoActions}>
              {BottomCenterStreamOptions.map(option => ICON_MAP[option])}
              {/* <IconButton disabled={!isRoom} onClick={handleScreenShareButtonClick} defaultIcon={IIconButtonUIIcons.SHARE_SCREEN_OFF} activeIcon={IIconButtonUIIcons.SHARE_SCREEN} type={IIconButtonTypes.ROUND_GREY_BLACK} iconBG={IIconButtonBGColors.NEUTRAL_DARK} /> */}
            </div>
          </div>
        )}
      </div>
      <MoreOptionsBtnView
        ref={moreOptionsViewRef}
        isComponentVisible={isMoreOptionsViewVisible}
      />
    </>
  );
};

export default StreamOptionsBar;
