// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__keynoteContainer___2OMei{display:flex;flex-flow:row nowrap;align-items:center;justify-content:center;position:relative;overflow:hidden}.styles-module__keynoteSpeakersContainer___3vqEf{display:flex;flex-flow:column nowrap;align-items:center;justify-content:center;position:relative;overflow:auto}.styles-module__keynoteAlignTop___gkui7{justify-content:flex-start !important}\n", ""]);
// Exports
exports.locals = {
	"keynoteContainer": "styles-module__keynoteContainer___2OMei",
	"keynoteSpeakersContainer": "styles-module__keynoteSpeakersContainer___3vqEf",
	"keynoteAlignTop": "styles-module__keynoteAlignTop___gkui7"
};
module.exports = exports;
