import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
// components
import NewLabel, {
  INewLabelColors,
  INewLabelMargins,
  INewLabelSizes,
  INewLabelStyles,
} from '@/components/ui/content/NewLabel';
import ProfilePicture from '@/components/ui/ProfilePicture';
import SearchField from '@/components/ui/fields/SearchField';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
// helpers + hooks + modals
import useAttendeeList from '@/services/messaging/use-attendee-list';
import { selectCurrentUser } from '@/models/account';
import { useDebounced } from '@/utils/helpers';
// Icons
import { ArrowRight, X } from 'react-feather';
// styles
import styles from './styles.module.scss';
import { IUserPrivacyTypes } from '@/types/userPrivacySetting';
import Text, {
  ITextColors,
  ITextSizes,
  ITextStyles,
} from '@/components/ui/content/Text';

const attendeeListLimit = 50;

const SearchEventMemberForm = ({ handleNextButtonClick }) => {
  const { eventId } = useParams() as any;
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const [searchText, setSearchText] = useState('');
  const [selectedAttendee, setSelectedAttendee] = useState(null) as any;

  const handleDoneButtonClick = attendee => {
    if (attendee == null) {
      dispatch({
        type: 'global/addDangerToast',
        payload: { description: 'Please Select Attendee' },
      });
      return;
    }
    handleNextButtonClick(attendee);
  };

  const setAttendeeSearchText = useDebounced(setSearchText, 1000);

  const handleSearchQueryChange = e => {
    setAttendeeSearchText(e.target.value);
  };

  const handleSelectedAttendee = attendee => {
    setSearchText('');
    handleDoneButtonClick(attendee)
  };

  let attendeeList;
  const { attendeeListDetails } = useAttendeeList({
    eventId,
    limit: attendeeListLimit,
    offset: 0,
    q: searchText,
    userPrivacyType: IUserPrivacyTypes.PRIVATE_MEETINGS,
  });

  attendeeList =
    attendeeListDetails &&
    attendeeListDetails.map(attendeeDetails => {
      if (attendeeDetails.accountId !== currentUser?.accountId) {
        return (
          <div
            className={styles.attendeeListItemContainer}
            onClick={() => handleSelectedAttendee(attendeeDetails)}
          >
            <div className={styles.attendeeSection}>
              <div className={styles.attendeeImage}>
                <ProfilePicture
                  hexColor={attendeeDetails.hexColor}
                  name={`${attendeeDetails.firstName} ${attendeeDetails.lastName}`}
                  styleClass="medium"
                />
              </div>
              <div className={styles.attendeeName}>
                <NewLabel
                  text={`${attendeeDetails.firstName} ${attendeeDetails.lastName}`}
                  labelColor={INewLabelColors.NEUTRAL_DARKEST}
                  labelSize={INewLabelSizes.XSMALL}
                  labelStyle={INewLabelStyles.SEMI_BOLDED}
                />
                <NewLabel
                  text={attendeeDetails.headline}
                  labelColor={INewLabelColors.NEUTRAL_DARK}
                  labelSize={INewLabelSizes.XXSMALL}
                  labelStyle={INewLabelStyles.DEFAULT}
                />
              </div>
            </div>
            <div tabIndex={0} role="button" aria-label={`select attendee ${attendeeDetails.firstName} ${attendeeDetails.secondName}`}  className={styles.arrowIconStyle} onClick={() => handleSelectedAttendee(attendeeDetails)}>
                <ArrowRight size={20} />
              </div>
          </div>
        );
      }
    });

  const clearSelectedAttendee = () => {
    setSelectedAttendee(null);
  };

  const getSelectedAttendeeDetails = selectedAttendee ? (
    <div
      className={styles.attendeeListItemContainer}
      onClick={() => clearSelectedAttendee()}
    >
      <div className={styles.attendeeSection}>
        <div className={styles.attendeeImage}>
          <ProfilePicture
            hexColor={selectedAttendee.hexColor}
            name={`${selectedAttendee.firstName} ${selectedAttendee.lastName}`}
            styleClass="medium"
          />
        </div>
        <div className={styles.attendeeName}>
          <NewLabel
            text={`${selectedAttendee.firstName} ${selectedAttendee.lastName}`}
            labelColor={INewLabelColors.NEUTRAL_DARKEST}
            labelSize={INewLabelSizes.XSMALL}
            labelStyle={INewLabelStyles.SEMI_BOLDED}
          />
          <NewLabel
            text={selectedAttendee.headline}
            labelColor={INewLabelColors.NEUTRAL_DARK}
            labelSize={INewLabelSizes.XXSMALL}
            labelStyle={INewLabelStyles.DEFAULT}
          />
        </div>
      </div>

    </div>
  ) : (
    <SearchField
      handleSearchQueryChange={handleSearchQueryChange}
      searchQuery={searchText}
      placeHolder="Search for Attendees"
      className={styles.attendeeSearch}
      inputStyle="attendeeSearch"
    />
  );

  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <Text
          text="Setup a new meeting"
          textColor={ITextColors.MONOCHROME_CONTRAST}
          textSize={ITextSizes.LARGE}
          textStyle={ITextStyles.BOLDED}
          textLineHeight="29px"
        />
      </div>
      <div className={styles.scheduleMeetingInnerContainer}>
        <div className={styles.meetingRoomSection}>
          <div className={styles.attendeeSearchField}>
            <div className={styles.inviteLable}>
              <NewLabel
                text="Search people"
                labelColor={INewLabelColors.NEUTRAL_DARK}
                labelSize={INewLabelSizes.XSMALL}
                labelStyle={INewLabelStyles.SEMI_BOLDED}
                labelMargin={INewLabelMargins.MEDIUM_MARGIN_BOTTOM}
              />
            </div>

            {getSelectedAttendeeDetails}

            <div className={styles.attendeeSearchBlock}>
              {attendeeList && attendeeList.length === 0 && (
                <div className={styles.notFound}>
                  No Records found with <b>&nbsp;{searchText}</b>
                </div>
              )}
              {attendeeList}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchEventMemberForm;
