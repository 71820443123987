const WEBSITE_LINK_TEXT = 'Website Link';
const STOP_WEBSITE_SHARE_TEXT = 'Stop Website Share';
const SHARE_CONTENT_TEXT = 'Share content';

const getStopPluginText = pluginName => {
  return `Stop ${pluginName} Presentation`;
};

const someoneSharingText = alreadySharePluginName => {
  return `Someone is already sharing ${alreadySharePluginName}`;
};

export {
  WEBSITE_LINK_TEXT,
  STOP_WEBSITE_SHARE_TEXT,
  SHARE_CONTENT_TEXT,
  getStopPluginText,
  someoneSharingText,
};
