import React, { useMemo } from 'react';
import classnames from 'classnames';
import SingleStreamPlayer from '@/components/PublishedStreamDisplay/SingleStreamPlayer';
import { getCondensedLayoutKeynoteContainerDims } from './utils';
import styles from './styles.module.scss';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { makeSelectCurrentPresentationForChannel } from '@/models/event';
import SharedFilePreview from '../../SharedFileView';

const KeynoteLayout = props => {
  const {
    primaryStream,
    secondaryStreams,
    streams,
    parentHeight,
    parentWidth,
    streamDetailsMap,
    volumeOn,
    greenRoom,
    userMoveFns,
    parent,
    streamNamesMap,
    presentationConfig,
    localClientAVStatus,
    localClient,
    liveRaisedHandRequestList,
  } = props;
  // let streams = [].concat(fakeStreams).concat(fakeStreams).concat(fakeStreams).concat(fakeStreams);
  const streamContainerDimsConfig = {
    height: parentHeight * 0.42,
    width: parentWidth * 0.7,
  };


  const currentChannelPresentation = presentationConfig.currentPresentation;

  const showSharedPresentation = Boolean(currentChannelPresentation);

  const [finalPrimary, finalSecondary] = useMemo(() => {
    let tempPrimary;
    const tempSecondary = [];

    streams.forEach(s => {
      if (
        !tempPrimary &&
        s.stream.uid === primaryStream?.uid &&
        !showSharedPresentation
      ) {
        tempPrimary = s;
      } else {
        tempSecondary.push(s);
      }
    });

    return [tempPrimary, tempSecondary];
  }, [primaryStream, secondaryStreams, streams]);

  const containerDims = getCondensedLayoutKeynoteContainerDims(
    streamContainerDimsConfig,
    streams.length,
  );

  return (
    <>
      <div
        style={{ height: parentHeight, width: parentWidth * 0.9 }}
        className={styles.keynoteContainer}
      >
        <div
          style={{ height: parentHeight, width: containerDims.secondary.width }}
          className={classnames(styles.keynoteSpeakersContainer, {
            [styles.keynoteAlignTop]: finalSecondary.length > 1,
          })}
        >
          {finalSecondary.map(s => (
            <SingleStreamPlayer
              volumeOn={volumeOn}
              parent={parent}
              containerStyles={containerDims.secondary}
              greenRoom={greenRoom}
              key={`grid${s.stream.uid}`}
              name={streamNamesMap[s.stream.uid]}
              streamDetails={streamDetailsMap[s.stream.uid]}
              stream={s.stream}
              userMoveFns={userMoveFns}
              showNasscomLabel={true}
              localClientAVStatus={localClientAVStatus}
              localClient={localClient}
              liveRaisedHandRequestList={liveRaisedHandRequestList}
            />
          ))}
        </div>
        {showSharedPresentation && (
          <SharedFilePreview
            fileUrl={currentChannelPresentation.presentationUrl}
            currentPage={currentChannelPresentation.currentPresentationPage}
            setCurrentPage={presentationConfig.goToPresentationPage}
            totalPages={currentChannelPresentation.presentationTotalPages}
            hasControl={currentChannelPresentation.hasPresentationControl}
            stopPresentation={presentationConfig.stopPresentation}
            dims={containerDims.primary}
            isLoading={currentChannelPresentation.isLoading}
            parent={parent}
          />
        )}
        {finalPrimary && !showSharedPresentation && (
          <SingleStreamPlayer
            volumeOn={volumeOn}
            parent={parent}
            containerStyles={containerDims.primary}
            greenRoom={greenRoom}
            key={`grid${finalPrimary.stream.uid}`}
            name={streamNamesMap[finalPrimary.stream.uid]}
            streamDetails={streamDetailsMap[finalPrimary.stream.uid]}
            stream={finalPrimary.stream}
            userMoveFns={userMoveFns}
            showNasscomLabel={true}
            localClientAVStatus={localClientAVStatus}
            localClient={localClient}
            liveRaisedHandRequestList={liveRaisedHandRequestList}
          />
        )}
      </div>
    </>
  );
};

export default KeynoteLayout;
