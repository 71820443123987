import React from 'react';
import PlayStream from '../PlayAudio';
import Text, { ITextSizes } from '@/components/ui/content/Text';
import { IContentTextColorTypes } from '@/types';
import styles from './styles.module.scss';

const MorePeople = ({ streams, volumeOn }) => {
  if (!streams?.length) {
    return null;
  }

  const totalPeople = streams.length;
  return (
    <>
      <div className={styles.textContainer}>
        <Text
          text={`+${totalPeople} ${totalPeople > 1 ? 'people' : 'person'}`}
          textColor={IContentTextColorTypes.MONOCHROME_CONTRAST}
          textSize={ITextSizes.XSMALL}
          className={styles.peopleCountText}
        />
      </div>
      {streams.map(stream => (
        <PlayStream key={stream.uid} stream={stream} volumeOn={volumeOn} />
      ))}
    </>
  );
};

export default MorePeople;
