import React from 'react';
import classnames from 'classnames';
// styles + types
import styles from './styles.module.scss';
import { ISquareFrameProps } from './types';

const SquareFrame = (props: ISquareFrameProps) => {
  const { children, height, roundedCorner = false, width, noMargin } = props;
  const squareDims = {
    minHeight: height,
    minWidth: width || height,
  };

  return (
    <div
      className={classnames(
        styles.squareFrameContainer,
        styles.streamFrameBorder,
        { [styles.streamFrameMargin]: !noMargin },
        { [styles.roundedCorner]: roundedCorner },
      )}
      style={squareDims}
    >
      {children}
    </div>
  );
};

export default SquareFrame;
