export enum IRaisedHandRequestStatus {
  REQUESTED = "REQUESTED",
  LIVE = "LIVE",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
  DISMISSED = "DISMISSED",
};

export enum StreamType {
  MAIN = "MAIN",
  VIEWER = "VIEWER",
  SCREEN = "SCREEN",
  MAIN_RELAY = "MAIN_RELAY",
  SCREEN_RELAY = "SCREEN_RELAY",
};

export interface IBroadcastPermissionsObj {
  audio: boolean;
  video: boolean;
}

export enum IStageParent {
  LIVE_STAGE = 'liveStage',
  LIVE_BACKSTAGE = 'liveBackstage',
  LIVE_DISCUSSION = 'liveDiscussion',
  BOOTH_OWNER = 'boothOwner',
  BOOTH = 'booth',
  STAGE_PREVIEW = 'stagePreview',
  NETWORKING = 'networking',
  AUDIENCE_GALLERY = 'audienceGallery',
  CONTRIBUTOR_SLOT = 'contributorSlot',
  CONTRIBUTOR_SLOT_AUDIO = 'contributorSlotAudio',
}

export enum CaptionType {
  AUTOMATED = 'AUTOMATED',
  SYNCWORDS = 'SYNCWORDS',
}

export interface IStageProps {
  parent: IStageParent;
}
