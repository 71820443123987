import React from 'react';
import { Link, useParams } from 'react-router-dom';
// components
import Button, {
  IButtonMargins,
  IButtonTypes,
} from '@/components/ui/buttons/Button';
import Heading, {
  IHeadingSizes,
  IHeadingColors,
} from '@/components/ui/content/Heading';
import IconTextButton, {
  IIconTextButtonTypes,
} from '@/components/ui/buttons/IconTextButton';
import EmptyStateWrapper, {
  IEmptyStateParentSources,
} from '@/components/ui/empty-states/EmptyStateWrapper';
import NewLabel, { INewLabelColors } from '@/components/ui/content/NewLabel';
import StreamPreviewModal from '@/components/ui/modals/StreamPreviewModal';
// styles + types
import styles from './styles.module.scss';
import { IStreamEmptyStateProps } from './types';
import { IStageParent } from '@/components/Stage/types';
// api + models + hooks
import useComputeBackstageAccess from '@/hooks/use-compute-backstage-access';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import {
  makeSelectIsHost,
  makeSelectEventStageIsBroadcasting,
  makeSelectEventRoleForEvent,
  makeSelectEventById,
  makeSelectStudioIdByStageId,
} from '@/models/event';
import { IUIIcons, IContentTextStyleTypes } from '@/types';
import { EEventType } from '@/types/event';

const { EXPO } = IUIIcons;

const StreamEmptyState = (props: IStreamEmptyStateProps) => {
  const {
    broadcastPermissions,
    localClient,
    localStream,
    parent,
    setBroadcastPermissions,
    hideEmptyStageMsg = false,
  } = props;

  const { eventId = '', stageId = '' } = useParams<{
    eventId: string;
    stageId: string;
  }>();

  const event = useMemoizedSelector(makeSelectEventById, eventId);
  const isWebinarEvent = event.type === EEventType.WEBINAR;

  const stageStudioId = useMemoizedSelector(
    makeSelectStudioIdByStageId,
    stageId,
  );
  const isStudioStage = !!stageStudioId;

  const imageBackgroundStyles = {};
  const isBroadcasting = useMemoizedSelector(
    makeSelectEventStageIsBroadcasting,
    { eventId, stageId },
  );

  const eventRole = useMemoizedSelector(makeSelectEventRoleForEvent, eventId);

  const boothAccessEventRoles = [
    'e6503b5d-f122-4d0b-8540-7c56175fd6d5',
    '72bb48ae-66c7-4976-9e2b-4e5b6bec2333',
    '50652b10-dc03-4bfe-9baa-dad1edfb56dd',
    '91204bc8-135c-4319-8791-0044662d51e9',
    '36daaad2-4dd5-4f3b-86f5-068b6b42bd35',
    '29317ffe-4d33-4c4c-bb37-fdd0e943de9f',
    'ae1cb60d-7a75-4d0e-892f-5ea03516a18f',
    '82e0071b-4d1c-40d1-a0b6-15037947776f',
    '5b8099bc-7884-4c68-84a9-a84f5831448a',
    'eceee359-8e77-496b-bd2e-9d3d9fbff5ee',
    'be156a98-76ee-4487-8a6f-fffa465d608d',
    'f3635b99-1f5c-401f-a8e7-b235f2901e04',
    'd75ecc5a-eb57-408a-8bde-468e0a4c930b',
    '954eb20a-f8bd-45e1-93f1-2983d997f112',
    '701de660-bda0-4f5f-b4f8-9299b97caa02',
    '78f540fa-5253-43d9-9910-09e2212eb71d'
  ];
  const isBoothOwnerAccess = eventRole && boothAccessEventRoles.includes(eventRole.eventRoleId);

  // const imageBackgroundStyles = fillerImageUrl ? {
  //   background: `linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.6)), url(${fillerImageUrl})`
  // } : {};

  const isHost = useMemoizedSelector(makeSelectIsHost, eventId);

  const hasBackstageAccess = useComputeBackstageAccess(eventId, stageId, eventRole);
  const canStream = (isHost && [IStageParent.LIVE_BACKSTAGE, IStageParent.BOOTH_OWNER].includes(parent))
    || IStageParent.LIVE_DISCUSSION === parent || parent === IStageParent.CONTRIBUTOR_SLOT_AUDIO;

  const showBackstageButton = !isBoothOwnerAccess && hasBackstageAccess && IStageParent.LIVE_STAGE === parent;
  const isStagePreview = IStageParent.STAGE_PREVIEW === parent;

  if (isStagePreview) {
    return (
      <NewLabel
        text="This is the stage preview"
        labelColor={INewLabelColors.CONTRAST}
      />
    );
  }

  if (canStream) {
    const broadcastPermissionsEmptyState = (() => {
      if (IStageParent.LIVE_DISCUSSION === parent) {
        return (
          <NewLabel
            text="You are joining this table."
            labelColor={INewLabelColors.CONTRAST}
          />
        );
      }
      if (isBroadcasting) {
        return (
          <>
            <Heading
              text={`There is no one on stage`}
              headingStyle={IContentTextStyleTypes.BOLDED}
              headingSize={IHeadingSizes.XXSMALL}
              headingColor={IHeadingColors.NEUTRAL_DARKEST}
            />
          </>
        );
      }
      return (
        <div className={styles.textContainer}>
          <Heading
            text={`There’s no one on stage yet!`}
            headingStyle={IContentTextStyleTypes.BOLDED}
            headingSize={IHeadingSizes.XXSMALL}
            headingColor={IHeadingColors.NEUTRAL_DARKEST}
          />
        </div>
      );
    })();

    return (
      <div className={styles.emptyStateContainer} style={imageBackgroundStyles}>
        {!broadcastPermissions ? (
          <div className={styles.streamOptionsContainer}>
            <StreamPreviewModal
              localClient={localClient}
              localStream={localStream}
              setBroadcastPermissions={setBroadcastPermissions}
              defaultOpen={true}
              parent={parent}
            />
          </div>
        ) : broadcastPermissionsEmptyState}
      </div>
    );
  }
  let LeftButton;
  if (showBackstageButton) {
    const buttonProps = {
      styleClass: IButtonTypes.NEW_PRIMARY,
      label: 'Go Backstage',
      margin: IButtonMargins.MEDIUM_MARGIN_RIGHT,
      hasHover: true,
    };

    LeftButton = isStudioStage ? (
      <Button
        type="button"
        {...buttonProps}
        onClick={() =>
          window.open(
            `${process.env.NX_STUDIO_APP_URL}/${isWebinarEvent ? 'webinar':'event'}/${stageStudioId}`,
            '_self',
          )
        }
      />
    ) : (
      <Link
        id="onboarding-go-backstage"
        to={`/l/event/${eventId}/stages/${stageId}/backstage`}
        replace={isStudioStage}
      >
        <Button type="button" {...buttonProps} />
      </Link>
    );
  } else {
    if (
      ['7ad3f19f-5716-4719-8585-a657265afca7', 'b9954050-213a-4dc5-93a0-055ffdcc5fd5', 'e49cc3c7-00c4-47b7-b780-1ba9607cda20', '4fae2873-34ab-4d85-8768-04d651792e47', '88793d97-9a4f-4d8b-bf50-a719f39bb4da', '365a990a-69ad-4135-abb3-0bb9055a4d9d'].includes(eventId)
    ) {
      if (isStagePreview) {
        LeftButton = null;
      } else if (isBroadcasting) {
        LeftButton = (
          <a href={`https://zoom.us/j/95427512698?pwd=NTJkeTdSWkx3UnREWUJ4V3BjRUdyZz09`} target="_blank">
            <IconTextButton
              activeIcon={EXPO}
              activeLabel="Join Zoom"
              defaultIcon={EXPO}
              defaultLabel="Join Zoom"
              showDefault={true}
              buttonType={IIconTextButtonTypes.NEW_SECONDARY}
            />
          </a>
        );
      } else {
        LeftButton = (
          <Link to={`/l/event/${eventId}/expo`}>
            <IconTextButton
              activeIcon={EXPO}
              activeLabel="Go to Expo"
              defaultIcon={EXPO}
              defaultLabel="Go to Expo"
              showDefault={true}
              buttonType={IIconTextButtonTypes.NEW_SECONDARY}
            />
          </Link>
        );
      }
    } else {
      LeftButton = null;
    }
  }

  if (hideEmptyStageMsg || parent === IStageParent.AUDIENCE_GALLERY || parent === IStageParent.CONTRIBUTOR_SLOT) {
    return <></>;
  }

  return (
    <>
      {
        <EmptyStateWrapper
          parent={parent === IStageParent.STAGE_PREVIEW ? IEmptyStateParentSources.STAGE_PREVIEW : IEmptyStateParentSources.STAGE_ATTENDEE}
          isBroadcasting={isBroadcasting}
        >
          {LeftButton}
          {/* <Link to={`/l/event/${eventId}/discussions`}>
            <IconTextButton
              activeIcon={USERS}
              activeLabel="Go to Tables"
              defaultIcon={USERS}
              defaultLabel="Go to Tables"
              showDefault={true}
              buttonType={IIconTextButtonTypes.NEW_SECONDARY}
            />
          </Link> */}
        </EmptyStateWrapper>
      }
    </>
  );
};

export default StreamEmptyState;
