import React from 'react';
import styles from './styles.module.scss';
import Modal from '@/components/ui/modals/Modal';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import Text, { ITextColors, ITextSizes } from '@/components/ui/content/Text';
import classnames from 'classnames';

const ConfirmExitModal = ({
  title = '',
  description = '',
  trigger,
  handleConfirm,
  exitText = 'Are you sure you want to exit?',
  confirmButtonText = 'Exit',
  cancelButtonText = 'Cancel',
  cancelButtonClassName,
  confirmButtonClassName,
  cancelButtonType = IButtonTypes.PRIMARY,
  confirmButtonType = IButtonTypes.PRIMARY_NO_BG_INLINE,
  headerClassName = 'backStageModalHeader',
  handleCancel,
  className,
  disableClose = true,
  hasActionButtons = true,
  hasNewCrossIcon = false,
  newCrossIconSize,
  wrapperClassName,
}) => (
  <Modal
    title={title}
    disableClose={disableClose}
    noHeader={!title}
    modalHeaderClass={headerClassName}
    isNewCrossIcon={hasNewCrossIcon}
    newCrossIconSize={newCrossIconSize}
    noBodyPadding
    trigger={trigger}
    className={className}
    modalBodyClass="modalBodyNeutralBase"
    render={({ setShow }) => (
      <div
        className={classnames(styles.confirmExitModal, {
          [styles[wrapperClassName]]: wrapperClassName,
        })}
        tabIndex={0}
        aria-label="delete question modal"
      >
        <Text
          text={exitText}
          textColor={ITextColors.MONOCHROME_CONTRAST}
          textSize={ITextSizes.LARGE}
          textAlign="center"
        />
        {description && <p className={styles.descriptionText}>{description}</p>}
        {hasActionButtons && (
          <div className={styles.btnMenu}>
            <Button
              className={classnames(confirmButtonClassName)}
              label={confirmButtonText}
              onClick={() => handleConfirm({ setShow })}
              styleClass={confirmButtonType}
              type="button"
            />
            <Button
              label={cancelButtonText}
              className={classnames(cancelButtonClassName)}
              onClick={e => {
                if (handleCancel) {
                  handleCancel(e, { setShow });
                }
                setShow(false);
              }}
              styleClass={cancelButtonType}
              type="button"
            />
          </div>
        )}
      </div>
    )}
  />
);

export default ConfirmExitModal;
