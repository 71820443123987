export const MAX_STREAMS_PER_PAGE = 3;

export const MAX_STREAMS_PER_PAGE_FOR_MOB = 2;

export const getRowsAndColumns = (streamsCount: number, isMobile: boolean) => {
  switch (streamsCount) {
    case 1:
      if (isMobile) {
        return { rows: 1, cols: 1 };
      }
      return { rows: 1, cols: 1 };
    case 2:
      if (isMobile) {
        return { rows: 1, cols: 2 };
      }
      return { rows: 2, cols: 1 };
    case 3:
      if (isMobile) {
        return { rows: 2, cols: 2 };
      }
      return { rows: 3, cols: 1 };
    case 4:
      if (isMobile) {
        return { rows: 2, cols: 2 };
      }
      return { rows: 4, cols: 1 };
    default:
      if (isMobile) {
        return { rows: 2, cols: 2 };
      }
      return { rows: 4, cols: 4 };
  }
};

export const getOtherStreamsContainerHeight = (
  parentHeight: number,
  isMobile: boolean,
  isTablet: boolean,
  isIPad: boolean,
  streamsCount: number,
) => {
  // Account for address bar, floating A/V controls, sliding dots on phones
  if (isMobile) {
    return 0.55 * parentHeight;
  }

  if (isTablet) {
    const safariTabsHeight = 48;
    return (
      parentHeight - (isIPad ? safariTabsHeight : 0) - (1 / 7) * parentHeight
    );
  }

  // Handle stretch of one stream
  if (streamsCount === 1) {
    return 0.4 * parentHeight;
  }
  return parentHeight - (1 / 7) * parentHeight;
};

export const getOtherStreamsContainerWidth = (
  parentWidth: number,
  isTablet: boolean,
  isMobile: boolean,
  isSidePanelOpen: boolean,
) => {
  if (isMobile) {
    return parentWidth;
  }

  if (isTablet) {
    return isSidePanelOpen ? 0.25 * parentWidth : 0.215 * parentWidth;
  }

  // ~25% of available space for other streams
  return isSidePanelOpen ? 0.24 * parentWidth : 0.22 * parentWidth;
};
