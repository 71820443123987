import React from 'react';

import Loader from '@/components/ui/Loader';

import { IEmptyStateProps } from './types';
import styles from './styles.module.scss';
import classnames from 'classnames';

const EmptyState = (props: IEmptyStateProps) => {
  const { isLoading = false, height, width, containerClass = '' } = props;

  if (isLoading) {
    return (
      <div
        className={classnames(styles.loadingContainer, containerClass)}
        style={{ height, width }}
      >
        <Loader styleClass="presentationLoading" />
      </div>
    );
  }

  return null;
};

export default EmptyState;
