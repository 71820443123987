import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
// Images
import FullLogo from '@/public/images/zuddl-logo.svg';
// Styles + Types
import styles from './styles.module.scss';
import { ILogoNewProps } from './types';

const LogoNew = ({
  isClickable = true,
  href = '/',
  className,
}: ILogoNewProps) =>
  isClickable ? (
    <Link className={className} to={href}>
      <img src={FullLogo} alt="Zuddl" className={styles.image} />
    </Link>
  ) : (
    <img
      src={FullLogo}
      alt="Zuddl"
      className={classnames(className, styles.image)}
    />
  );

export default LogoNew;
