import React from 'react';
import SingleStreamPlayer from '@/components/PublishedStreamDisplay/SingleStreamPlayer';
import { getCondensedGridLayoutDims } from './utils';
import styles from './styles.module.scss';

const GridLayout = props => {
  const {
    streams,
    parentHeight,
    parentWidth,
    streamDetailsMap,
    volumeOn,
    greenRoom,
    userMoveFns,
    parent,
    streamNamesMap,
    localClientAVStatus,
    localClient,
    liveRaisedHandRequestList,
  } = props;
  const streamContainerDimsConfig = {
    height: parentHeight,
    width: parentWidth,
    numCols: 1,
    numRows: 1,
  };

  const containerDims = getCondensedGridLayoutDims(
    streamContainerDimsConfig,
    streams ? streams.length : 0,
  );

  const containerStyles = (() => {
    if (!streams) {
      return {};
    }

    if (streams.length >= 4 && streams.length <= 6) {
      return {
        position: 'relative',
        top: '-8%',
      };
    }

    if (streams.length >= 7 && streams.length <= 9) {
      return {
        position: 'relative',
        padding: '11%',
        top: '-10%',
      };
    }

    if (streams.length >= 10 && streams.length <= 12) {
      return {
        position: 'relative',
        top: '-8%',
      };
    }

    if (streams.length >= 13 && streams.length <= 16) {
      return {
        position: 'relative',
        top: '-10%',
      };
    }

    return {};
  })();

  return (
    <div style={containerStyles} className={styles.razorpayGridOuter}>
      {streams.map((s, i) => (
        <SingleStreamPlayer
          volumeOn={volumeOn}
          parent={parent}
          containerStyles={containerDims[i]}
          greenRoom={greenRoom}
          key={`grid${s.stream.uid}`}
          name={streamNamesMap[s.stream.uid]}
          streamDetails={streamDetailsMap[s.stream.uid]}
          showNasscomLabel={false}
          stream={s.stream}
          userMoveFns={userMoveFns}
          showGradientLabel={true}
          localClientAVStatus={localClientAVStatus}
          localClient={localClient}
          liveRaisedHandRequestList={liveRaisedHandRequestList}
        />
      ))}
    </div>
  );
};

export default GridLayout;
