import localforage from 'localforage';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// components
import useComponentVisible from '@/hooks/use-component-visible';
// styles + types
import { CaptionType, IStageParent } from '@/components/Stage/types';
import MultilingualIcon from '@/components/ui/new-icons/Multilingual';
import VideoWithPlayIcon from '@/components/ui/new-icons/VideoWithPlay';
import useSyncWords from '@/hooks/use-sync-words';
import { selectCurrentUser } from '@/models/account';
import {
  selectChannelSidePanelState,
  selectStageVideoQuality,
  selectUserCurrentScreen,
} from '@/models/global';
import { IContentColors, IUIIcons } from '@/types';
import { IScreen } from '@/types/screen';
import { Check, ChevronLeft, ChevronRight, Edit3 } from 'react-feather';
import styles from './styles.module.scss';
import {
  DefaultVideoQuality,
  VideoQualityTypes,
  hideInterprefyButtonEventIds,
  videoQualityOptions,
  videoQualityOptionsRooms,
} from './utils';
import { makeSelectPresentationLayoutByEventId } from '@/models/event';
import { useParams } from 'react-router-dom';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import { isPresentationLayoutV2Enabled } from '@/components/ChannelStreamsContainer/config';
import { useAtom } from 'jotai';
import { isClosedCaptionsEnabledAtom } from '@/components/Stage/atoms';
import EmojiBarButton from '../EmojiBar/EmojiBarButton';
import IconButton, {
  IIconButtonBGColors,
  IIconButtonTypes,
} from '@/components/ui/buttons/IconButton';
import { EMOJI_ICON_SIZE } from '@/styles/iconSizes';
import { TooltipTypes } from '@/components/ui/buttons/IconButton/types';
import BottomSheet from '@/components/ui/BottomSheet';
import { SheetRef } from 'react-modal-sheet';
import EmojiBar from '../EmojiBar';
import CaptionIcon from '@/components/ui/new-icons/Caption';
import CloseCircleFilled from '@/components/ui/new-icons/CloseCircleFilled';
import { createPortal } from 'react-dom';
import { keyboardAccessible } from '@/utils/keyboard accessible';

let interprefyPortalRoot: HTMLElement | null = null;
let syncwordsPortalRoot: HTMLElement | null = null;

const StreamSettingsBar = ({
  networkStrengthIcon,
  settingsData,
  setShowStageAudioTooltip,
  isOrganizer,
  refId,
  parent,
  isStudioStage = false,
  handleEmojiClick,
  handleToggleAudio,
  isMuted,
  isWebinar,
  isBroadcasting,
  setIsMenuVisible: setDisableClick,
  setShowStreamingIssuesPopup,
}) => {
  const { eventId = '' } = useParams<{
    eventId: string;
  }>();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser) as { accountId: string };
  const [captionSelectedLanguage, setCaptionSelectedLanguage] = useState('OFF');
  const selectedVideoQuality = useSelector(selectStageVideoQuality);
  const {
    recapdLanguagesList,
    fetchLanguages,
    clearSyncWordsJs,
  } = useSyncWords({
    recapdEventId: settingsData?.recapdEventId,
    recapdEventToken: settingsData?.recapdEventToken,
    isCaptionSupport: settingsData?.isCaptionSupport && settingsData?.captionType === CaptionType.SYNCWORDS && !!settingsData?.recapdEventId,
  });
  const [showBottomSheetMenu, setShowBottomSheetMenu] = useState(false);
  const isRoom = parent === IStageParent.LIVE_DISCUSSION;
  const isStage =
    parent === IStageParent.LIVE_STAGE ||
    parent === IStageParent.LIVE_BACKSTAGE;
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const bottomSheetRef = React.useRef<SheetRef>();
  const snapTo = (i: number) => bottomSheetRef.current?.snapTo(i);
  const [selectedSnapPoint, setSnapPoint] = useState(0);
  const isAgoraLargeChannel =
    useMemoizedSelector(makeSelectPresentationLayoutByEventId, eventId) ||
    isPresentationLayoutV2Enabled(eventId);
  const [isCCEnabled, setIsCCEnabled] = useAtom(isClosedCaptionsEnabledAtom);
  const toggleAgoraClosedCaptions = () => setIsCCEnabled(v => !v);

  useEffect(() => {
    if (!settingsData?.recapdEventId) {
      return undefined;
    }
    // in worst case syncWords not responding on page. After 3sec again trying to fetch languages.
    const startTime = new Date().getTime();
    const intervalId = setInterval(() => {
      if (new Date().getTime() - startTime > 60000) {
        clearInterval(intervalId);
        return;
      }
      if (recapdLanguagesList.length === 1 && fetchLanguages) {
        fetchLanguages();
      } else {
        clearInterval(intervalId);
      }
    }, 3000);

    return () => clearInterval(intervalId);
  }, [settingsData, recapdLanguagesList]);

  useEffect(
    () => () => {
      if (clearSyncWordsJs) {
        clearSyncWordsJs();
      }
    },
    [],
  );

  const currentScreen = useSelector(selectUserCurrentScreen);
  const showCaptionOption = useMemo(
    () =>
      (parent === IStageParent.LIVE_STAGE ||
        currentScreen === IScreen.ONSTAGE) &&
      !hideInterprefyButtonEventIds.includes(eventId),
    [currentScreen, parent],
  );

  useEffect(() => {
    if (!currentUser || !refId) return;
    const stageVideoQualityUniqueKey = `${currentUser.accountId}-stage-${refId}-video-quality`;
    localforage.getItem(stageVideoQualityUniqueKey).then((val: string) => {
      if (!val) {
        dispatch({
          type: 'global/setStageVideoQuality',
          payload: {
            videoQuality: DefaultVideoQuality.DEFAULT_VIDEO_QUALITY,
          },
        });
        localforage.setItem(
          stageVideoQualityUniqueKey,
          DefaultVideoQuality.DEFAULT_VIDEO_QUALITY,
        );
      } else {
        dispatch({
          type: 'global/setStageVideoQuality',
          payload: {
            videoQuality: val,
          },
        });
      }
    });
  }, [refId, currentUser]);

  const {
    ref,
    isComponentVisible: isMenuVisible,
    setIsComponentVisible: setIsMenuVisible,
  } = useComponentVisible(false);

  const [isInterpreterVisible, setIsInterpreterVisible] = useState(false);

  const {
    ref: captionListRef,
    isComponentVisible: showCaptionLanguages,
    setIsComponentVisible: setShowCaptionLanguages,
  } = useComponentVisible(false);

  useEffect(() => {
    if (setDisableClick) {
      setDisableClick(isMenuVisible);
    }
  }, [isMenuVisible, setDisableClick]);

  const toggleInterpreterButton = (e?: any) => {
    if (e) {
      e.stopPropagation();
    }
    setIsMenuVisible(false);
    setIsInterpreterVisible(!isInterpreterVisible);
    if (setShowStageAudioTooltip) {
      setShowStageAudioTooltip(true);
    }
  };

  const captionOptionsToggle = () => setShowCaptionLanguages(v => !v);

  const [showVideoQualityOptions, setShowVideoQualityOptions] = useState(false);
  const videoQualityToggle = e => {
    e.stopPropagation();
    setIsMenuVisible(true);
    setShowVideoQualityOptions(!showVideoQualityOptions);
  };

  const handleVideoQualitySelect = (selectedQuality: string) => {
    const stageVideoQualityUniqueKey = `${currentUser.accountId}-stage-${refId}-video-quality`;
    localforage.setItem(stageVideoQualityUniqueKey, selectedQuality);
    dispatch({
      type: 'global/setStageVideoQuality',
      payload: {
        videoQuality: selectedQuality,
      },
    });
  };

  const handleCaptionLanguageSelect = (selectedLanguage: string) => {
    if (selectedLanguage !== 'OFF') {
      // deleting old script
      const recapdUpdateLanguageOld = document.getElementById(
        'recapdChangeLanguage',
      );
      if (recapdUpdateLanguageOld) {
        document.body.removeChild(recapdUpdateLanguageOld);
      }
      const recapdUpdateLanguage = document.createElement('script');
      recapdUpdateLanguage.type = 'text/javascript';
      recapdUpdateLanguage.id = 'recapdChangeLanguage';
      recapdUpdateLanguage.async = true;
      recapdUpdateLanguage.innerHTML = `Recapd.changeLanguage('${selectedLanguage}');`;
      document.body.appendChild(recapdUpdateLanguage);
    }
    const recapdWidget = document.getElementById('recapdWidget');
    if (recapdWidget && selectedLanguage !== 'OFF') {
      Recapd.show();
      recapdWidget.style.display = 'block';
    } else if (recapdWidget && selectedLanguage === 'OFF') {
      Recapd.hide();
      recapdWidget.style.display = 'none';
    }
    setCaptionSelectedLanguage(selectedLanguage);
    setShowCaptionLanguages(false);
    setIsMenuVisible(false);
  };

  const multilingualToken = useMemo(
    () => settingsData?.isInterprefySupport && settingsData?.interprefyToken,
    [settingsData],
  );

  const isSyncwordsCaptionEnabled = useMemo(
    () =>
      showCaptionOption &&
      settingsData?.isCaptionSupport &&
      settingsData?.captionType === CaptionType.SYNCWORDS &&
      !!settingsData?.recapdEventId,
    [settingsData, showCaptionOption],
  );

  const isAutomatedCaptionEnabled = useMemo(
    () =>
      showCaptionOption &&
      settingsData?.isCaptionSupport &&
      settingsData?.captionType === CaptionType.AUTOMATED,
    [settingsData, showCaptionOption],
  );

  const channelSidePanelState = useSelector(selectChannelSidePanelState);
  const isShowingGreenRoomActionPanel =
    isOrganizer && parent === IStageParent.LIVE_BACKSTAGE;

  // TODO: remove this later
  const captionWidgetStyle = useMemo(() => {
    if (isShowingGreenRoomActionPanel && channelSidePanelState) {
      return styles.recapdCaptioningContainerWithSidePanel;
    }
    if (isShowingGreenRoomActionPanel && !channelSidePanelState) {
      return styles.recapdCaptioningContainer;
    }
    if (channelSidePanelState) {
      return styles.recapdCaptioningContainerCenterWithSidePanel;
    }
    return styles.recapdCaptioningContainerCenter;
  }, [isShowingGreenRoomActionPanel, channelSidePanelState]);

  const isVideoSwitchEnabled =
    (isStage && !isStudioStage) || (isRoom && isAgoraLargeChannel);

  useEffect(() => {
    if (!multilingualToken) return;

    const handleInterprefyMsg = (e: { data }) => {
      const { event } = e.data;
      if (event !== 'floor-muted') return;
      // This also returns 'issuerLang' to tell which language it switches to
      // floorMute means that translation has started, mute the stage
      const { floorMute } = e.data;
      handleToggleAudio(!!floorMute);
    };

    window.addEventListener('message', handleInterprefyMsg);

    return () => window.removeEventListener('message', handleInterprefyMsg);
  }, [multilingualToken]);

  if (
    !isVideoSwitchEnabled &&
    !isSyncwordsCaptionEnabled &&
    !isAutomatedCaptionEnabled &&
    !showCaptionOption &&
    !multilingualToken
  ) {
    return null;
  }

  const _videoQualityOptions =
    parent === IStageParent.LIVE_DISCUSSION
      ? videoQualityOptionsRooms
      : videoQualityOptions;

  const showEditProfileModal = () => {
    dispatch({
      type: 'global/setEditProfileModal',
    });
  };

  const showEmojiButton = isWebinar || isStudioStage || isStage;

  const renderVideoQualityOptions = () => (
    <div className={styles.menuContainer}>
      <li
        aria-hidden
        className={styles.menuOption}
        onClick={videoQualityToggle}
      >
        <div className={styles.menuItemLabelContainer}>
          <ChevronLeft size={12} />
          Video Quality
        </div>
      </li>
      <div className={styles.captionLanguagesContainer}>
        {_videoQualityOptions.map(Option => (
          <div
            aria-hidden
            className={styles.captionOption}
            onClick={() => handleVideoQualitySelect(Option.value)}
          >
            <div className={styles.menuItemLabelContainer}>
              <Option.icon
                size={Option.value === VideoQualityTypes.AUDIO_ONLY ? 13 : 16}
              />
              {Option.label}
            </div>
            <Check
              size={15}
              color={
                selectedVideoQuality === Option.value
                  ? 'white'
                  : IContentColors.BLACK
              }
            />
          </div>
        ))}
      </div>
    </div>
  );

  const renderCaptionLanguages = () => (
    <div className={styles.menuContainer}>
      <div className={styles.captionLanguagesContainer}>
        {recapdLanguagesList.map(language => (
          <div
            tabIndex={0}
            className={classnames(styles.captionOption,'elementAsButton')}
            onKeyDown={(e: any) => keyboardAccessible(e, () => handleCaptionLanguageSelect(language.id))}
            onClick={() => handleCaptionLanguageSelect(language.id)}
          >
            <div className={styles.menuItemLabelContainer}>
              {language.value}
            </div>
            <Check
              size={15}
              color={
                captionSelectedLanguage === language.id
                  ? 'white'
                  : IContentColors.BLACK
              }
            />
          </div>
        ))}
      </div>
    </div>
  );

  const renderSettingsMenu = () => (
    <>
      <div className={styles.menuContainer}>
        {isWebinar && (
          <li
            aria-hidden
            className={styles.menuOption}
            onClick={showEditProfileModal}
          >
            <div className={styles.menuItemLabelContainer}>
              <Edit3 size={16} />
              Edit profile
            </div>
          </li>
        )}

        {!!multilingualToken && isBroadcasting && (
          <li
            aria-hidden
            className={styles.menuOption}
            onClick={toggleInterpreterButton}
          >
            <div className={styles.menuItemLabelContainer}>
              <MultilingualIcon color={IContentColors.WHITE} size={16} />
              Audio language
            </div>
          </li>
        )}

        {!showVideoQualityOptions && !isWebinar && isVideoSwitchEnabled && (
          <li
            aria-hidden
            className={styles.menuOption}
            onClick={videoQualityToggle}
          >
            <div className={styles.menuItemLabelContainer}>
              <VideoWithPlayIcon size={16} /> Video Quality
            </div>
            <ChevronRight size={12} />
          </li>
        )}
      </div>

      {showVideoQualityOptions && renderVideoQualityOptions()}
    </>
  );

  interprefyPortalRoot = !interprefyPortalRoot
    ? document.getElementById('interprefy-widget-container-portal')
    : interprefyPortalRoot;

  syncwordsPortalRoot = !syncwordsPortalRoot
    ? document.getElementById('syncwords-widget-container-portal')
    : syncwordsPortalRoot;

  return (
    <div className={styles.mainContainer}>
      {!isMobile && showEmojiButton && !!handleEmojiClick && (
        <EmojiBarButton
          onEmojiClick={handleEmojiClick}
          tooltip={TooltipTypes.none}
          defaultIconBgColor={IIconButtonBGColors.NEUTRAL_LIGHTEST}
        />
      )}

      {!!handleToggleAudio && (
        <IconButton
          key="volumeButton"
          activeIcon={IUIIcons.NEW_VOLUME_ON}
          defaultIcon={IUIIcons.NEW_VOLUME_OFF}
          onClick={handleToggleAudio}
          showDefault={isMuted}
          iconBG={
            isMuted
              ? IIconButtonBGColors.DANGER
              : IIconButtonBGColors.NEUTRAL_LIGHTEST
          }
          iconSize={EMOJI_ICON_SIZE}
          type={IIconButtonTypes.ROUND_PRIMARY}
          className={styles.volumeButton}
          tooltip={TooltipTypes.none}
        />
      )}
      <div ref={captionListRef}>
        {isSyncwordsCaptionEnabled && !isMobile && (
          <IconButton
            key="closedCaptionsButton"
            activeIcon={IUIIcons.CLOSED_CAPTION}
            defaultIcon={IUIIcons.CLOSED_CAPTION}
            onClick={captionOptionsToggle}
            showDefault={true}
            iconBG={
              captionSelectedLanguage !== 'OFF' || showCaptionLanguages
                ? IIconButtonBGColors.PRIMARY
                : IIconButtonBGColors.NEUTRAL_LIGHTEST
            }
            iconSize={EMOJI_ICON_SIZE}
            type={IIconButtonTypes.ROUND_PRIMARY}
            className={styles.volumeButton}
            tooltip={TooltipTypes.none}
          />
        )}
        {showCaptionLanguages && renderCaptionLanguages()}

        {isStudioStage && isAutomatedCaptionEnabled && !isMobile && (
          <div
            aria-hidden
            className={classnames(styles.closedCaptions, {
              [styles.closedCaptionsEnabled]: isCCEnabled,
            })}
            onClick={toggleAgoraClosedCaptions}
          >
            <CaptionIcon color={IContentColors.MONOCHROME_CONTRAST} size={24} />
          </div>
        )}
      </div>

      <div ref={ref}>
        {!isMobile &&
          (isWebinar ||
            (!isWebinar &&
              isBroadcasting &&
              (isVideoSwitchEnabled || !!multilingualToken))) && (
            <IconButton
              key="settingsButton"
              activeIcon={IUIIcons.SETTINGS}
              defaultIcon={IUIIcons.SETTINGS}
              onClick={() => setIsMenuVisible(v => !v)}
              showDefault={true}
              iconBG={
                isMenuVisible
                  ? IIconButtonBGColors.PRIMARY
                  : IIconButtonBGColors.NEUTRAL_LIGHTEST
              }
              iconSize={EMOJI_ICON_SIZE}
              type={IIconButtonTypes.ROUND_PRIMARY}
              className={styles.volumeButton}
              tooltip={TooltipTypes.none}
            />
          )}
        {isMenuVisible && !isMobile && renderSettingsMenu()}
      </div>

      {isMobile && (isStage || isStudioStage || isWebinar) && (
        <IconButton
          key="threeDotsMenuButton"
          activeIcon={IUIIcons.MORE}
          defaultIcon={IUIIcons.MORE}
          onClick={() => setShowBottomSheetMenu(true)}
          showDefault={true}
          iconBG={
            isMenuVisible
              ? IIconButtonBGColors.PRIMARY
              : IIconButtonBGColors.NEUTRAL_LIGHTEST
          }
          iconSize={EMOJI_ICON_SIZE}
          type={IIconButtonTypes.ROUND_PRIMARY}
          className={styles.volumeButton}
          tooltip={TooltipTypes.none}
        />
      )}
      {isMobile && showBottomSheetMenu && (
        <BottomSheet
          ref={bottomSheetRef}
          isOpen={true}
          onSnap={snapPoints => setSnapPoint(snapPoints)}
          onClose={() => setShowBottomSheetMenu(false)}
          snapPoints={[0.3, 0]}
          showCloseButton
          onCloseClick={() => setShowBottomSheetMenu(false)}
          onBackdropTouch={() => snapTo(1)}
          backdropStyle={{ left: selectedSnapPoint === 0 ? 0 : '-100vw' }}
          id="stageMenuBottomSheet"
        >
          <div className={styles.bottomSheetContainer}>
            {showEmojiButton && !!handleEmojiClick && (
              <>
                <EmojiBar
                  onEmojiClick={(emojiId: any) => {
                    handleEmojiClick(emojiId);
                    setShowBottomSheetMenu(false);
                  }}
                  containerClassName={styles.emojiBar}
                />
                <div className={styles.divider}></div>
              </>
            )}
            {isWebinar && (
              <li
                aria-hidden
                className={styles.menuOption}
                onClick={showEditProfileModal}
              >
                <div className={styles.menuItemLabelContainer}>
                  <Edit3 size={16} />
                  Edit profile
                </div>
                <ChevronRight size={16} />
              </li>
            )}
            {!!networkStrengthIcon && (
              <li
                aria-hidden
                className={styles.menuOption}
                onClick={() => setShowStreamingIssuesPopup(true)}
              >
                <div className={styles.menuItemLabelContainer}>
                  <div style={{ marginLeft: '-6px' }}>
                    {networkStrengthIcon}
                  </div>
                  Streaming issues?
                </div>
                <ChevronRight size={16} />
              </li>
            )}
            {!showVideoQualityOptions && !isWebinar && isVideoSwitchEnabled && (
              <li
                aria-hidden
                className={styles.menuOption}
                onClick={videoQualityToggle}
              >
                <div className={styles.menuItemLabelContainer}>
                  <VideoWithPlayIcon size={16} /> Video Quality
                </div>
                <ChevronRight size={16} />
              </li>
            )}
            {isSyncwordsCaptionEnabled && (
              <li
                aria-hidden
                className={styles.menuOption}
                onClick={captionOptionsToggle}
              >
                <div className={styles.menuItemLabelContainer}>
                  <CaptionIcon size={16} /> Captions
                </div>
                <ChevronRight size={16} />
              </li>
            )}
            {isStudioStage && isAutomatedCaptionEnabled && (
              <div
                aria-hidden
                className={classnames(styles.closedCaptions, {
                  [styles.closedCaptionsEnabled]: isCCEnabled,
                })}
                onClick={toggleAgoraClosedCaptions}
              >
                <CaptionIcon
                  color={IContentColors.MONOCHROME_CONTRAST}
                  size={24}
                />
              </div>
            )}
            {!!multilingualToken && isBroadcasting && (
              <li
                aria-hidden
                className={styles.menuOption}
                onClick={e => {
                  toggleInterpreterButton(e);
                  setShowBottomSheetMenu(false);
                }}
              >
                <div className={styles.menuItemLabelContainer}>
                  <MultilingualIcon color={IContentColors.WHITE} size={16} />
                  Audio language
                </div>
                <ChevronRight size={16} />
              </li>
            )}
            <div ref={ref}>
              {showVideoQualityOptions && renderVideoQualityOptions()}
            </div>
            <div ref={captionListRef}>
              {showCaptionLanguages && renderCaptionLanguages()}
            </div>
          </div>
        </BottomSheet>
      )}

      {interprefyPortalRoot &&
        createPortal(
          <div
            style={{ display: isInterpreterVisible ? 'block' : 'none' }}
            className={classnames(styles.interpretContainer, {
              [styles.interpretContainerOrganizer]: isOrganizer,
            })}
          >
            <CloseCircleFilled
              className={styles.closeIcon}
              onClick={() => setIsInterpreterVisible(false)}
            />
            <iframe
              title="Interprefy"
              style={{
                width: 'inherit',
                height: 'inherit',
              }}
              id="interprefyiFrame"
              src={`https://interpret.world/integrationlink?token=${multilingualToken}`}
              scrolling="no"
            />
          </div>,
          interprefyPortalRoot,
        )}

      {syncwordsPortalRoot &&
        createPortal(
          <div
            id="recapdWidget"
            className={styles.recapdCaptioningContainer}
            style={{
              display:
                captionSelectedLanguage !== 'OFF' && isSyncwordsCaptionEnabled
                  ? 'block'
                  : 'none',
            }}
          >
            {/* syncWords widget will be injected into the below place holder. don't change below div id. */}
            <div
              id="fw-placeholder"
              style={{ border: 'none', background: 'no-repeat' }}
              className={styles.recapdWidget}
            ></div>
          </div>,
          syncwordsPortalRoot,
        )}
    </div>
  );
};

export default StreamSettingsBar;
