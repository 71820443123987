import React, { useEffect } from 'react';
import classnames from 'classnames';
// helpers
import { getFirstNameLastName, getNameInitial } from '@/utils/helpers';
// styles + types
import styles from './styles.module.scss';
import { EVolumeLevels, IStreamLabelProps } from './types';
import { useParams } from 'react-router';
import MicOffInStreams from '@/components/ui/new-icons/MicOffInStreams';
import {
  STANDARD_ICON_BUTTON_SIZE,
} from '@/styles/iconSizes';
import IconButton, {
  IIconButtonBGColors,
} from '@/components/ui/buttons/IconButton';
import {
  IUIIcons,
} from '@/types';
import { useMediaQuery } from 'react-responsive';
import useEllipsisCheck from '@/hooks/use-ellipsis-check';
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';

const StreamLabel = (props: IStreamLabelProps) => {
  const {
    companyName = '',
    greenRoom,
    nasscomScreenShare,
    headline,
    bio,
    hideNameBadge,
    isOrganizerStream,
    name,
    showCondensed,
    showNasscomLabel,
    showGradientLabel,
    showMutedStatus,
    volumeLevel,
    showVolumeIndicator,
    hideHeadline
  } = props;
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const dispatch = useDispatch();
  const {
    ref: speakerNameRef,
    id: speakerNameId,
    isEllipsis: speakerNameEllipsisActive,
  } = useEllipsisCheck();
  const {
    ref: speakerHeadlineRef,
    id: speakerHeadlineId,
    isEllipsis: speakerHeadlineEllipsisActive,
  } = useEllipsisCheck();

  const { stageId = '' } = useParams<{
    stageId: string;
  }>();
  const displayName = (() => {
    if (!name) {
      return '';
    }

    if (name.length === 0) {
      return '';
    }

    // const [firstName, lastName] = getFirstNameLastName(name);
    // const lastInitial = getNameInitial(lastName);

    // if (name.length > 20 && !name.includes('Screenshare')) {
    //   return `${firstName} ${lastInitial}.`;
    // }
    return name.trim();
  })();

  useEffect(() => {
    if (!displayName || !greenRoom || !stageId || isMobile) {
      return;
    }
    if (displayName.includes('Screenshare')) {
      return;
    }
    dispatch({
      type: 'event/setBackstageTalkingUserInfo',
      payload: {
        stageId: stageId,
        name: volumeLevel !== EVolumeLevels.NONE ? displayName : null,
      },
    });
  }, [displayName, greenRoom, volumeLevel, stageId, isMobile]);

  if (!name || name.length === 0 || !displayName || displayName.length === 0) {
    return null;
  }

  const isScreenshare = name.includes('Screenshare');

  // if (!greenRoom && (eventId === 'c9b34104-3eea-4848-9be3-9b01b5d0119d' || eventId === 'c1a00fc0-266e-4288-a034-32b468a0df2e')) {
  //   if (isScreenshare) {
  //     return null;
  //   }

  //   if (showCondensed) {
  //     return (
  //       <div className={styles.nameContainer}>
  //         <div className={styles.nameLabel}>{displayName}</div>
  //       </div>
  //     );
  //   }

  //   return (
  //     <div className={styles.gleamLabel}>
  //       <div className={styles.gleamLabelInner}></div>
  //       <div className={styles.gleamName}>{displayName}</div>
  //       <div className={styles.gleamSubline}>{headline}</div>
  //       <div className={styles.gleamSubline}>{bio}</div>
  //     </div>
  //   );
  // }

  if (hideNameBadge) {
    return null;
  }

  if (isScreenshare) {
    return null;
  }

  // if (showNasscomLabel || eventId === 'a668cb3b-92d8-4a3f-976b-0737c566348e') {
  //   if (isScreenshare) {
  //     return null;
  //   }

  //   return (
  //     <div className={classnames(styles.nasscomLabel, {
  //       [styles.nasscomLabelScreenshare]: nasscomScreenShare,
  //     })}>
  //       <div
  //         className={classnames(styles.nasscomCompanyName, {
  //           [styles.nasscomCompanyNameScreenshare]: nasscomScreenShare,
  //         })}
  //       >
  //         {displayName}
  //       </div>
  //       <div
  //         className={classnames(styles.nasscomCompanyHeadline, {
  //           [styles.nasscomCompanyHeadlineScreenshare]: nasscomScreenShare,
  //         })}
  //       >
  //         {headline}
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className={classnames(styles.gradientLabel, {
      [styles.gradientLabelMobile]: isMobile && window.location.href.includes("booth")
    })}>
      <div className={styles.gradientText}>
        <div
          className={classnames(styles.gradientName)}
          ref={speakerNameRef}
          data-tip
          data-for={speakerNameId}
        >
          {displayName}
        </div>
        {speakerNameEllipsisActive && (
          <ReactTooltip
            className={styles.reactTooltip}
            id={speakerNameId}
            place="top"
            effect="solid"
          >
            {displayName}
          </ReactTooltip>
        )}
        {!greenRoom && (
          <>
            <div
              className={classnames(styles.gradientHeadline)}
              data-tip
              data-for={speakerHeadlineId}
              ref={speakerHeadlineRef}
            >
              {!hideHeadline && headline}
            </div>
            {speakerHeadlineEllipsisActive && (
              <ReactTooltip
                className={styles.reactTooltip}
                id={speakerHeadlineId}
                place="top"
                effect="solid"
              >
                {!hideHeadline && headline}
              </ReactTooltip>
            )}
          </>
        )}
      </div>
      {showVolumeIndicator && (
        <div className={styles.volumeStatus}>
          {!showMutedStatus && (
            <IconButton
              key="micButton"
              className={styles.volumeStatusOff}
              activeIcon={IUIIcons.NEW_MIC_OFF}
              iconBG={IIconButtonBGColors.NEUTRAL_LIGHT_3}
              iconSize={STANDARD_ICON_BUTTON_SIZE}
              />          
          )}
          {showMutedStatus && volumeLevel === EVolumeLevels.NONE && (
            <div className={styles.volumeStatusUnMute}>
              <div className={styles.volumeStatusUnMuteBox}></div>
              <div className={styles.volumeStatusUnMuteBox}></div>
              <div className={styles.volumeStatusUnMuteBox}></div>
            </div>
          )}
          {showMutedStatus && volumeLevel === EVolumeLevels.HIGH && (
            <div className={styles.volumeStatusSpeaking}>
              <div
                className={`${styles.volumeStatusSpeakingBox} ${styles.volumeStatusSpeakingBox1}`}
              ></div>
              <div
                className={`${styles.volumeStatusSpeakingBox} ${styles.volumeStatusSpeakingBox2}`}
              ></div>
              <div
                className={`${styles.volumeStatusSpeakingBox} ${styles.volumeStatusSpeakingBox3}`}
              ></div>
            </div>
          )}
          {showMutedStatus && volumeLevel === EVolumeLevels.LOW && (
            <div className={styles.volumeStatusSpeakingLow}>
              <div
                className={`${styles.volumeStatusSpeakingLowBox} ${styles.volumeStatusSpeakingLowBox1}`}
              ></div>
              <div
                className={`${styles.volumeStatusSpeakingLowBox} ${styles.volumeStatusSpeakingLowBox2}`}
              ></div>
              <div
                className={`${styles.volumeStatusSpeakingLowBox} ${styles.volumeStatusSpeakingLowBox3}`}
              ></div>
            </div>
          )}
        </div>
      )}
    </div>
  );

  // if (isOrganizerStream) {
  //   if (showCondensed) {
  //     return (
  //       <div className={styles.nameContainer}>
  //         <div className={styles.nameLabel}>{`Moderator: ${displayName}`}</div>
  //       </div>
  //     );
  //   }
  //   return (
  //     <div className={styles.organizerLabel}>
  //       <div className={styles.labelShadowBox}>
  //         <div className={styles.nameSizingDiv}>{displayName}</div>
  //         <div className={styles.mainLabel}>
  //           {displayName}
  //           <div className={styles.moderatorLabel}>
  //             {companyName && (
  //               <div className={styles.companyName}>{companyName}</div>
  //             )}
  //             <div className={styles.roleName}>MODERATOR</div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  const strippedHeadline = headline ? headline.trim() : '';
  const strippedBio = bio ? bio.trim() : '';

  return (
    <div className={styles.nameContainer}>
      <div className={styles.nameLabel}>
        {displayName}
        {strippedHeadline && (
          <>
            <br />
            {headline}
          </>
        )}
      </div>
    </div>
  );
};

export default StreamLabel;