import { IStreamContainerDims } from "../../utils";

export const getBottomSponsorLayoutKeynoteContainerDims = (config: IStreamContainerDims) => {
  const { height, width, numRows = 1, numCols = 1 } = config;

  const primaryWidth = Math.floor(width) * 3 / 4;
  const primaryHeight = Math.floor(height);

  const newWidth = Math.floor(width) / (4 * numCols);
  const newHeight = Math.floor(height) / (2 * numRows);
  return {
    primary: {
      width: primaryWidth,
      height: primaryHeight
    },
    secondary: {
      width: newWidth,
      height: newHeight
    }
  };
};
