import { selectAudioPlaybackDeviceId } from '@/models/global';
import { handleAudioStreamPlay } from '@/utils/helpers';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const PlayStream = ({ stream, volumeOn }) => {
  const audioPlaybackDeviceId = useSelector(selectAudioPlaybackDeviceId);

  useEffect(() => {
    if (!stream) {
      console.error('Stream not found in <PlayStream />');
      return;
    }
    const { audioTrack } = stream;
    if (!stream.isLocal) {
      handleAudioStreamPlay(audioTrack, volumeOn, audioPlaybackDeviceId);
    }
  }, [audioPlaybackDeviceId, stream, volumeOn]);

  return null;
};

export default PlayStream;
