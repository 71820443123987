import React, { forwardRef } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { ISliderWrapperProps } from './types';
import { useVideoMutation } from '@/services/video/video-context';

const StyledSlider = styled(Slider)`
  .slick-dots {
    bottom: -24px;
  }
  .slick-dots li.slick-active button:before {
    color: white;
  }
  .slick-track {
    height: 100% !important;
  }
`;

const SliderWrapper = forwardRef((props: ISliderWrapperProps, ref) => {
  const {
    children,
    slidesToShowAndScroll = 1,
    shouldSlideVertically = false,
  } = props;

  const videoMutation = useVideoMutation();

  const handleSlideChange = (idx: number) => {
    const currPage = Math.ceil(idx / slidesToShowAndScroll) + 1;
    console.error('debugPagination > handleSlideChange >', idx, currPage);
    videoMutation.setPaginationConfig({
      currentPage: currPage,
    });
  };

  return (
    <StyledSlider
      ref={ref}
      swipe
      touchMove
      arrows={false}
      infinite={false}
      dots={!shouldSlideVertically}
      slidesToShow={slidesToShowAndScroll}
      slidesToScroll={slidesToShowAndScroll}
      vertical={shouldSlideVertically}
      verticalSwiping={shouldSlideVertically}
      afterChange={idx => handleSlideChange(idx)}
      // Logically afterChange should be used here, but when using
      // adaptiveHeight = true, the window resize callback in Slider
      // is not letting afterChange to fire.
      // If Adaptive Heigh is set to True then use beforeChange and not afterChange
      // More Info - https://github.com/akiran/react-slick/issues/1262
      // beforeChange={(curr, nxt) =>
      //   setTimeout(() => setCurrentSlideIdx(nxt), 500)
      // }
      speed={500}
      lazyLoad="progressive"
    >
      {children}
    </StyledSlider>
  );
});

export default SliderWrapper;
