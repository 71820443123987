import React, { useState } from 'react';
import styles from './styles.module.scss';
import Modal from '@/components/ui/modals/Modal';
import { InputField } from '@/components/ui/NewFormComponents';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import { isValidUrl } from '@/utils/jsHelpers/validator';
import EmptyState from '../../../StreamsDisplay/SharedFileView/EmptyState';
import {
  ENTER_VALID_URL_TEXT,
  getModifiedWebsiteLinkForMiro,
  PASTE_LINK_LABEL,
  PASTE_LINK_PLACEHOLDER,
  SHARE_WEBSITE_TEXT,
} from './config';
import { IPluginType } from '@/types/plugin';
import CrossCircleIcon from '@/components/ui/new-icons/CrossCircle';

export const ModalBody = ({
  setShowShareWebsitePopup,
  saveAndBroadcastChannelPlugin,
}) => {
  const [shareWebsiteUrl, setShareWebsiteUrl] = useState('');
  const [shareWebsiteUrlError, setShareWebsiteUrlError] = useState('');
  const [isLoadingIframePreview, setIsLoadingIframePreview] = useState(true);

  const handleShareWebsiteUrlChange = e => {
    const modifiedWebsiteUrl = getModifiedWebsiteLinkForMiro(e.target.value);
    setShareWebsiteUrl(modifiedWebsiteUrl);
    const isUrlChanged = shareWebsiteUrl !== modifiedWebsiteUrl;
    setIsLoadingIframePreview(isUrlChanged);
    if (modifiedWebsiteUrl) {
      setShareWebsiteUrlError('');
    }
  };

  const saveWebsiteLinkPlugin = () => {
    if (!isValidUrl(shareWebsiteUrl)) {
      setShareWebsiteUrlError(ENTER_VALID_URL_TEXT);
      return;
    }
    setShowShareWebsitePopup(false);
    saveAndBroadcastChannelPlugin(
      shareWebsiteUrl,
      'Custom Website',
      IPluginType.CUSTOM,
    );
  };
  return (
    <div className={styles.shareWebsiteContainer}>
      <div className={styles.modalHeaderContainer}>
        <div className={styles.modalHeaderText}>Share Website</div>
        <div
          aria-hidden
          className={styles.crossIconContainer}
          onClick={() => {
            setShowShareWebsitePopup(false);
          }}
        >
          <CrossCircleIcon size={20} />
        </div>
      </div>
      <InputField
        label={PASTE_LINK_LABEL}
        placeholder={PASTE_LINK_PLACEHOLDER}
        onChange={handleShareWebsiteUrlChange}
        containerStyles={styles.inputWebsiteLinkContainer}
        error={shareWebsiteUrlError}
        isLastElement={true}
        data-testid="website-url-input"
      />
      {shareWebsiteUrl && isValidUrl(shareWebsiteUrl) && (
        <div className={styles.iframeWrapper}>
          {isLoadingIframePreview && (
            <div className={styles.loaderContainer}>
              <EmptyState
                isLoading={true}
                height={188}
                width={360}
                containerClass={styles.loader}
              />
            </div>
          )}
          <iframe
            className={styles.iframeContainer}
            src={shareWebsiteUrl}
            referrerPolicy="no-referrer-when-downgrade"
            frameBorder="0"
            onLoad={() => {
              setIsLoadingIframePreview(false);
            }}
          />
        </div>
      )}
      <Button
        type="button"
        onClick={saveWebsiteLinkPlugin}
        styleClass={IButtonTypes.NEW_PRIMARY_LARGE}
        disabled={!shareWebsiteUrl}
        className={styles.shareWebsiteButton}
        data-testid="share-website-button"
      >
        {SHARE_WEBSITE_TEXT}
      </Button>
    </div>
  );
};

const ShareWebsitePopup = ({
  saveAndBroadcastChannelPlugin,
  showShareWebsitePopup,
  setShowShareWebsitePopup,
}) => {
  return (
    showShareWebsitePopup && (
      <Modal
        noHeader={true}
        noBodyPadding={true}
        modalBodyClass="modalBodyNeutralBase"
        autoTrigger
        render={({}) => (
          <ModalBody
            setShowShareWebsitePopup={setShowShareWebsitePopup}
            saveAndBroadcastChannelPlugin={saveAndBroadcastChannelPlugin}
          />
        )}
      />
    )
  );
};

export default ShareWebsitePopup;
