// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__spotlightContainer___xBK_g{display:flex;flex-flow:row nowrap;align-items:center;justify-content:center;position:relative;overflow:hidden}.styles-module__spotlightSecondaryContainer___1L5cw{width:100%;display:flex;flex-flow:row wrap;align-items:center;justify-content:center;position:relative}\n", ""]);
// Exports
exports.locals = {
	"spotlightContainer": "styles-module__spotlightContainer___xBK_g",
	"spotlightSecondaryContainer": "styles-module__spotlightSecondaryContainer___1L5cw"
};
module.exports = exports;
