// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__razorpayGridOuter___3bza3{width:100%;height:100%;display:flex;padding:0 10%;flex-wrap:wrap;justify-content:center;align-items:center;justify-items:center;align-content:center}\n", ""]);
// Exports
exports.locals = {
	"razorpayGridOuter": "styles-module__razorpayGridOuter___3bza3"
};
module.exports = exports;
