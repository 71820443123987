import React, { useEffect, useState } from 'react';
// components
import ToggleField from '@/components/ui/fields/ToggleField';
// styles + types
import styles from './styles.module.scss';
import useComponentVisible from '@/hooks/use-component-visible';

const StreamRecordingToggle = ({
  isBroadcasting,
  stageSettingsObj,
  updateStageRecordingToggle,
}) => {
  const [isRecordingEnable, setIsRecordingEnable] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  useEffect(() => {
    if (stageSettingsObj) {
      setIsRecordingEnable(stageSettingsObj.isRecordingSupport);
    }
  }, [stageSettingsObj]);

  const handleToggleChange = () => {
    if (!isBroadcasting) {
      if (!isRecordingEnable) {
        updateStageRecordingToggle(!isRecordingEnable);
        return;
      }
    }
    setShowModal(true);
    setIsComponentVisible(true);
  };

  const stopRecording = () => {
    updateStageRecordingToggle(false);
    setShowModal(false);
    setIsComponentVisible(false);
  };

  return (
    <div>
      <div className={styles.toggleMainContainer}>
        <span className={styles.recordLabel}>REC</span>
        <ToggleField
          onToggle={() => handleToggleChange()}
          enabled={isRecordingEnable}
          className={styles.toggleButton}
        />
        {showModal && isComponentVisible && (
          <div ref={ref} className={styles.modalMainContainer}>
            {isBroadcasting ? (
              <>
                <p>
                  You cannot enable/disable recording while session is live or
                  in dry runs.
                </p>
              </>
            ) : (
              <>
                <p>
                  Turning off recording will stop recording for the sessions.
                </p>
                <p> Are you sure you want to stop further recordings?</p>
                <div className={styles.modalButtonsLayout}>
                  <button
                    type="button"
                    className={styles.cancelButton}
                    onClick={() => {
                      setShowModal(false);
                      setIsComponentVisible(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={styles.yesButton}
                    onClick={() => {
                      stopRecording();
                    }}
                  >
                    Yes, Stop Recording
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StreamRecordingToggle;
