import React from 'react';
import SingleStreamPlayer from '@/components/PublishedStreamDisplay/SingleStreamPlayer';
import { getRazorpayGridLayoutDims } from './utils';
import styles from './styles.module.scss';

const GridLayout = props => {
  const {
    streams,
    parentHeight,
    parentWidth,
    streamDetailsMap,
    volumeOn,
    greenRoom,
    userMoveFns,
    parent,
    streamNamesMap,
    localClientAVStatus,
    liveRaisedHandRequestList,
  } = props;
  const streamContainerDimsConfig = {
    height: parentHeight,
    width: parentWidth,
    numCols: 1,
    numRows: 1,
  };

  const containerDims: any = getRazorpayGridLayoutDims(
    streamContainerDimsConfig,
    streams ? streams.length : 0,
  );

  const { streamStyles = {}, containerStyles = {} } = (() => {
    if (!streams) {
      return {};
    }
    if (streams.length === 2) {
      return {
        streamStyles: {
          position: 'relative',
          top: '-8%',
        },
      };
    }

    if (streams.length === 3) {
      return {
        containerStyles: {
          padding: '0 11%',
        },
      };
    }

    if (streams.length === 4) {
      return {
        containerStyles: {
          padding: '0 11%',
          position: 'relative',
          left: '0.7%',
        },
      };
    }

    if (streams.length === 5) {
      return {
        containerStyles: {
          padding: '0 11%',
          position: 'relative',
          left: '0.7%',
        },
      };
    }

    if (streams.length === 6) {
      return {
        containerStyles: {
          padding: '0 11%',
          position: 'relative',
          left: '0.7%',
        },
      };
    }

    return {};
  })();

  return (
    <div style={containerStyles} className={styles.razorpayGridOuter}>
      {streams.map((s, i) => (
        <div style={streamStyles}>
          <SingleStreamPlayer
            volumeOn={volumeOn}
            parent={parent}
            containerStyles={containerDims[i]}
            greenRoom={greenRoom}
            key={`grid${s.stream.uid}`}
            name={streamNamesMap[s.stream.uid]}
            streamDetails={streamDetailsMap[s.stream.uid]}
            showNasscomLabel={false}
            stream={s.stream}
            userMoveFns={userMoveFns}
            showGradientLabel={true}
            localClientAVStatus={localClientAVStatus}
            liveRaisedHandRequestList={liveRaisedHandRequestList}
          />
        </div>
      ))}
    </div>
  );
};

export default GridLayout;
