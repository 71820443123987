import IconButton, {
  IIconButtonBGColors,
  IIconButtonTypes,
} from '@/components/ui/buttons/IconButton';
import { HOVER_NAV_ICON_SIZE } from '@/styles/iconSizes';
import { IUIIcons } from '@/types';
import classnames from 'classnames';
import React, { useRef, useState } from 'react';
import { isIPad13 } from 'react-device-detect';
import { useClickAway } from 'react-use';
import EmojiBar from '..';
import styles from './styles.module.scss';
import { TooltipTypes } from '@/components/ui/buttons/IconButton/types';

interface Props {
  onEmojiClick: (type: string) => void;
  tooltip?: TooltipTypes;
  defaultIconBgColor?: IIconButtonBGColors;
  activeIconBgColor?: IIconButtonBGColors;
}

interface InnerProps extends Props {
  parentRef: React.RefObject<HTMLDivElement>;
  closeEmojiBar: () => void;
  showEmojiBar: boolean;
}

/**
 * Inner component is created to prevent constant click listener on the page
 * We only need to listen for click away when emoji bar is open
 * We also need to keep it mounted to avoid extra API calls inside EmojiBar component
 */
const EmojiBarButtonInner = ({
  showEmojiBar,
  parentRef,
  onEmojiClick,
  closeEmojiBar,
}: InnerProps) => {
  useClickAway(parentRef, closeEmojiBar);

  return (
    <div
      className={classnames(styles.emojiBarWrapper, {
        [styles.iPadWrapper]: isIPad13,
        [styles.showEmojiBar]: showEmojiBar,
      })}
    >
      <EmojiBar
        onEmojiClick={onEmojiClick}
        containerClassName={styles.emojiBar}
      />
    </div>
  );
};

const EmojiBarButton = ({
  onEmojiClick,
  tooltip,
  activeIconBgColor = IIconButtonBGColors.PRIMARY,
  defaultIconBgColor = IIconButtonBGColors.NEUTRAL_LIGHT_3,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showEmojiBar, setShowEmojiBar] = useState(false);

  return (
    <div className={styles.container} ref={containerRef}>
      <IconButton
        key="emojiButton"
        activeIcon={IUIIcons.SMILE_EMOJI}
        defaultIcon={IUIIcons.SMILE_EMOJI}
        onClick={() => setShowEmojiBar(prev => !prev)}
        showDefault={true}
        iconBG={showEmojiBar ? activeIconBgColor : defaultIconBgColor}
        iconSize={HOVER_NAV_ICON_SIZE}
        type={IIconButtonTypes.ROUND_PRIMARY}
        tooltipText="Emoji Reactions"
        className={styles.emojiButton}
        {...(!!tooltip && { tooltip })}
      />
      <EmojiBarButtonInner
        showEmojiBar={showEmojiBar}
        parentRef={containerRef}
        closeEmojiBar={() => setShowEmojiBar(false)}
        onEmojiClick={onEmojiClick}
      />
    </div>
  );
};

export default EmojiBarButton;
