export interface IStreamActiveUserProps {
  hasActiveVolume: boolean;
  user: any;
  containerStyles: any;
  showVolumeIndicator: boolean;
}
  
export enum PicSizes {
  XXSmall = 'XXSmall',
  XSmall = 'XSmall',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}
