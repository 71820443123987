import React from 'react';
import styles from './styles.module.scss';
import { useMediaQuery } from 'react-responsive';
import SharedFilePreview from '../../SharedFileView';
import SharedPluginView from '../../SharedPluginView';
import SingleStreamPlayer from '@/components/PublishedStreamDisplay/SingleStreamPlayer';
import {
  getContainerHeight,
  getContainerWidth,
  getSharedFileLayoutDims,
  getSharedPluginLayoutDims,
} from './config';
import classnames from 'classnames';
import { isTablet } from 'react-device-detect';
import { IStageParent } from '@/components/Stage/types';

const SharedStreamOrContentView = props => {
  const {
    streams,
    streamDetailsMap,
    sharedStream,
    isContentShared,
    streamNamesMap,
    parentWidth,
    parentHeight,
    parent,
    volumeOn,
    greenRoom,
    userMoveFns,
    localClientAVStatus,
    localClient,
    liveRaisedHandRequestList,
    handleStreamDoubleClick,
    currentPresentation,
    currentPlugin,
    pluginConfig,
    broadcastPermissions,
    presentationConfig,
    goToPresentationPage,
    setShowTabIndex,
    channelSidePanelState,
    layoutVariant,
  } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  // Container Dims Calc
  const containerDimsConfig = {
    height: getContainerHeight({
      parentHeight,
      isTablet,
      isMobile,
      isSidePanelOpen: !!channelSidePanelState,
      layoutVariant,
    }),
    width: getContainerWidth(parentWidth, isTablet, isMobile),
  };

  return (
    <div className={styles.sharedStreamWrapper}>
      {currentPresentation && (
        <div
          className={classnames(styles.sharedFilePreviewWrapper)}
          style={{
            width: containerDimsConfig.width,
            height: containerDimsConfig.height,
          }}
        >
          <SharedFilePreview
            parent={parent}
            setShowTabIndex={setShowTabIndex}
            fileUrl={currentPresentation.presentationUrl}
            currentPage={currentPresentation.currentPresentationPage}
            setCurrentPage={goToPresentationPage}
            totalPages={currentPresentation.presentationTotalPages}
            hasControl={currentPresentation.hasPresentationControl}
            stopPresentation={presentationConfig.stopPresentation}
            dims={getSharedFileLayoutDims({
              dims: {
                width: containerDimsConfig.width,
                height: containerDimsConfig.height,
              },
              isTablet,
              isMobile,
              isSidePanelOpen: !!channelSidePanelState,
            })}
            isLoading={currentPresentation.isLoading}
            classes={{
              page: styles.page,
            }}
          />
        </div>
      )}
      {!currentPresentation && currentPlugin && (
        <SharedPluginView
          parent={parent}
          setShowTabIndex={setShowTabIndex}
          dims={getSharedPluginLayoutDims(
            { width: parentWidth, height: parentHeight },
            isTablet,
            isMobile,
            !!channelSidePanelState,
            layoutVariant,
          )}
          currentPlugin={currentPlugin}
          stopPlugin={pluginConfig.stopPlugin}
          streams={streams}
          streamDetailsMap={streamDetailsMap}
          broadcastPermissions={broadcastPermissions}
        />
      )}
      {!isContentShared && sharedStream && (
        <SingleStreamPlayer
          volumeOn={volumeOn}
          parent={parent}
          containerStyles={{
            width: containerDimsConfig.width,
            height: containerDimsConfig.height,
          }}
          greenRoom={greenRoom}
          key={`line${sharedStream.uid}`}
          name={streamNamesMap[sharedStream.uid]}
          onDoubleClick={() => handleStreamDoubleClick(sharedStream)}
          streamDetails={streamDetailsMap[sharedStream.uid]}
          stream={sharedStream}
          userMoveFns={userMoveFns}
          localClientAVStatus={localClientAVStatus}
          localClient={localClient}
          liveRaisedHandRequestList={liveRaisedHandRequestList}
          onScreenMount={() =>
            parent === IStageParent.LIVE_DISCUSSION && setShowTabIndex(null)
          }
        />
      )}
    </div>
  );
};

export default React.memo(SharedStreamOrContentView);
