import useAudioVolume from '@/services/video/useAudioVolume';
import { useVideoMutation } from '@/services/video/video-context';
import React, { useEffect, useRef } from 'react';

const DialInAudioVolumeHandler = ({dialInStream}) => {
    const mutationCtx: any = useVideoMutation();

    // Show active user indicator
    const { currentVolumeLevel, hasActiveVolume } = useAudioVolume({
        audioTrack: dialInStream?.audioTrack,
        isLocalStream: false,
        isLocalAudioEnabled: false,
    });

    const removeStreamPresenceTimeout = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (hasActiveVolume) {
            const timeoutFn = removeStreamPresenceTimeout.current
            timeoutFn && clearTimeout(timeoutFn);
            // if hasActiveVolume then add to presence streams
            console.log('debugDialIn > DialInAudioVolumeHandler > hasActiveVolume > ', dialInStream.uid)
            mutationCtx.addDialInStreamPresence(dialInStream.uid);
        } else {
            console.log('debugDialIn > DialInAudioVolumeHandler > hasActiveVolume > setTimeout > ', dialInStream.uid)
            removeStreamPresenceTimeout.current = setTimeout(() => {
                console.log('debugDialIn > DialInAudioVolumeHandler > hasActiveVolume > no > ', dialInStream.uid)
                mutationCtx.removeStreamPresence(dialInStream.uid);
            }, 5000);
        }
    }, [hasActiveVolume]);

    useEffect(() => {
        return () => {
            const timeoutFn = removeStreamPresenceTimeout.current
            console.log('debugDialIn > DialInAudioVolumeHandler > cleanup > clearTimeout', dialInStream.uid)
            timeoutFn && clearTimeout(timeoutFn);
        };
    }, []);

    return <></>;
};

const DialInAudio = ({dialInStreams, }) => {
    return (
        <>
        {dialInStreams.map((dialInStream) => dialInStream && <DialInAudioVolumeHandler key={dialInStream.uid} dialInStream={dialInStream} />)}
        </>
    );
}

export default DialInAudio;