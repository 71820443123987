import React, { useRef, useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import classnames from 'classnames';
import styled from 'styled-components';
import Imgix from 'react-imgix';

import Text, {
  ITextColors,
  ITextSizes,
  ITextStyles,
} from '@/components/ui/content/Text';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { IContentColors } from '@/types';
import { getCssVar } from '@/utils/cssVars';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import {
  makeSelectDiscussionListByEventId,
  makeSelectIsOrganizer,
} from '@/models/event';
import { IStageParent } from '@/components/Stage/types';
import BroadcastStop from '@/components/ui/new-icons/BroadcastStop';
import { useFullscreen } from 'react-use';

import { ISharedFilePreviewProps } from './types';
import EmptyState from './EmptyState';
import styles from './styles.module.scss';
import ConfirmExitModal from '../../StreamOptionsBar/ConfirmExitModal';
import { useDebounced } from '@/utils/helpers';
import { useMediaQuery } from 'react-responsive';
import { getIsCurrentUserOwnerInRoom } from '../../utils';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '@/models/account';

const __testIdPrefix = 'SharedFileView';

const getDataTestId = (id: string) => `${__testIdPrefix}--${id}`;

// Snapshot testing here
// Do testing for all the actions

// If there is any onClick event, APIs should be called
// Condiotional rendering
// If there is a util file, write all input test cases with the expected output

const SharedFilePreview = (props: ISharedFilePreviewProps) => {
  const {
    fileUrl,
    currentPage,
    setCurrentPage,
    totalPages,
    hasControl,
    stopPresentation,
    parent,
    dims,
    isLoading = false,
    forceShowStopBroadcastIcon = true,
    setShowTabIndex,
    classes = {},
  } = props;

  const sliderRef = useRef<any>(null);
  const documentRef = useRef<any>(null);
  const { eventId = '', discussionTableId = '' } = useParams<any>();
  const isOrganizer = useMemoizedSelector(makeSelectIsOrganizer, eventId);
  const isBackstage = parent === IStageParent.LIVE_BACKSTAGE;
  const isDiscussion = parent === IStageParent.LIVE_DISCUSSION;
  const [showSlidesPreview, setShowSlidesPreview] = useState(false);
  const [previewSlidesToShow, setPreviewSlidesToShow] = useState(1);
  const [previewSlideWidth, setPreviewSlideWidth] = useState(102);
  const [sliderWidth, setSliderWidth] = useState('auto');
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [isWarmedUp, setIsWarmedUp] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const discussionTables = useMemoizedSelector(
    makeSelectDiscussionListByEventId,
    eventId,
  );

  const currentUser = useSelector(selectCurrentUser);

  const isRoomOwner = React.useMemo(
    () => {
        if(!discussionTableId){
          return false
        }
      
        return getIsCurrentUserOwnerInRoom(currentUser.accountId, discussionTables, discussionTableId)
    },
    [discussionTables, currentUser.accountId, discussionTableId]
  )   
  
  useEffect(() => {
    // In Rooms, whenever a presentation is shared, collapse the sidepanel
    if (
      !setShowTabIndex ||
      parent !== IStageParent.LIVE_DISCUSSION ||
      isMobile
    ) {
      return;
    }

    setShowTabIndex(null);
  }, []);

  const onPageLoadSuccess = ({ height, width }) => {
    // Page width minus, action container padding on left-right and
    // preview container padding on left-right
    const availableWidthForSlider = width - (2 * 4 + 2 * 12);
    const whRatio = width / height;
    const previewSlidesHeight = 77;
    const previewSlidesWidth = previewSlidesHeight * whRatio;
    const previewSlidesWidthWithMargin = previewSlidesWidth + 8;

    const slidesToShowForSlider = Math.floor(
      availableWidthForSlider / previewSlidesWidthWithMargin,
    );

    setPreviewSlidesToShow(slidesToShowForSlider);
    setPreviewSlideWidth(previewSlidesWidth);
    setSliderWidth(`${availableWidthForSlider}px`);
  };

  const resizeCallback = () => {
    const pageWrapperDiv = documentRef.current as HTMLDivElement;
    if (pageWrapperDiv) {
      const width = pageWrapperDiv.offsetWidth;
      const height = pageWrapperDiv.offsetHeight;
      onPageLoadSuccess({ height, width });
    }
  };

  const debouncedResizeCallback = useDebounced(resizeCallback, 100);

  useEffect(() => {
    if (!showSlidesPreview) {
      return () => undefined;
    }
    debouncedResizeCallback();
    window.addEventListener('resize', debouncedResizeCallback);
    return () => window.removeEventListener('resize', debouncedResizeCallback);
  }, [showSlidesPreview]);

  const disabledNextButton = currentPage === totalPages;
  const disabledPrevButton = currentPage === 1;

  const StyledSlider = useMemo(
    () => styled(Slider)`
      &.slick-slider {
        width: ${sliderWidth};
      }
      &.slick-slider .slick-track {
        margin-left: unset;
      }
    `,
    [sliderWidth],
  );

  const showPrev = currentSlide !== 0;
  const handlePrevClick = () => sliderRef.current?.slickPrev();

  const showNext = currentSlide + previewSlidesToShow < totalPages;
  const handleNextClick = () => sliderRef.current?.slickNext();

  const canControl = hasControl && (isBackstage || isDiscussion);
  const canStopPresentation = (isOrganizer || isRoomOwner) && (isBackstage || isDiscussion) && forceShowStopBroadcastIcon;
  const showCtaContainer = totalPages > 1;

  const isFullScreen = useFullscreen(documentRef, showFullScreen);

  const [fullScreenCss, showFullScreenCss] = useState(isFullScreen);

  useEffect(() => {
    // This is done to handle the transition of fullscreen properly.
    if (!isFullScreen) {
      const tid = setTimeout(() => showFullScreenCss(false), 500);
      return () => clearTimeout(tid);
    }
    showFullScreenCss(isFullScreen);
  }, [isFullScreen]);

  const PrefetchAdjacentPages = () => {
    return (
      <>
        {[
          currentPage - 2,
          currentPage - 1,
          currentPage + 1,
          currentPage + 2,
        ].map(cachedPage => {
          if (cachedPage > 0 && cachedPage <= totalPages) {
            return (
              <Imgix
                src={fileUrl}
                imgixParams={{ page: cachedPage, bg: 'fff' }}
                className={styles.pageCache}
              />
            );
          }
          return null;
        })}
      </>
    );
  };

  useEffect(() => {
    if (isWarmedUp) {
      setIsWarmedUp(false);
    }
  }, [currentPage]);

  if (isLoading) {
    return (
      <EmptyState isLoading={true} height={dims.height} width={dims.width} />
    );
  }

  const showStopPresentationButton = canStopPresentation && isWarmedUp && !isFullScreen;

  return (
    <div className={styles.document}>
      <div
        ref={documentRef}
        className={styles.pageWrapper}
        style={{ '--page-height': `${dims.height}px` }}
        onDoubleClick={() => {
          if (isWarmedUp) {
            setShowFullScreen(prev => !prev);
          }
        }}
      >
        {isWarmedUp ? (
          <Imgix
            className={classnames(classes.page, {
              [styles.pageFullScreen]: fullScreenCss,
              [styles.page]: !classes.page,
            })}
            htmlAttributes={{ height: dims.height, width: dims.width }}
            imgixParams={{ page: currentPage, bg: 'fff' }}
            src={fileUrl}
          />
        ) : (
          <>
            <EmptyState
              isLoading={true}
              height={isFullScreen ? '100%' : dims.height}
              width={isFullScreen ? '100%' : dims.width}
            />
            <Imgix
              src={fileUrl}
              imgixParams={{ page: currentPage, bg: 'fff' }}
              className={styles.pageCache}
              htmlAttributes={{
                onLoad: () => setIsWarmedUp(true)
              }}
            />
          </>
        )}
        <PrefetchAdjacentPages />
        {showStopPresentationButton && (
          <ConfirmExitModal
            trigger={({ setShow }) => (
              <div
                className={styles.stopContainer}
                onClick={() => setShow(true)}
                role="none"
              >
                <BroadcastStop color={IContentColors.WHITE} size={12} />
                <Text
                  text="Stop Presentation"
                  className={styles.text}
                  textSize={ITextSizes.XSMALL}
                  textColor={ITextColors.WHITE}
                  clickable
                />
              </div>
            )}
            handleConfirm={({ setShow }) => {
              setShow(false);
              stopPresentation();
            }}
            exitText="Are you sure you want to stop broadcasting this presentation?"
            confirmButtonText="Yes"
          />
        )}
      </div>

      {canControl && isWarmedUp && showCtaContainer && (
        <div
          className={styles.actionsContainer}
          data-testid={getDataTestId('actionsContainer')}
        >
          <div className={styles.ctaContainer}>
            <div
              className={classnames(styles.ctaWrapper, {
                [styles.ctaWrapperWithoutPreview]: !showSlidesPreview,
              })}
            >
              <div className={styles.paginationWrapper}>
                <ChevronLeft
                  color={getCssVar(
                    disabledPrevButton
                      ? IContentColors.NEUTRAL_MID_1
                      : IContentColors.WHITE,
                  )}
                  size={13}
                  strokeWidth={3}
                  className={styles.navigateIcon}
                  onClick={
                    !disabledPrevButton
                      ? () => setCurrentPage(currentPage - 1)
                      : undefined
                  }
                />
                <Text
                  text={`${currentPage} / ${totalPages}`}
                  textColor={ITextColors.WHITE}
                  textSize={ITextSizes.SMALL}
                  textStyle={ITextStyles.BOLDED}
                  textLineHeight="150%"
                  className={styles.text}
                />
                <ChevronRight
                  color={getCssVar(
                    disabledNextButton
                      ? IContentColors.NEUTRAL_MID_1
                      : IContentColors.WHITE,
                  )}
                  size={13}
                  strokeWidth={3}
                  className={styles.navigateIcon}
                  onClick={
                    !disabledNextButton
                      ? () => setCurrentPage(currentPage + 1)
                      : undefined
                  }
                />
              </div>
              <div
                className={styles.togglePreviewWrapper}
                onClick={() => setShowSlidesPreview(!showSlidesPreview)}
                role="none"
                data-testid={getDataTestId('togglePreviewWrapper')}
              >
                <Text
                  text={`${showSlidesPreview ? 'Hide' : 'Preview'} Slides`}
                  textColor={ITextColors.WHITE}
                  textSize={ITextSizes.SMALL}
                  textStyle={ITextStyles.BOLDED}
                  textLineHeight="150%"
                  className={styles.text}
                />
              </div>
            </div>
          </div>
          {showSlidesPreview && (
            <div
              className={styles.previewContainer}
              data-testid={getDataTestId('previewContainer')}
            >
              <StyledSlider
                ref={sliderRef}
                swipe={false}
                touchMove={false}
                infinite={false}
                arrows={false}
                adaptiveHeight={false}
                variableWidth={true}
                slide="span"
                slidesToShow={previewSlidesToShow}
                afterChange={setCurrentSlide}
              >
                {Array.from(new Array(totalPages), (el, idx) => (
                  <img
                    width={previewSlideWidth}
                    className={classnames(styles.page, {
                      [styles.currentPage]: currentPage === idx + 1,
                    })}
                    src={fileUrl + '?w=108&page=' + (idx + 1)}
                    onClick={() => setCurrentPage(idx + 1)}
                    loading="lazy"
                  />
                ))}
              </StyledSlider>
              {showPrev && (
                <div
                  className={styles.prevArrowContainer}
                  onClick={handlePrevClick}
                  role="none"
                >
                  <ChevronLeft
                    size={20}
                    strokeWidth={3}
                    color={getCssVar(IContentColors.NEUTRAL_MID_1)}
                  />
                </div>
              )}
              {showNext && (
                <div
                  className={styles.nextArrowContainer}
                  onClick={handleNextClick}
                  role="none"
                >
                  <ChevronRight
                    size={20}
                    strokeWidth={3}
                    color={getCssVar(IContentColors.NEUTRAL_MID_1)}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(SharedFilePreview);
