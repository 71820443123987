/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo, useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import queryString from 'query-string';
// components
import CircleFrame from '@/components/PublishedStreamDisplay/frames/CircleFrame';
import PetalFrame, {
  IPetalFrameCurveOrientations,
} from '@/components/PublishedStreamDisplay/frames/PetalFrame';
import SingleStreamPlayer from '../SingleStreamPlayer';
import SquareFrame from '@/components/PublishedStreamDisplay/frames/SquareFrame';
// helpers + hooks
import {
  getHeightBasedStreamContainerDims,
  getHorizontalSpotlightDims,
  getSpotlightDims,
  getStreamContainerDims,
  getTwoRowSpotlightDims,
  getEmbeddedRtmpDims,
} from './utils';
import { IMAGE_URL } from '@/config';
import { interpreterStreamIds } from './interpreter';
// config + styles + types
import configByEvent, { configByStage } from '@/custom-config';
import styles from './styles.module.scss';
import { IStreamsDisplayProps } from './types';
import { IStageParent } from '@/components/Stage/types';
import {
  IVideoFrameTypes,
  IStreamsDisplayMode,
} from '@/components/PublishedStreamDisplay/types';
// models + hooks + helpers
import { selectIsStageFull, selectCurrentProfile } from '@/models/global';
import {
  makeSelectIsOrganizer,
  makeSelectEventUserRole,
  makeSelectStageLayoutByStageId,
  makeSelectIsSpeaker,
} from '@/models/event';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { timeLeftMsToMins, setOnboardingTour, prefixPicUrlIfNeeded } from '@/utils/helpers';
import {
  TABLE_STREAM_JOIN_FLOW,
  getStreamIntro as getTableStreamIntro,
  TABLE_MEETING_STREAM_FLOW,
  getMeetingStreamIntro,
} from '@/components/onboarding/tableFlows';
import {
  STAGE_STREAM_JOIN_FLOW,
  getStreamIntro as getStageStreamIntro,
} from '@/components/onboarding/stageFlows';

import { makeSelectShowFlow } from '@/models/account';
import Confetti from 'react-confetti';

import CenterStageLayout from './CenterStageLayout';
import BottomSponsorLayout from './BottomSponsorLayout';
import RazorpayLayout from './RazorpayLayout';
import CondensedLayout from './CondensedLayout';
import InterpreterBox from './InterpreterBox';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import SharedFilePreview from './SharedFileView';
import LiveStream from '@/components/Stage/LiveStream';
import { useMediaQuery } from 'react-responsive';
import SharedPluginView from './SharedPluginView';
import { isMobileOnly } from 'react-device-detect';
import { IEventRoleOptions } from '@/models/event/types';
import ScrollBarArrowButton from './ScrollBarArrowButton';
import DialInAudio from '../SingleStreamPlayer/DialInAudio';
import GenericErrorBoundary from '@/components/GenericErrorBoundary';
import { HIDE_SESSION_BEGIN_TIMER } from './config';
import PresentationLayout from './PresentationLayout';
import { EPresentationLayoutVariant } from './PresentationLayout/types';

const StreamsDisplay = (props: IStreamsDisplayProps) => {
  const {
    containerDims,
    eventConfig,
    fillerImageUrl = '',
    greenRoom = false,
    handleUserKickout,
    moveUserToGreenRoom,
    moveUserToStage,
    mutationCtx,
    parent,
    broadcastPermissions,
    streamDetailsMap,
    streamNamesMap,
    streamsDisplayMode,
    timeLeftMs,
    showConfetti,
    volumeOn = true,
    presentationConfig,
    pluginConfig,
    liveRaisedHandRequestList,
    completeRaisedHandRequest,
    localAvConfig: { audioEnabled = false, videoEnabled = false },
    useRoundedCorners,
    currentLiveStream,
    localClient,
    refId,
    dialInStreams,
    setShowTabIndex,
  } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const location = useLocation();
  const { search, pathname } = location;
  const searchParams = queryString.parse(search);
  const { cur } = searchParams;
  const isMeetingRoom = cur != undefined;

  const { eventId = '', stageId: paramStageId = '' } = useParams() as any;
  const stageId = parent === IStageParent.STAGE_PREVIEW ? refId : paramStageId;
  const stageDetailsByStageId = useMemoizedSelector(
    makeSelectStageLayoutByStageId,
    stageId,
  );
  const dispatch = useDispatch();
  const currentVideoProfile = useSelector(selectCurrentProfile);
  const currentUserRole = useMemoizedSelector(makeSelectEventUserRole, eventId);
  const scrollContainerRef = useRef() as any;

  const {
    currentPresentation: currentChannelPresentation,
    goToPresentationPage,
  } = presentationConfig;

  const { currentPlugin } = pluginConfig;

  const config = (eventId && configByEvent[eventId]) || configByEvent.default;

  const { frameType } = eventConfig || {};
  const {
    streams: mainStreams,
    primaryStream: mainPrimaryStream,
    secondaryStreams: mainSecondaryStreams,
    greenRoomStreams,
  } = props;

  const selectStreamFlow = useMemo(
    () =>
      makeSelectShowFlow(
        parent === IStageParent.LIVE_DISCUSSION
          ? isMeetingRoom
            ? TABLE_MEETING_STREAM_FLOW
            : TABLE_STREAM_JOIN_FLOW
          : STAGE_STREAM_JOIN_FLOW,
      ),
    [],
  );
  const showStreamFlow = useSelector(selectStreamFlow);
  const isOrganizer = useMemoizedSelector(makeSelectIsOrganizer, eventId);
  const isSpeaker = useMemoizedSelector(makeSelectIsSpeaker, eventId);
  const isAttendee = !isOrganizer && !isSpeaker;
  const hideBeginTimer =
    HIDE_SESSION_BEGIN_TIMER.includes(eventId) && isAttendee;

  // Intro
  const [introInProgress, setIntroInProgress] = useState(false);
  const introObject = useMemo(() => {
    if (parent === IStageParent.LIVE_DISCUSSION && !isMeetingRoom) {
      return getTableStreamIntro(updatedFlowState => {
        dispatch({
          type: 'account/updateUserFlowProgress',
          payload: updatedFlowState,
        });
        setIntroInProgress(false);
      });
    }
    if (parent === IStageParent.LIVE_DISCUSSION && isMeetingRoom) {
      return getMeetingStreamIntro(updatedFlowState => {
        dispatch({
          type: 'account/updateUserFlowProgress',
          payload: updatedFlowState,
        });
        setIntroInProgress(false);
      });
    }
    if (!isOrganizer) {
      return getStageStreamIntro(updatedFlowState => {
        dispatch({
          type: 'account/updateUserFlowProgress',
          payload: updatedFlowState,
        });
        setIntroInProgress(false);
      });
    }
  }, []);
  useEffect(() => {
    if (showStreamFlow && !introInProgress && introObject && !isMobileOnly) {
      introObject && introObject.start();
      setIntroInProgress(true);
      introObject && setOnboardingTour(introObject);
    }
  }, [showStreamFlow]);
  useEffect(
    () => () => {
      introObject && introObject.exit(true);
    },
    [],
  );

  const userMoveFns = {
    handleUserKickout,
    moveUserToStage,
    moveUserToGreenRoom,
    completeRaisedHandRequest,
  }

  const localClientAVStatus = { audio: audioEnabled, video: videoEnabled };

  const { interpreterStream, nonInterpreterStreams, primaryStream, secondaryStreams } = useMemo(() => {
    let interpreterStream;
    const nonInterpreterStreams = [];
    let primaryStream = mainPrimaryStream;
    let secondaryStreams = [];

    mainSecondaryStreams.forEach(mst => {
      if (!interpreterStreamIds.includes(mst.uid)) {
        secondaryStreams.push(mst);
      }
    });

    if (
      mainPrimaryStream &&
      interpreterStreamIds.includes(mainPrimaryStream.uid) &&
      secondaryStreams.length > 0
    ) {
      primaryStream = secondaryStreams[0];
      if (secondaryStreams.length === 1) {
        secondaryStreams = [];
      }
    }

    mainStreams.forEach(st => {
      if (!interpreterStream && interpreterStreamIds.includes(st.uid)) {
        interpreterStream = st;
      } else {
        nonInterpreterStreams.push(st);
      }
    });

  return { interpreterStream, nonInterpreterStreams, primaryStream, secondaryStreams }
  }, [mainPrimaryStream, mainSecondaryStreams, interpreterStreamIds ])

  const streams = greenRoom ? greenRoomStreams : nonInterpreterStreams;

  const [streamsCount, setStreamsCount] = useState(0);
  useEffect(() => {
    if (streams && streams.length > 0) {
      if (streams.length !== streamsCount) {
        setStreamsCount(streams.length);
        if (
          currentUserRole === IEventRoleOptions.ORGANIZER ||
          currentUserRole === IEventRoleOptions.MODERATOR
        ) {
          dispatch({
            type: 'event/getAllEventSpeakers',
            payload: {
              eventId,
              refresh: true,
            },
          });
        }
      }
    }
  }, [streams, currentUserRole]);
  const streamLength = (streams && streams.length) || 0;
  const frame = (() => {
    if (greenRoom) {
      return IVideoFrameTypes.NO_FRAME_STACKED;
    }
    if (frameType) {
      return frameType;
    }
    return IVideoFrameTypes.SQUARE_ROUNDED;
  })();
  const rightAlignVideos =
    frame === IVideoFrameTypes.SQUARE_TO_EDGE &&
    streamLength > 2 &&
    streamLength < 5;

  const isStageFull = useSelector(selectIsStageFull);

  const stageCustomConfig = stageId && configByStage[stageId];

  const { height: containerHeight, width: containerWidth } = containerDims;
  const {
    parentHeight,
    parentWidth,
    parentLeftPosition,
    parentRightPosition,
    parentTopPosition,
    parentBottomPosition,
  } = useMemo(() => {
    let plp = 0; // parentLeftPosition
    let ptp = 0; // parentTopPosition
    let prp = 0; // parentRightPosition
    let pbp = 0; // parentBottomPosition
    let ph = containerHeight; // parentHeight
    let pw = containerWidth; // parentWidth

    if (stageCustomConfig && stageCustomConfig.containerPadding) {
      const {
        leftPercentage = 0,
        rightPercentage = 0,
        topPercentage = 0,
        bottomPercentage = 0,
      } = stageCustomConfig.containerPadding || {};

      plp = (leftPercentage / 100) * containerWidth;
      prp = (rightPercentage / 100) * containerWidth;
      pw -= plp + prp;

      ptp = (topPercentage / 100) * containerHeight;
      pbp = (bottomPercentage / 100) * containerHeight;
      ph -= ptp + pbp;
    } else if (eventConfig && eventConfig.containerPadding) {
      const {
        leftPercentage = 0,
        rightPercentage = 0,
        topPercentage = 0,
        bottomPercentage = 0,
      } = eventConfig.containerPadding || {};

      plp = (leftPercentage / 100) * containerWidth;
      prp = (rightPercentage / 100) * containerWidth;
      pw -= plp + prp;

      ptp = (topPercentage / 100) * containerHeight;
      pbp = (bottomPercentage / 100) * containerHeight;
      ph -= ptp + pbp;
    }
    return {
      parentHeight: ph,
      parentWidth: pw,
      parentLeftPosition: plp,
      parentRightPosition: prp,
      parentTopPosition: ptp,
      parentBottomPosition: pbp,
    };
  }, [containerHeight, containerWidth, stageCustomConfig]);

  const DISPLAY_AS_LINE =
    greenRoom ||
    (![
      '7ad3f19f-5716-4719-8585-a657265afca7',
      'b9954050-213a-4dc5-93a0-055ffdcc5fd5',
      'e49cc3c7-00c4-47b7-b780-1ba9607cda20',
      '4fae2873-34ab-4d85-8768-04d651792e47',
      '88793d97-9a4f-4d8b-bf50-a719f39bb4da',
      '365a990a-69ad-4135-abb3-0bb9055a4d9d',
    ].includes(eventId) &&
      !isStageFull &&
      streamsDisplayMode === IStreamsDisplayMode.LINE);

  const DISPLAY_AS_GRID =
    ([
      '7ad3f19f-5716-4719-8585-a657265afca7',
      'b9954050-213a-4dc5-93a0-055ffdcc5fd5',
      'e49cc3c7-00c4-47b7-b780-1ba9607cda20',
      '4fae2873-34ab-4d85-8768-04d651792e47',
      '88793d97-9a4f-4d8b-bf50-a719f39bb4da',
      '365a990a-69ad-4135-abb3-0bb9055a4d9d',
    ].includes(eventId) ||
      isStageFull ||
      streamsDisplayMode === IStreamsDisplayMode.GRID) &&
    !currentPlugin;
  const DISPLAY_AS_SPOTLIGHT =
    streamsDisplayMode === IStreamsDisplayMode.SPOTLIGHT;
  const DISPLAY_AS_TWO_ROW_SPOTLIGHT =
    streamsDisplayMode === IStreamsDisplayMode.TWO_ROW_SPOTLIGHT;
  const DISPLAY_AS_HORIZONTAL_SPOTLIGHT =
    streamsDisplayMode === IStreamsDisplayMode.HORIZONTAL_SPOTLIGHT;
  const DISPLAY_AS_CENTER_STAGE =
    streamsDisplayMode === IStreamsDisplayMode.CENTER_STAGE;
  const DISPLAY_AS_BOTTOM_SPONSOR_LAYOUT =
    streamsDisplayMode === IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
  const DISPLAY_AS_RAZORPAY_LAYOUT =
    streamsDisplayMode === IStreamsDisplayMode.RAZORPAY;
  const DISPLAY_AS_PRESENTATION_LAYOUT =
    streamsDisplayMode === IStreamsDisplayMode.PRESENTATION;
  const DISPLAY_AS_PRESENTATION_LAYOUT_V2 =
    streamsDisplayMode === IStreamsDisplayMode.PRESENTATION_V2;

  const handleStreamDoubleClick = stream => {
    mutationCtx.setPrimaryStream(stream);
  };

  // variables that will be defined per condition
  let StreamContents;
  const streamContainerDimsConfig = {
    height: parentHeight,
    width: parentWidth,
    leftPosition: parentLeftPosition,
    topPosition: parentTopPosition,
    numCols: 1,
    numRows: 1,
  };

  const greenroomStreamContainerDimsConfig = {
    height: containerHeight,
    width: containerWidth,
    leftPosition: 0,
    topPosition: 0,
    numCols: 1,
    numRows: 1,
  };

  const fillerImage = useMemo(() => {
    if (!fillerImageUrl) {
      return fillerImageUrl;
    }

    const baseURL = `${fillerImageUrl}?w=${parentWidth}&h=${parentHeight}&fit=fill&fill=blur`;
    if (fillerImageUrl.indexOf(IMAGE_URL) === -1) {
      return `${IMAGE_URL}${baseURL}`;
    }
    return baseURL;
  }, [fillerImageUrl, parentWidth, parentHeight]);

  if (currentLiveStream) {
    const { embedded: spotlightDims } = getEmbeddedRtmpDims(
      streamContainerDimsConfig,
    );

    StreamContents = (
      <div
        id="currentLiveStream"
        className={styles.rtmpCentered}
        style={spotlightDims}
      >
        <LiveStream
          volumeOn={volumeOn}
          config={config}
          currentLiveStream={currentLiveStream}
          isOrganizer={isOrganizer}
        />
      </div>
    );
  } else if (streams) {
    // split into variables to make options below more legible
    const SHOW_ONE_BY_ONE_GRID = streamLength === 1;
    const SHOW_TWO_BY_ONE_GRID = streamLength === 2;
    const SHOW_TWO_BY_TWO_GRID = streamLength === 3 || streamLength === 4;
    const SHOW_THREE_BY_TWO_GRID = streamLength > 4 && streamLength <= 6;
    const SHOW_THREE_BY_THREE_GRID = streamLength > 6 && streamLength <= 9;
    const SHOW_FOUR_BY_THREE_GRID = streamLength > 9 && streamLength <= 12;
    const SHOW_FOUR_BY_FOUR_GRID = streamLength > 12 && streamLength <= 16;

    if (DISPLAY_AS_LINE) {
      streamContainerDimsConfig.numCols = streamLength;
      streamContainerDimsConfig.numRows = 1;
      let lineContainerDims = getStreamContainerDims(streamContainerDimsConfig);

      switch (frame) {
        case IVideoFrameTypes.NO_FRAME_STACKED: {
          const noFrameStackedDims = getHeightBasedStreamContainerDims(
            greenRoom
              ? greenroomStreamContainerDimsConfig
              : streamContainerDimsConfig,
          );

          StreamContents = streams.map(s => (
            <SingleStreamPlayer
              volumeOn={volumeOn}
              parent={parent}
              containerStyles={noFrameStackedDims}
              eventId={eventId}
              greenRoom={greenRoom}
              hideNameBadge={true}
              moveUserToStage={moveUserToStage}
              name={streamNamesMap[s.stream.uid]}
              streamDetails={streamDetailsMap[s.stream.uid]}
              stream={s.stream}
              userMoveFns={userMoveFns}
              localClientAVStatus={localClientAVStatus}
              localClient={localClient}
              liveRaisedHandRequestList={liveRaisedHandRequestList}
            />
          ));
          break;
        }
        case IVideoFrameTypes.CIRCLE: {
          StreamContents = streams.map(s => (
            <CircleFrame
              key={`line${s.stream.uid}`}
              height={lineContainerDims.height}
            >
              <SingleStreamPlayer
                volumeOn={volumeOn}
                parent={parent}
                containerStyles={lineContainerDims}
                greenRoom={greenRoom}
                hideNameBadge={true}
                name={streamNamesMap[s.stream.uid]}
                onDoubleClick={() => handleStreamDoubleClick(s.stream)}
                stream={s.stream}
                streamDetails={streamDetailsMap[s.stream.uid]}
                userMoveFns={userMoveFns}
                noMargin={true}
                localClientAVStatus={localClientAVStatus}
                localClient={localClient}
                liveRaisedHandRequestList={liveRaisedHandRequestList}
              />
            </CircleFrame>
          ));
          break;
        }
        case IVideoFrameTypes.SQUARE: {
          StreamContents = streams.map(s => (
            <SquareFrame
              key={`line${s.stream.uid}`}
              height={lineContainerDims.height}
            >
              <SingleStreamPlayer
                volumeOn={volumeOn}
                parent={parent}
                containerStyles={lineContainerDims}
                greenRoom={greenRoom}
                hideNameBadge={true}
                name={streamNamesMap[s.stream.uid]}
                onDoubleClick={() => handleStreamDoubleClick(s.stream)}
                stream={s.stream}
                streamDetails={streamDetailsMap[s.stream.uid]}
                userMoveFns={userMoveFns}
                noMargin={true}
                localClientAVStatus={localClientAVStatus}
                localClient={localClient}
                liveRaisedHandRequestList={liveRaisedHandRequestList}
              />
            </SquareFrame>
          ));
          break;
        }
        case IVideoFrameTypes.SQUARE_ROUNDED: {
          let divStyles = {};
          if (streamLength === 1) {
            const ratio = 4 / 8;
            lineContainerDims = {
              height: lineContainerDims.height * ratio,
              width: lineContainerDims.height * ratio,
            };
            divStyles = {
              marginTop: '-20%',
              marginRight: '-6%',
            };
          } else if (streamLength === 2) {
            const ratio = 7 / 8;
            lineContainerDims = {
              height: lineContainerDims.height * ratio,
              width: lineContainerDims.height * ratio,
            };
            divStyles = {
              marginTop: '-20%',
              marginRight: '-2%',
            };
          }
          StreamContents = streams.map(s => (
            <div className={styles.frame} style={divStyles}>
              <SquareFrame
                key={`line${s.stream.uid}`}
                height={lineContainerDims.height}
                roundedCorner={true}
              >
                <SingleStreamPlayer
                  volumeOn={volumeOn}
                  parent={parent}
                  containerStyles={lineContainerDims}
                  greenRoom={greenRoom}
                  hideNameBadge={true}
                  name={streamNamesMap[s.stream.uid]}
                  onDoubleClick={() => handleStreamDoubleClick(s.stream)}
                  stream={s.stream}
                  streamDetails={streamDetailsMap[s.stream.uid]}
                  userMoveFns={userMoveFns}
                  noMargin={true}
                  localClientAVStatus={localClientAVStatus}
                  localClient={localClient}
                  liveRaisedHandRequestList={liveRaisedHandRequestList}
                />
              </SquareFrame>
            </div>
          ));
          break;
        }
        case IVideoFrameTypes.SQUARE_TO_EDGE: {
          let divStyles = {};
          if (streamLength === 1) {
            const ratio = 2.25 / 5;
            lineContainerDims = {
              height: parentHeight,
              width: parentWidth * ratio,
            };
            divStyles = {
              marginTop: '-30%',
              marginRight: '-5.5%',
            };
          } else if (streamLength === 2) {
            const ratio = 1.2 / 4;
            lineContainerDims = {
              height: parentHeight,
              width: parentWidth * ratio,
            };
            divStyles = {
              marginTop: '-30%',
              marginRight: '-4px',
            };
          } else if (streamLength === 3) {
            const ratio = 2.4 / 10;
            lineContainerDims = {
              height: parentHeight,
              width: parentWidth * ratio,
            };
            divStyles = {
              marginTop: '-30%',
              marginRight: '-4px',
            };
          } else if (streamLength === 4) {
            const ratio = 1.3 / 8;
            lineContainerDims = {
              height: parentHeight,
              width: parentWidth * ratio,
            };
            divStyles = {
              marginTop: '-30%',
              marginRight: '-4px',
            };
          }
          StreamContents = streams.map(s => (
            <div style={divStyles}>
              <SquareFrame
                key={`line${s.stream.uid}`}
                height={lineContainerDims.height}
                width={lineContainerDims.width}
                roundedCorner={false}
                noMargin={Boolean(streamLength >= 2 && streamLength < 5)}
              >
                <SingleStreamPlayer
                  volumeOn={volumeOn}
                  parent={parent}
                  containerStyles={lineContainerDims}
                  greenRoom={greenRoom}
                  hideNameBadge={true}
                  name={streamNamesMap[s.stream.uid]}
                  onDoubleClick={() => handleStreamDoubleClick(s.stream)}
                  stream={s.stream}
                  streamDetails={streamDetailsMap[s.stream.uid]}
                  userMoveFns={userMoveFns}
                  showNasscomLabel={true}
                  noBorder={true}
                  localClientAVStatus={localClientAVStatus}
                  localClient={localClient}
                  liveRaisedHandRequestList={liveRaisedHandRequestList}
                />
              </SquareFrame>
            </div>
          ));
          break;
        }
        case IVideoFrameTypes.PETAL: {
          if (streamLength === 1) {
            const ratio = 5.5 / 8;
            lineContainerDims = {
              height: lineContainerDims.height * ratio,
              width: lineContainerDims.height * ratio,
            };

            StreamContents = (
              <div style={{ marginTop: '-2%' }}>
                <PetalFrame
                  height={lineContainerDims.height * ratio}
                  curveOrientation={IPetalFrameCurveOrientations.LEFT}
                >
                  <SingleStreamPlayer
                    volumeOn={volumeOn}
                    parent={parent}
                    containerStyles={lineContainerDims}
                    greenRoom={greenRoom}
                    hideNameBadge={true}
                    key={`line${streams[0].stream.uid}`}
                    name={streamNamesMap[streams[0].stream.uid]}
                    stream={streams[0].stream}
                    streamDetails={streamDetailsMap[streams[0].stream.uid]}
                    userMoveFns={userMoveFns}
                    localClientAVStatus={localClientAVStatus}
                    localClient={localClient}
                    liveRaisedHandRequestList={liveRaisedHandRequestList}
                  />
                </PetalFrame>
              </div>
            );
          } else if (streamLength === 2) {
            StreamContents = (
              <div className={styles.petalTwoStreamLayout}>
                <PetalFrame
                  key={`line${streams[0].stream.uid}`}
                  height={lineContainerDims.height}
                  curveOrientation={IPetalFrameCurveOrientations.LEFT}
                >
                  <SingleStreamPlayer
                    volumeOn={volumeOn}
                    parent={parent}
                    containerStyles={lineContainerDims}
                    greenRoom={greenRoom}
                    hideNameBadge={true}
                    name={streamNamesMap[streams[0].stream.uid]}
                    stream={streams[0].stream}
                    streamDetails={streamDetailsMap[streams[0].stream.uid]}
                    userMoveFns={userMoveFns}
                    localClientAVStatus={localClientAVStatus}
                    localClient={localClient}
                    liveRaisedHandRequestList={liveRaisedHandRequestList}
                  />
                </PetalFrame>
                <PetalFrame
                  key={`line${streams[1].stream.uid}`}
                  height={lineContainerDims.height}
                  curveOrientation={IPetalFrameCurveOrientations.RIGHT}
                >
                  <SingleStreamPlayer
                    volumeOn={volumeOn}
                    parent={parent}
                    containerStyles={lineContainerDims}
                    greenRoom={greenRoom}
                    hideNameBadge={true}
                    name={streamNamesMap[streams[1].stream.uid]}
                    stream={streams[1].stream}
                    streamDetails={streamDetailsMap[streams[1].stream.uid]}
                    userMoveFns={userMoveFns}
                    localClientAVStatus={localClientAVStatus}
                    localClient={localClient}
                    liveRaisedHandRequestList={liveRaisedHandRequestList}
                  />
                </PetalFrame>
              </div>
            );
          } else if (streamLength === 3) {
            StreamContents = (
              <div className={styles.petalThreeStreamLayout}>
                <PetalFrame
                  key={`line${streams[0].stream.uid}`}
                  height={lineContainerDims.height}
                  curveOrientation={IPetalFrameCurveOrientations.RIGHT}
                >
                  <SingleStreamPlayer
                    volumeOn={volumeOn}
                    parent={parent}
                    containerStyles={lineContainerDims}
                    greenRoom={greenRoom}
                    hideNameBadge={true}
                    name={streamNamesMap[streams[0].stream.uid]}
                    stream={streams[0].stream}
                    streamDetails={streamDetailsMap[streams[0].stream.uid]}
                    userMoveFns={userMoveFns}
                    localClientAVStatus={localClientAVStatus}
                    localClient={localClient}
                    liveRaisedHandRequestList={liveRaisedHandRequestList}
                  />
                </PetalFrame>
                <SquareFrame
                  key={`line${streams[1].stream.uid}`}
                  height={lineContainerDims.height}
                  roundedCorner={true}
                >
                  <SingleStreamPlayer
                    volumeOn={volumeOn}
                    parent={parent}
                    containerStyles={lineContainerDims}
                    greenRoom={greenRoom}
                    hideNameBadge={true}
                    name={streamNamesMap[streams[1].stream.uid]}
                    stream={streams[1].stream}
                    streamDetails={streamDetailsMap[streams[1].stream.uid]}
                    userMoveFns={userMoveFns}
                    localClientAVStatus={localClientAVStatus}
                    localClient={localClient}
                    liveRaisedHandRequestList={liveRaisedHandRequestList}
                  />
                </SquareFrame>
                <PetalFrame
                  key={`line${streams[2].stream.uid}`}
                  height={lineContainerDims.height}
                  curveOrientation={IPetalFrameCurveOrientations.LEFT}
                >
                  <SingleStreamPlayer
                    volumeOn={volumeOn}
                    parent={parent}
                    containerStyles={lineContainerDims}
                    greenRoom={greenRoom}
                    hideNameBadge={true}
                    name={streamNamesMap[streams[2].stream.uid]}
                    stream={streams[2].stream}
                    streamDetails={streamDetailsMap[streams[2].stream.uid]}
                    userMoveFns={userMoveFns}
                    localClientAVStatus={localClientAVStatus}
                    localClient={localClient}
                    liveRaisedHandRequestList={liveRaisedHandRequestList}
                  />
                </PetalFrame>
              </div>
            );
          } else {
            StreamContents = streams.map(s => (
              <PetalFrame
                key={`line${s.stream.uid}`}
                height={lineContainerDims.height}
              >
                <SingleStreamPlayer
                  volumeOn={volumeOn}
                  parent={parent}
                  containerStyles={lineContainerDims}
                  greenRoom={greenRoom}
                  hideNameBadge={true}
                  name={streamNamesMap[s.stream.uid]}
                  onDoubleClick={() => handleStreamDoubleClick(s.stream)}
                  stream={s.stream}
                  streamDetails={streamDetailsMap[s.stream.uid]}
                  userMoveFns={userMoveFns}
                  localClientAVStatus={localClientAVStatus}
                  localClient={localClient}
                  liveRaisedHandRequestList={liveRaisedHandRequestList}
                />
              </PetalFrame>
            ));
          }
          break;
        }
        default: {
          StreamContents = streams.map(s => (
            <SingleStreamPlayer
              volumeOn={volumeOn}
              parent={parent}
              containerStyles={getStreamContainerDims(
                streamContainerDimsConfig,
              )}
              greenRoom={greenRoom}
              key={`line${s.stream.uid}`}
              name={streamNamesMap[s.stream.uid]}
              onDoubleClick={() => handleStreamDoubleClick(s.stream)}
              streamDetails={streamDetailsMap[s.stream.uid]}
              stream={s.stream}
              userMoveFns={userMoveFns}
              localClientAVStatus={localClientAVStatus}
              localClient={localClient}
              liveRaisedHandRequestList={liveRaisedHandRequestList}
            />
          ));
        }
      }
    } else if (DISPLAY_AS_PRESENTATION_LAYOUT) {
      StreamContents = (
        <PresentationLayout
          streams={streams}
          primaryStream={primaryStream}
          secondaryStreams={secondaryStreams}
          streamNamesMap={streamNamesMap}
          streamDetailsMap={streamDetailsMap}
          parentWidth={parentWidth}
          parentHeight={parentHeight}
          parentLeftPosition={parentLeftPosition}
          parentTopPosition={parentTopPosition}
          parent={parent}
          volumeOn={volumeOn}
          greenRoom={greenRoom}
          userMoveFns={userMoveFns}
          localClientAVStatus={localClientAVStatus}
          localClient={localClient}
          liveRaisedHandRequestList={liveRaisedHandRequestList}
          handleStreamDoubleClick={handleStreamDoubleClick}
          currentPresentation={currentChannelPresentation}
          currentPlugin={currentPlugin}
          pluginConfig={pluginConfig}
          broadcastPermissions={broadcastPermissions}
          presentationConfig={presentationConfig}
          goToPresentationPage={goToPresentationPage}
          setShowTabIndex={setShowTabIndex}
          layoutVariant={EPresentationLayoutVariant.SLIDER}
        />
      );
    } else if (DISPLAY_AS_PRESENTATION_LAYOUT_V2) {
      StreamContents = (
        <PresentationLayout
          streams={streams}
          primaryStream={primaryStream}
          secondaryStreams={secondaryStreams}
          streamNamesMap={streamNamesMap}
          streamDetailsMap={streamDetailsMap}
          parentWidth={parentWidth}
          parentHeight={parentHeight}
          parent={parent}
          volumeOn={volumeOn}
          greenRoom={greenRoom}
          userMoveFns={userMoveFns}
          localClientAVStatus={localClientAVStatus}
          localClient={localClient}
          liveRaisedHandRequestList={liveRaisedHandRequestList}
          handleStreamDoubleClick={handleStreamDoubleClick}
          currentPresentation={currentChannelPresentation}
          currentPlugin={currentPlugin}
          pluginConfig={pluginConfig}
          broadcastPermissions={broadcastPermissions}
          presentationConfig={presentationConfig}
          goToPresentationPage={goToPresentationPage}
          setShowTabIndex={setShowTabIndex}
          layoutVariant={EPresentationLayoutVariant.SINGLE_PAGE}
        />
      );
    } else if (
      !isStageFull &&
      streamsDisplayMode === IStreamsDisplayMode.CONDENSED
    ) {
      const centerStageProps = {
        streams,
        parentHeight,
        parentWidth,
        streamDetailsMap,
        volumeOn,
        greenRoom,
        userMoveFns,
        isStageFull,
        parent,
        streamNamesMap,
        primaryStream,
        secondaryStreams,
        presentationConfig,
        localClientAVStatus,
        localClient,
        liveRaisedHandRequestList,
      };
      StreamContents = <CondensedLayout {...centerStageProps} />;
    } else if (!isStageFull && DISPLAY_AS_CENTER_STAGE) {
      const centerStageProps = {
        streams,
        parentHeight,
        parentWidth,
        parentLeftPosition,
        parentTopPosition,
        streamDetailsMap,
        volumeOn,
        greenRoom,
        userMoveFns,
        isStageFull,
        parent,
        streamNamesMap,
        primaryStream,
        secondaryStreams,
        presentationConfig,
        localClientAVStatus,
        localClient,
        liveRaisedHandRequestList,
      };
      StreamContents = <CenterStageLayout {...centerStageProps} />;
    } else if (!isStageFull && DISPLAY_AS_BOTTOM_SPONSOR_LAYOUT) {
      const bottomSponsorLayoutProps = {
        streams,
        parentHeight,
        parentWidth,
        parentLeftPosition,
        parentTopPosition,
        streamDetailsMap,
        volumeOn,
        greenRoom,
        userMoveFns,
        isStageFull,
        parent,
        streamNamesMap,
        primaryStream,
        secondaryStreams,
        localClientAVStatus,
        localClient,
        liveRaisedHandRequestList,
      };
      StreamContents = <BottomSponsorLayout {...bottomSponsorLayoutProps} />;
    } else if (!isStageFull && DISPLAY_AS_RAZORPAY_LAYOUT) {
      const razorpayLayoutProps = {
        streams,
        parentHeight,
        parentWidth,
        streamDetailsMap,
        volumeOn,
        greenRoom,
        userMoveFns,
        isStageFull,
        parent,
        streamNamesMap,
        primaryStream,
        secondaryStreams,
        localClientAVStatus,
        localClient,
        liveRaisedHandRequestList,
      };
      StreamContents = <RazorpayLayout {...razorpayLayoutProps} />;
    } else if (
      SHOW_ONE_BY_ONE_GRID &&
      !(currentChannelPresentation || currentPlugin)
    ) {
      const singleStream = streams.length >= 1 && streams[0];
      StreamContents = singleStream && (
        <SingleStreamPlayer
          volumeOn={volumeOn}
          parent={parent}
          containerStyles={getStreamContainerDims(streamContainerDimsConfig)}
          greenRoom={greenRoom}
          key={singleStream.stream.uid}
          hideNameBadge={true}
          name={streamNamesMap[singleStream.stream.uid]}
          streamDetails={streamDetailsMap[singleStream.stream.uid]}
          stream={singleStream.stream}
          userMoveFns={userMoveFns}
          noBorder={isStageFull}
          localClientAVStatus={localClientAVStatus}
          localClient={localClient}
          liveRaisedHandRequestList={liveRaisedHandRequestList}
        />
      );
    } else if (DISPLAY_AS_GRID) {
      if (SHOW_TWO_BY_ONE_GRID) {
        streamContainerDimsConfig.numCols = 2;
        streamContainerDimsConfig.numRows = 1;
      } else if (SHOW_TWO_BY_TWO_GRID) {
        streamContainerDimsConfig.numCols = 2;
        streamContainerDimsConfig.numRows = 2;
      } else if (SHOW_THREE_BY_TWO_GRID) {
        streamContainerDimsConfig.numCols = 3;
        streamContainerDimsConfig.numRows = 2;
      } else if (SHOW_THREE_BY_THREE_GRID) {
        streamContainerDimsConfig.numCols = 3;
        streamContainerDimsConfig.numRows = 3;
      } else if (SHOW_FOUR_BY_THREE_GRID) {
        streamContainerDimsConfig.numCols = 4;
        streamContainerDimsConfig.numRows = 3;
      } else if (SHOW_FOUR_BY_FOUR_GRID) {
        streamContainerDimsConfig.numCols = 4;
        streamContainerDimsConfig.numRows = 4;
      }
      if (isMobile && parent === IStageParent.LIVE_DISCUSSION) {
        streamContainerDimsConfig.numCols = 2;
        streamContainerDimsConfig.numRows = streams?.length / 2;
      }
      StreamContents = streams.map(s => (
        <SingleStreamPlayer
          volumeOn={volumeOn}
          parent={parent}
          containerStyles={getStreamContainerDims(
            streamContainerDimsConfig,
            isMobile,
          )}
          greenRoom={greenRoom}
          key={`grid${s.stream.uid}`}
          name={streamNamesMap[s.stream.uid]}
          onDoubleClick={() => handleStreamDoubleClick(s.stream)}
          streamDetails={streamDetailsMap[s.stream.uid]}
          stream={s.stream}
          userMoveFns={userMoveFns}
          localClientAVStatus={localClientAVStatus}
          localClient={localClient}
          liveRaisedHandRequestList={liveRaisedHandRequestList}
        />
      ));
    } else if (DISPLAY_AS_SPOTLIGHT) {
      const {
        secondaryStreams: secondaryStreamsDims,
        spotlight: spotlightDims,
      } = getSpotlightDims(streamContainerDimsConfig);

      // If presentation is being shared, all the other streams
      // will be shown in the bottom row, if no presentation is being shared then
      // The Spotlight view will work as a combination of both primary and secondary
      // Depending upon the Active speaker. Active Speaker is the primary.
      const bottomRowStreams = (() => {
        if (currentChannelPresentation || currentPlugin) {
          // Mapping because we need it in the same format as secondary streams
          return streams.map(s => s.stream);
        }
        return [...secondaryStreams];
      })();
      const secondaryStreamsCopy = [...secondaryStreams];
      // Add the primary stream to secondary stream if a pdf is being shared
      // And show the pdf in the primary spotlight
      if ((currentChannelPresentation || currentPlugin) && primaryStream) {
        secondaryStreamsCopy.unshift(primaryStream);
      }

      StreamContents = (
        <div
          className={classnames(styles.streamWithSpotlightContainer, {
            [styles.streamSpotlightContainerPluginView]:
              !currentChannelPresentation && currentPlugin,
          })}
        >
          {currentChannelPresentation && (
            <SharedFilePreview
              fileUrl={currentChannelPresentation.presentationUrl}
              currentPage={currentChannelPresentation.currentPresentationPage}
              setCurrentPage={goToPresentationPage}
              totalPages={currentChannelPresentation.presentationTotalPages}
              hasControl={currentChannelPresentation.hasPresentationControl}
              stopPresentation={presentationConfig.stopPresentation}
              dims={spotlightDims}
              isLoading={currentChannelPresentation.isLoading}
              parent={parent}
              setShowTabIndex={setShowTabIndex}
            />
          )}
          {!currentChannelPresentation && currentPlugin && (
            <SharedPluginView
              dims={spotlightDims}
              currentPlugin={currentPlugin}
              stopPlugin={pluginConfig.stopPlugin}
              streams={mainStreams}
              streamDetailsMap={streamDetailsMap}
              broadcastPermissions={broadcastPermissions}
              parent={parent}
              setShowTabIndex={setShowTabIndex}
            />
          )}
          {!(currentChannelPresentation || currentPlugin) && primaryStream && (
            <SingleStreamPlayer
              volumeOn={volumeOn}
              parent={parent}
              containerStyles={spotlightDims}
              key={`primary${primaryStream.uid}`}
              name={streamNamesMap[primaryStream.uid]}
              streamDetails={streamDetailsMap[primaryStream.uid]}
              stream={primaryStream}
              greenRoom={greenRoom}
              userMoveFns={userMoveFns}
              localClientAVStatus={localClientAVStatus}
              localClient={localClient}
              liveRaisedHandRequestList={liveRaisedHandRequestList}
              onScreenMount={() =>
                parent === IStageParent.LIVE_DISCUSSION && setShowTabIndex(null)
              }
            />
          )}
          <div
            className={classnames(
              {
                [styles.streamSpotlightContainer]: bottomRowStreams.length < 4,
              },
              {
                [styles.streamSpotlightContainerLeftAligned]:
                  bottomRowStreams.length >= 4,
              },
            )}
          >
            {bottomRowStreams.map(s => (
              <SingleStreamPlayer
                volumeOn={volumeOn}
                parent={parent}
                containerStyles={secondaryStreamsDims}
                greenRoom={greenRoom}
                key={`secondary${s.uid}`}
                name={streamNamesMap[s.uid]}
                onDoubleClick={() => handleStreamDoubleClick(s)}
                streamDetails={streamDetailsMap[s.uid]}
                stream={s}
                userMoveFns={userMoveFns}
                localClientAVStatus={localClientAVStatus}
                localClient={localClient}
                liveRaisedHandRequestList={liveRaisedHandRequestList}
              />
            ))}
          </div>
        </div>
      );
    } else if (DISPLAY_AS_TWO_ROW_SPOTLIGHT) {
      const {
        secondaryStreams: secondaryStreamsDims,
        spotlight: spotlightDims,
      } = getTwoRowSpotlightDims(streamContainerDimsConfig);
      const secondaryStreamsCopy = [...secondaryStreams];

      StreamContents = (
        <div className={styles.streamWithSpotlightContainer}>
          {primaryStream && (
            <SingleStreamPlayer
              volumeOn={volumeOn}
              parent={parent}
              containerStyles={spotlightDims}
              greenRoom={greenRoom}
              key={`primary${primaryStream.uid}`}
              name={streamNamesMap[primaryStream.uid]}
              streamDetails={streamDetailsMap[primaryStream.uid]}
              stream={primaryStream}
              userMoveFns={userMoveFns}
              localClientAVStatus={localClientAVStatus}
              localClient={localClient}
              liveRaisedHandRequestList={liveRaisedHandRequestList}
            />
          )}
          <div
            className={classnames(
              {
                [styles.streamTwoRowSpotlightContainer]:
                  secondaryStreams.length < 4,
              },
              {
                [styles.streamTwoRowSpotlightContainerLeftAligned]:
                  secondaryStreams.length >= 4,
              },
            )}
          >
            {secondaryStreamsCopy.map(s => (
              <SingleStreamPlayer
                parent={parent}
                volumeOn={volumeOn}
                containerStyles={secondaryStreamsDims}
                greenRoom={greenRoom}
                key={`secondary${s.uid}`}
                name={streamNamesMap[s.uid]}
                onDoubleClick={() => handleStreamDoubleClick(s)}
                stream={s}
                streamDetails={streamDetailsMap[s.uid]}
                userMoveFns={userMoveFns}
                localClientAVStatus={localClientAVStatus}
                localClient={localClient}
                liveRaisedHandRequestList={liveRaisedHandRequestList}
              />
            ))}
          </div>
        </div>
      );
    } else if (DISPLAY_AS_HORIZONTAL_SPOTLIGHT) {
      const {
        secondaryStream: secondaryStreamDims,
        spotlight: spotlightDims,
      } = getHorizontalSpotlightDims(streamContainerDimsConfig);

      StreamContents = (
        <div className={styles.horizontalSpotlightContainer}>
          <div className={styles.horizontalSpotlightSecondaryStream}>
            <SquareFrame
              key={`line${secondaryStreams[0].uid}`}
              height={secondaryStreamDims.height}
              width={secondaryStreamDims.width}
              roundedCorner={false}
              noMargin={true}
            >
              <SingleStreamPlayer
                parent={parent}
                volumeOn={volumeOn}
                containerStyles={secondaryStreamDims}
                greenRoom={greenRoom}
                key={`secondary${secondaryStreams[0].uid}`}
                name={streamNamesMap[secondaryStreams[0].uid]}
                streamDetails={streamDetailsMap[secondaryStreams[0].uid]}
                stream={secondaryStreams[0]}
                userMoveFns={userMoveFns}
                noBorder={true}
                showNasscomLabel={true}
                nasscomScreenShare={true}
                localClientAVStatus={localClientAVStatus}
                localClient={localClient}
                liveRaisedHandRequestList={liveRaisedHandRequestList}
              />
            </SquareFrame>
          </div>
          <SquareFrame
            key={`line${primaryStream.uid}`}
            height={spotlightDims.height}
            width={spotlightDims.width}
            roundedCorner={false}
            noMargin={Boolean(streamLength > 2 && streamLength < 5)}
          >
            <SingleStreamPlayer
              parent={parent}
              volumeOn={volumeOn}
              containerStyles={spotlightDims}
              greenRoom={greenRoom}
              key={`primary${primaryStream.uid}`}
              name={streamNamesMap[primaryStream.uid]}
              streamDetails={streamDetailsMap[primaryStream.uid]}
              stream={primaryStream}
              userMoveFns={userMoveFns}
              noBorder={true}
              hideNameBadge={true}
              localClientAVStatus={localClientAVStatus}
              localClient={localClient}
              liveRaisedHandRequestList={liveRaisedHandRequestList}
            />
          </SquareFrame>
        </div>
      );
    }
  }

  const timerPositionConfig = (() => {
    if (
      eventId === '8b269856-b6aa-4b73-8e8b-25eacfcb97d2' ||
      eventId === 'a5c8e27e-631e-44e4-bf0d-040e3015e73f' ||
      eventId === 'f4d3e2ad-38c8-4670-83b4-0b024114de2e' ||
      eventId === 'ca586e61-20dc-408a-9c9a-2c1d5e48835f'
    ) {
      // top left

      return {
        top: 20,
        height: 73,
        left: 20,
        width: 210,
      };
    }

    if (
      eventId === '0cdae855-8eed-477a-b897-e3f7b333a666' ||
      eventId === 'fb9e79bf-3335-49b8-8b7a-0e31cf12709e' ||
      eventId === '078858b8-0f2c-4b25-a304-47ba7ea27a20' ||
      eventId === '10d1dd13-cb22-4448-a65b-15dfef4aff41'
    ) {
      // top right

      return {
        top: 20,
        height: 73,
        left: 1600,
        width: 210,
      };
    }

    if (eventId === '8c3ff25e-2818-4982-bd66-b2cf2095093e') {
      // bottom right
      return {
        top: 966,
        height: 73,
        left: 1600,
        width: 210,
      };
    }
    if (eventId === 'a52fc575-a987-402f-b297-cb30b878564a') {
      // DSCI FinSec Custom
      return {
        top: 895,
        height: 55,
        left: 890,
        width: 140,
      };
    }
    if (eventId === '77edf186-afc9-452b-ae86-a0e3a2e3c450') {
      // DSCI BPM2022 Custom
      return {
        top: 885,
        height: 55,
        left: 860,
        width: 205,
      };
    }
    if (eventId === '849d8de1-c350-4b08-ac3e-43ba8b6a0ebd') {
      // Nasscom NATC
      return {
        top: 855,
        height: 55,
        left: 35,
        width: 175,
      };
    }
     if (eventId === 'e5b22666-07f7-4e50-a58e-59fc156ad3f5') {
      // WE-ACE SPARK 2022 Custom
      return {
        top: 700,
        height: 55,
        left: 150,
        width: 140,
      };
    }

    if (eventId === 'd6b57eab-0531-4d78-a90d-cdeb1af09942') {
      // top center
      return {
        top: 20,
        height: 73,
        left: 850,
        width: 210,
      };
    }

    if (eventId === '5097020d-4805-4f6c-9cd5-efa08dfae56f') {
      // little up from bottom center
      return {
        top: 906,
        height: 55,
        left: 891,
        width: 140,
      };
    }

    if (eventId === 'e2fdaa82-ec9d-41d6-aee9-80c923f4eddb') {
      return {
        top: 988,
        height: 73,
        left: 860,
        width: 210,
      };
    }
    if (
      eventId === '7232ea3e-fda1-4ffe-a645-def210323fa9'||
      eventId === 'dff9b16b-d9ae-47aa-ad68-468b6158d3f2'||
      eventId === 'df3f0b1a-4692-4db4-b137-4ed5390b11c5'||
      eventId === '7f0b248c-71e3-4a0e-b0ae-41c79323127c'||
      eventId === '1ffc74da-8cb0-43e7-8f72-e3d25302eec2'||
      eventId === '7181e8e1-b469-403b-92e6-f59fa8b2300c'||
      eventId === 'aa109bbf-8d4e-4199-8c40-b083da049920'
      ) {
      // bottom left
      return {
        top: 966,
        height: 73,
        left: 20,
        width: 210,
      };
    }
    if (eventId === 'b2be2ed0-47b1-4f47-9959-f5793147c79a') {
      // bottom 1/4th of the screen
      return {
        top: 966,
        height: 73,
        left: 480,
        width: 210,
      };
    }
     if (eventId === '478fc059-7701-4a86-a894-3b2814b1920f') {
      // Custom - BCCI - CSR Conclave
      return {
        top: 935,
        height: 55,
        left: 410,
        width: 160,
      };
    }

    // bottom center
    return {
      top: 966,
      height: 55,
      left: 891,
      width: 140,
    };
  })();

  // top: 760.31px;
  // height: 44.2135px;
  // left: 716.061px;
  // width: 112.569px;

  const { timerTop, timerHeight, timerLeft, timerWidth } = useMemo(() => {
    if (!containerHeight || !containerWidth) {
      return {};
    }

    const height = containerHeight;
    const width = containerWidth;

    const baseRatio = 1920 / 1080;
    const liveRatio = width / height;

    let offsetTop;
    let offsetLeft;
    let imgHeight;
    let imgWidth;

    if (liveRatio > baseRatio) {
      // container is wider than base
      offsetTop = 0;
      imgHeight = height;
      imgWidth = (imgHeight * 1920) / 1080;
      offsetLeft = (width - imgWidth) / 2;
    } else {
      // container is taller than base
      offsetLeft = 0;
      imgWidth = width;
      imgHeight = (imgWidth * 1080) / 1920;
      offsetTop = (height - imgHeight) / 2;
    }

    const scaleFactor = imgWidth / 1920;

    return {
      timerTop: offsetTop + timerPositionConfig.top * scaleFactor,
      timerHeight: timerPositionConfig.height * scaleFactor,
      timerLeft: offsetLeft + timerPositionConfig.left * scaleFactor,
      timerWidth: timerPositionConfig.width * scaleFactor,
    };
  }, [containerWidth, containerHeight]);

  const resolutionOptions = [
    {
      label: '480px slow',
      value: '480p_1',
    },
    {
      label: '480px fast',
      value: '480p_2',
    },
    {
      label: '720px slow',
      value: '720p_1',
    },
    {
      label: '720px fast',
      value: '720p_2',
    },
  ];

  const selectedVideoProfile =
    currentVideoProfile &&
    resolutionOptions.find(
      option => option.value === currentVideoProfile.screen,
    );

  const onScreenResolutionSelection = selectedProfile => {
    dispatch({
      type: 'global/updateScreenResolution',
      payload: {
        screenProfile: selectedProfile.value,
      },
    });
  };

  const stageImageMap = {
    '3281ae69-e508-4c05-bcad-634918a810ed':
      'https://phoenixlive.imgix.net/Cipla_R%26R/Hall_A.jpg',
    '9b69f6e9-d1bc-4cfa-806f-302f2b6c943a':
      'https://phoenixlive.imgix.net/Cipla_R%26R/Hall_B.jpg',
    '988f0f21-b41f-4d8f-9b2c-9ca632998d0f':
      'https://phoenixlive.imgix.net/Cipla_R%26R/Hall_C.jpg',
    '5100cee7-1580-464e-a18f-3c54a61b957b':
      'https://phoenixlive.imgix.net/Cipla_R%26R/Hall_D.jpg',
    '0aef2e00-8908-41e2-836c-bfcc617c3643':
      'https://phoenixlive.imgix.net/Cipla_R%26R/R%26R_Hall.jpg',
    'aec91ad0-a6b8-4f89-923d-60056dd0eeb4':
      'https://phoenixlive.imgix.net/mma-breakout-stage.jpg',
    '149098d4-acd8-423a-adf9-902bb5ff1e28':
      'https://phoenixlive.imgix.net/mma-breakout-stage.jpg',
    'b046b227-64be-42b8-8a63-805757217685':
      'https://phoenixlive.imgix.net/mma-breakout-stage.jpg',
  };

  const stageBG = (() => {
    if (stageImageMap[stageId]) {
      return stageImageMap[stageId];
    }
    if (stageDetailsByStageId && stageDetailsByStageId.bgUrl) {
      // If there's no appended url on the base url, return null to show nothing
      if (stageDetailsByStageId.bgUrl === IMAGE_URL) {
        return null;
      }
      return stageDetailsByStageId.bgUrl;
    }
    if (config && config.stageBG) {
      return config.stageBG;
    }
    return null;
  })();

  const getFormattedStageBg = () => {
    if (isMobile) {
      return `${prefixPicUrlIfNeeded(stageBG)}?h=${containerHeight}`;
    }
    return prefixPicUrlIfNeeded(stageBG);
  };

  const [enabledScrollArrow, setEnabledScrollArrow] = useState<
    'LEFT' | 'RIGHT' | 'LEFT_AND_RIGHT' | ''
  >('');

  const calculateScrollBarForRightArrow = () => {
    const isLeftEnable = scrollContainerRef.current.scrollLeft > 0;
    let isRightEnable = true;
    if (
      scrollContainerRef.current.offsetWidth +
        scrollContainerRef.current.scrollLeft >=
      scrollContainerRef.current.scrollWidth - 10
    ) {
      isRightEnable = false;
    }
    if (isLeftEnable && isRightEnable) {
      setEnabledScrollArrow('LEFT_AND_RIGHT');
    } else if (isLeftEnable || isRightEnable) {
      setEnabledScrollArrow(isLeftEnable ? 'LEFT' : 'RIGHT');
    } else {
      setEnabledScrollArrow('');
    }
  };

  const onScroll = () => {
    calculateScrollBarForRightArrow();
  };

  useEffect(() => {
    if (!scrollContainerRef?.current) {
      return;
    }
    calculateScrollBarForRightArrow();
  }, [scrollContainerRef?.current, greenRoomStreams]);

  const scrollOffset = (offset: number) => {
    scrollContainerRef.current.scrollLeft += offset;
  };

  return (
    <>
      {greenRoom && (
        <div className={styles.greenRoomHeader}>
          <div className={styles.greenRoomHeaderTitle}>
            {`Backstage ${streamLength > 0 ? `(${streamLength})` : ''}`}
          </div>
          {eventId === 'b5e620e2-2576-4c69-a2c7-a01fe269a1e8' &&
            stageId === 'a23a1c69-fd4e-4623-b30e-04fb72a96e18' && (
              <a
                target="_self"
                className={styles.ntlfWebexLink}
                href="https://nasscom01.webex.com/nasscom01/j.php?MTID=m6af0d2b630753c22f0539362ebe0a93c"
              >
                <Button
                  type="button"
                  styleClass={IButtonTypes.NEW_DANGER}
                  label="Join Live Session"
                />
              </a>
            )}
          {/* 2022 NTLF */}
          {eventId === '02d8da06-5b33-4e84-967e-8ecd8a9617e7' &&
            stageId === '205f9640-4b02-4d4e-a6b3-13360a6cbc18' && (
              <a
                target="_self"
                className={styles.ntlfWebexLink}
                href="https://vouchpro.zoom.us/j/81701213544?pwd=NDlTYWswbFV5dERueXEzSmg4cE1wZz09"
              >
                <Button
                  type="button"
                  styleClass={IButtonTypes.BCKSTG_SUCCESS_SQUARE_RADIUS}
                  label="Join Live Session"
                />
              </a>
            )}
          {/* NASSCOM Global Inclusion Summit 2022 */}
          {eventId === 'badd1046-3ed4-438a-a688-959f9d587027' &&
            stageId === '8006b367-c4a1-4bef-980c-ce0b67d9b7db' && (
              <a
                target="_self"
                className={styles.ntlfWebexLink}
                href="https://us02web.zoom.us/j/81174641918"
              >
                <Button
                  type="button"
                  styleClass={IButtonTypes.BCKSTG_SUCCESS_SQUARE_RADIUS}
                  label="Join Live Session"
                />
              </a>
            )}
          {/* <div className={styles.screenshareSelection}>
            <div>Screenshare Resolution</div>
            <div className={styles.selectContainer}>
              <SelectFieldExternal
                value={selectedVideoProfile}
                options={resolutionOptions}
                onChange={onScreenResolutionSelection}
              />
            </div>
          </div> */}
        </div>
      )}
      <div
        className={classnames({
          [styles.greenRoomStreamsMainContainer]: greenRoom,
          [styles.centerWrapper]: parent === IStageParent.LIVE_DISCUSSION
        })}
      >
        {greenRoom && (
          <ScrollBarArrowButton
            scrollOffset={scrollOffset}
            enableButton={enabledScrollArrow.includes('LEFT')}
          />
        )}
        {!greenRoom && dialInStreams && dialInStreams.length > 0 && (
          <GenericErrorBoundary showFallbackUI={false}><DialInAudio dialInStreams={dialInStreams} /></GenericErrorBoundary>
        )}
        <div
          ref={scrollContainerRef}
          onScroll={onScroll}
          className={classnames(styles.streamsDisplayContainer, {
            [styles.greenRoom]: greenRoom,
            [styles.streamsDisplayContainerRightAligned]: rightAlignVideos,
            [styles.roundedCorner]: useRoundedCorners,
            [styles.mobileScrollFix]: isMobile && streams?.length > 2 && pathname.includes('/discussions/'),
            [styles.streamsDisplayContainerForRoom]: parent === IStageParent.LIVE_DISCUSSION
          })}
          style={{
            width: containerWidth,
            height:
              isMobile && parent === IStageParent.LIVE_DISCUSSION
                ? 'initial'
                : containerHeight,
            minHeight: isMobile ? 'initial' : containerHeight,
            padding: greenRoom
              ? 'unset'
              : `${parentTopPosition}px ${parentRightPosition}px ${parentBottomPosition}px ${parentLeftPosition}px`,
          }}
        >
          {!greenRoom && interpreterStream && (
            <InterpreterBox
              eventId={eventId}
              stream={interpreterStream}
              parentHeight={parentHeight}
              parentWidth={parentWidth}
              streamDetailsMap={streamDetailsMap}
              volumeOn={false}
              greenRoom={greenRoom}
              userMoveFns={userMoveFns}
              isStageFull={isStageFull}
              parent={parent}
              streamNamesMap={streamNamesMap}
              moveUserToStage={moveUserToStage}
              localClientAVStatus={localClientAVStatus}
              liveRaisedHandRequestList={liveRaisedHandRequestList}
            />
          )}
          {!greenRoom &&
            !hideBeginTimer &&
            [IStageParent.LIVE_BACKSTAGE, IStageParent.LIVE_STAGE].includes(
              parent,
            ) &&
            timeLeftMs !== undefined &&
            timeLeftMs !== null && (
            <div
              className={styles.timerTextContainer}
              style={{
                top: `${timerTop}px`,
                height: `${timerHeight}px`,
                left: `${timerLeft}px`,
                width: `${timerWidth}px`,
              }}
            >
              <div className={styles.timerText}>
                {timeLeftMsToMins(timeLeftMs)}
              </div>
            </div>
          )}
          {/* {!greenRoom && !isHost
              && [IStageParent.LIVE_BACKSTAGE, IStageParent.LIVE_STAGE].includes(parent)
              && ['a5c8e27e-631e-44e4-bf0d-040e3015e73f', '4d142c74-2382-4f61-a3ab-08037b05446c'].includes(eventId) &&
              <a href="https://twitter.com/intent/tweet?text=I%20am%20loving%20FTX%202020%20by%20%40Razorpay%20so%20far!%20Looking%20forward%20to%20the%20sessions%20from%20Kunal%20Shah%20and%20many%20other%20distinguished%20speakers%20who%20will%20be%20speaking%20today!%0A%0AJoin%20me%20at%20India%E2%80%99s%20biggest%20fintech%20conference%3A&url=https%3A%2F%2Frazorpay.com%2Fftx%2F%0A%0A&via=Razorpay&hashtags=FTX2020%0A%0A" target="_blank">
                <img className={styles.razorPayTweet} src='https://phoenixlive.imgix.net/razorpay-tweet-banner.png' />
              </a>
            } */}
          {!greenRoom &&
            [IStageParent.LIVE_BACKSTAGE, IStageParent.LIVE_STAGE].includes(
              parent,
            ) &&
            [
              '755b8c34-17d4-40b5-a675-f9fba123783b',
              '3cb6dc40-ebfc-49f6-bcf5-6ccd325ce766',
              '5136d61f-22d2-411b-86b3-d9ff9af05d87',
            ].includes(stageId) && (
            <div className={styles.floater}>
                <iframe
                  className={styles.iframe}
                  src="https://elaborate-truth-293606.uc.r.appspot.com/embed.html?sessionID=MICROSOFT"
                ></iframe>
              </div>
            )}
          {!greenRoom &&
            [
              IStageParent.LIVE_BACKSTAGE,
              IStageParent.LIVE_STAGE,
              IStageParent.STAGE_PREVIEW,
              IStageParent.AUDIENCE_GALLERY,
            ].includes(parent) &&
            stageBG && (
              <img
                className={styles.stageBackgroundFit}
                src={getFormattedStageBg()}
                alt="Stage Background"
              />
            )}
          {!greenRoom && showConfetti && (
            <Confetti
              width={containerWidth}
              height={containerHeight}
              numberOfPieces={100}
              gravity={0.04}
            />
          )}
          {StreamContents}
        </div>
        {greenRoom && (
          <ScrollBarArrowButton
            buttonType="right"
            scrollOffset={scrollOffset}
            enableButton={enabledScrollArrow.includes('RIGHT')}
          />
        )}
      </div>
    </>
  );
};

export default StreamsDisplay;
