import IconButton, {
  IIconButtonTypes,
  IUIIcons,
} from '@/components/ui/buttons/IconButton';
import { TooltipTypes } from '@/components/ui/buttons/IconButton/types';
import React, { useState, useEffect } from 'react';
import ConfirmExitModal from '../../StreamOptionsBar/ConfirmExitModal';
import EmptyState from '../SharedFileView/EmptyState';
import styles from './styles.module.scss';
import { useParams } from 'react-router-dom';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { makeSelectIsOrganizer } from '@/models/event';
import { IPluginType } from '@/types/plugin';
import {
  getStopPluginText,
  STOP_WEBSITE_SHARE_TEXT,
} from '../../StreamOptionsBar/ScreenOrFileShareToggle/config';
import { IStageParent } from '@/components/Stage/types';
import { useMediaQuery } from 'react-responsive';
import { ISharedPluginViewProps } from './types';

const SharedPluginView = (props: ISharedPluginViewProps) => {
  const {
    dims,
    currentPlugin,
    stopPlugin,
    streams,
    streamDetailsMap,
    broadcastPermissions,
    parent,
    setShowTabIndex,
  } = props;

  const { eventId = '' } = useParams<any>();
  const isOrganizer = useMemoizedSelector(makeSelectIsOrganizer, eventId);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    // In Rooms, whenever a presentation is shared, collapse the sidepanel
    if (
      !setShowTabIndex ||
      parent !== IStageParent.LIVE_DISCUSSION ||
      isMobile
    ) {
      return;
    }

    setShowTabIndex(null);
  }, []);

  const [
    isPluginPublishedAccountPresent,
    setIsPluginPublishedAccountPresent,
  ] = useState(true);
  const [showLoadingForIframe, setShowLoadingForIframe] = useState(true);

  useEffect(() => {
    if (!streamDetailsMap || !streams) {
      return;
    }
    const presentAccountIds = new Set();
    streams.forEach(element => {
      const streamDetails = streamDetailsMap[element.uid];
      if (streamDetails) {
        presentAccountIds.add(streamDetails.accountId);
      }
    });
    setIsPluginPublishedAccountPresent(
      presentAccountIds.has(currentPlugin.pluginPublishedBy),
    );
  }, [streams, streamDetailsMap]);

  const canStopPresentation =
    broadcastPermissions && (isOrganizer || !isPluginPublishedAccountPresent);
  const newDims = {
    ...dims,
    height: dims.height - 56, // reducing height 56px bcz not overlapping with topStreamOptionsContainer
  };
  const stopPresentationText =
    currentPlugin.pluginType === IPluginType.CUSTOM
      ? STOP_WEBSITE_SHARE_TEXT
      : getStopPluginText(currentPlugin.pluginType?.toLowerCase());

  const LoadingContainer = () => {
    return (
      <div className={styles.loaderContainer}>
        <EmptyState
          isLoading={true}
          height={newDims.height}
          width={newDims.width}
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {canStopPresentation && (
        <ConfirmExitModal
          trigger={({ setShow }) => (
            <IconButton
              key="stop presentation"
              activeIcon={IUIIcons.STREAM_LEAVE}
              defaultIcon={IUIIcons.STREAM_LEAVE}
              onClick={() => setShow(true)}
              showDefault={true}
              type={IIconButtonTypes.ROUND_DARK_GREY_WHITE}
              tooltip={TooltipTypes.right}
              tooltipText={stopPresentationText}
            />
          )}
          handleConfirm={({ setShow }) => {
            stopPlugin();
            setShow(false);
          }}
          exitText="Are you sure you want to stop presentation?"
          confirmButtonText="Yes"
        />
      )}
      <div className={styles.iframeContainer}>
        {(currentPlugin.isLoading || showLoadingForIframe) && (
          <LoadingContainer />
        )}
        <iframe
          style={newDims}
          src={currentPlugin.pluginUrl}
          referrerPolicy="no-referrer-when-downgrade"
          frameBorder="0"
          onLoad={() => {
            setShowLoadingForIframe(false);
          }}
        ></iframe>
      </div>
    </div>
  );
};

export default React.memo(SharedPluginView);
