import React, { useMemo } from 'react';
import classnames from 'classnames';
import SingleStreamPlayer from '@/components/PublishedStreamDisplay/SingleStreamPlayer';
import { getKeynoteLayoutDims } from './utils';
import styles from './styles.module.scss';

const KeynoteLayout = props => {
  const {
    primaryStream,
    secondaryStreams,
    streams,
    parentHeight,
    parentWidth,
    streamDetailsMap,
    volumeOn,
    greenRoom,
    userMoveFns,
    parent,
    streamNamesMap,
    localClientAVStatus,
    liveRaisedHandRequestList,
  } = props;
  const streamContainerDimsConfig = {
    height: parentHeight,
    width: parentWidth,
  };

  const [finalPrimary, finalSecondary] = useMemo(() => {
    let tempPrimary;
    const tempSecondary = [];

    streams.forEach(s => {
      if (!tempPrimary && s.stream.uid === primaryStream?.uid) {
        tempPrimary = s;
      } else {
        tempSecondary.push(s);
      }
    });

    return [tempPrimary, tempSecondary];
  }, [primaryStream, secondaryStreams, streams]);

  const containerDims = getKeynoteLayoutDims(
    streamContainerDimsConfig,
    streams.length,
  );

  return (
    <>
      <div
        style={{ height: parentHeight, width: parentWidth }}
        className={styles.keynoteContainer}
      >
        <div
          style={{
            position: 'absolute',
            top: (parentHeight * 481) / 720,
            left: (parentWidth * 485) / 1280,
            height: containerDims.secondary.height,
            width: containerDims.secondary.width,
          }}
          className={classnames(styles.keynoteSpeakersContainer, {
            [styles.keynoteAlignTop]: finalSecondary.length > 1,
          })}
        >
          {finalSecondary.map(s => (
            <SingleStreamPlayer
              volumeOn={volumeOn}
              parent={parent}
              containerStyles={containerDims.secondary}
              greenRoom={greenRoom}
              key={`grid${s.stream.uid}`}
              name={streamNamesMap[s.stream.uid]}
              streamDetails={streamDetailsMap[s.stream.uid]}
              stream={s.stream}
              userMoveFns={userMoveFns}
              showGradientLabel={true}
              localClientAVStatus={localClientAVStatus}
              liveRaisedHandRequestList={liveRaisedHandRequestList}
            />
          ))}
        </div>
        <div
          style={{
            position: 'absolute',
            top: (parentHeight * 78) / 720,
            left: (parentWidth * 296) / 1280,
            height: containerDims.primary.height,
            width: containerDims.primary.width,
          }}
          className={classnames(styles.keynotePresentationContainer, {
            [styles.keynoteAlignTop]: finalSecondary.length > 1,
          })}
        >
          {finalPrimary && (
            <SingleStreamPlayer
              volumeOn={volumeOn}
              parent={parent}
              containerStyles={containerDims.primary}
              greenRoom={greenRoom}
              key={`grid${finalPrimary.stream.uid}`}
              name=""
              streamDetails={{}}
              stream={finalPrimary.stream}
              userMoveFns={userMoveFns}
              hideNameBadgeBase={true}
              localClientAVStatus={localClientAVStatus}
              liveRaisedHandRequestList={liveRaisedHandRequestList}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default KeynoteLayout;
