import React, { useState, useEffect, useMemo } from 'react';
import styles from './styles.module.scss';
import Modal from '@/components/ui/modals/Modal';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import { SessionBroadcastStatus } from '../../SessionSelectBar/types';
import CheckBoxField from '@/components/ui/fields/CheckBoxField';

const SessionConfirmModal = ({ currentSession, trigger, handleConfirm }) => {
  const [isArchiveData, setIsArchiveData] = useState(false);
  let modalForStartSession = true;
  if (
    currentSession &&
    currentSession.broadcastStatus !== SessionBroadcastStatus.PENDING
  ) {
    modalForStartSession = false;
  }

  return (
    <Modal
      title={modalForStartSession ? 'Start session?' : 'End session?'}
      disableClose={true}
      modalHeaderClass={
        modalForStartSession
          ? 'backStageModalHeaderNew'
          : 'backStageModalDangerHeaderNew'
      }
      className={styles.confirmModal}
      noBodyPadding
      trigger={trigger}
      render={({ setShow }) => (
        <div className={styles.confirmExitModal}>
          {modalForStartSession && (
            <p className={styles.descriptionText}>
              Starting the session will make the stage live for the attendees
            </p>
          )}
          <p className={styles.deleteText}>
            {`Are you sure you want to ${
              modalForStartSession
                ? `start ${currentSession ? currentSession.title : 'session'}?`
                : `end ${!currentSession.isDummy ? currentSession.title : 'session'}?`
            }`}
          </p>
          {/* {!modalForStartSession && (
            <div className={styles.descriptionText}>
              <CheckBoxField
                checked={isArchiveData}
                label="Clear out chat, poll and Q&A info from the previous sessions."
                onCheckBoxToggle={() => setIsArchiveData(!isArchiveData)}
              />
            </div>
          )} */}
          <div className={styles.btnMenu}>
            <Button
              label="Cancel"
              className={styles.cancelButton}
              onClick={() => {
                setShow(false);
              }}
              styleClass={IButtonTypes.PRIMARY_NO_BG_INLINE}
              type="button"
            />
            <Button
              className={
                modalForStartSession
                  ? styles.constructiveButton
                  : styles.destructiveButton
              }
              label={modalForStartSession ? 'Yes, Start' : 'Yes, End'}
              onClick={() => handleConfirm({ setShow }, isArchiveData)}
              styleClass={IButtonTypes.PRIMARY}
              type="button"
            />
          </div>
        </div>
      )}
    />
  );
};

export default SessionConfirmModal;
