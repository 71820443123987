export const alterNumStreams = (streams, totalNumScreens) => {
  const numIters = Math.floor(totalNumScreens / 2);
  const filledArray = Array(numIters).fill(streams);
  if (numIters * 2 !== totalNumScreens) {
    filledArray.push(streams[0]);
  }
  return filledArray.flat();
};

export interface IStreamContainerDims {
  height: number;
  width: number;
  leftPosition: number;
  topPosition: number;
  numCols?: number;
  numRows?: number;
}

export const getStreamContainerDims = (config: IStreamContainerDims, isMobile = false) => {
  const { width, numCols = 1 } = config;

  const baseWidth = numCols == 2 && !isMobile ? 0.7 * width : width;
  const newWidth = Math.floor(baseWidth) / numCols;
  return {
    width: newWidth,
    height: (newWidth * 9) / 16
  };
};

export const getHeightBasedStreamContainerDims = (
  config: IStreamContainerDims,
) => {
  const { height, numRows = 1 } = config;
  const newHeight = Math.floor(height) / numRows;
  return {
    width: (newHeight * 16) / 9,
    height: newHeight,
  };
};

export const getEmbeddedRtmpDims = config => {
  const { height, width } = config;
  const spotlightHeight = (height * 208) / 342;
  const spotlightWidth = (width * 358) / 608;

  return {
    embedded: {
      height: spotlightHeight,
      width: spotlightWidth,
      marginTop: height * (-60) / 342,
    },
  };
};

export const getSpotlightDims = config => {
  const { height } = config;
  const spotlightHeight = (height * 3) / 4;
  const spotlightWidth = (spotlightHeight * 16) / 9;

  const spotlightItemsHeight = (height * 1) / 4;
  const spotlightItemsWidth = (spotlightItemsHeight * 16) / 9;

  return {
    spotlight: {
      height: spotlightHeight,
      width: spotlightWidth,
    },
    secondaryStreams: {
      height: spotlightItemsHeight,
      width: spotlightItemsWidth,
    },
  };
};

export const getHorizontalSpotlightDims = config => {
  const { height } = config;
  const spotlightHeight = (height * 6.75) / 10;
  const spotlightWidth = (spotlightHeight * 16) / 9;

  const spotlightItemHeight = (spotlightHeight * 2) / 3;
  const spotlightItemWidth = (spotlightItemHeight * 3) / 4;

  return {
    spotlight: {
      height: spotlightHeight,
      width: spotlightWidth,
    },
    secondaryStream: {
      height: spotlightItemHeight,
      width: spotlightItemWidth,
    },
  };
};

export const getCenterStageLargeStreamDims = config => {
  const { width, height } = config;

  return {
    center: {
      height: (height * 6) / 10,
      width: (width * 6) / 10,
    },
    secondary: {
      height: (height * 1.85) / 10,
      width: (width * 1.85) / 10,
    }
  };
}

export const getCenterStageDims = config => {
  const { width, height } = config;

  return {
    height: (height * 4.82) / 10,
    width: (width * 4.82) / 10,
  };
};

export const getCenterStageTwoStreamDims = config => {
  const { width, height } = config;

  return {
    center: {
      height: (height * 4.82) / 10,
      width: (width * 4.82) / 10,
    },
    secondary: {
      height: (height * 1.85) / 10,
      width: (width * 1.85) / 10,
    }
  };
}

export const getCenterStageThreeStreamDims = config => {
  const { width, height } = config;

  return {
    main: {
      height: (height * 4.82) / 10,
      width: (width * 4.82) / 10,
    },
    secondary: {
      height: (height * 2.64) / 10,
      width: (width * 2.64) / 10,
    }
  };
}

export const getCenterStageFourStreamDims = config => {
  const { width, height } = config;

  return {
    main: {
      height: (height * 4.82) / 10,
      width: (width * 4.82) / 10,
    },
    secondary: {
      height: (height * 2.64) / 10,
      width: (width * 2.64) / 10,
    },
  };
}

export const getTwoRowSpotlightDims = config => {
  const { height } = config;
  const spotlightHeight = (height * 3) / 5;
  const spotlightWidth = (spotlightHeight * 16) / 9;

  const spotlightItemsHeight = (height * 1) / 8;
  const spotlightItemsWidth = (spotlightItemsHeight * 16) / 9;

  return {
    spotlight: {
      height: spotlightHeight,
      width: spotlightWidth,
    },
    secondaryStreams: {
      height: spotlightItemsHeight,
      width: spotlightItemsWidth,
    },
  };
};
