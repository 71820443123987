import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { StreamType } from '@/components/Stage/types';
import { selectChannelSidePanelState } from '@/models/global';
import { EPresentationLayoutVariant } from './types';
import SlidesLayout from './SlidesLayout';
import SinglePageLayout from './SinglePageLayout';

const PresentationLayout = props => {
  const {
    streams: originalStreams,
    primaryStream,
    secondaryStreams,
    streamDetailsMap,
    currentPresentation,
    currentPlugin,
    layoutVariant,
  } = props;

  const channelSidePanelState = useSelector(selectChannelSidePanelState);

  const isContentShared = currentPresentation || currentPlugin;
  const sharedStreamUid =
    !isContentShared &&
    streamDetailsMap[primaryStream?.uid]?.type === StreamType.SCREEN
      ? primaryStream?.uid
      : undefined;

  const streams = useMemo(() => {
    if (isContentShared) {
      return originalStreams.map(s => s.stream);
    }
    if (sharedStreamUid) {
      return [...secondaryStreams];
    }
    return originalStreams.map(s => s.stream);
  }, [originalStreams, secondaryStreams, isContentShared, sharedStreamUid]);

  const otherStreams = useMemo(() => {
    if (sharedStreamUid) {
      return streams.filter(s => s.uid !== sharedStreamUid);
    }
    return streams;
  }, [sharedStreamUid, streams]);

  const isSharingViewEnabled = sharedStreamUid || isContentShared;

  const streamsCount = streams.length;

  const isSliderLayoutVariant =
    layoutVariant === EPresentationLayoutVariant.SLIDER;
  const isSinglePageLayoutVariant =
    layoutVariant === EPresentationLayoutVariant.SINGLE_PAGE;

  return (
    <div
      className={classnames({
        [styles.ptLayoutContainer]: isSliderLayoutVariant,
        [styles.ptSingleLayoutContainer]: isSinglePageLayoutVariant,
      })}
    >
      {isSliderLayoutVariant && (
        <SlidesLayout
          {...props}
          streams={streams}
          streamsCount={streamsCount}
          otherStreams={otherStreams}
          sharedStreamUid={sharedStreamUid}
          isContentShared={isContentShared}
          isSharingViewEnabled={isSharingViewEnabled}
          channelSidePanelState={channelSidePanelState}
        />
      )}
      {isSinglePageLayoutVariant && (
        <SinglePageLayout
          {...props}
          streams={streams}
          streamsCount={streamsCount}
          otherStreams={otherStreams}
          sharedStreamUid={sharedStreamUid}
          isContentShared={isContentShared}
          isSharingViewEnabled={isSharingViewEnabled}
          channelSidePanelState={channelSidePanelState}
        />
      )}
    </div>
  );
};

export default React.memo(PresentationLayout);
