// types
import { IScreenCalcOrientationOptions } from '@/components/PublishedStreamDisplay/types';
import { IStageParent } from '../Stage/types';
import {
  IStageLayoutTemplate,
  IStageLayoutTemplateType,
} from '@/custom-config/types';
// configs
import template1 from '@/custom-config/baseTemplates/template1';
import template2 from '@/custom-config/baseTemplates/template2';
import template3 from '@/custom-config/baseTemplates/template3';
import template4 from '@/custom-config/baseTemplates/template4';
import template6 from '@/custom-config/baseTemplates/template6';
import template8 from '@/custom-config/baseTemplates/template8';
import template9 from '@/custom-config/baseTemplates/template9';

interface ISingleStreamDimsInput {
  primaryScreenOrientation: IScreenCalcOrientationOptions;
  primaryScreenOrientationSize: number;
  parentHeight: number;
  parentWidth: number;
  hasGreenRoomStreams: boolean;
  isGreenRoomStream: boolean;
  parent: IStageParent;
  useBlurLayout?: boolean;
  isRecordingMode?: boolean;
}

export const getOriginalParentDims = (config: ISingleStreamDimsInput) => {
  const {
    primaryScreenOrientationSize,
    primaryScreenOrientation,
    hasGreenRoomStreams,
    isGreenRoomStream,
    parent,
    useBlurLayout,
  } = config;
  let { parentHeight, parentWidth } = config;

  // // account for options bar taking up 10%;
  // parentHeight = parentHeight * 0.9;

  if (hasGreenRoomStreams) {
    if (isGreenRoomStream) {
      parentHeight = parentHeight * 0.225;
    } else {
      parentHeight = parentHeight * 0.75 - 40;
    }
  }

  if (!isGreenRoomStream && [IStageParent.LIVE_BACKSTAGE, IStageParent.LIVE_STAGE].includes(parent) && !useBlurLayout) {
    parentHeight = parentHeight - 40;
    parentWidth = parentWidth - 40;
  }

  if (primaryScreenOrientation === IScreenCalcOrientationOptions.HEIGHT) {
    const ratio = 16 / 9;
    const desiredHeight = (primaryScreenOrientationSize / 100) * parentHeight;
    const desiredWidth = desiredHeight * ratio;

    // If the width is too large as a result of the height, then switch
    if (desiredWidth > parentWidth) {
      return {
        height: (parentWidth * 1) / ratio,
        width: parentWidth,
      };
    }
    return {
      height: desiredHeight,
      width: desiredWidth,
    };
  }
  const ratio = 9 / 16;
  const desiredWidth = (primaryScreenOrientationSize / 100) * parentWidth;
  const desiredHeight = desiredWidth * ratio;
  // If the height is too large as a result of the width, then switch
  if (desiredHeight > parentHeight) {
    return {
      width: (parentHeight * 1) / ratio,
      height: parentHeight,
    };
  }
  return {
    width: desiredWidth,
    height: desiredHeight,
  };
};

export const getDisplayModeByTemplate = (
  stageLayoutTemplate: IStageLayoutTemplate,
  interpreterStreamCount: number,
  hasScreenshareStream: boolean,
) => {
  // Get the stage layout component by the template number
  let template: IStageLayoutTemplateType;
  switch (stageLayoutTemplate) {
    case IStageLayoutTemplate.TEMPLATE_1:
      template = template1;
      break;
    case IStageLayoutTemplate.TEMPLATE_2:
      template = template2;
      break;
    case IStageLayoutTemplate.TEMPLATE_3:
      template = template3;
      break;
    case IStageLayoutTemplate.TEMPLATE_4:
      template = template4;
      break;
    case IStageLayoutTemplate.TEMPLATE_6:
      template = template6;
      break;
    case IStageLayoutTemplate.TEMPLATE_8:
      template = template8;
      break;
    case IStageLayoutTemplate.TEMPLATE_9:
      template = template9;
      break;
    default:
      template = template1;
  }
  // Use the template to get the display mode
  return template.getDisplayMode(interpreterStreamCount, hasScreenshareStream);
};

export const getIsCurrentUserOwnerInRoom = (userAccountId, discussionTables, discussionTableId) => {
  const currentDiscussionTable = discussionTables.find(table => table.discussionTableId === discussionTableId)
  
  if(!currentDiscussionTable?.lockedRoomOwners?.length){
    return false
  }
  
  return currentDiscussionTable.lockedRoomOwners.some(element => element.accountId === userAccountId)
}
