import React, { useEffect, useMemo, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import SingleStreamPlayer from '../../../SingleStreamPlayer';
import {
  getRowsAndColumns,
  getContainerWidth,
  getContainerHeight,
} from '../config';
import {
  getLayoutDims,
  getMaxStreamsPerPage,
  MAX_STREAMS_PER_PAGE,
  MAX_STREAMS_PER_PAGE_FOR_MOB,
} from './config';
import chunk from 'lodash/chunk';
import SharingView from './SharingView';
import SliderWrapper from './SliderWrapper';
import styles from './styles.module.scss';
import { ChevronLeft, ChevronRight } from '@styled-icons/boxicons-solid';
import { IContentColors } from '@/types';
import { getCssVar } from '@/utils/cssVars';
import classnames from 'classnames';
import Slider from 'react-slick';
import {
  useVideoMutation,
  useVideoState,
} from '@/services/video/video-context';
import { usePrevious } from 'react-use';
import { isTablet } from 'react-device-detect';

const SlidesLayout = props => {
  const {
    streams,
    streamsCount,
    primaryStream,
    sharedStreamUid,
    isSharingViewEnabled,
    streamNamesMap,
    streamDetailsMap,
    parentWidth,
    parentHeight,
    parentLeftPosition,
    parentTopPosition,
    parent,
    volumeOn,
    greenRoom,
    userMoveFns,
    localClientAVStatus,
    localClient,
    liveRaisedHandRequestList,
    handleStreamDoubleClick,
    channelSidePanelState,
  } = props;

  const sliderRef = useRef<Slider>(null);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const videoMutation = useVideoMutation();
  const { streamPagination } = useVideoState();
  const { currentPage } = streamPagination;

  const chunkedStreams = useMemo(
    () =>
      chunk(
        streams,
        getMaxStreamsPerPage(isMobile || isTablet, !!sharedStreamUid),
      ),
    [streams, isMobile, sharedStreamUid],
  );

  const { rows, cols } = getRowsAndColumns(
    streamsCount,
    {
      maxStreams: MAX_STREAMS_PER_PAGE,
      maxStreamsOnTab: MAX_STREAMS_PER_PAGE_FOR_MOB,
      maxStreamsOnMob: MAX_STREAMS_PER_PAGE_FOR_MOB,
    },
    isMobile,
    isTablet,
  );
  const streamContainerDimsConfig = {
    height: getContainerHeight(parentHeight, isTablet, isMobile),
    width: getContainerWidth(parentWidth, isMobile, streamsCount),
    leftPosition: parentLeftPosition,
    topPosition: parentTopPosition,
    numCols: isMobile && sharedStreamUid ? 2 : cols,
    numRows: isMobile && sharedStreamUid ? 2 : rows,
  };

  const prevIsSharingViewEnabledVal = usePrevious(isSharingViewEnabled);
  useEffect(() => {
    if (!isSharingViewEnabled && prevIsSharingViewEnabledVal) {
      videoMutation.setPaginationConfig({
        currentPage: 1,
      });
    }
  }, [isSharingViewEnabled]);

  const isSlidingEnabled = streamsCount > MAX_STREAMS_PER_PAGE;
  const isSlidingEnabledOnMob = streamsCount > MAX_STREAMS_PER_PAGE_FOR_MOB;
  const isPrevSlideAllowed = currentPage > 1;
  const isNextSlideAllowed = currentPage < chunkedStreams.length;
  const streamsPerPage = getMaxStreamsPerPage(
    isMobile || isTablet,
    isSharingViewEnabled,
  );

  useEffect(() => {
    if (!isSharingViewEnabled) {
      videoMutation.setPaginationConfig({
        perPage: streamsPerPage,
      });
    }
  }, [isSharingViewEnabled, streamsPerPage]);

  const handlePrevClick = () =>
    sliderRef.current && sliderRef.current.slickPrev();
  const handleNextClick = () =>
    sliderRef.current && sliderRef.current.slickNext();

  const { width: streamWidth, height: streamHeight } = getLayoutDims(
    streamContainerDimsConfig,
    isMobile,
    isSlidingEnabledOnMob,
  );

  if (isSharingViewEnabled) {
    return (
      <SharingView
        {...props}
        sharedStream={sharedStreamUid ? primaryStream : undefined}
      />
    );
  }

  return (
    <>
      {!!chunkedStreams.length && (
        <div
          className={classnames(styles.streamsWrapper, {
            [styles.streamsWrapperWithoutSidePanel]: !channelSidePanelState,
            [styles.streamsWrapperWithoutSidePanelOnTablet]:
              !channelSidePanelState && isTablet,
          })}
        >
          {!isMobile && !isTablet && isSlidingEnabled && (
            <div
              aria-hidden
              className={classnames(styles.arrowBackground, {
                [styles.arrowBackgroundDisabled]: !isPrevSlideAllowed,
              })}
              onClick={isPrevSlideAllowed ? handlePrevClick : undefined}
            >
              <ChevronLeft size={24} color={getCssVar(IContentColors.WHITE)} />
            </div>
          )}
          <div
            className={classnames(styles.slidingWrapper, {
              [styles.slidingWrapperWithArrows]:
                !(isMobile || isTablet) && isSlidingEnabled,
            })}
          >
            <SliderWrapper ref={sliderRef}>
              {chunkedStreams.map((chunkedArr, idx: number) => (
                <div
                  key={`key-chunked-streams-${idx}`}
                  className={classnames(styles.streamsWrapper)}
                >
                  {chunkedArr?.map((st: any) => (
                    <div
                      key={`key-single-stream-${st.uid}`}
                      className={styles.streamWrapper}
                    >
                      <SingleStreamPlayer
                        parent={parent}
                        volumeOn={volumeOn}
                        containerStyles={{
                          width: streamWidth,
                          height: streamHeight,
                        }}
                        key={`line${st.uid}`}
                        name={streamNamesMap[st.uid]}
                        onDoubleClick={() => handleStreamDoubleClick(st)}
                        streamDetails={streamDetailsMap[st.uid]}
                        stream={st}
                        greenRoom={greenRoom}
                        userMoveFns={userMoveFns}
                        localClientAVStatus={localClientAVStatus}
                        localClient={localClient}
                        liveRaisedHandRequestList={liveRaisedHandRequestList}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </SliderWrapper>
          </div>
          {!isMobile && !isTablet && isSlidingEnabled && (
            <div
              aria-hidden
              className={classnames(styles.arrowBackground, {
                [styles.arrowBackgroundDisabled]: !isNextSlideAllowed,
              })}
              onClick={isNextSlideAllowed ? handleNextClick : undefined}
            >
              <ChevronRight size={24} color={getCssVar(IContentColors.WHITE)} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(SlidesLayout);
