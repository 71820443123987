import { IStreamContainerDims } from "../../utils";

export const getKeynoteLayoutDims = (config: IStreamContainerDims) => {
  const { height, width } = config;

  const primaryWidth = Math.floor(width) * 687 / 1280;
  const primaryHeight = Math.floor(height) * 386 / 720;

  const secondaryWidth = Math.floor(width) * 309 / 1280;
  const secondaryHeight = Math.floor(height) * 188 / 720;
  return {
    primary: {
      width: primaryWidth,
      height: primaryHeight
    },
    secondary: {
      width: secondaryWidth,
      height: secondaryHeight
    }
  };
};
