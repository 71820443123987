import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { isIPad13, isTablet } from 'react-device-detect';
import styles from './styles.module.scss';
import {
  getOtherStreamsContainerHeight,
  getOtherStreamsContainerWidth,
} from './config';
import SharedStreamOrContentView from '../../SharedStreamOrContentView';
import { getMaxStreamsPerPage } from '../config';
import DisplayedStreams from '../DisplayedStreams';

const SharingView = props => {
  const {
    streamDetailsMap,
    isSharingViewEnabled,
    otherStreams,
    streamNamesMap,
    parentWidth,
    parentHeight,
    parent,
    volumeOn,
    greenRoom,
    userMoveFns,
    localClientAVStatus,
    localClient,
    liveRaisedHandRequestList,
    handleStreamDoubleClick,
    channelSidePanelState,
  } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const streamsCountToSlice = getMaxStreamsPerPage(
    isMobile,
    isSharingViewEnabled,
  );

  const displayedStreams = useMemo(
    () => otherStreams.slice(0, streamsCountToSlice),
    [otherStreams, streamsCountToSlice],
  );

  const allStreamsCount = otherStreams?.length;
  const displayedStreamsCount = displayedStreams?.length;

  const hiddenStreams = useMemo(() => {
    if (allStreamsCount > streamsCountToSlice) {
      return otherStreams.slice(streamsCountToSlice, allStreamsCount);
    }
    return undefined;
  }, [otherStreams, allStreamsCount, streamsCountToSlice]);

  // Container Dims Calc
  const streamContainerDimsConfig = {
    height: getOtherStreamsContainerHeight(
      parentHeight,
      isMobile,
      isTablet,
      isIPad13,
      displayedStreamsCount,
    ),
    width: getOtherStreamsContainerWidth(
      parentWidth,
      isTablet,
      isMobile,
      !!channelSidePanelState,
    ),
  };

  return (
    <div
      className={classnames(styles.sharedViewWrapper, {
        [styles.sharedViewWrapperWithoutSidePanel]: !channelSidePanelState,
      })}
    >
      <SharedStreamOrContentView {...props} />
      {!!otherStreams.length && (
        <div
          className={classnames(styles.otherStreamsWrapper, {
            [styles.otherStreamsWrapperWithoutSidePanel]: !channelSidePanelState,
          })}
        >
          <DisplayedStreams
            isContentShared
            displayedStreams={displayedStreams}
            hiddenStreams={hiddenStreams}
            streamContainerDims={streamContainerDimsConfig}
            parent={parent}
            volumeOn={volumeOn}
            greenRoom={greenRoom}
            userMoveFns={userMoveFns}
            streamNamesMap={streamNamesMap}
            streamDetailsMap={streamDetailsMap}
            localClient={localClient}
            localClientAVStatus={localClientAVStatus}
            liveRaisedHandRequestList={liveRaisedHandRequestList}
            handleStreamDoubleClick={handleStreamDoubleClick}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(SharingView);
