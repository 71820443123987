// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__bottomSponsorContainer___2atvP{width:100%;display:flex;flex-flow:row wrap;align-items:center;justify-content:center;position:relative;overflow:hidden}\n", ""]);
// Exports
exports.locals = {
	"bottomSponsorContainer": "styles-module__bottomSponsorContainer___2atvP"
};
module.exports = exports;
