import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { getCenterStageLargeStreamDims } from '../utils';
import { StreamType } from '@/components/Stage/types';
import styles from './styles.module.scss';
import SquareFrame from '../../frames/SquareFrame';
import SingleStreamPlayer from '../../SingleStreamPlayer';

const CenterStageLayout = props => {
  const {
    primaryStream,
    secondaryStreams,
    streams,
    parentHeight,
    parentWidth,
    streamDetailsMap,
    volumeOn,
    greenRoom,
    userMoveFns,
    isStageFull,
    parent,
    streamNamesMap,
    localClientAVStatus,
    localClient,
    liveRaisedHandRequestList,
  } = props;

  const streamingIds = [
    671637,
    671638,
    671639,
    671640,
    671656,
    671657,
    671658,
    671659,
    671660,
    6581,
    6582,
    6583,
    6584,
    6585,
    8606,
    8607,
    8608,
    8609,
    8610,
    652721,
    652722,
    652723,
    652724,
    652725,
    585716,
    585717,
    585718,
    585719,
    585720,
    641276,
    641277,
    641278,
    641279,
    641280,
    640841,
    640842,
    640843,
    640844,
    640845,
    696856,
    696857,
    696858,
    696859,
    696860,
    696851,
    696852,
    696853,
    696854,
    696855,
    706066,
    706067,
    706068,
    706069,
    706070,
    732961,
    732962,
    732963,
    732964,
    732965,
  ];

  const streamContainerDimsConfig = {
    height: parentHeight,
    width: parentWidth,
    numCols: 1,
    numRows: 1,
  };

  const {
    center: centerDims,
    secondary: secondaryDims,
  } = getCenterStageLargeStreamDims(streamContainerDimsConfig);

  const [streamState, setStreamState] = useState({
    finalPrimaryStream: null,
    finalSecondaryStreams: [],
    isStreaming: false,
    isScreen: false,
  });

  const {
    finalPrimaryStream,
    finalSecondaryStreams,
    isStreaming,
    isScreen,
  } = streamState;

  const pinnedSecondary = [800191, 800192, 800193, 800194, 800195];
  useEffect(() => {
    let tempPrimaryStream;
    const tempSecondaryStreams = [];
    streams.forEach(stream => {
      if (pinnedSecondary.includes(stream.stream.uid)) {
        tempSecondaryStreams.push(stream);
      } else if (primaryStream && stream.stream.uid === primaryStream.uid) {
        tempPrimaryStream = stream;
      } else {
        tempSecondaryStreams.push(stream);
      }
    });

    if (!tempPrimaryStream) {
      tempPrimaryStream = tempSecondaryStreams.pop();
    }

    if (!tempPrimaryStream) {
      setStreamState({
        finalPrimaryStream: tempPrimaryStream,
        finalSecondaryStreams: tempSecondaryStreams,
        isStreaming: false,
        isScreen: false,
      });
      return;
    }

    const streamDetails =
      tempPrimaryStream && streamDetailsMap[tempPrimaryStream.stream.uid];

    const streaming =
      tempPrimaryStream && streamingIds.includes(tempPrimaryStream.stream.uid);
    const screen =
      streamDetails &&
      [StreamType.SCREEN, StreamType.SCREEN_RELAY].includes(streamDetails.type);

    setStreamState({
      finalPrimaryStream: tempPrimaryStream,
      finalSecondaryStreams: tempSecondaryStreams,
      isStreaming: streaming,
      isScreen: screen,
    });
  }, [streams, primaryStream, secondaryStreams, streamDetailsMap]);

  if (!finalPrimaryStream) {
    return null;
  }

  return (
    <>
      <div
        className={classnames(styles.centerPrimaryContainer, {
          [styles.objectFitContain]: isStreaming,
        })}
      >
        <SquareFrame
          key={`line${finalPrimaryStream.stream.uid}`}
          height={centerDims.height}
          noMargin={true}
        >
          <SingleStreamPlayer
            volumeOn={volumeOn}
            parent={parent}
            hideNameBadge={isStreaming || isScreen}
            containerStyles={centerDims}
            greenRoom={greenRoom}
            key={finalPrimaryStream.stream.uid}
            name={streamNamesMap[finalPrimaryStream.stream.uid]}
            streamDetails={streamDetailsMap[finalPrimaryStream.stream.uid]}
            stream={finalPrimaryStream.stream}
            userMoveFns={userMoveFns}
            noBorder={isStageFull}
            noMargin={true}
            localClientAVStatus={localClientAVStatus}
            localClient={localClient}
            liveRaisedHandRequestList={liveRaisedHandRequestList}
          />
        </SquareFrame>
      </div>
      <div
        style={{ width: parentWidth }}
        className={styles.centerSecondaryStreamsContainer}
      >
        {finalSecondaryStreams.map(secondaryStream => (
          <div className={styles.secondaryStream}>
            <SquareFrame
              key={`line${secondaryStream.stream.uid}`}
              height={secondaryDims.height}
              noMargin={true}
            >
              <SingleStreamPlayer
                volumeOn={volumeOn}
                parent={parent}
                containerStyles={secondaryDims}
                hideNameBadge={true}
                greenRoom={greenRoom}
                key={secondaryStream.stream.uid}
                name={streamNamesMap[secondaryStream.stream.uid]}
                streamDetails={streamDetailsMap[secondaryStream.stream.uid]}
                stream={secondaryStream.stream}
                userMoveFns={userMoveFns}
                noBorder={isStageFull}
                noMargin={true}
                localClientAVStatus={localClientAVStatus}
                localClient={localClient}
                liveRaisedHandRequestList={liveRaisedHandRequestList}
              />
            </SquareFrame>
          </div>
        ))}
      </div>
    </>
  );
};

export default CenterStageLayout;
