import { IStreamContainerDims } from "../../utils";

const fillArray = (value, times) => {
  let arr = [];
  for (let i = 0; i < times; i++) {
    arr.push(value);
  }
  return arr;
};

export const getCondensedGridLayoutDims = (config: IStreamContainerDims, numStreams: number) => {
  const { height, width } = config;

  if (numStreams === 1) {
    return [{
      height: height * 296 / 720,
      width: width * 500 / 1280
    }];
  }

  if (numStreams === 2) {
    return fillArray({
      height: height * 296 / 720,
      width: width * 422 / 1280
    }, 2);
  }

  if (numStreams === 3) {
    return fillArray({
      height: height * 294 / 720,
      width: width * 281 / 1280
    }, 3);
  }

  if (numStreams === 4) {
    return fillArray({
      height: height * 230 / 720,
      width: width * 435 / 1280
    }, 4);
  }

  if (numStreams === 5) {
    return [{
      height: height * 230 / 720,
      width: width * 290 / 1280
    }, {
      height: height * 230 / 720,
      width: width * 290 / 1280
    }, {
      height: height * 230 / 720,
      width: width * 290 / 1280
    }, {
      height: height * 230 / 720,
      width: width * 435 / 1280
    }, {
      height: height * 230 / 720,
      width: width * 435 / 1280
    }];
  }

  if (numStreams === 6) {
    return fillArray({
      height: height * 230 / 720,
      width: width * 310 / 1280
    }, 6);
  }

  if (numStreams <= 9) {
    return fillArray({
      height: height * 180 / 720,
      width: width * 290 / 1280
    }, numStreams);
  }

  if (numStreams <= 12) {
    return fillArray({
      height: height * 140 / 720,
      width: width * 235 / 1280
    }, numStreams);
  }

  if (numStreams <= 16) {
    return fillArray({
      height: height * 135 / 720,
      width: width * 236 / 1280
    }, numStreams);
  }

  return {};
};
