import {
  Microphone,
  MicrophoneOff,
  Video,
  VideoOff,
} from '@styled-icons/boxicons-solid';
import { LogOut } from "@styled-icons/boxicons-regular";
import { StyledIcon } from '@styled-icons/styled-icon';
import ScreenShareOn from '@/components/ui/new-icons/ScreenShareOn';

export type REMOTE_USER_ACTION =
  | 'NONE'
  | 'AUDIO_OFF'
  | 'AUDIO_ON'
  | 'VIDEO_OFF'
  | 'VIDEO_ON'
  | 'SCREEN_SHARE_OFF'
  | 'KICKOUT_USER' ;

export const getMobileOptions = (
  isAudioEnabled: boolean,
  isVideoEnabled: boolean,
  isAScreenShare: boolean,
): { type: REMOTE_USER_ACTION; icon: StyledIcon; text: string }[] => {
  if (isAScreenShare) {
    return [
      {
        type: 'SCREEN_SHARE_OFF',
        icon: ScreenShareOn,
        text: 'End presentation',
      },
    ];
  }
  return [
    isVideoEnabled
      ? { type: 'VIDEO_OFF', icon: Video, text: 'Turn video off' }
      : { type: 'VIDEO_ON', icon: VideoOff, text: 'Request to turn on video' },
    isAudioEnabled
      ? { type: 'AUDIO_OFF', icon: Microphone, text: 'Mute participant' }
      : { type: 'AUDIO_ON', icon: MicrophoneOff, text: 'Request to unmute' },
    { type: 'KICKOUT_USER', icon: LogOut , text: `Remove this user` },
  ];
};

export const getConfirmation = (type: REMOTE_USER_ACTION, name: string) => {
  switch (type) {
    case 'AUDIO_OFF':
      return {
        title: 'Mute this participant?',
        description: `This will mute ${name} for everyone in the room. Only they can unmute themselves.`,
        actionText: 'Mute',
      };
    case 'AUDIO_ON':
      return {
        title: 'Ask participant to unmute?',
        description: `${name} will be sent a notification to enable their microphone.`,
        actionText: 'Send',
      };
    case 'VIDEO_OFF':
      return {
        title: 'Turn this video off?',
        description: `This will turn off ${name}'s video for everyone in the room. Only they can turn it on again.`,
        actionText: 'Turn off',
      };
    case 'VIDEO_ON':
      return {
        title: 'Ask participant to enable video?',
        description: `${name} will be sent a notification to turn on their camera.`,
        actionText: 'Send',
      };
    case 'KICKOUT_USER':
      return {
        title: 'Remove this user?',
        description: `${name} will be removed from this room.`,
        actionText: 'Remove',
      };
    default:
      return null;
  }
};
