/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
// models
import {
  makeSelectEventById,
  makeSelectStageByEventId,
  makeSelectRecordingSessionObj,
} from '@/models/event';
// hooks
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { formatDateWithDayMonthOnly, formatDateFilter, convertInAnotherTimeZone } from '@/utils/helpers';
// styles + types
import styles from './styles.module.scss';
import { ChevronLeft, ChevronRight } from 'react-feather';
import useComponentVisible from '@/hooks/use-component-visible';
import SessionsList from './SessionsList';
import { useComputeRecordingSessionsList } from '@/hooks/use-compute-recording-sessions-list';
import ToggleField from '@/components/ui/fields/ToggleField';
import { SessionBroadcastStatus } from './types';
import api from '@/api';
import SettingsIcon from '@/components/ui/new-icons/Settings';
import { IContentColors } from '@/types';
import ConfirmExitModal from '@/components/PublishedStreamDisplay/StreamOptionsBar/ConfirmExitModal';
import { eachDayOfInterval } from 'date-fns';

const SessionSelectBar = ({
  isBroadcasting,
  stageSettingsObj,
  updateDryRunToggle,
}) => {
  const dispatch = useDispatch();
  const { stageId, eventId } = useParams() as any;
  const [showMenu, setShowMenu] = useState(false);
  const [showAllDays, setShowAllDays] = useState(false);
  const currentSession = useMemoizedSelector(
    makeSelectRecordingSessionObj,
    stageId,
  );
  const { pendingSessions } = useComputeRecordingSessionsList();
  const [isDryRunEnabled, setIsDryRunEnabled] = useState(false);
  const [disableDryRunToggle, setDisableDryRunToggle] = useState(false);
  const [sortedSessions, setSortedSessions] = useState([]) as any;
  const [showBottomBar, setShowBottomBar] = useState(false);
  const [isDryRunModalOpened, setIsDryRunModalOpened] = useState(false);
  const [showSessionsView, setShowSessionsView] = useState(false);

  const event = useMemoizedSelector(makeSelectEventById, eventId);
  const stageData = useMemoizedSelector(makeSelectStageByEventId, {
    eventId,
    stageId,
  });
  const [eventDays, setEventDays] = useState([]) as any;
  const [currentDay, setCurrentDay] = useState(null) as any;

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  const sortSessionsList = () => {
    const sortedList = pendingSessions.filter(
      session =>
        formatDateFilter(convertInAnotherTimeZone(session.startDateTime, event.tz)) ===
        currentDay.dateFilter,
    );
    setSortedSessions(sortedList);
    setShowBottomBar(false);
  };

  useEffect(() => {
    setShowSessionsView(pendingSessions.length !== 0);
    setShowAllDays(pendingSessions.length === 0);
  }, [pendingSessions]);

  useEffect(() => {
    if (!currentDay || !pendingSessions) return;
    sortSessionsList();
  }, [pendingSessions, currentDay]);

  useEffect(() => {
    if (!stageSettingsObj) return;
    setIsDryRunEnabled(stageSettingsObj.isDryRun);
    if (
      // this condition for disable toggle while running any session
      isBroadcasting ||
      (currentSession &&
        (currentSession.broadcastStatus === SessionBroadcastStatus.PAUSED ||
          currentSession.broadcastStatus === SessionBroadcastStatus.STARTED))
    ) {
      setDisableDryRunToggle(true);
    } else {
      setDisableDryRunToggle(false);
    }
  }, [stageSettingsObj?.isDryRun, isBroadcasting, currentSession]);

  useEffect(() => {
    if (!pendingSessions) return;
    const startDate = convertInAnotherTimeZone(event.startDateTime, event.tz);
    const endDate = convertInAnotherTimeZone(event.endDateTime, event.tz);
    const dateOptions = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });
    const options = [] as any;
    let dayNumber = 1;
    let eventCurrentDay = null as any;
    dateOptions.forEach(dateObject => {
      const dayObj = {
        date: dateObject,
        dayNumber: dayNumber,
        dateFilter: formatDateFilter(dateObject),
        shortDateText: formatDateWithDayMonthOnly(dateObject),
      };
      const hasSessions = pendingSessions.some(
        session =>
          formatDateFilter(convertInAnotherTimeZone(session.startDateTime, event.tz)) ===
          dayObj.dateFilter,
      );
      if (hasSessions) {
        options.push(dayObj);
      }
      if (
        currentSession &&
        formatDateFilter(convertInAnotherTimeZone(currentSession.startDateTime, event.tz)) ===
        dayObj.dateFilter
      ) {
        eventCurrentDay = dayObj;
      } else if (
        dayObj.dateFilter === formatDateFilter(convertInAnotherTimeZone(new Date(), event.tz)) &&
        hasSessions
      ) {
        eventCurrentDay = dayObj;
      }
      dayNumber += 1;
    });
    setEventDays(options);
    if (eventCurrentDay) {
      setCurrentDay(eventCurrentDay);
    } else {
      // set event first day as default day
      setCurrentDay(options[0]);
    }
  }, [event, pendingSessions]);

  const handleSetDraggedList = (list: any) => {
    setSortedSessions(list);
    setShowBottomBar(true);
  };

  const saveSortedList = () => {
    const requestBody = [] as any;
    sortedSessions.map(session =>
      requestBody.push({
        segmentId: session.segmentId,
        startDateTime: session.startDateTime,
        endDateTime: session.endDateTime,
      }),
    );
    api.segment.updateSegmentsOrdering(eventId, requestBody);
    // api.event.archiveEventData(eventId).then(() => {
    //   dispatch({
    //     type: 'global/addSuccessToast',
    //     payload: { description: 'Previous Sessions Data Cleared Successfully' },
    //   });
    // });
  };

  const handleSelectSession = session => {
    dispatch({
      type: 'event/setRecordingSession',
      payload: {
        stageId,
        sessionObj: session,
      },
    });
  };

  const handleToggleChange = setShow => {
    if (disableDryRunToggle) {
      dispatch({
        type: 'global/addDangerToast',
        payload: {
          description:
            'You cannot enable/disable dry run while session is live or in dry runs.',
        },
      });

      return;
    }
    setShow(true);
    setIsDryRunModalOpened(true);
  };

  // show stage name on top bar if session is dummy.
  let sessionName = stageData?.name;
  if (currentSession && !currentSession.isDummy) {
    sessionName = currentSession.title;
  }

  return (
    <div>
      <div
        className={styles.dropDownMainContainer}
        onClick={() => {
          setShowMenu(true);
          setIsComponentVisible(true);
        }}
      >
        <div className={styles.dropDownTextLayout}>
          <div className={styles.sessionTitle}>{sessionName}</div>
          <SettingsIcon color={IContentColors.MONOCHROME_CONTRAST} size={16} />
          {showMenu && isComponentVisible && (
            <div
              className={styles.sessionsMenuContainer}
              ref={isDryRunModalOpened ? null : ref}
            >
              <>
                <div
                  className={
                    showSessionsView
                      ? styles.sessionMenuTopBar
                      : styles.sessionMenuTopBarWithoutSession
                  }
                >
                  {!showAllDays && (
                    <div
                      onClick={() => setShowAllDays(true)}
                      className={styles.backButton}
                    >
                      <ChevronLeft size={12} />
                      <div className={styles.sessionLabelHeading}>
                        <span>Day {(currentDay && currentDay.dayNumber) || '0'}</span>
                        <span className={styles.timeZoneLabel}>({event && event.tz})</span>
                      </div>
                    </div>
                  )}
                  {showAllDays && (
                    <span className={styles.stageNameLabel}>
                      {stageData && stageData.name}
                    </span>
                  )}
                  <div className={styles.dryRunContainer}>
                    Dry Run
                    <ConfirmExitModal
                      title={
                        isDryRunEnabled ? 'Disable Dry Run?' : 'Enable Dry Run'
                      }
                      headerClassName={
                        isDryRunEnabled
                          ? 'backStageModalDangerHeaderNew'
                          : 'backStageModalHeaderNew'
                      }
                      cancelButtonClassName={styles.cancelButton}
                      confirmButtonClassName={
                        isDryRunEnabled
                          ? styles.destructiveButton
                          : styles.constructiveButton
                      }
                      className={styles.confirmModal}
                      trigger={({ setShow }) => (
                        <ToggleField
                          onToggle={() => handleToggleChange(setShow)}
                          enabled={isDryRunEnabled}
                          className={styles.toggleButton}
                        />
                      )}
                      handleConfirm={({ setShow }) => {
                        updateDryRunToggle(!isDryRunEnabled);
                        setShow(false);
                        setIsDryRunModalOpened(false);
                      }}
                      handleCancel={() => {
                        setIsDryRunModalOpened(false);
                      }}
                      exitText={`Are you sure you want to ${isDryRunEnabled ? 'disable' : 'enable'
                        } dry run?`}
                      confirmButtonText={
                        isDryRunEnabled ? 'Yes Disable' : 'Yes Enable'
                      }
                    />
                  </div>
                </div>
                {showSessionsView && (
                  <>
                    {!showAllDays && (
                      <SessionsList
                        sessions={sortedSessions}
                        handleSelectSession={handleSelectSession}
                        selectedSession={currentSession}
                        currentRunningSession={currentSession}
                        isDryRunMode={isDryRunEnabled}
                        handleSetDraggedList={handleSetDraggedList}
                      />
                    )}
                    {showAllDays && (
                      <div className={styles.sessionMenuCenterBar}>
                        {eventDays.map(day => (
                          <div
                            className={styles.dayLayout}
                            onClick={() => {
                              setShowAllDays(false);
                              setCurrentDay(day);
                            }}
                          >
                            <div className={styles.dayTextLayout}>
                              <div className={styles.dayNumberLabel}>
                                Day {day.dayNumber}
                              </div>
                              <div className={styles.dayDateLabel}>
                                ({day.shortDateText}, {event.tz})
                              </div>
                            </div>
                            <ChevronRight size={12} />
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </>
              {showBottomBar && (
                <div className={styles.bottomBar}>
                  <ConfirmExitModal
                    title="Change Session Order"
                    className={styles.confirmModal}
                    headerClassName="backStageModalHeaderNew"
                    cancelButtonClassName={styles.cancelButton}
                    confirmButtonClassName={styles.constructiveButton}
                    trigger={({ setShow }) => (
                      <div
                        className={styles.saveChangesButton}
                        onClick={() => {
                          setShow(true);
                          setIsDryRunModalOpened(true);
                        }}
                      >
                        Save Changes
                      </div>
                    )}
                    handleConfirm={async ({ setShow }) => {
                      saveSortedList();
                      setShow(false);
                      setIsDryRunModalOpened(false);
                    }}
                    handleCancel={() => {
                      setIsDryRunModalOpened(false);
                      sortSessionsList();
                    }}
                    exitText="Are you sure you want to change the order of the sessions?"
                    confirmButtonText="Change Order"
                    cancelButtonText="Revert Changes"
                  />
                  <div
                    className={styles.undoButton}
                    onClick={() => sortSessionsList()}
                  >
                    Undo
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SessionSelectBar;
