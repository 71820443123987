export const getContainerWidth = (
  parentWidth: number,
  isMobile: boolean,
  streamsCount: number,
) => {
  if (isMobile) {
    // To prevent ultra-wide streams
    if (streamsCount === 3) {
      return 0.7 * parentWidth;
    }
    return parentWidth;
  }
  return 0.875 * parentWidth;
};

export const getContainerHeight = (
  parentHeight: number,
  isTablet: boolean,
  isMobile: boolean,
) => {
  if (isMobile) {
    return parentHeight;
  }
  if (isTablet) {
    return 0.875 * parentHeight;
  }
  return 0.9 * parentHeight;
};

export const getRowsAndColumns = (
  streamsCount: number,
  { maxStreams, maxStreamsOnTab, maxStreamsOnMob },
  isMobile: boolean,
  isTablet: boolean,
) => {
  let _streamsCount = streamsCount;

  if (isMobile && streamsCount > maxStreamsOnMob) {
    _streamsCount = maxStreamsOnMob;
  }
  if (isTablet && streamsCount > maxStreamsOnTab) {
    _streamsCount = maxStreamsOnTab;
  }
  if (!(isMobile || isTablet) && streamsCount > maxStreams) {
    _streamsCount = maxStreams;
  }

  switch (_streamsCount) {
    case 1:
      if (isMobile || isTablet) {
        return { rows: 1, cols: 1 };
      }
      return { rows: 1, cols: 1 };
    case 2:
      if (isMobile || isTablet) {
        return { rows: 2, cols: 1 };
      }
      return { rows: 1, cols: 2 };
    case 3:
      if (isMobile) {
        return { rows: 3, cols: 1 };
      }
      // Same for tablets and desktop
      return { rows: 2, cols: 2 };
    case 4:
      if (isMobile || isTablet) {
        return { rows: 2, cols: 2 };
      }
      return { rows: 2, cols: 2 };
    case 5:
      if (isMobile || isTablet) {
        return { rows: 3, cols: 2 };
      }
      return { rows: 2, cols: 3 };
    case 6:
      if (isMobile || isTablet) {
        return { rows: 3, cols: 2 };
      }
      return { rows: 2, cols: 3 };
    case 7:
      if (isMobile || isTablet) {
        return { rows: 4, cols: 2 };
      }
      return { rows: 3, cols: 3 };
    case 8:
      if (isMobile || isTablet) {
        return { rows: 4, cols: 2 };
      }
      return { rows: 3, cols: 3 };
    case 9:
      if (isMobile || isTablet) {
        // Does not apply
        return { rows: 4, cols: 4 };
      }
      return { rows: 3, cols: 3 };
    default:
      return { rows: 4, cols: 4 };
  }
};
