import React from 'react';
import classnames from 'classnames';
// components
import Label from '@/components/ui/content/Label';
// styles + types
import styles from './styles.module.scss';

const DropdownOption = props => {
  const {
    option: { label, onClick },
  } = props;

  const handleOnLinkClick = e => {
    // if there's no relative url don't do anything.
    if (onClick) {
      onClick(e);
      return;
    }
  };

  return (
    <div
      className={classnames(styles.dropdownOption, {
        [styles.dropdownOptionForDelete]: label === 'Delete',
      })}
      onClick={handleOnLinkClick}
      tabIndex={0}
    >
      <Label value={label} styleClass="" />
    </div>
  );
};

export default DropdownOption;
