import React, { useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { isTablet } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { getContainerWidth, getContainerHeight } from '../config';
import { getMaxStreamsPerPage } from './config';
import SharingView from './SharingView';
import styles from './styles.module.scss';
import DisplayedStreams from './DisplayedStreams';
import { useVideoMutation } from '@/services/video/video-context';

const SinglePageLayout = props => {
  const {
    streams,
    streamsCount,
    primaryStream,
    sharedStreamUid,
    isSharingViewEnabled,
    streamNamesMap,
    streamDetailsMap,
    parentWidth,
    parentHeight,
    parent,
    volumeOn,
    greenRoom,
    userMoveFns,
    localClientAVStatus,
    localClient,
    liveRaisedHandRequestList,
    handleStreamDoubleClick,
    channelSidePanelState,
  } = props;

  const videoMutation = useVideoMutation();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const streamCountToSlice = getMaxStreamsPerPage(
    isMobile,
    isSharingViewEnabled,
  );
  const displayedStreams = useMemo(() => streams.slice(0, streamCountToSlice), [
    streams,
    streamCountToSlice,
  ]);
  const hiddenStreams = useMemo(() => {
    if (streamsCount > streamCountToSlice) {
      return streams.slice(streamCountToSlice, streamsCount);
    }
    return undefined;
  }, [streams, streamsCount, streamCountToSlice]);

  const streamContainerDimsConfig = {
    height: getContainerHeight(parentHeight, isTablet, isMobile),
    width: getContainerWidth(parentWidth, isMobile, displayedStreams.length),
  };

  useEffect(() => {
    videoMutation.setPaginationConfig({
      perPage: streamCountToSlice,
    });
  }, [streamCountToSlice]);

  if (isSharingViewEnabled) {
    return (
      <SharingView
        {...props}
        streamContainerDims={streamContainerDimsConfig}
        sharedStream={sharedStreamUid ? primaryStream : undefined}
      />
    );
  }

  return (
    <>
      {!!displayedStreams.length && (
        <div
          className={classnames(styles.streamsWrapper, {
            [styles.streamsWrapperWithoutSidePanel]: !channelSidePanelState,
          })}
        >
          <DisplayedStreams
            displayedStreams={displayedStreams}
            streamContainerDims={streamContainerDimsConfig}
            hiddenStreams={hiddenStreams}
            parent={parent}
            volumeOn={volumeOn}
            greenRoom={greenRoom}
            userMoveFns={userMoveFns}
            streamNamesMap={streamNamesMap}
            streamDetailsMap={streamDetailsMap}
            localClient={localClient}
            localClientAVStatus={localClientAVStatus}
            liveRaisedHandRequestList={liveRaisedHandRequestList}
            handleStreamDoubleClick={handleStreamDoubleClick}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(SinglePageLayout);
