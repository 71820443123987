const getOneStreamLayoutDims = (parentWidth, parentHeight) => {
  const parentAspectRatio = parentWidth / parentHeight;

  if (parentAspectRatio > 16 / 9) {
    return [
      [
        {
          width: (parentHeight * 16) / 9,
          height: parentHeight,
          idx: 0,
        },
      ],
    ];
  }

  return [
    [
      {
        width: parentWidth,
        height: (parentWidth * 9) / 16,
        idx: 0,
      },
    ],
  ];
};

const getTwoStreamLayoutDims = (parentWidth, parentHeight) => {
  const parentAspectRatio = parentWidth / parentHeight;

  if (parentAspectRatio > 1.35) {
    // Show 2 streams side by side
    return [
      [
        {
          width: parentWidth / 2,
          height: ((parentWidth / 2) * 9) / 16,
          idx: 0,
        },
        {
          width: parentWidth / 2,
          height: ((parentWidth / 2) * 9) / 16,
          idx: 1,
        },
      ],
    ];
  }

  if (parentAspectRatio > 0.9) {
    return [
      [
        {
          width: ((parentHeight / 2) * 16) / 9,
          height: parentHeight / 2,
          idx: 0,
        },
      ],
      [
        {
          width: ((parentHeight / 2) * 16) / 9,
          height: parentHeight / 2,
          idx: 1,
        },
      ],
    ];
  }

  // Show 2 streams stacked
  return [
    [
      {
        width: parentWidth,
        height: (parentWidth * 9) / 16,
        idx: 0,
      },
    ],
    [
      {
        width: parentWidth,
        height: (parentWidth * 9) / 16,
        idx: 1,
      },
    ],
  ];
};

const getThreeStreamLayoutDims = (parentWidth, parentHeight) => {
  const parentAspectRatio = parentWidth / parentHeight;
  console.log('debugLayout > parentAspectRatio', parentAspectRatio);

  if (parentAspectRatio > 2.2) {
    // Show 3 streams side by side
    return [
      [
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 3) / 4,
          idx: 0,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 3) / 4,
          idx: 1,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 3) / 4,
          idx: 2,
        },
      ],
    ];
  }

  if (parentAspectRatio > 16 / 9) {
    // In between. TODO
    return [
      [
        {
          width: ((parentHeight / 2) * 16) / 9,
          height: parentHeight / 2,
          idx: 0,
        },
        {
          width: ((parentHeight / 2) * 16) / 9,
          height: parentHeight / 2,
          idx: 1,
        },
      ],
      [
        {
          width: ((parentHeight / 2) * 16) / 9,
          height: parentHeight / 2,
          idx: 2,
        },
      ],
    ];
  }

  if (parentAspectRatio >= 0.96) {
    // In between. TODO
    return [
      [
        {
          width: parentWidth / 2,
          height: ((parentWidth / 2) * 9) / 16,
          idx: 0,
        },
        {
          width: parentWidth / 2,
          height: ((parentWidth / 2) * 9) / 16,
          idx: 1,
        },
      ],
      [
        {
          width: parentWidth / 2,
          height: ((parentWidth / 2) * 9) / 16,
          idx: 2,
        },
      ],
    ];
  }

  // Show 3 streams stacked
  if (parentAspectRatio > 9 / 16) {
    return [
      [
        {
          width: ((parentHeight / 3) * 16) / 9,
          height: parentHeight / 3,
          idx: 0,
        },
      ],
      [
        {
          width: ((parentHeight / 3) * 16) / 9,
          height: parentHeight / 3,
          idx: 1,
        },
      ],
      [
        {
          width: ((parentHeight / 3) * 16) / 9,
          height: parentHeight / 3,
          idx: 2,
        },
      ],
    ];
  }

  // Show 3 streams stacked
  return [
    [
      {
        width: parentWidth,
        height: (parentWidth * 9) / 16,
        idx: 0,
      },
    ],
    [
      {
        width: parentWidth,
        height: (parentWidth * 9) / 16,
        idx: 1,
      },
    ],
    [
      {
        width: parentWidth,
        height: (parentWidth * 9) / 16,
        idx: 2,
      },
    ],
  ];
};

const getFourStreamLayoutDims = (parentWidth, parentHeight) => {
  const parentAspectRatio = parentWidth / parentHeight;
  console.log('debugLayout > parentAspectRatio', parentAspectRatio);

  if (parentAspectRatio > 2.2) {
    // Show 3 streams side by side
    return [
      [
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 3) / 4,
          idx: 0,
        },
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 3) / 4,
          idx: 1,
        },
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 3) / 4,
          idx: 2,
        },
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 3) / 4,
          idx: 3,
        },
      ],
    ];
  }

  if (parentAspectRatio > 16 / 9) {
    // In between. TODO
    return [
      [
        {
          width: ((parentHeight / 2) * 16) / 9,
          height: parentHeight / 2,
          idx: 0,
        },
        {
          width: ((parentHeight / 2) * 16) / 9,
          height: parentHeight / 2,
          idx: 1,
        },
      ],
      [
        {
          width: ((parentHeight / 2) * 16) / 9,
          height: parentHeight / 2,
          idx: 2,
        },
        {
          width: ((parentHeight / 2) * 16) / 9,
          height: parentHeight / 2,
          idx: 3,
        },
      ],
    ];
  }

  if (parentAspectRatio >= 0.96) {
    // In between. TODO
    return [
      [
        {
          width: parentWidth / 2,
          height: ((parentWidth / 2) * 9) / 16,
          idx: 0,
        },
        {
          width: parentWidth / 2,
          height: ((parentWidth / 2) * 9) / 16,
          idx: 1,
        },
      ],
      [
        {
          width: parentWidth / 2,
          height: ((parentWidth / 2) * 9) / 16,
          idx: 2,
        },
        {
          width: parentWidth / 2,
          height: ((parentWidth / 2) * 9) / 16,
          idx: 3,
        },
      ],
    ];
  }

  // Show 4 streams stacked
  return [
    [
      {
        width: ((parentHeight / 4) * 16) / 9,
        height: parentHeight / 4,
        idx: 0,
      },
    ],
    [
      {
        width: ((parentHeight / 4) * 16) / 9,
        height: parentHeight / 4,
        idx: 1,
      },
    ],
    [
      {
        width: ((parentHeight / 4) * 16) / 9,
        height: parentHeight / 4,
        idx: 2,
      },
    ],
    [
      {
        width: ((parentHeight / 4) * 16) / 9,
        height: parentHeight / 4,
        idx: 3,
      },
    ],
  ];
};

const getFiveStreamLayoutDims = (parentWidth, parentHeight) => {
  const parentAspectRatio = parentWidth / parentHeight;
  console.log('debugLayout > parentAspectRatio', parentAspectRatio);

  if (parentAspectRatio > 3.1) {
    // Show 5 streams side by side
    return [
      [
        {
          width: parentWidth / 5,
          height: ((parentWidth / 5) * 3) / 4,
          idx: 0,
        },
        {
          width: parentWidth / 5,
          height: ((parentWidth / 5) * 3) / 4,
          idx: 1,
        },
        {
          width: parentWidth / 5,
          height: ((parentWidth / 5) * 3) / 4,
          idx: 2,
        },
        {
          width: parentWidth / 5,
          height: ((parentWidth / 5) * 3) / 4,
          idx: 3,
        },
        {
          width: parentWidth / 5,
          height: ((parentWidth / 5) * 3) / 4,
          idx: 4,
        },
      ],
    ];
  }

  if (parentAspectRatio > 1.4) {
    return [
      [
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 0,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 1,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 2,
        },
      ],
      [
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 3,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 4,
        },
      ],
    ];
  }

  if (parentAspectRatio > 1) {
    return [
      [
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 0,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 1,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 2,
        },
      ],
      [
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 3,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 4,
        },
      ],
    ];
  }

  return [
    [
      {
        width: parentWidth / 2,
        height: ((parentWidth / 2) * 3) / 4,
        idx: 0,
      },
      {
        width: parentWidth / 2,
        height: ((parentWidth / 2) * 3) / 4,
        idx: 1,
      },
    ],
    [
      {
        width: parentWidth / 2,
        height: ((parentWidth / 2) * 3) / 4,
        idx: 2,
      },
      {
        width: parentWidth / 2,
        height: ((parentWidth / 2) * 3) / 4,
        idx: 3,
      },
    ],
    [
      {
        width: parentWidth / 2,
        height: ((parentWidth / 2) * 3) / 4,
        idx: 4,
      },
    ],
  ];
};

const getSixStreamLayoutDims = (parentWidth, parentHeight) => {
  const parentAspectRatio = parentWidth / parentHeight;
  console.log('debugLayout > parentAspectRatio', parentAspectRatio);

  if (parentAspectRatio > 3.1) {
    // Show 5 streams side by side
    return [
      [
        {
          width: parentWidth / 6,
          height: ((parentWidth / 6) * 3) / 4,
          idx: 0,
        },
        {
          width: parentWidth / 6,
          height: ((parentWidth / 6) * 3) / 4,
          idx: 1,
        },
        {
          width: parentWidth / 6,
          height: ((parentWidth / 6) * 3) / 4,
          idx: 2,
        },
        {
          width: parentWidth / 6,
          height: ((parentWidth / 6) * 3) / 4,
          idx: 3,
        },
        {
          width: parentWidth / 6,
          height: ((parentWidth / 6) * 3) / 4,
          idx: 4,
        },
        {
          width: parentWidth / 6,
          height: ((parentWidth / 6) * 3) / 4,
          idx: 5,
        },
      ],
    ];
  }

  if (parentAspectRatio > 1.4) {
    return [
      [
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 0,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 1,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 2,
        },
      ],
      [
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 3,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 4,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 5,
        },
      ],
    ];
  }

  if (parentAspectRatio > 1) {
    return [
      [
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 0,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 1,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 2,
        },
      ],
      [
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 3,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 4,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 5,
        },
      ],
    ];
  }

  return [
    [
      {
        width: parentWidth / 2,
        height: ((parentWidth / 2) * 3) / 4,
        idx: 0,
      },
      {
        width: parentWidth / 2,
        height: ((parentWidth / 2) * 3) / 4,
        idx: 1,
      },
    ],
    [
      {
        width: parentWidth / 2,
        height: ((parentWidth / 2) * 3) / 4,
        idx: 2,
      },
      {
        width: parentWidth / 2,
        height: ((parentWidth / 2) * 3) / 4,
        idx: 3,
      },
    ],
    [
      {
        width: parentWidth / 2,
        height: ((parentWidth / 2) * 3) / 4,
        idx: 4,
      },
      {
        width: parentWidth / 2,
        height: ((parentWidth / 2) * 3) / 4,
        idx: 5,
      },
    ],
  ];
};

const getSevenStreamLayoutDims = (parentWidth, parentHeight) => {
  const parentAspectRatio = parentWidth / parentHeight;
  console.log('debugLayout > parentAspectRatio', parentAspectRatio);

  if (parentAspectRatio > 4.5) {
    // Show 5 streams side by side
    return [
      [
        {
          width: parentWidth / 7,
          height: ((parentWidth / 7) * 3) / 4,
          idx: 0,
        },
        {
          width: parentWidth / 7,
          height: ((parentWidth / 7) * 3) / 4,
          idx: 1,
        },
        {
          width: parentWidth / 7,
          height: ((parentWidth / 7) * 3) / 4,
          idx: 2,
        },
        {
          width: parentWidth / 7,
          height: ((parentWidth / 7) * 3) / 4,
          idx: 3,
        },
        {
          width: parentWidth / 7,
          height: ((parentWidth / 7) * 3) / 4,
          idx: 4,
        },
        {
          width: parentWidth / 7,
          height: ((parentWidth / 7) * 3) / 4,
          idx: 5,
        },
        {
          width: parentWidth / 7,
          height: ((parentWidth / 7) * 3) / 4,
          idx: 6,
        },
      ],
    ];
  }

  if (parentAspectRatio > 2) {
    return [
      [
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 9) / 16,
          idx: 0,
        },
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 9) / 16,
          idx: 1,
        },
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 9) / 16,
          idx: 2,
        },
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 9) / 16,
          idx: 3,
        },
      ],
      [
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 9) / 16,
          idx: 4,
        },
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 9) / 16,
          idx: 5,
        },
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 9) / 16,
          idx: 6,
        },
      ],
    ];
  }

  if (parentAspectRatio > 1) {
    return [
      [
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 0,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 1,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 2,
        },
      ],
      [
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 3,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 4,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 5,
        },
      ],
      [
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 6,
        },
      ],
    ];
  }

  return [
    [
      {
        width: parentWidth / 2,
        height: parentHeight / 4,
        idx: 0,
      },
      {
        width: parentWidth / 2,
        height: parentHeight / 4,
        idx: 1,
      },
    ],
    [
      {
        width: parentWidth / 2,
        height: parentHeight / 4,
        idx: 2,
      },
      {
        width: parentWidth / 2,
        height: parentHeight / 4,
        idx: 3,
      },
    ],
    [
      {
        width: parentWidth / 2,
        height: parentHeight / 4,
        idx: 4,
      },
      {
        width: parentWidth / 2,
        height: parentHeight / 4,
        idx: 5,
      },
    ],
    [
      {
        width: parentWidth / 2,
        height: parentHeight / 4,
        idx: 6,
      },
    ],
  ];
};

const getEightStreamLayoutDims = (parentWidth, parentHeight) => {
  const parentAspectRatio = parentWidth / parentHeight;
  console.log('debugLayout > parentAspectRatio', parentAspectRatio);

  if (parentAspectRatio > 5.9) {
    // Show 5 streams side by side
    return [
      [
        {
          width: parentWidth / 8,
          height: ((parentWidth / 8) * 3) / 4,
          idx: 0,
        },
        {
          width: parentWidth / 8,
          height: ((parentWidth / 8) * 3) / 4,
          idx: 1,
        },
        {
          width: parentWidth / 8,
          height: ((parentWidth / 8) * 3) / 4,
          idx: 2,
        },
        {
          width: parentWidth / 8,
          height: ((parentWidth / 8) * 3) / 4,
          idx: 3,
        },
        {
          width: parentWidth / 8,
          height: ((parentWidth / 8) * 3) / 4,
          idx: 4,
        },
        {
          width: parentWidth / 8,
          height: ((parentWidth / 8) * 3) / 4,
          idx: 5,
        },
        {
          width: parentWidth / 8,
          height: ((parentWidth / 8) * 3) / 4,
          idx: 6,
        },
        {
          width: parentWidth / 8,
          height: ((parentWidth / 8) * 3) / 4,
          idx: 7,
        },
      ],
    ];
  }

  if (parentAspectRatio > 4.2) {
    return [
      [
        {
          width: ((parentHeight / 2) * 16) / 9,
          height: parentHeight / 2,
          idx: 0,
        },
        {
          width: ((parentHeight / 2) * 16) / 9,
          height: parentHeight / 2,
          idx: 1,
        },
        {
          width: ((parentHeight / 2) * 16) / 9,
          height: parentHeight / 2,
          idx: 2,
        },
        {
          width: ((parentHeight / 2) * 16) / 9,
          height: parentHeight / 2,
          idx: 3,
        },
      ],
      [
        {
          width: ((parentHeight / 2) * 16) / 9,
          height: parentHeight / 2,
          idx: 4,
        },
        {
          width: ((parentHeight / 2) * 16) / 9,
          height: parentHeight / 2,
          idx: 5,
        },
        {
          width: ((parentHeight / 2) * 16) / 9,
          height: parentHeight / 2,
          idx: 6,
        },
        {
          width: ((parentHeight / 2) * 16) / 9,
          height: parentHeight / 2,
          idx: 7,
        },
      ],
    ];
  }

  if (parentAspectRatio > 2.3) {
    return [
      [
        {
          width: ((parentHeight / 2) * 4) / 3,
          height: parentHeight / 2,
          idx: 0,
        },
        {
          width: ((parentHeight / 2) * 4) / 3,
          height: parentHeight / 2,
          idx: 1,
        },
        {
          width: ((parentHeight / 2) * 4) / 3,
          height: parentHeight / 2,
          idx: 2,
        },
        {
          width: ((parentHeight / 2) * 4) / 3,
          height: parentHeight / 2,
          idx: 3,
        },
      ],
      [
        {
          width: ((parentHeight / 2) * 4) / 3,
          height: parentHeight / 2,
          idx: 4,
        },
        {
          width: ((parentHeight / 2) * 4) / 3,
          height: parentHeight / 2,
          idx: 5,
        },
        {
          width: ((parentHeight / 2) * 4) / 3,
          height: parentHeight / 2,
          idx: 6,
        },
        {
          width: ((parentHeight / 2) * 4) / 3,
          height: parentHeight / 2,
          idx: 7,
        },
      ],
    ];
  }

  if (parentAspectRatio > 2) {
    return [
      [
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 9) / 16,
          idx: 0,
        },
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 9) / 16,
          idx: 1,
        },
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 9) / 16,
          idx: 2,
        },
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 9) / 16,
          idx: 3,
        },
      ],
      [
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 9) / 16,
          idx: 4,
        },
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 9) / 16,
          idx: 5,
        },
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 9) / 16,
          idx: 6,
        },
        {
          width: parentWidth / 4,
          height: ((parentWidth / 4) * 9) / 16,
          idx: 7,
        },
      ],
    ];
  }

  if (parentAspectRatio > 1.3) {
    return [
      [
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 0,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 1,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 2,
        },
      ],
      [
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 3,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 4,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 5,
        },
      ],
      [
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 6,
        },
        {
          width: parentWidth / 3,
          height: ((parentWidth / 3) * 9) / 16,
          idx: 7,
        },
      ],
    ];
  }

  if (parentAspectRatio > 1.15) {
    return [
      [
        {
          width: ((parentHeight / 3) * 4) / 3,
          height: parentHeight / 3,
          idx: 0,
        },
        {
          width: ((parentHeight / 3) * 4) / 3,
          height: parentHeight / 3,
          idx: 1,
        },
        {
          width: ((parentHeight / 3) * 4) / 3,
          height: parentHeight / 3,
          idx: 2,
        },
      ],
      [
        {
          width: ((parentHeight / 3) * 4) / 3,
          height: parentHeight / 3,
          idx: 3,
        },
        {
          width: ((parentHeight / 3) * 4) / 3,
          height: parentHeight / 3,
          idx: 4,
        },
        {
          width: ((parentHeight / 3) * 4) / 3,
          height: parentHeight / 3,
          idx: 5,
        },
      ],
      [
        {
          width: ((parentHeight / 3) * 4) / 3,
          height: parentHeight / 3,
          idx: 6,
        },
        {
          width: ((parentHeight / 3) * 4) / 3,
          height: parentHeight / 3,
          idx: 7,
        },
      ],
    ];
  }

  return [
    [
      {
        width: parentWidth / 2,
        height: parentHeight / 4,
        idx: 0,
      },
      {
        width: parentWidth / 2,
        height: parentHeight / 4,
        idx: 1,
      },
    ],
    [
      {
        width: parentWidth / 2,
        height: parentHeight / 4,
        idx: 2,
      },
      {
        width: parentWidth / 2,
        height: parentHeight / 4,
        idx: 3,
      },
    ],
    [
      {
        width: parentWidth / 2,
        height: parentHeight / 4,
        idx: 4,
      },
      {
        width: parentWidth / 2,
        height: parentHeight / 4,
        idx: 5,
      },
    ],
    [
      {
        width: parentWidth / 2,
        height: parentHeight / 4,
        idx: 6,
      },
      {
        width: parentWidth / 2,
        height: parentHeight / 4,
        idx: 7,
      },
    ],
  ];
};

export const getLayoutConfig = (
  parentWidth,
  parentHeight,
  numStreams,
  isContentShared?,
) => {
  if (!numStreams) {
    return [];
  }
  if (numStreams === 1) {
    return getOneStreamLayoutDims(parentWidth, parentHeight);
  }
  if (numStreams === 2) {
    return getTwoStreamLayoutDims(parentWidth, parentHeight, isContentShared);
  }
  if (numStreams === 3) {
    return getThreeStreamLayoutDims(parentWidth, parentHeight, isContentShared);
  }
  if (numStreams === 4) {
    return getFourStreamLayoutDims(parentWidth, parentHeight, isContentShared);
  }
  if (numStreams === 5) {
    return getFiveStreamLayoutDims(parentWidth, parentHeight);
  }
  if (numStreams === 6) {
    return getSixStreamLayoutDims(parentWidth, parentHeight);
  }
  if (numStreams === 7) {
    return getSevenStreamLayoutDims(parentWidth, parentHeight);
  }
  if (numStreams === 8) {
    return getEightStreamLayoutDims(parentWidth, parentHeight);
  }

  return [];
};
