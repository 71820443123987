import React, {
  useRef,
  useState,
  useLayoutEffect,
  useEffect,
  useMemo,
} from 'react';
import classnames from 'classnames';
// components
import StreamsDisplay from './StreamsDisplay';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import configByEvent from '@/custom-config';
// styles + types
import {
  IPublishedStreamDisplayProps,
  IVideoFrameTypes,
  IScreenCalcOrientationOptions,
  IStreamsDisplayMode,
} from './types';
import styles from './styles.module.scss';
import {
  IRaisedHandRequestStatus,
  IStageParent,
} from '@/components/Stage/types';
// helpers
import { getOriginalParentDims } from './utils';
import { selectIsStageFull, selectRecordingMode } from '@/models/global';
import { useSelector } from 'react-redux';
import {
  makeSelectCurrentlyPlayingVideoForChannel,
  makeSelectIsOrganizer,
  makeSelectCurrentOverlayForChannel,
  makeSelectCurrentLiveStreamForChannel,
  makeSelectEventStageIsBroadcasting,
  makeSelectFeatureFlagConfigById,
  makeSelectIsHost,
  makeSelectIsAttendee,
  makeSelectStageLayoutByStageId,
} from '@/models/event';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import StreamEmptyState from './StreamEmptyState';
import { _isEmpty } from '@/utils/jsHelpers/array';
import Overlay from './Overlay';
import RedirectOverlay from './RedirectOverlay';
import FullScreenVideo from '../Stage/FullScreenVideo';
import LiveStream from '../Stage/LiveStream';
import { IUIIcons } from '@/types';
import IconButton, { IIconButtonTypes } from '../ui/buttons/IconButton';
import ReactionOverlay from '../ReactionOverlay';
import { isRockCentralDomain } from '@/utils/custom';
import Video from '../ui/media/Video';
import useBreakoutAttendeeChannel from '@/services/messaging/use-breakout-attendee-channel';
import {
  CURRENT_COMPONENT_TYPES,
  StreamContainerContext,
} from '@/services/stream-container-context';
import { useMediaQuery } from 'react-responsive';
import { useLatest, usePrevious } from 'react-use';
import { isBoolean } from 'lodash';
import FloatingToast from '../FloatingToast';
import { useChannelContext } from '@/services/video/channel-context/channel-context';

const {
  NEW_VOLUME_OFF,
  NEW_VOLUME_ON,
  BOOTH_VOLUME_OFF,
  BOOTH_VOLUME_ON,
} = IUIIcons;

const PublishedStreamDisplay = (props: IPublishedStreamDisplayProps) => {
  const {
    greenRoomStreams,
    onBroadcastButtonClick,
    parent,
    parentHeight,
    parentWidth,
    primaryScreenOrientation,
    primaryScreenOrientationSize,
    timeLeftMs,
    streams,
    broadcastPermissions,
    fillerImageUrl,
    localClient,
    localStream,
    setBroadcastPermissions,
    channelConfig,
    attendeeChannel,
    presentationConfig,
    volumeOn,
    toggleVolume,
    showVolumeToggle,
    refId,
    raisedHandRequestList,
    eventRole,
    currentUser,
    myRaisedHandStatus,
    dialInStreams,
    streamsDisplayMode,
  } = props;
  const { floatingToast } = useChannelContext();

  const isRecordingMode = useSelector(selectRecordingMode);
  const { eventId = '', stageId = '' } = useParams() as any;
  const history = useHistory();
  const location = useLocation();
  const isBooth = location.pathname.includes('booth');
  let { useOldLobbyStageLayout, disableBoothScroll } = useMemoizedSelector(
    makeSelectFeatureFlagConfigById,
    eventId,
  );
  //For booths, disableBoothScroll takes precedence if it is set.
  if ( isBooth && isBoolean(disableBoothScroll) ){
    useOldLobbyStageLayout = disableBoothScroll;
  }

  const { currentComponent } = React.useContext(StreamContainerContext);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const isStageFull = useSelector(selectIsStageFull);
  const isBackstage = parent === IStageParent.LIVE_BACKSTAGE;
  const containerRef = useRef(null) as any;

  const isOrganizer = useMemoizedSelector(makeSelectIsOrganizer, eventId);
  const isHost = useMemoizedSelector(makeSelectIsHost, eventId);
  const isAttendee = useMemoizedSelector(makeSelectIsAttendee, eventId);

  const isStage = parent === IStageParent.LIVE_STAGE;
  const isStagePreview = parent === IStageParent.STAGE_PREVIEW;

  const currentOverlay = useMemoizedSelector(
    makeSelectCurrentOverlayForChannel,
    channelConfig && channelConfig.channelId,
  );
  const prevCurrentOverlayValue = usePrevious(currentOverlay);
  const currentlyPlayingVideo = useMemoizedSelector(
    makeSelectCurrentlyPlayingVideoForChannel,
    channelConfig && channelConfig.channelId,
  );
  const prevCurrentlyPlayingVideoValue = usePrevious(currentlyPlayingVideo);
  const liveStream = useMemoizedSelector(
    makeSelectCurrentLiveStreamForChannel,
    channelConfig && channelConfig.channelId,
  );

  // below variable for shows the image overlay, video publish stream (media tab) & live stream.
  const showOverlayViews = ![
    IStageParent.CONTRIBUTOR_SLOT,
    IStageParent.CONTRIBUTOR_SLOT_AUDIO,
    IStageParent.AUDIENCE_GALLERY,
  ].includes(parent);

  const currentLiveStream = useMemo(() => {
    if (showOverlayViews) {
      return liveStream;
    }
    return null;
  }, [showOverlayViews, liveStream]);

  const isBroadcasting = useMemoizedSelector(
    makeSelectEventStageIsBroadcasting,
    {
      eventId,
      stageId: parent === IStageParent.STAGE_PREVIEW ? refId : stageId,
    },
  );

  const showVideoOverlay = useMemo(() => {
    if (!currentlyPlayingVideo || !showOverlayViews) {
      return false;
    }
    if (currentlyPlayingVideo?.isFillerVideo) {
      return true;
    }
    return isBroadcasting || isBackstage;
  }, [isBroadcasting, isBackstage, currentlyPlayingVideo, showOverlayViews]);

  const liveRaisedHandRequestList = useMemo(
    () =>
      raisedHandRequestList?.filter(
        (req: any) => req.status === IRaisedHandRequestStatus.LIVE,
      ) || [],
    [raisedHandRequestList],
  );

  const hasGreenRoomStreams = greenRoomStreams && greenRoomStreams.length > 0;
  const isStageEmpty = _isEmpty(streams) && !currentLiveStream;
  const isStageFromBackstage = isBackstage && !hasGreenRoomStreams;

  const initialState = getOriginalParentDims({
    hasGreenRoomStreams,
    isGreenRoomStream: false,
    parentHeight: parentHeight,
    parentWidth: parentWidth,
    primaryScreenOrientation,
    primaryScreenOrientationSize,
    parent,
    isRecordingMode,
  });

  const initialGreenRoom = hasGreenRoomStreams
    ? getOriginalParentDims({
      hasGreenRoomStreams,
      isGreenRoomStream: true,
      parentHeight,
      parentWidth,
      primaryScreenOrientation,
      primaryScreenOrientationSize,
      parent,
      isRecordingMode,
    })
    : {};

  if (hasGreenRoomStreams) {
    initialGreenRoom.width = initialState.width;
  }

  const [containerDims, setContainerDims] = useState(initialState) as any;
  const [greenRoomDims, setGreenRoomDims] = useState(initialGreenRoom) as any;
  const [showLiveIndicator, setShowLiveIndicator] = useState(false);
  const liveIndicatorValue = useLatest(showLiveIndicator);
  const isStageOrBackstage =
    (parent === IStageParent.LIVE_STAGE || IStageParent.LIVE_BACKSTAGE) &&
    parent !== IStageParent.STAGE_PREVIEW;

  useLayoutEffect(() => {
    let dims;
    if (isStageFull) {
      if (hasGreenRoomStreams) {
        dims = {
          height: parentHeight * 0.75,
          width: parentWidth,
        };
      } else {
        dims = {
          height: parentHeight,
          width: parentWidth,
        };
      }
    } else if (
      (streamsDisplayMode === IStreamsDisplayMode.PRESENTATION ||
        streamsDisplayMode === IStreamsDisplayMode.PRESENTATION_V2) &&
      parent === IStageParent.LIVE_DISCUSSION
    ) {
      dims = {
        height: parentHeight,
        width: parentWidth,
      };
    } else {
      dims = getOriginalParentDims({
        hasGreenRoomStreams,
        isGreenRoomStream: false,
        parentHeight,
        parentWidth,
        primaryScreenOrientation: IScreenCalcOrientationOptions.HEIGHT,
        primaryScreenOrientationSize,
        parent,
        useBlurLayout: useOldLobbyStageLayout || isRecordingMode,
      });
    }
    setContainerDims(dims);

    const grDims = getOriginalParentDims({
      hasGreenRoomStreams,
      isGreenRoomStream: true,
      parentHeight,
      parentWidth,
      primaryScreenOrientation: IScreenCalcOrientationOptions.HEIGHT,
      primaryScreenOrientationSize,
      parent,
      useBlurLayout: useOldLobbyStageLayout || isRecordingMode,
    });

    if (hasGreenRoomStreams) {
      grDims.width = dims.width;
    }
    setGreenRoomDims(grDims);
  }, [
    parentHeight,
    parentWidth,
    hasGreenRoomStreams,
    isStageFull,
    useOldLobbyStageLayout,
    parent,
    streamsDisplayMode,
  ]);

  const stageLayoutByStageId = useMemoizedSelector(
    makeSelectStageLayoutByStageId,
    stageId,
  );

  const timerRef = useRef(undefined) as any;
  const hasShownLiveIndicatorOnStageRef = useRef(false);
  const hasShownLiveIndicatorOnBackstageRef = useRef(false);

  const isUserOnStage = useMemo(
    () => streams.some(s => s?.uid === localStream?.uid),
    [localStream, streams],
  );

  const liveIndicatorContent = isUserOnStage
    ? 'You are live'
    : 'The session is now live';

  const hasLocalClientJoined =
    localClient.getIsMainClientHost() || localClient.getIsGreenroomClientHost();

  const isAttendeeLiveOnStage =
    myRaisedHandStatus === IRaisedHandRequestStatus.LIVE;

  useEffect(() => {
    if (!isBroadcasting) {
      setShowLiveIndicator(false);
      // Check if previous indicator is already showing
      // and the user has joined the backstage
    } else if (currentOverlay || currentlyPlayingVideo) {
      setShowLiveIndicator(false);
      clearTimeout(timerRef?.current);
      // Don't show the indicator if a overlay or video was played before
      // eslint-disable-next-line no-empty
    } else if (prevCurrentOverlayValue || prevCurrentlyPlayingVideoValue) {
      // return
    } else if (
      prevCurrentlyPlayingVideoValue === undefined &&
      currentlyPlayingVideo === ''
    ) {
      // return, they video was played before and now set to empty
    } else if (
      (isHost || isAttendeeLiveOnStage) &&
      !liveIndicatorValue.current &&
      hasLocalClientJoined
    ) {
      // Don't show the indicator for the user who has been on stage
      // Because, he would've already seen it. i.e user comes from stage to backstage
      if (isUserOnStage) {
        hasShownLiveIndicatorOnStageRef.current = true;
        hasShownLiveIndicatorOnBackstageRef.current = true;
      } else if (
        hasShownLiveIndicatorOnStageRef.current ||
        hasShownLiveIndicatorOnBackstageRef.current
      ) {
        hasShownLiveIndicatorOnStageRef.current = false;
        hasShownLiveIndicatorOnBackstageRef.current = false;
        return;
      }

      setShowLiveIndicator(true);

      timerRef.current = setTimeout(() => {
        setShowLiveIndicator(false);
      }, 5000);

      // eslint-disable-next-line consistent-return
      return () => {
        setShowLiveIndicator(false);
        clearTimeout(timerRef.current);
      };
    } else if (liveIndicatorValue.current && isUserOnStage) {
      hasShownLiveIndicatorOnBackstageRef.current = true;
      setShowLiveIndicator(false);
    }
  }, [
    isBroadcasting,
    isHost,
    isUserOnStage,
    currentOverlay,
    currentlyPlayingVideo,
    hasLocalClientJoined,
    isAttendeeLiveOnStage,
  ]);

  // On UnMount
  useEffect(() => () => clearTimeout(timerRef.current), []);

  const config = (eventId && configByEvent[eventId]) || configByEvent.default;
  const stageBG = (() => {
    if (stageLayoutByStageId && stageLayoutByStageId.bgUrl) {
      return stageLayoutByStageId.bgUrl;
    }

    if (config && config.stageBG) {
      return config.stageBG;
    }

    return null;
  })();

  const isDiscussion = parent === IStageParent.LIVE_DISCUSSION;

  // Breakout Room functionality.
  const {
    redirectType,
    redirectTo,
    setRedirectType,
  } = useBreakoutAttendeeChannel({
    attendeeChannel,
    parent,
  });

  const redirect = () => {
    if (!redirectTo || redirectTo.length === 0) return;
    setRedirectType(null);
    if (redirectTo.includes('studio/')) {
      window.location.assign(redirectTo);
      return;
    }
    history.push(redirectTo);
  };
  // const { isPreview, shouldMockSpeakers } = useIsPreviewView();
  const backgroundStyles = (() => {
    if (!useOldLobbyStageLayout && !isRecordingMode) {
      return {
        height: parentHeight,
        width: '100%',
        // backgroundSize: 'cover',
        // backgroundColor:
        //   isPreview && shouldMockSpeakers
        //     ? 'transparent'
        //     : getCssVar(IContentColors.NEUTRAL_BASE_1),
      };
    }

    if (stageBG) {
      return {
        height: parentHeight,
        width: '100%',
        // backgroundSize: 'cover',
        // background: `url('${stageBG}?auto=format,compress&q=75&sharp=10&blur=80') center center / cover no-repeat fixed`,
      };
    }

    return {
      height: parentHeight,
      width: parentWidth,
    };
  })();

  const rockCentralConfig = {
    '2a4229dc-38a7-4506-bed9-f40f34249d6e': {
      iframeLink: 'https://sso-player.streams.foc.zone/stream/techcon1',
      cdnLink: 'https://eclive.streams.foc.zone/out/v1/de9230f2763048d49bae21c75516bb10/index.m3u8',
    },
    '5e4ff825-78cc-44f1-9970-efb51d6417ee': {
      iframeLink: 'https://sso-player.streams.foc.zone/stream/techcon1',
      cdnLink: 'https://eclive.streams.foc.zone/out/v1/de9230f2763048d49bae21c75516bb10/index.m3u8',
    },
    '0223575d-58dc-4ede-b55b-da3c7f542778': {
      iframeLink: 'https://sso-player.streams.foc.zone/stream/techcon2',
      cdnLink: 'https://eclive2.streams.foc.zone/out/v1/0d864513b653484f9791ec3020d15df7/index.m3u8',
    },
    'a90d8a62-c6d2-4296-ab16-8ac5ca074427': {
      iframeLink: 'https://sso-player.streams.foc.zone/stream/techcon3',
      cdnLink: 'https://eclive3.streams.foc.zone/out/v1/0e3ef05e13ef4a0988bd0ca47d53c02a/index.m3u8',
    },
    'e2d82314-5996-4e99-a8bd-6892f48da0df': {
      iframeLink: 'https://sso-player.streams.foc.zone/stream/techcon4',
      cdnLink: 'https://eclive4.streams.foc.zone/out/v1/2bf6acab2880496fa5360a6c8f4f7397/index.m3u8',
    },
    '755f64cd-80de-43f9-b50c-f8f4a993aef5': {
      iframeLink: 'https://sso-player.streams.foc.zone/stream/techcon5',
      cdnLink: 'https://eclive5.streams.foc.zone/out/v1/978c0281bd5c4bc4a2a09019592be57d/index.m3u8',
    },
    '75ea2c12-0266-4f76-aaef-8c08a824b6a6': {
      iframeLink: 'https://sso-player.streams.foc.zone/stream/techcon6',
      cdnLink: 'https://eclive6.streams.foc.zone/out/v1/23c06c1cf3b448d59bc203efa4889ffd/index.m3u8',
    },
    '4d7df0dc-fe7e-4263-9f5b-db3d119efc0b': {
      iframeLink: 'https://sso-player.streams.foc.zone/stream/techcon10',
      cdnLink: 'https://eclive10.streams.foc.zone/out/v1/9391c47507b34e8d9f3b3244c6eb7449/index.m3u8',
    },
    '2c84af5a-dcd5-46a2-813b-c02c7056d2f2': {
      iframeLink: 'https://sso-player.streams.foc.zone/stream/techcon9',
      cdnLink: 'https://eclive9.streams.foc.zone/out/v1/b048717c7c5d426cbc04b7af860ead3f/index.m3u8',
    },
    '2e4c219e-e94b-4e1d-958c-9f2a5373fcc5': {
      iframeLink: 'https://sso-player.streams.foc.zone/stream/techcon8',
      cdnLink: 'https://eclive8.streams.foc.zone/out/v1/faddbc85f0a04586bd9f9d9896586e45/index.m3u8',
    },
    'd8d9226d-dc07-428f-90c8-1f4277cf25fd': {
      iframeLink: 'https://sso-player.streams.foc.zone/stream/techcon7',
      cdnLink: 'https://eclive7.streams.foc.zone/out/v1/7b13f313669b47dab1b9b7c0c2508b45/index.m3u8',
    },
  };

  // TODO: Remove custom stuff for Quicken
  // if (refId === '4d7df0dc-fe7e-4263-9f5b-db3d119efc0b') {
  const rockCentralLinks = rockCentralConfig[refId];
  if (rockCentralLinks) {
    if (eventRole.ticketTypeId === '00b3ae1b-da13-4406-8af6-73b8df89da42' || isRockCentralDomain(currentUser.email)) {
      return (
        <div
          className={styles.publishedStreamDisplayContainer}
          ref={containerRef}
          style={backgroundStyles}
        >
          <iframe
            style={{
              width: '100%',
              height: '100%',
            }}
            src={`${rockCentralLinks.iframeLink}?login=true&referralUrl=https%3A%2F%2Fapp.zuddl.com%2Fl%2Fevent%2F651413b5-187f-45ef-bf62-c27bffed09d4%2Fstages%2F${refId}`}>
          </iframe>
        </div>
      );
    }

    return (
      <div
        className={styles.publishedStreamDisplayContainer}
        ref={containerRef}
        style={backgroundStyles}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 9,
          }}
        >
          <Video
            className={styles.videoZ}
            autoplay={true}
            playsinline={true}
            showLoader={true}
            url={rockCentralLinks.cdnLink}
            controls={true}
            loop={false}
            muted={false}
            showAutoplayOverlay={true}
          />
        </div>
      </div>
    );
  }

  const isEmbeddedRtmp = [
    '10792665-bb24-4842-888e-be377de18bd0',
    '22479123-9f70-4c33-83e2-c80b648c53fa',
    '13bdca58-9ade-4cee-8c10-4617e7f10760',
    '8a948601-f6ff-4c61-94c3-14c5b3a3a53e',
    '7345200c-8daf-4d47-a875-b24fe691f91c',
    '4291a2f9-5188-4e6f-bca0-a151ff670c96',
    'f81cb825-85b1-4b50-bacd-e8ca2bc2e8ca',
  ].includes(refId);
  const isRecordingView =
    location.pathname.startsWith('/l/a/r/event') ||
    location.pathname.startsWith('/l/r/event');

  const videoTrackingMetadata = {
    eventId,
    stageId: isStagePreview ? refId : stageId,
  };

  const shouldShowImageOverlay = currentOverlay && showOverlayViews;

  return (
    <div
      id="publishedStreamDisplayContainer"
      className={classnames(styles.publishedStreamDisplayContainer, {
        [styles.bottomSheetPublishedStreamDisplayContainer]: isMobile && currentComponent === CURRENT_COMPONENT_TYPES.BOTTOM_SHEET, 
      })}
      ref={containerRef}
      style={backgroundStyles}
    >
      {!isRecordingMode && (
        <FloatingToast toastList={floatingToast?.floatingToastList} />
      )}
      {showVolumeToggle && (
        <div
          className={classnames(styles.volumeToggle, {
            [styles.boothVolumeToggle]: parent === IStageParent.BOOTH,
          })}
          id="volume-toggle-button"
        >
          <IconButton
            key="micButton"
            activeIcon={
              parent === IStageParent.BOOTH ? BOOTH_VOLUME_OFF : NEW_VOLUME_OFF
            }
            defaultIcon={
              parent === IStageParent.BOOTH ? BOOTH_VOLUME_ON : NEW_VOLUME_ON
            }
            onClick={toggleVolume}
            disabled={false}
            showDefault={volumeOn}
            type={IIconButtonTypes.ROUND_DARK_GREY_WHITE}
          />
        </div>
      )}
      <div className={classnames(styles.mainStageContainer, {
        [styles.bottomSheetStagePreview]: isMobile && currentComponent === CURRENT_COMPONENT_TYPES.BOTTOM_SHEET
      })}>
        {currentOverlay && showOverlayViews && (
          <Overlay isOrganizer={isOrganizer} parent={parent} {...currentOverlay} />
        )}
        {!!redirectType && !isRecordingView && (
          <RedirectOverlay onDone={redirect} redirectType={redirectType} />
        )}
        {showVideoOverlay && (
          <FullScreenVideo
            config={config}
            channelId={channelConfig && channelConfig.channelId}
            isOrganizer={isOrganizer}
            currentlyPlayingVideo={currentlyPlayingVideo}
            volumeOn={volumeOn}
            toggleVolume={toggleVolume}
            shouldTrack={true}
            trackingMetadata={videoTrackingMetadata}
          />
        )}
        {(isBroadcasting || isBackstage) && currentLiveStream && !isEmbeddedRtmp && showOverlayViews && (
          <LiveStream
            volumeOn={volumeOn}
            config={config}
            currentLiveStream={currentLiveStream}
            isOrganizer={isOrganizer}
            toggleVolume={toggleVolume}
            shouldTrack={true}
            trackingMetadata={videoTrackingMetadata}
          />
        )}
        {(isBroadcasting || isBackstage) && currentLiveStream && isEmbeddedRtmp && showOverlayViews && (
          <StreamsDisplay
            {...props}
            currentLiveStream={currentLiveStream}
            volumeOn={volumeOn}
            containerDims={containerDims}
            hasGreenRoomStreams={hasGreenRoomStreams}
            timeLeftMs={timeLeftMs}
            useRoundedCorners={!useOldLobbyStageLayout && [IStageParent.LIVE_BACKSTAGE, IStageParent.LIVE_STAGE].includes(parent) && !isRecordingMode}
          />
        )}
        {currentLiveStream &&
          !isBroadcasting &&
          (isStage || isStagePreview) && !showVideoOverlay && (
          <StreamEmptyState
            broadcastPermissions={broadcastPermissions}
            fillerImageUrl={fillerImageUrl}
            localClient={localClient}
            localStream={localStream}
            onBroadcastButtonClick={onBroadcastButtonClick}
            parent={parent}
            parentHeight={parentHeight}
            setBroadcastPermissions={setBroadcastPermissions}
            hideEmptyStageMsg={shouldShowImageOverlay}
          />
        )}
        {!currentLiveStream && (!isRecordingMode && isStageEmpty && !showVideoOverlay ? (
          <StreamEmptyState
            broadcastPermissions={broadcastPermissions}
            fillerImageUrl={fillerImageUrl}
            localClient={localClient}
            localStream={localStream}
            onBroadcastButtonClick={onBroadcastButtonClick}
            parent={parent}
            parentHeight={parentHeight}
            setBroadcastPermissions={setBroadcastPermissions}
            hideEmptyStageMsg={isStage && shouldShowImageOverlay}
          />
        ) : (
          <StreamsDisplay
            {...props}
            volumeOn={volumeOn}
            containerDims={containerDims}
            hasGreenRoomStreams={hasGreenRoomStreams}
            timeLeftMs={timeLeftMs}
            presentationConfig={presentationConfig}
            liveRaisedHandRequestList={liveRaisedHandRequestList}
            useRoundedCorners={!useOldLobbyStageLayout && [IStageParent.LIVE_BACKSTAGE, IStageParent.LIVE_STAGE].includes(parent) && !isRecordingMode}
          />
        ))}
        {showLiveIndicator && isStageOrBackstage && (
          <div
            className={classnames(styles.isBroadcastingIndicator, {
              [styles.isBroadcastingIndicatorForAttendee]:
                isAttendee && parent === IStageParent.LIVE_STAGE,
              [styles.isBroadcastingIndicatorForMobile]:
                isMobile &&
                (parent === IStageParent.LIVE_STAGE || isStageFromBackstage),
            })}
          >
            <div className={styles.text}>{liveIndicatorContent}</div>
          </div>
        )}
      </div>
      {hasGreenRoomStreams && (
        <>
          <StreamsDisplay
            {...props}
            volumeOn={true}
            greenRoom={true}
            containerDims={Object.assign(greenRoomDims, { width: '100%' })}
            liveRaisedHandRequestList={liveRaisedHandRequestList}
            useRoundedCorners={false}
          />
        </>
      )}
      <ReactionOverlay attendeeChannel={attendeeChannel} />
    </div>
  );
};

export default PublishedStreamDisplay;
export { IVideoFrameTypes };
