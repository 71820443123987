import React from 'react';
import classnames from 'classnames';
// styles + types
import styles from './styles.module.scss';
import { IPetalFrameProps, IPetalFrameCurveOrientations } from './types';

const PetalFrame = (props: IPetalFrameProps) => {
  const { children, curveOrientation = IPetalFrameCurveOrientations.LEFT, height } = props
  const petalDims = {
    height,
    width: height
  };

  return (
    <div
      className={classnames(
        styles.petalFrameContainer,
        styles[curveOrientation]
      )}
      style={petalDims}
    >
      {children}
    </div>
  );
}

export default PetalFrame;
export { IPetalFrameCurveOrientations };