import React from 'react';
import classnames from 'classnames';
import { IStreamActiveUserProps, PicSizes } from './types';
import { prefixPicUrlIfNeeded } from '@/utils/helpers';

import styles from './styles.module.scss';

import ProfilePicture from '@/components/ui/ProfilePicture';
import { ITextColors } from '@/components/ui/ExpandableContent';

const StreamActiveUser = (props: IStreamActiveUserProps) => {
  const { user, hasActiveVolume, containerStyles, showVolumeIndicator } = props;

  const size = containerStyles.height;
  const picSizeClass = (() => {
    if (size > 600) {
      return PicSizes.Large;
    }
    if (size > 400) {
      return PicSizes.Medium;
    }
    if (size > 150) {
      return PicSizes.Small;
    }
    if (size > 75) {
      return PicSizes.XSmall;
    }
    return PicSizes.XXSmall;
  })();

  const picUrl = user && user.picUrl ? prefixPicUrlIfNeeded(user.picUrl) : undefined;

  return (
    <div
      className={classnames(
        styles.streamUserFrame,
        styles[`streamUserFrame${picSizeClass}`],
        {
          [styles.showRipples]:
            hasActiveVolume &&
            picSizeClass !== PicSizes.XXSmall &&
            showVolumeIndicator,
        },
      )}
    >
      <ProfilePicture
        imageUrl={picUrl}
        name={user && user.name}
        className={classnames(
          styles.profilePicture,
          styles[`profilePicture${picSizeClass}`],
        )}
        hexColor={ITextColors.DEFAULT}
        disableTooltip={true}
        styleClass={`streamProfilePic${picSizeClass}`}
        isLive={false}
      />
    </div>
  );
};

export default StreamActiveUser;
