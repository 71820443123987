import { IStreamContainerDims } from "../../utils";

export const getBottomSponsorLayoutSpotlightContainerDims = (config: IStreamContainerDims, numStreams) => {
  const { height, width } = config;
  let numCols = 1;
  let numRows = 1;

  if (numStreams - 1 === 2) {
    numCols = 2;
    numRows = 1;
  } else if (numStreams - 1 <= 4) {
    numCols = 2;
    numRows = 2;
  } else if (numStreams - 1 <= 6) {
    numCols = 3;
    numRows = 2;
  } else if (numStreams - 1 <= 9) {
    numCols = 3;
    numRows = 3;
  } else if (numStreams - 1 <= 12) {
    numCols = 4;
    numRows = 3;
  } else if (numStreams - 1 <= 16) {
    numCols = 4;
    numRows = 4;
  }

  console.log('debugLayout > numRows, numCols ', numRows, numCols);
  const primaryWidth = Math.floor(width) / 3;
  const primaryHeight = Math.floor(height);

  const newWidth = Math.floor(width) * 2 / (3 * numCols);
  const newHeight = Math.floor(height) / (numRows || 1);
  return {
    primary: {
      width: primaryWidth,
      height: primaryHeight
    },
    secondary: {
      width: newWidth,
      height: newHeight
    }
  };
};
