/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Text, {
  ITextColors,
  ITextSizes,
  ITextStyles,
} from '@/components/ui/content/Text';
import { XCircle, Link } from 'react-feather';
import { getCssVar } from '@/utils/cssVars';
import { IContentColors } from '@/types';
import ScreenShareOff from '@/components/ui/new-icons/ScreenshareOffDynamic';
import PdfFileDynamicIcon from '@/components/ui/new-icons/PdfFileDynamic';
import useOutsideClick from '@/hooks/use-outside-click';
import useUploader, {
  IUploadMetadata,
  IUploadType,
} from '@/hooks/use-uploader';
import { IMAGE_URL, MIRO_BOARD_CLIENT_ID } from '@/config';
import Loader from '@/components/ui/Loader';
import { useHover } from 'react-use';

import ScreenshareToggle from './ScreenshareToggle';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { makeSelectUserAccountId } from '@/models/account';
import ReactTooltip from 'react-tooltip';
import { TooltipTypes } from '@/components/ui/buttons/IconButton/types';
import ShareWebsitePopup from './ShareWebsitePopup';
import { IPluginType } from '@/types/plugin';
import {
  getStopPluginText,
  SHARE_CONTENT_TEXT,
  someoneSharingText,
  STOP_WEBSITE_SHARE_TEXT,
  WEBSITE_LINK_TEXT,
} from './config';
import { IStageParent } from '@/components/Stage/types';
import { useMediaQuery } from 'react-responsive';
import { isIOS, isMobile, isIPad13 } from 'react-device-detect';

const MIRO_BOARD_PICKER_JS = 'https://miro.com/app/static/boardsPicker.1.0.js';

const ScreenOrFileShareToggle = ({
  isOrganizer,
  openMediaTabInGreenroomPanel,
  pendingScreen,
  onScreenShareButtonClick,
  isScreenShared,
  isPresentationShared,
  addPresentation,
  stopPresentation,
  refreshCurrentPresentationState,
  setLoadingPresentation,
  refreshPresentationList,
  pluginConfig,
  showScreenOrFileToggle,
  isMiroPluginActive,
  activePluginDetails,
  parent,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showShareWebsitePopup, setShowShareWebsitePopup] = useState(false);
  const isMobileViewPort = useMediaQuery({ query: '(max-width: 767px)' });
  const showScreenshareOption = !(
    isMobile ||
    isMobileViewPort ||
    isIOS ||
    isIPad13
  );
  const popupRef = useRef(null);
  const {
    addPlugin,
    stopPlugin,
    currentPlugin,
    refreshCurrentPluginState,
    setLoadingPlugin,
  } = pluginConfig;
  const currentUserAccountId = useMemoizedSelector(makeSelectUserAccountId);
  const isPluginShared =
    currentPlugin?.pluginId &&
    currentPlugin?.pluginPublishedBy === currentUserAccountId;
  const isChannelPluginActive =
    currentPlugin?.pluginId && currentPlugin?.pluginUrl;
  const alreadySharePluginName =
    currentPlugin?.pluginType === IPluginType.CUSTOM
      ? WEBSITE_LINK_TEXT
      : activePluginDetails?.name;

  const { isSomethingShared, onButtonClick } = useMemo(() => {
    let iss = false; // isSomethingShared
    let obc = () => setShowPopup(!showPopup); // onButtonClick
    if (isScreenShared || isPresentationShared || isPluginShared) {
      iss = true;
      if (isScreenShared) {
        obc = onScreenShareButtonClick;
      } else if (isPresentationShared) {
        obc = stopPresentation;
      } else if (isPluginShared) {
        obc = stopPlugin;
      }
    }

    return {
      isSomethingShared: iss,
      onButtonClick: obc,
    };
  }, [
    isScreenShared,
    isPresentationShared,
    isPluginShared,
    onScreenShareButtonClick,
    stopPresentation,
    stopPlugin,
    showPopup,
  ]);

  async function getMiroPresentationIframeLink() {
    const pos = { left: 0, top: 0 };
    const size = { width: 500, height: 500 };

    const windowRef = window.open(
      '',
      '_blank',
      `width=${size.width},height=${size.height},left=${pos.left},top=${pos.top},menubar=0,toolbar=0,location=0,personalbar=0,status=0`,
    );

    const script = document.createElement('script');
    script.src = MIRO_BOARD_PICKER_JS;
    document.body.appendChild(script);
    script.onload = () => {
      miroBoardsPicker.open({
        windowRef: windowRef,
        clientId: MIRO_BOARD_CLIENT_ID,
        action: 'access-link',
        success: data => {
          if (data?.accessLink) {
            saveAndBroadcastChannelPlugin(
              data.accessLink,
              activePluginDetails?.name,
              IPluginType.MIRO,
            );
          }
        },
        error: e => {
          console.log('on Miro Board error', e);
        },
      });
    };
  }

  const saveAndBroadcastChannelPlugin = (
    channelPluginUrl,
    pluginName,
    pluginType,
  ) => {
    setLoadingPlugin();
    const date = new Date();
    addPlugin(
      `${pluginName} at ${date.toLocaleString()}`,
      channelPluginUrl,
      pluginType,
    )
      .then(() => {
        setShowPopup(false);
      })
      .catch(() => {
        refreshCurrentPluginState();
      });
  };

  const saveAndBroadcastUploadedPdf = (uploadedfileUrl: string, metadata : IUploadMetadata) => {
    setLoadingPresentation();
    // TODO API Call to save uploaded pdf, in the response start broadcasting of the current uploaded pdf
    // If fails, refreshCurrentPresentationState
    // If success, startPresentation with this id and also refresh presentationlist
    // Tell backend that this is temporary only
    const date = new Date();
    addPresentation(
      `My Presentation at ${date.toLocaleString()}`,
      uploadedfileUrl,
      metadata.totalPages,
      true,
      true,
    )
      .then(() => {
        setShowPopup(false);
        refreshPresentationList();
      })
      .catch(() => {
        refreshCurrentPresentationState();
      });
  };

  const {
    startUpload,
    fileName: uploadedPdfFileName,
    isUploading: isPdfUploading,
    errorUploading,
    resetEverything,
    metadata,
  } = useUploader({
    type: IUploadType.PDF_FILE,
  });

  useEffect(() => {
    if (isPdfUploading) {
      setLoadingPresentation();
    }
    if (
      !isPdfUploading &&
      uploadedPdfFileName &&
      (metadata?.totalPages || 0) > 0
    ) {
      saveAndBroadcastUploadedPdf(
        `${IMAGE_URL}${uploadedPdfFileName}`,
        metadata,
      );
      resetEverything();
    }
  }, [isPdfUploading, uploadedPdfFileName]);

  useEffect(() => {
    if (errorUploading) {
      refreshCurrentPresentationState();
      resetEverything();
    }
  }, [errorUploading]);

  const isFileLoadingState = isPdfUploading;

  useOutsideClick(
    popupRef,
    () => showPopup && !isFileLoadingState && setShowPopup(false),
    // Only close on outside click if the popup is open and the file is not being uploaded to s3 and to our backend
  );

  const onClickPdfShare = () => {
    if (isOrganizer && parent !== IStageParent.LIVE_DISCUSSION) {
      openMediaTabInGreenroomPanel();
      setShowPopup(false);
      return;
    }
    if (!isFileLoadingState) {
      startUpload();
    }
  };

  const HoverablePdfOptionElement = hovered => (
    <div className={styles.option} onClick={onClickPdfShare}>
      <div className={styles.optionIconWrapper}>
        {!isFileLoadingState && (
          <PdfFileDynamicIcon size={30} active={hovered} />
        )}
        {isFileLoadingState && <Loader styleClass="presentationLoading" />}
      </div>
      <Text
        text="PDF File"
        textColor={ITextColors.WHITE}
        textSize={ITextSizes.SMALL}
        textStyle={ITextStyles.SEMI_BOLDED}
        textLineHeight={1.2}
        block={true}
        clickable={true}
      />
    </div>
  );
  const [HoverablePdfOption] = useHover(HoverablePdfOptionElement);

  const HoverableScreenshareOptionElement = hovered => (
    <div
      className={styles.option}
      onClick={() => {
        onScreenShareButtonClick();
        setShowPopup(false);
      }}
    >
      <div className={styles.optionIconWrapper}>
        <ScreenShareOff
          color={IContentColors.NEUTRAL_CONTRAST_2}
          size={30}
          active={hovered}
        />
      </div>
      <Text
        text="Your Screen"
        textColor={ITextColors.WHITE}
        textSize={ITextSizes.SMALL}
        textStyle={ITextStyles.SEMI_BOLDED}
        textLineHeight={1.2}
        block={true}
        clickable={true}
      />
    </div>
  );

  const MiroScreenShareOptionElement = () => (
    <div
      className={classnames(styles.option, {
        [styles.disableOption]: isChannelPluginActive,
      })}
      onClick={() => {
        if (!isChannelPluginActive) {
          getMiroPresentationIframeLink();
          setShowPopup(false);
        }
      }}
      data-tip
      data-for="tootltip_miro_active"
    >
      <div className={styles.optionIconWrapper}>
        <img
          className={styles.miroIconClasses}
          src={activePluginDetails?.logoUrl}
        ></img>
      </div>
      <Text
        text={`${activePluginDetails?.name} Whiteboard`}
        textColor={ITextColors.WHITE}
        textSize={ITextSizes.SMALL}
        textStyle={ITextStyles.SEMI_BOLDED}
        textLineHeight={1.2}
        block={true}
        clickable={true}
        readOnly={isChannelPluginActive}
      />
      {isChannelPluginActive && (
        <ReactTooltip
          id="tootltip_miro_active"
          place={TooltipTypes.top}
          effect="solid"
        >
          {someoneSharingText(alreadySharePluginName)}
        </ReactTooltip>
      )}
    </div>
  );

  const WebsiteShareOptionElement = () => (
    <div
      className={classnames(styles.option, {
        [styles.disableOption]: isChannelPluginActive,
      })}
      onClick={() => {
        if (!isChannelPluginActive) {
          setShowShareWebsitePopup(true);
        }
      }}
      data-tip
      data-for="tootltip_website_active"
    >
      <div className={styles.optionIconWrapper}>
        <Link color={getCssVar(IContentColors.NEUTRAL_CONTRAST_3)} size={30} />
      </div>
      <Text
        text="Website"
        textColor={ITextColors.WHITE}
        textSize={ITextSizes.SMALL}
        textStyle={ITextStyles.SEMI_BOLDED}
        textLineHeight={1.2}
        block={true}
        clickable={true}
        readOnly={isChannelPluginActive}
      />
      {isChannelPluginActive && (
        <ReactTooltip
          id="tootltip_website_active"
          place={TooltipTypes.top}
          effect="solid"
        >
          {someoneSharingText(alreadySharePluginName)}
        </ReactTooltip>
      )}
    </div>
  );
  const [HoverableScreenshareOption] = useHover(
    HoverableScreenshareOptionElement,
  );

  const iconButtonTooltipText = (() => {
    if (isSomethingShared) {
      if (isScreenShared) return 'Stop Screenshare';
      if (isPresentationShared) return 'Stop Presentation';
      if (isPluginShared) {
        return currentPlugin.pluginType === IPluginType.CUSTOM
          ? STOP_WEBSITE_SHARE_TEXT
          : getStopPluginText(activePluginDetails?.name);
      }
    } else {
      return SHARE_CONTENT_TEXT;
    }
  })();

  return (
    <div className={styles.container}>
      {showPopup && (
        <div ref={popupRef} className={styles.popupContainer}>
          <div className={styles.title}>
            <Text
              text="Present"
              textColor={ITextColors.MONOCHROME_CONTRAST}
              textSize={ITextSizes.MEDIUM}
              textStyle={ITextStyles.SEMI_BOLDED}
              textLineHeight="150%"
            />
            <XCircle
              className={styles.closeIcon}
              onClick={() => setShowPopup(false)}
              color={getCssVar(IContentColors.NEUTRAL_MID_2)}
              size={14}
            />
          </div>
          <div className={styles.options}>
            {WebsiteShareOptionElement()}
            {showScreenOrFileToggle && HoverablePdfOption}
            {isMiroPluginActive && MiroScreenShareOptionElement()}
            {showScreenshareOption && (
              <>
                <hr className={styles.divider} />
                {HoverableScreenshareOption}
              </>
            )}
          </div>
        </div>
      )}
      <ScreenshareToggle
        pendingScreen={pendingScreen}
        onScreenShareButtonClick={onButtonClick}
        isScreenShared={isSomethingShared}
        showTooltip={!showPopup}
        tooltipText={iconButtonTooltipText}
      />
      <ShareWebsitePopup
        saveAndBroadcastChannelPlugin={saveAndBroadcastChannelPlugin}
        showShareWebsitePopup={showShareWebsitePopup}
        setShowShareWebsitePopup={setShowShareWebsitePopup}
      />
    </div>
  );
};

export { ScreenshareToggle };
export default ScreenOrFileShareToggle;
