import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import { useInterval } from '@/hooks/use-interval';

const ONE_SECOND_MS = 1 * 1000;
const FULL_DASH_ARRAY = 283;

const CircularProgressTimer = ({ timeLimit, onDone }) => {
  const [timePassed, setTimePassed] = useState(0);
  const [timeLeft, setTimeLeft] = useState(timeLimit);
  const [stroke, setStroke] = useState(`${FULL_DASH_ARRAY}`);

  const [delayMs, setDelayMs] = useState(ONE_SECOND_MS);
  useEffect(() => {
    if (!timeLimit) return;

    setDelayMs(ONE_SECOND_MS);
    // startTimer();
  }, [timeLimit])
  
  useInterval(() => {
    if (!timeLimit) return;
    const newTimePassed = timePassed + 1;
    const newTimeLeft = timeLimit - newTimePassed;

    setTimeLeft(newTimeLeft);
    setTimePassed(newTimePassed);
    setCircleDasharray(newTimeLeft);

    if (newTimeLeft <= 0) {
      setDelayMs(null);
      if (!!onDone) onDone();
    }
  }, delayMs)

  const calculateTimeFraction = (remainingTime) => {
    const rawTimeFraction = remainingTime / timeLimit;
    return rawTimeFraction - (1 / timeLimit) * (1 - rawTimeFraction);
  }

  const setCircleDasharray = (remainingTime) => {
    const circleDasharray = `${(
      calculateTimeFraction(remainingTime) * FULL_DASH_ARRAY
    ).toFixed(0)} 283`;
    setStroke(circleDasharray);
  }

  return (
    <div className={styles.baseTimer}>
      <svg className={styles.baseTimerSVG} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <g className={styles.baseTimerCircle}>
          <circle className={styles.baseTimerPathElapsed} cx="50" cy="50" r="45"></circle>
          <path
            stroke-dasharray={stroke}
            className={styles.baseTimerPathRemaining}
            d="
              M 50, 50
              m -45, 0
              a 45,45 0 1,0 90,0
              a 45,45 0 1,0 -90,0
            "
          ></path>
        </g>
      </svg>
      <span className={styles.baseTimerLabel}>
        {/* {formatTime(displayTime)} */}
        {timeLeft}
      </span>
    </div>
  )
}

export default CircularProgressTimer;
