import React from 'react';
import SingleStreamPlayer from '@/components/PublishedStreamDisplay/SingleStreamPlayer';
import { getBottomSponsorLayoutGridStreamContainerDims } from './utils';

const GridLayout = props => {
  const {
    streams,
    parentHeight,
    parentWidth,
    streamDetailsMap,
    volumeOn,
    greenRoom,
    userMoveFns,
    parent,
    streamNamesMap,
    localClientAVStatus,
    liveRaisedHandRequestList,
  } = props;

  const streamContainerDimsConfig = {
    height: parentHeight,
    width: parentWidth * 0.9,
    numCols: 1,
    numRows: 1,
  };

  if (streams.length === 2) {
    streamContainerDimsConfig.numCols = 2;
    streamContainerDimsConfig.numRows = 1;
  } else if (streams.length <= 3) {
    streamContainerDimsConfig.numCols = 3;
    streamContainerDimsConfig.numRows = 1;
  } else if (streams.length <= 4) {
    streamContainerDimsConfig.numCols = 2;
    streamContainerDimsConfig.numRows = 2;
  } else if (streams.length <= 6) {
    streamContainerDimsConfig.numCols = 3;
    streamContainerDimsConfig.numRows = 2;
  } else if (streams.length <= 9) {
    streamContainerDimsConfig.numCols = 3;
    streamContainerDimsConfig.numRows = 3;
  } else if (streams.length <= 12) {
    streamContainerDimsConfig.numCols = 4;
    streamContainerDimsConfig.numRows = 3;
  } else if (streams.length <= 16) {
    streamContainerDimsConfig.numCols = 4;
    streamContainerDimsConfig.numRows = 4;
  }

  return (
    <>
      {streams.map(s => (
        <SingleStreamPlayer
          volumeOn={volumeOn}
          parent={parent}
          containerStyles={getBottomSponsorLayoutGridStreamContainerDims(
            streamContainerDimsConfig,
          )}
          greenRoom={greenRoom}
          key={`grid${s.stream.uid}`}
          name={streamNamesMap[s.stream.uid]}
          streamDetails={streamDetailsMap[s.stream.uid]}
          showNasscomLabel={true}
          stream={s.stream}
          userMoveFns={userMoveFns}
          localClientAVStatus={localClientAVStatus}
          liveRaisedHandRequestList={liveRaisedHandRequestList}
        />
      ))}
    </>
  );
};

export default GridLayout;
