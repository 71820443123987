/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { convertInAnotherTimeZone, getTime } from '@/utils/helpers';
// styles + types
import styles from './styles.module.scss';
import { SessionBroadcastStatus } from './types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import ShuffleIcon from '@/components/ui/new-icons/Shuffle';
import DragIcon from '@/components/ui/new-icons/Drag';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { makeSelectEventById } from '@/models/event';
import { useParams } from 'react-router-dom';

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
});

const SessionsList = ({
  sessions,
  handleSelectSession,
  selectedSession,
  currentRunningSession,
  isDryRunMode,
  handleSetDraggedList,
}) => {
  const dispatch = useDispatch();
  const { eventId } = useParams() as any;
  const event = useMemoizedSelector(makeSelectEventById, eventId);
  const handleDragAndDropSessions = e => {
    if (!e || !e.source || !e.destination) return;
    if (e.destination.index > e.source.index) {
      dispatch({
        type: 'global/addDangerToast',
        payload: { description: 'You cannot move session from top to bottom' },
      });
      return;
    }
    if (
      currentRunningSession &&
      (currentRunningSession.broadcastStatus ===
        SessionBroadcastStatus.PAUSED ||
        currentRunningSession.broadcastStatus ===
          SessionBroadcastStatus.STARTED)
    ) {
      dispatch({
        type: 'global/addDangerToast',
        payload: { description: 'Please stop current running session' },
      });
      return;
    }
    const sourceIndex = e.source.index;
    const destinationIndex = e.destination.index;
    const sourceSession = sessions[sourceIndex];
    const destinationSession = sessions[destinationIndex];
    const newList = [] as any;
    const minPosition = Math.min(sourceIndex, destinationIndex);
    for (let a = 0; a < minPosition; a += 1) {
      newList.push({ ...sessions[a] });
    }

    // change dropped session timings with replacing session timings
    const sourceSessionDuration =
      new Date(sourceSession.endDateTime).getTime() -
      new Date(sourceSession.startDateTime).getTime();
    const draggingSession = { ...sourceSession } as any;
    draggingSession.startDateTime = new Date(destinationSession.startDateTime);
    draggingSession.endDateTime = new Date(
      new Date(destinationSession.startDateTime).getTime() +
        sourceSessionDuration,
    );

    newList.push(draggingSession);
    let previousEndDateTime = draggingSession.endDateTime;
    for (let a = minPosition; a < sessions.length; a += 1) {
      const oldSession = sessions[a];
      if (oldSession.segmentId !== draggingSession.segmentId) {
        newList.push(oldSession);
      }
    }
    for (
      let newIndex = destinationIndex + 1;
      newIndex < newList.length;
      newIndex += 1
    ) {
      const session = newList[newIndex];
      let breakTime = 0;
      if (newIndex !== 0) {
        const prevSession_1 = sessions[newIndex - 1];
        const prevSession_2 = sessions[newIndex];
        breakTime =
          new Date(prevSession_2.startDateTime).getTime() -
          new Date(prevSession_1.endDateTime).getTime();
      }
      const newSession = { ...session } as any;
      const startTime = new Date(
        new Date(previousEndDateTime).getTime() + breakTime,
      );
      const sessionDuration =
        new Date(session.endDateTime).getTime() -
        new Date(session.startDateTime).getTime();
      newSession.startDateTime = startTime;
      newSession.endDateTime = new Date(startTime.getTime() + sessionDuration);
      previousEndDateTime = newSession.endDateTime;
      newList[newIndex] = newSession;
    }
    handleSetDraggedList(newList);
  };

  return (
    <div className={styles.sessionMenuCenterBar}>
      <DragDropContext onDragEnd={handleDragAndDropSessions}>
        <Droppable droppableId="droppable-session">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={classnames(
                snapshot.isDraggingOver
                  ? styles.isDraggingOverActive
                  : styles.isDraggingOverInActive,
              )}
            >
              {sessions.map((session, index) => (
                <Draggable
                  key={session.segmentId}
                  draggableId={session.segmentId}
                  index={index}
                  isDragDisabled={isDryRunMode}
                >
                  {(_provided, _snapshot) => (
                    <div
                      ref={_provided.innerRef}
                      {..._provided.draggableProps}
                      {..._provided.dragHandleProps}
                      className={
                        isDryRunMode
                          ? styles.dragLayoutWithDisableMode
                          : _snapshot.isDragging
                          ? styles.dragLayoutWithDragging
                          : styles.dragLayout
                      }
                      style={getItemStyle(
                        _snapshot.isDragging,
                        _provided.draggableProps.style,
                      )}
                    >
                      <div
                        className={
                          _snapshot.isDragging
                            ? styles.dragLayoutMainCard
                            : styles.sessionLayout
                        }
                        key={`SESSION-CARD-${index}`}
                      >
                        <div className={styles.detailsContainer}>
                          <div className={styles.sessionIndexLabel}>
                            {index + 1}.
                          </div>
                          <div className={styles.dragIcon}>
                            <DragIcon />
                          </div>
                          <div>
                            <div className={styles.sessionNameLabel}>
                              {session.title}
                            </div>
                            <div className={styles.sessionTimeLabel}>
                              {getTime(convertInAnotherTimeZone(session.startDateTime, event.tz))} -{' '}
                              {getTime(convertInAnotherTimeZone(session.endDateTime, event.tz))}
                            </div>
                          </div>
                        </div>
                        {(session.broadcastStatus ===
                          SessionBroadcastStatus.PAUSED ||
                          session.broadcastStatus ===
                            SessionBroadcastStatus.STARTED) &&
                          selectedSession.segmentId === session.segmentId && (
                            <div className={styles.sessionOnStageState}>
                              On Stage
                            </div>
                          )}
                        {session.broadcastStatus ===
                          SessionBroadcastStatus.PENDING &&
                          selectedSession.segmentId === session.segmentId && (
                            <div className={styles.sessionOnStageState}>
                              Up Next
                            </div>
                          )}
                        {session.broadcastStatus ===
                          SessionBroadcastStatus.PENDING &&
                          selectedSession.segmentId !== session.segmentId &&
                          isDryRunMode && (
                            <div
                              className={styles.sessionPendingState}
                              onClick={() => handleSelectSession(session)}
                            >
                              <ShuffleIcon /> Change
                            </div>
                          )}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default SessionsList;
