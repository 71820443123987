import React, { useMemo } from 'react';
import SingleStreamPlayer from '@/components/PublishedStreamDisplay/SingleStreamPlayer';
import { getBottomSponsorLayoutSpotlightContainerDims } from './utils';
import styles from './styles.module.scss';

const SpotlightLayout = props => {
  const {
    primaryStream,
    secondaryStreams,
    streams,
    parentHeight,
    parentWidth,
    streamDetailsMap,
    volumeOn,
    greenRoom,
    userMoveFns,
    parent,
    streamNamesMap,
    localClientAVStatus,
    localClient,
    liveRaisedHandRequestList,
  } = props;
  const streamContainerDimsConfig = {
    height: parentHeight,
    width: parentWidth * 0.9,
  };

  const moderatorStreams = [763781, 763782, 763783, 763784, 763785];
  const [finalPrimary, finalSecondary] = useMemo(() => {
    let tempPrimary;
    const tempSecondary = [];

    streams.forEach(s => {
      if (moderatorStreams.includes(s.stream.uid)) {
        if (tempPrimary) {
          tempSecondary.push(tempPrimary);
        }
        tempPrimary = s;
      } else if (!tempPrimary && s.stream.uid === primaryStream.uid) {
        tempPrimary = s;
      } else {
        tempSecondary.push(s);
      }
    });

    return [tempPrimary, tempSecondary];
  }, [primaryStream, secondaryStreams, streams]);

  const containerDims = getBottomSponsorLayoutSpotlightContainerDims(
    streamContainerDimsConfig,
    streams.length,
  );

  return (
    <>
      <div
        style={{ height: parentHeight, width: parentWidth * 0.9 }}
        className={styles.spotlightContainer}
      >
        {finalPrimary && (
          <SingleStreamPlayer
            volumeOn={volumeOn}
            parent={parent}
            containerStyles={containerDims.primary}
            greenRoom={greenRoom}
            key={`grid${finalPrimary.stream.uid}`}
            name={streamNamesMap[finalPrimary.stream.uid]}
            streamDetails={streamDetailsMap[finalPrimary.stream.uid]}
            stream={finalPrimary.stream}
            userMoveFns={userMoveFns}
            showNasscomLabel={true}
            localClientAVStatus={localClientAVStatus}
            localClient={localClient}
            liveRaisedHandRequestList={liveRaisedHandRequestList}
          />
        )}
        <div className={styles.spotlightSecondaryContainer}>
          {finalSecondary.map(s => (
            <SingleStreamPlayer
              volumeOn={volumeOn}
              parent={parent}
              containerStyles={containerDims.secondary}
              greenRoom={greenRoom}
              key={`grid${s.stream.uid}`}
              name={streamNamesMap[s.stream.uid]}
              streamDetails={streamDetailsMap[s.stream.uid]}
              stream={s.stream}
              userMoveFns={userMoveFns}
              showNasscomLabel={true}
              localClientAVStatus={localClientAVStatus}
              localClient={localClient}
              liveRaisedHandRequestList={liveRaisedHandRequestList}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SpotlightLayout;
