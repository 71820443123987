import React from 'react';
import SingleStreamPlayer from '@/components/PublishedStreamDisplay/SingleStreamPlayer';

const SingleStreamLayout = (props) => {
  const {
    streams,
    parentHeight,
    parentWidth,
    streamDetailsMap,
    volumeOn,
    greenRoom,
    userMoveFns,
    isStageFull,
    parent,
    streamNamesMap,
    localClientAVStatus,
    liveRaisedHandRequestList,
  } = props;
  const streamContainerDimsConfig = {
    height: parentHeight,
    width: parentWidth * 0.9,
    numCols: 1,
    numRows: 1,
  };
  const singleStream = streams.length >= 1 && streams[0];

  if (!singleStream) {
    return <></>;
  }

  return (
    <SingleStreamPlayer
      volumeOn={volumeOn}
      parent={parent}
      containerStyles={streamContainerDimsConfig}
      greenRoom={greenRoom}
      key={singleStream.stream.uid}
      hideNameBadge={true}
      name={streamNamesMap[singleStream.stream.uid]}
      streamDetails={streamDetailsMap[singleStream.stream.uid]}
      stream={singleStream.stream}
      userMoveFns={userMoveFns}
      noBorder={isStageFull}
      showNasscomLabel={true}
      localClientAVStatus={localClientAVStatus}
      liveRaisedHandRequestList={liveRaisedHandRequestList}
    />
  );
};

export default SingleStreamLayout;
