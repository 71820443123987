import React from 'react';
import { useParams } from 'react-router-dom';
import IconButton, {
  IIconButtonBGColors,
  IIconButtonTypes,
  IUIIcons,
} from '@/components/ui/buttons/IconButton';
import { STREAM_FLOW_STEPS } from '@/components/onboarding/stageFlows';
import { TooltipTypes } from '@/components/ui/buttons/IconButton/types';
import ConfirmExitPromptsModal from '../ConfirmExitPromptsModal';
import { IButtonTypes } from '@/components/ui/buttons/Button';
import { MEDIUM_ICON_SIZE } from '@/styles/iconSizes';
import { useChannelContext } from '@/services/video/channel-context/channel-context';
import isEmpty from 'lodash/isEmpty';
import useIsOrganizerOrRoomOwner from '@/hooks/events/useIsOrganizerOrRoomOwner';

const ScreenshareToggle = ({
  pendingScreen,
  onScreenShareButtonClick,
  isScreenShared,
  showTooltip = true,
  tooltipText,
}) => {
  const { eventId, discussionTableId } = useParams<{
    eventId: string;
    discussionTableId: string;
  }>();
  const isOrganizerOrRoomOwner = useIsOrganizerOrRoomOwner(
    eventId,
    discussionTableId,
  );
  const { channelSettings } = useChannelContext();

  const handleScreenShareClick = setShow => {
    if (isEmpty(channelSettings)) return;

    if (
      isScreenShared ||
      isOrganizerOrRoomOwner ||
      channelSettings?.hasScreenSharePerm
    ) {
      onScreenShareButtonClick();
    } else {
      setShow(true);
    }
  };

  return (
    <div id={STREAM_FLOW_STEPS.SCREEN_SHARE}>
      <ConfirmExitPromptsModal
        hasActionButtons={false}
        disableClose={false}
        hasNewCrossIcon
        newCrossIconSize={24}
        title="Screen sharing is disabled"
        exitText=""
        description="To share your screen, send a request to the organizer."
        confirmButtonText="Okay"
        confirmButtonType={IButtonTypes.NO_BG_CONTRAST}
        noSecondaryButton = {true}
        cancelButtonType={IButtonTypes.PRIMARY}
        wrapperClassName="screenSharePermWrapper"
        headerClassName="screenSharePermHeader"
        trigger={({ setShow }) => (
          <IconButton
            key="screenShareButton"
            disabled={pendingScreen}
            activeIcon={IUIIcons.SCREEN_SHARE_ON}
            defaultIcon={IUIIcons.NEW_SCREEN_SHARE_OFF}
            onClick={() => handleScreenShareClick(setShow)}
            showDefault={!isScreenShared}
            type={IIconButtonTypes.ROUND_THEME}
            iconBG={IIconButtonBGColors.NEUTRAL_LIGHT_3}
            iconSize={MEDIUM_ICON_SIZE}
            tooltip={showTooltip ? undefined : TooltipTypes.none}
            tooltipText={tooltipText}
          />
        )}
        handleConfirm={({ setShow }) => {
          setShow(false);
        }}
      />
    </div>
  );
};

export default ScreenshareToggle;
