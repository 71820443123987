import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import Video from '@/components/ui/media/Video';
import { useDispatch } from 'react-redux';
import { ChannelVideoStatus } from '@/types/channel';

const FullScreenVideo = ({
  config,
  currentlyPlayingVideo,
  channelId,
  isOrganizer,
  volumeOn,
  toggleVolume,
  shouldTrack,
  trackingMetadata = {},
}) => {
  // Here checking channel video id from channel_videos table for play in loop.
  const isPlayInLoop: boolean =
    currentlyPlayingVideo?.playInLoop ||
   // Local
   currentlyPlayingVideo?.channelVideoId === 'ea8cf971-3f36-4c9a-90b9-d68151c437a6' ||

   // Pre-prod test
   currentlyPlayingVideo?.channelVideoId === 'd31e5f18-d1a1-43a7-8ff4-77462803a46a' ||

   //Vodafone Experience Centre
   currentlyPlayingVideo?.channelVideoId === '64caa8ec-d364-4fff-8a80-0caaa4830552' ||

   // CS-628
   currentlyPlayingVideo?.channelVideoId === '2aec1661-173c-4284-89ba-bd1fa777cbba' ||

   //CS-622
   currentlyPlayingVideo?.channelVideoId === 'add01cb7-3e5b-469f-8bba-98b6a63946f7' ||

   //CS-638
   currentlyPlayingVideo?.channelVideoId === 'a3d3be45-77e6-499b-bac4-425b1ff1d762' ||
   currentlyPlayingVideo?.channelVideoId === 'd12b7961-4867-454d-8e89-0c183adb27c3' ||
   currentlyPlayingVideo?.channelVideoId === 'e6da1b10-cc82-404b-9194-98d69010780b' ||
   currentlyPlayingVideo?.channelVideoId === '298d2e96-ac24-43d2-915d-61a23062fd25' ||

   //CS-636
   currentlyPlayingVideo?.channelVideoId === '26b5022b-90f2-462e-9a98-f5014b6cb369' ||
   currentlyPlayingVideo?.channelVideoId === '93ec0a1f-4c8c-48fa-b8df-9391004a6f6a' ||
   currentlyPlayingVideo?.channelVideoId === 'c28dc5b8-19f4-490e-853b-3aa94fd72e72' ||
   currentlyPlayingVideo?.channelVideoId === 'fd5b45f3-a151-498d-aaca-5b4572a64922' ||

   //CS-631
   currentlyPlayingVideo?.channelVideoId === 'f89a9960-e753-4fbb-ab33-b53480001e92' ||

   //CS-630
   currentlyPlayingVideo?.channelVideoId === '829d4208-40a8-4dc5-a3f9-3e4b2d8dbdf9' ||

   //CS-629
   currentlyPlayingVideo?.channelVideoId === 'a0530930-878a-4838-958a-8a464db6f539' ||

   //CS-649
   currentlyPlayingVideo?.channelVideoId === '355f2bd9-b8e7-4943-a204-a4a3323b5580' ||

   //CS-700
   currentlyPlayingVideo?.channelVideoId === '5a33be1d-2008-486c-ba29-a88085254780' ||

   //CS-722
   currentlyPlayingVideo?.channelVideoId === 'd8a4357f-9651-4a2c-a755-ab32fdd33435' ||

   //CS-725
   currentlyPlayingVideo?.channelVideoId === 'b5ba55eb-8867-4e54-8907-c5f6776aacda' ||

   //CS-726
   currentlyPlayingVideo?.channelVideoId === '4afc150b-80ff-431e-8f49-873a304f1f0d' ||

   //CS-741
   currentlyPlayingVideo?.channelVideoId === 'f38f7baf-d8ca-4595-94da-72dee198315d' ||

   //CS-742
   currentlyPlayingVideo?.channelVideoId === 'a04bdc65-7db1-4040-b5d1-d761387b5eb8' ||

   //CS-753
   currentlyPlayingVideo?.channelVideoId === '92a6579d-7614-4988-8007-0cc4ddab78e3' ||

   //CS-742
   currentlyPlayingVideo?.channelVideoId === 'f4a7a97c-8d8d-451a-a32f-59c10e21d5a4' ||

   //CS-756
   currentlyPlayingVideo?.channelVideoId === 'c2534cc7-8f9f-4736-833c-6877939e2041' ||

   //CS-762
   currentlyPlayingVideo?.channelVideoId === 'b36f9dc5-e556-462a-af5b-5baca1feb5ef' ||

   //CS-770
   currentlyPlayingVideo?.channelVideoId === '1178bce3-03b6-48bf-aee6-964a4985d41d' ||

   //CS-791
   currentlyPlayingVideo?.channelVideoId === '42510c6d-4afa-428d-b371-275c3d6eef9e' ||

   //CS-896
   currentlyPlayingVideo?.channelVideoId === 'adc6d828-cf7c-4251-89a9-3d134f6e1ae6' ||

   //CS-841
   currentlyPlayingVideo?.channelVideoId === 'ed7ff9f6-aee7-41e9-a1ba-0d790d5ce549' ||
   //CS-821
   currentlyPlayingVideo?.channelVideoId === '60378065-6ff9-4cff-b3cc-5e8eaacb26f0' ||

   //CS-882
   currentlyPlayingVideo?.channelVideoId === '0948e534-7c64-42ca-a9ab-de5f4bfb4608' ||

   //CS-906
   currentlyPlayingVideo?.channelVideoId === '980218ce-ffa2-45a3-b27e-123ba58678e3' ||

   //CS-912
   currentlyPlayingVideo?.channelVideoId === '9a5acec0-888c-4f57-86d5-36548c515ccc' ||

   //CS-915
   currentlyPlayingVideo?.channelVideoId === '0ecca36d-9a91-4b3a-a9da-3ff24b845986' ||

   //CS-938
   currentlyPlayingVideo?.channelVideoId === '5b7f4f4c-96b8-4f79-98e5-3be2f5e2e1c3' ||

   //CS-935
   currentlyPlayingVideo?.channelVideoId === '2d7bee3a-3b53-4bcd-83d9-b1cbc3df6e88' ||

   //CS-957
   currentlyPlayingVideo?.channelVideoId === 'a2705f3a-0dd6-4c03-af58-745b2623a30d' ||
   //CS-948
   currentlyPlayingVideo?.channelVideoId === 'b37edee2-51eb-41bc-82aa-980a7363dff6' ||

   //CS-952
   currentlyPlayingVideo?.channelVideoId === '3c50c907-3edf-456a-9ef8-e1a1938b2c8a' ||
    
   //CS-977
   currentlyPlayingVideo?.channelVideoId === '1069124a-03d1-472f-9a3a-7e042e595a2a' ||
    
   //CS-978
   currentlyPlayingVideo?.channelVideoId === 'fe7bf130-2a5a-46aa-ac1b-b1fa6a0ae17d' ||
    
   //CS-980
   currentlyPlayingVideo?.channelVideoId === '9bcf221e-0621-4198-81d5-55df6cfdf387' ||
    
   //CS-989
   currentlyPlayingVideo?.channelVideoId === 'f0aacd78-f92f-40f4-99b1-fb42f82f4869' ||
    
   //CS-994
   currentlyPlayingVideo?.channelVideoId === 'b14365e6-9727-4220-a0be-1f8c1a57f248' ||

   // Google Experience
   currentlyPlayingVideo?.channelVideoId === 'ea8cf971-3f36-4c9a-90b9-d68151c437a6' ||
   currentlyPlayingVideo?.channelVideoId === '94e6e874-ba8d-4dd1-a178-7450733dd16f' ||
   currentlyPlayingVideo?.channelVideoId === '00258905-e7bc-4098-84c3-432c733146bc';

  const dispatch = useDispatch();
  const clearCurrentlyPlayingVideo = () => {
    if (isPlayInLoop) {
      return;
    }
    return;
    // if (isOrganizer) {
    //   dispatch({
    //     type: 'event/completeCurrentlyPlayingVideo',
    //     payload: {
    //       channelId,
    //       video: currentlyPlayingVideo
    //     }
    //   });
    // } else {
    //   dispatch({
    //     type: 'event/setCurrentlyPlayingVideo',
    //     payload: {
    //       channelId,
    //       videoInfo: null
    //     }
    //   });
    // }
  };

  const onPlay = (playingTime) => {
    // dispatch({
    //   type: 'event/playVideo',
    //   payload: {
    //     channelId,
    //     channelVideoId: currentlyPlayingVideo.channelVideoId,
    //     playingTime
    //   }
    // });
  };

  const onPause = (playingTime) => {
    // dispatch({
    //   type: 'event/pauseVideo',
    //   payload: {
    //     channelId,
    //     channelVideoId: currentlyPlayingVideo.channelVideoId,
    //     playingTime
    //   }
    // });
  };

  const startTime = useMemo(() => {
    if (!currentlyPlayingVideo) {
      return;
    }

    if (currentlyPlayingVideo.channelVideoStatus === ChannelVideoStatus.PAUSED) {
      return currentlyPlayingVideo.playingTime;
    }

    if (currentlyPlayingVideo.channelVideoStatus === ChannelVideoStatus.PLAYING) {
      const now = new Date(currentlyPlayingVideo.currentTime);
      const updatedAt = new Date(currentlyPlayingVideo.updatedAt);
      const elapsedSeconds = (now.getTime() - updatedAt.getTime()) / 1000;

      if (elapsedSeconds > 1) { // Allow a one second delay
        return currentlyPlayingVideo.playingTime + elapsedSeconds;
      }

      return currentlyPlayingVideo.playingTime;
    }
  }, [currentlyPlayingVideo]);

  return currentlyPlayingVideo ? (
    <div className={styles.heroVideo} style={
      config
        ? {
          background: `url('${config.stageBG}?auto=format,compress&q=75&sharp=10&blur=80') no-repeat center center fixed`,
          backgroundSize: 'cover',
          width: '100%',
          height: '100%',
        }
        : {}
    }>
      <Video
        startTime={startTime}
        styleClass='fullParent'
        url={currentlyPlayingVideo.videoLink}
        className={styles.videoZ}
        autoplay={currentlyPlayingVideo.channelVideoStatus !== ChannelVideoStatus.PAUSED}
        showLoader={true}
        controls={false}
        loop={isPlayInLoop}
        playsinline={true}
        muted={!volumeOn}
        onPlay={onPlay}
        onPause={onPause}
        onEnded={clearCurrentlyPlayingVideo}
        showAutoplayOverlay={true}
        seekToEndWhenEnded={true}
        toggleVolume={toggleVolume}
        shouldTrack={shouldTrack}
        trackingMetadata={trackingMetadata}
        showTimer={isOrganizer}
      />
    </div>
  ) : null;
}

export default FullScreenVideo;
