import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Emoji from '@/components/ui/Emoji';
import styles from './styles.module.scss';
import { IEmojiBarProps } from './types';
import api from '@/api';
import classnames from 'classnames';

const EmojiBar = ({ onEmojiClick, containerClassName }: IEmojiBarProps) => {
  const { stageId, boothId, discussionTableId } = useParams<{
    stageId: string;
    boothId: string;
    discussionTableId: string;
  }>();
  const [emojiList, setEmojiList] = useState([]);

  useEffect(() => {
    if (discussionTableId) {
      api.discussion
        .getEmojiListByDiscussionTableId(discussionTableId)
        .then(({ data }) => {
          setEmojiList(data);
        });
    }
  }, [discussionTableId]);

  useEffect(() => {
    if (stageId) {
      api.stage.getEmojiListByStageId(stageId).then(res => {
        setEmojiList(res.data);
      });
    }
  }, [stageId]);

  useEffect(() => {
    if (boothId) {
      api.booth.getEmojiListByBoothId(boothId).then(({ data }) => {
        setEmojiList(data);
      });
    }
  }, [boothId]);

  return (
    <div
      tabIndex={0}
      aria-label="emoji container"
      className={classnames(styles.emojiMenu, containerClassName)}
    >
      {emojiList.map(emoji => (
        <Emoji
          key={emoji.emojiMasterId}
          onClick={onEmojiClick}
          emojiId={emoji.emojiMasterId}
          className={classnames(styles.emoji,'elementAsButton')}
          type={emoji.emoji}
        />
      ))}
    </div>
  );
};

export default EmojiBar;
