const ENTER_VALID_URL_TEXT = 'Please Enter Valid Url';
const PASTE_LINK_LABEL = 'PASTE LINK';
const PASTE_LINK_PLACEHOLDER = 'Paste website link';
const SHARE_WEBSITE_TEXT = 'Share Website';

export const getModifiedWebsiteLinkForMiro = websiteUrl => {
  if (!websiteUrl || !websiteUrl.startsWith('https://miro.com/app/board/')) {
    return websiteUrl;
  }
  const boardId = websiteUrl.split('/')[5];
  if (!boardId) {
    return websiteUrl;
  }
  return `https://miro.com/app/live-embed/${boardId}`;
};

export {
  ENTER_VALID_URL_TEXT,
  PASTE_LINK_LABEL,
  PASTE_LINK_PLACEHOLDER,
  SHARE_WEBSITE_TEXT,
};
