import React, { useEffect, useRef } from 'react';
import { IContentColors } from '@/types';
import { getCssVar } from '@/utils/cssVars';
import { useMediaQuery } from 'react-responsive';
import { getMaxStreamsPerPage } from '../config';
import SliderWrapper from '../SliderWrapper';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { ChevronUp, ChevronDown } from '@styled-icons/boxicons-solid';
import SingleStreamPlayer from '@/components/PublishedStreamDisplay/SingleStreamPlayer';
import {
  getMaxStreamsToSlide,
  getOtherStreamsContainerHeight,
  getOtherStreamsContainerWidth,
  getRowsAndColumns,
  MAX_STREAMS_PER_PAGE,
  getStreamDims,
} from './config';
import Slider from 'react-slick';
import { isIPad13, isTablet } from 'react-device-detect';
import {
  useVideoState,
  useVideoMutation,
} from '@/services/video/video-context';
import SharedStreamOrContentView from '../../SharedStreamOrContentView';

const SharingView = props => {
  const {
    streamDetailsMap,
    otherStreams,
    streamNamesMap,
    parentWidth,
    parentHeight,
    parentLeftPosition,
    parentTopPosition,
    parent,
    volumeOn,
    greenRoom,
    userMoveFns,
    localClientAVStatus,
    localClient,
    liveRaisedHandRequestList,
    handleStreamDoubleClick,
    channelSidePanelState,
  } = props;

  const { streamPagination } = useVideoState();
  const videoMutation = useVideoMutation();
  const { currentPage } = streamPagination;

  const sliderRef = useRef<Slider>(null);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const streamsCount = otherStreams?.length;
  const streamsPerSlide = getMaxStreamsPerPage(isMobile, true);

  useEffect(() => {
    videoMutation.setPaginationConfig({
      perPage: streamsPerSlide,
    });
  }, [streamsPerSlide]);

  const shouldShowSlidingArrows = streamsCount > MAX_STREAMS_PER_PAGE;

  // Container Dims Calc
  const { rows, cols } = getRowsAndColumns(streamsCount, isMobile);
  const commonDimsConfig = {
    leftPosition: parentLeftPosition,
    topPosition: parentTopPosition,
  };
  const otherStreamContainerDimsConfig = {
    ...commonDimsConfig,
    numCols: isMobile && cols === 1 ? 2 : cols,
    numRows: (isMobile || isTablet) && rows <= 2 ? rows + 1 : rows,
    height: getOtherStreamsContainerHeight(
      parentHeight,
      isMobile,
      isTablet,
      isIPad13,
      shouldShowSlidingArrows,
      streamsCount,
    ),
    width: getOtherStreamsContainerWidth(
      parentWidth,
      isTablet,
      isMobile,
      !!channelSidePanelState,
    ),
  };

  const handlePrevClick = () =>
    sliderRef.current && sliderRef.current.slickPrev();
  const handleNextClick = () =>
    sliderRef.current && sliderRef.current.slickNext();

  const isPrevSlideAllowed = currentPage > 1;
  const isNextSlideAllowed =
    currentPage < Math.ceil(otherStreams.length / streamsPerSlide);

  const { width: otherStreamWidth, height: otherStreamHeight } = getStreamDims(
    otherStreamContainerDimsConfig,
  );

  return (
    <div
      className={classnames(styles.sharedViewWrapper, {
        [styles.sharedViewWrapperWithoutSidePanel]: !channelSidePanelState,
      })}
    >
      <SharedStreamOrContentView {...props} />
      {!!otherStreams.length && (
        <div
          className={classnames(styles.scrollableStreamsWrapper, {
            [styles.scrollableStreamsWrapperWithoutSidePanel]: !channelSidePanelState,
          })}
        >
          {!isMobile && shouldShowSlidingArrows && (
            <div
              aria-hidden
              className={classnames(styles.arrowBackground, {
                [styles.arrowBackgroundDisabled]: !isPrevSlideAllowed,
              })}
              onClick={isPrevSlideAllowed ? handlePrevClick : undefined}
            >
              <ChevronUp size={24} color={getCssVar(IContentColors.WHITE)} />
            </div>
          )}
          <div className={styles.sliderWrapper}>
            <SliderWrapper
              ref={sliderRef}
              shouldSlideVertically={!isMobile}
              slidesToShowAndScroll={getMaxStreamsToSlide(
                streamsCount,
                isMobile,
              )}
            >
              {otherStreams?.map(s => (
                <SingleStreamPlayer
                  parent={parent}
                  volumeOn={volumeOn}
                  containerStyles={{
                    width: otherStreamWidth,
                    height: otherStreamHeight,
                  }}
                  key={`line${s.uid}`}
                  name={streamNamesMap[s.uid]}
                  onDoubleClick={() => handleStreamDoubleClick(s)}
                  streamDetails={streamDetailsMap[s.uid]}
                  stream={s}
                  greenRoom={greenRoom}
                  userMoveFns={userMoveFns}
                  localClientAVStatus={localClientAVStatus}
                  localClient={localClient}
                  liveRaisedHandRequestList={liveRaisedHandRequestList}
                />
              ))}
            </SliderWrapper>
          </div>
          {!isMobile && shouldShowSlidingArrows && (
            <div
              aria-hidden
              className={classnames(styles.arrowBackground, {
                [styles.arrowBackgroundDisabled]: !isNextSlideAllowed,
              })}
              onClick={isNextSlideAllowed ? handleNextClick : undefined}
            >
              <ChevronDown size={24} color={getCssVar(IContentColors.WHITE)} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(SharingView);
