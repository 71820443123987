import React, { useState, useEffect } from 'react';
import KeynoteLayout from './KeynoteLayout';
import GridLayout from './GridLayout';
import produce from 'immer';
import styles from './styles.module.scss';
import { StreamType } from '@/components/ChannelStreamsContainer/types';

const RazorpayLayout = props => {
  const {
    primaryStream,
    secondaryStreams,
    streams,
    parentHeight: originalParentHeight,
    parentWidth,
    streamDetailsMap,
    volumeOn,
    greenRoom,
    userMoveFns,
    isStageFull,
    parent,
    streamNamesMap,
    localClientAVStatus,
  } = props;
  const containerHeight = originalParentHeight;
  const [layoutConfig, setLayoutConfig] = useState({
    hasScreenShare: false,
    isGridMode: true,
    pinnedUid: null,
  });
  const { hasScreenShare } = layoutConfig;

  useEffect(() => {
    const hasAnyScreenStream =
      primaryStream &&
      streamDetailsMap[primaryStream.uid] &&
      [StreamType.SCREEN, StreamType.SCREEN_RELAY].includes(
        streamDetailsMap[primaryStream.uid].type,
      );

    setLayoutConfig(
      produce(draft => {
        draft.hasScreenShare = hasAnyScreenStream;
        draft.isGridMode = true;
      }),
    );
  }, [streams, primaryStream, secondaryStreams, streamDetailsMap]);

  const innerLayoutProps = {
    ...props,
    parentHeight: containerHeight,
  };
  const StreamElement = (() => {
    if (hasScreenShare) {
      console.log('debugLayout > KeynoteLayout');
      return <KeynoteLayout {...innerLayoutProps} />;
    }

    console.log('debugLayout > GridLayout');
    return <GridLayout {...innerLayoutProps} />;
  })();

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: parentWidth,
          height: containerHeight,
        }}
        className={styles.bottomSponsorContainer}
      >
        {StreamElement}
      </div>
    </>
  );
};

export default RazorpayLayout;
