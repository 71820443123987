// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__overlayImage___3DmM-{position:absolute;width:100%;height:100%;object-fit:contain;z-index:10;-webkit-transform:translate3d(0, 0, 0);pointer-events:none}.styles-module__organizerOverlay___33Yb-{opacity:0.6}\n", ""]);
// Exports
exports.locals = {
	"overlayImage": "styles-module__overlayImage___3DmM-",
	"organizerOverlay": "styles-module__organizerOverlay___33Yb-"
};
module.exports = exports;
