import React from 'react';
import styles from './styles.module.scss';
import Video from '@/components/ui/media/Video';

const LiveStream = ({
  config,
  currentLiveStream,
  isOrganizer,
  volumeOn,
  toggleVolume,
  shouldTrack,
  trackingMetadata = {},
}) => {
  const onEnded = () => { };

  return currentLiveStream ? (
    <div className={styles.heroVideo} style={
      config
        ? {
          background: `url('${config.stageBG}?auto=format,compress&q=75&sharp=10&blur=80') no-repeat center center fixed`,
          backgroundSize: 'cover',
          width: '100%',
          height: '100%',
        }
        : {}
    }>
      <Video
        styleClass='fullParent'
        url={currentLiveStream.liveStreamUrl}
        className={styles.videoZ}
        autoplay={true}
        showLoader={true}
        controls={false}
        loop={false}
        muted={!volumeOn}
        onEnded={onEnded}
        showAutoplayOverlay
        toggleVolume={toggleVolume}
        shouldTrack={shouldTrack}
        trackingMetadata={trackingMetadata}
      />
    </div>
  ) : null;
}

export default LiveStream;
