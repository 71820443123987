import React from 'react';
import styles from './styles.module.scss';
import CircularProgressTimer from './CircularProgressTimer';
import { RedirectTypes } from './config';
import random from 'lodash/random';

const RedirectOverlay = ({ onDone, redirectType }) => {
  if (redirectType === RedirectTypes.Breakout) {
    return (
      <div className={styles.breakoutOverlay}>
        <div className={styles.notification}>
          <div className={styles.timer}>
            <CircularProgressTimer timeLimit={5} onDone={onDone} />
          </div>
          <div className={styles.heading}>
            The stage is breaking into rooms.
          </div>
          <div className={styles.description}>
            Dont forget to turn on your camera.
          </div>
        </div>
      </div>
    );
  }

  if (redirectType === RedirectTypes.BringBack) {
    const timeLimit = random(8, 15);
    return (
      <div className={styles.breakoutOverlay}>
        <div className={styles.notification}>
          <div className={styles.timer}>
            <CircularProgressTimer timeLimit={timeLimit} onDone={onDone} />
          </div>
          <div className={styles.heading}>Closing Breakout Rooms.</div>
          <div className={styles.description}>
            You will automatically go to stage.
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default RedirectOverlay;
