import React from 'react';
import styles from './styles.module.scss';
import SingleStreamPlayer from '../../SingleStreamPlayer';

const InterpreterBox = props => {
  const {
    stream,
    parentHeight,
    parentWidth,
    streamDetailsMap,
    volumeOn,
    greenRoom,
    userMoveFns,
    parent,
    streamNamesMap,
    localClientAVStatus,
    liveRaisedHandRequestList,
    eventId,
    moveUserToStage,
  } = props;
  const streamContainerDimsConfig = {
    width: parentWidth * 0.15,
    height: parentHeight * 0.2,
  };

  return (
    <div
      style={{
        position: 'absolute',
        left: '1.5%',
        bottom: '2.7%',
      }}
      className={styles.interpreterBox}
    >
      {stream && (
        <SingleStreamPlayer
          volumeOn={volumeOn}
          parent={parent}
          containerStyles={streamContainerDimsConfig}
          eventId={eventId}
          greenRoom={greenRoom}
          key={`interpreter${stream.stream.uid}`}
          name={streamNamesMap[stream.stream.uid]}
          streamDetails={streamDetailsMap[stream.stream.uid]}
          stream={stream.stream}
          userMoveFns={userMoveFns}
          localClientAVStatus={localClientAVStatus}
          liveRaisedHandRequestList={liveRaisedHandRequestList}
          interpreter={true}
          moveUserToStage={moveUserToStage}
        />
      )}
    </div>
  );
};

export default InterpreterBox;
