import React from 'react';
import styles from './styles.module.scss';
import Modal from '@/components/ui/modals/Modal';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import Text, { ITextColors, ITextSizes } from '@/components/ui/content/Text';
import classnames from 'classnames';
import PromptsModal from '@/components/ui/modals/PromptsModal';


const ConfirmExitPromptsModal = ({
  title = '',
  description = '',
  trigger,
  handleConfirm,
  exitText = 'Are you sure you want to exit?',
  confirmButtonText = 'Yes, Leave',
  cancelButtonText = 'Cancel',
  cancelButtonClassName,
  confirmButtonClassName,
  cancelButtonType = IButtonTypes.PRIMARY,
  confirmButtonType = IButtonTypes.PRIMARY_NO_BG_INLINE,
  headerClassName = 'backStageModalHeader',
  handleCancel,
  autoTrigger,
  className,
  disableClose = true,
  hasActionButtons = true,
  hasNewCrossIcon = false,
  newCrossIconSize,
  wrapperClassName,
  noSecondaryButton
}) => {
  const CancelHandler = ({setShow})=>{
    if (handleCancel) {
      handleCancel( { setShow });
    }
    setShow(false);
  } 
  const primaryButtonProps = {
    styleClass: IButtonTypes.PRIMARY,
    label: confirmButtonText,
    type: "button",
    onClick : ({setShow}) => handleConfirm({ setShow })
  }
  const secondaryButtonProps = {
    styleClass: IButtonTypes.NO_BG_CONTRAST,
    label: cancelButtonText,
    type: "button",
    onClick :({setShow})=> CancelHandler({setShow})
  }
  return(
  <PromptsModal
    title={title}
    subTitle={exitText || description}
    disableClose={disableClose}
    trigger={trigger}
    primaryButtonProps={primaryButtonProps}
    secondaryButtonProps={noSecondaryButton ? null : secondaryButtonProps}
  />
);
  }

export default ConfirmExitPromptsModal;