import React from 'react';
import classnames from 'classnames';
// styles + types
import styles from './styles.module.scss';
import { ICircleFrameProps, } from './types';

const CircleFrame = (props: ICircleFrameProps) => {
  const { children, height } = props
  const circleDims = {
    height,
    width: height
  };

  return (
    <div className={classnames(
      styles.circleFrameContainer,
    )} style={circleDims}>
      {children}
    </div>
  );
}

export default CircleFrame;