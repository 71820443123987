import { IStreamContainerDims } from "../../utils";

export const getBottomSponsorLayoutGridStreamContainerDims = (config: IStreamContainerDims) => {
  const { height, width, numRows, numCols = 1 } = config;

  const newWidth = Math.floor(width) / numCols;
  const newHeight = Math.floor(height) / (numRows || 1);
  return {
    width: newWidth,
    height: newHeight
  };
};
