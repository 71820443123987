import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import classnames from 'classnames';
// components
import { EmojiSymbols, CustomEmojiSymbos } from '@/components/ui/Emoji';
// models
import { selectCurrentUser } from '@/models/account';
// styles + types
import styles from './styles.module.scss';
import { AttendeeChannelEvent } from '@/types/messaging';
import { useParams } from 'react-router';
import { makeSelectEventById, makeSelectStudioIdByStageId } from '@/models/event';
import { EEventType } from '@/types/event';
import { selectChannelSidePanelState } from '@/models/global';
import ReactDOM from 'react-dom';
import { v4 as uuidv4 } from 'uuid';
import Text, { ITextSizes, ITextColors } from '@/components/ui/content/Text';
import { useMediaQuery } from 'react-responsive';

const EMOJI_CONTAINER_ID = 'emoji-container-id';

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

const FloatingEmoji = ({ emoji, sender, onAnimationEnd }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <div
      className={styles.floatingEmojiContainer}
      onAnimationEnd={onAnimationEnd}
      style={{
        bottom: `${20 + getRandomInt(50)}px`,
        right: `${50 + getRandomInt(50)}px`,
      }}
    >
      <span role="img" className={styles.reactionEmoji}>
        {emoji}
      </span>
      {sender ? (
        <Text
          text={sender}
          block
          textLineHeight={1.25}
          textAlign="center"
          textSize={isMobile ? ITextSizes.XXSMALL : ITextSizes.XSMALL}
          textColor={ITextColors.MONOCHROME_CONTRAST}
          className={styles.emojiSenderName}
        />
      ) : null}
    </div>
  );
};

const ReactionOverlay = ({ attendeeChannel }) => {
  const currentUser = useSelector(selectCurrentUser);
  const { eventId, stageId } = useParams();
  const stageStudioId = useMemoizedSelector(
    makeSelectStudioIdByStageId,
    stageId,
  );
  const isStudioStage = !!stageStudioId;
  const channelSidePanelState = useSelector(selectChannelSidePanelState);
  const [inFocus, setInFocus] = useState(true);
  const inFocusRef = useRef(inFocus);

  useEffect(() => {
    inFocusRef.current = inFocus;
  }, [inFocus]);

  // User has switched back to the tab
  const onFocus = () => {
    setInFocus(true);
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    setInFocus(false);
  };

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  const [currentEmojiCount, setCurrentEmojiCount] = useState(0);
  const EMOJI_MAP = eventId === 'd6e0235d-470a-40ba-887c-3af5bd5bdd2e' ? CustomEmojiSymbos : EmojiSymbols;

  const addEmojiReaction = useCallback((emojiType, sender) => {
    if (currentEmojiCount > 200) {
      console.error('emoji > addEmojiReaction > count exceeded');
      return;
    }
    const reactionContainer = document.getElementById(EMOJI_CONTAINER_ID);
    const nodeId = uuidv4();
    const newReactionNode = document.createElement('div');
    newReactionNode.setAttribute("id", nodeId);
    reactionContainer.appendChild(newReactionNode);
    ReactDOM.render(
      <FloatingEmoji
        emoji={EMOJI_MAP[emojiType] || emojiType}
        sender={sender}
        onAnimationEnd={() => {
          ReactDOM.unmountComponentAtNode(newReactionNode);
          reactionContainer.removeChild(newReactionNode);
          setCurrentEmojiCount(currentEmojiCount => currentEmojiCount - 1);
        }}
      />,
      newReactionNode,
    );
    setCurrentEmojiCount(currentEmojiCount => currentEmojiCount + 1);
  }, [currentEmojiCount]);

  const addEmojiReactionRef = useRef(addEmojiReaction);
  useEffect(() => {
    addEmojiReactionRef.current = addEmojiReaction;
  }, [addEmojiReaction]);

  useEffect(() => {
    if (!attendeeChannel || !currentUser.accountId) {
      return;
    }

    const reactionListener = (data) => {
      const { type: reactionType, sender } = JSON.parse(data);
      // if (accountId === currentUser.accountId) { TODO(VS): do this immediately instead of by
      //   return;
      // }

      if (!inFocusRef || !inFocusRef.current) {
        console.error('emoji > reactionListener > inFocusRef null');
        return;
      }

      const fn = addEmojiReactionRef && addEmojiReactionRef.current;
      if (fn) {
        fn(reactionType, sender);
      } else {
        console.error('emoji > reactionListener > fn null');
      }
    };
    attendeeChannel.bind(AttendeeChannelEvent.REACTION, reactionListener);

    return () => {
      attendeeChannel.unbind(
        AttendeeChannelEvent.REACTION,
        reactionListener,
      );
    };
  }, [attendeeChannel, currentUser.accountId]);

  return (
    <div
      id={EMOJI_CONTAINER_ID}
      className={classnames(styles.reactionOverlay, {
        [styles.webinarReactionOverlay]: isStudioStage,
        [styles.withSideBarOpen]: isStudioStage && channelSidePanelState,
      })}
    ></div>
  );
};

export default React.memo(ReactionOverlay);
