import { IStreamContainerDims } from "../../utils";

export const getCondensedLayoutKeynoteContainerDims = (config: IStreamContainerDims) => {
  const { height, width } = config;

  const primaryHeight = Math.floor(height);
  const primaryWidth = primaryHeight * 16 / 9;

  const secondaryHeight = Math.floor(height) * 0.75;
  const secondaryWidth = secondaryHeight * 4 / 3;
  return {
    primary: {
      width: primaryWidth,
      height: primaryHeight
    },
    secondary: {
      width: secondaryWidth,
      height: secondaryHeight
    }
  };
};
