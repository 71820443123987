import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import MorePeople from '../../MorePeople';
import SingleStreamPlayer from '@/components/PublishedStreamDisplay/SingleStreamPlayer';
import { getLayoutConfig } from './config';

const DisplayedStreams = props => {
  const {
    isContentShared = false,
    displayedStreams,
    hiddenStreams,
    streamContainerDims,
    parent,
    volumeOn,
    greenRoom,
    userMoveFns,
    streamNamesMap,
    streamDetailsMap,
    localClient,
    localClientAVStatus,
    liveRaisedHandRequestList,
    handleStreamDoubleClick,
  } = props;

  const { width: parentWidth, height: parentHeight } = streamContainerDims;
  const streamsDimsConfig = getLayoutConfig(
    parentWidth,
    parentHeight,
    displayedStreams?.length || 0,
    isContentShared,
  );

  return (
    <>
      {streamsDimsConfig?.map((rowConfig, idx) => (
        <div key={`key-streams-row-config-${idx}`} className={styles.streamRow}>
          {rowConfig.map(({ width, height, idx }) => {
            const st = displayedStreams[idx];
            const isLastDisplayedStream = idx === displayedStreams.length - 1;

            return (
              <div
                key={`key-single-stream-${st.uid}`}
                className={styles.streamCol}
              >
                <div
                  className={classnames({
                    [styles.lastDisplayedStream]: isLastDisplayedStream,
                  })}
                >
                  {isLastDisplayedStream && (
                    <MorePeople streams={hiddenStreams} volumeOn={volumeOn} />
                  )}
                  <SingleStreamPlayer
                    parent={parent}
                    volumeOn={volumeOn}
                    containerStyles={{
                      width: width,
                      height: height,
                    }}
                    name={streamNamesMap[st.uid]}
                    onDoubleClick={() => handleStreamDoubleClick(st)}
                    streamDetails={streamDetailsMap[st.uid]}
                    stream={st}
                    greenRoom={greenRoom}
                    userMoveFns={userMoveFns}
                    localClientAVStatus={localClientAVStatus}
                    localClient={localClient}
                    liveRaisedHandRequestList={liveRaisedHandRequestList}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </>
  );
};

export default React.memo(DisplayedStreams);
