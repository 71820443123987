/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { IContentColors } from '@/types';
import { getCssVar } from '@/utils/cssVars';
import React from 'react';
import styles from './scroll-bar-arrow-button.module.scss';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { ChevronLeft, ChevronRight } from 'react-feather';

const ScrollBarArrowButton = props => {
  const { buttonType = 'left', scrollOffset, enableButton } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const iconColor = enableButton
    ? getCssVar(IContentColors.NEUTRAL_MID_3)
    : getCssVar(IContentColors.NEUTRAL_BASE_3);
  const buttonClass = classnames(styles.leftRightArrowContainer, {
    [styles.leftRightArrowDisableContainer]: !enableButton,
  });

  return (
    <>
      {buttonType === 'left' && (
        <div
          className={buttonClass}
          onClick={() => scrollOffset(isMobile ? -50 : -150)}
        >
          <ChevronLeft size={24} color={iconColor} />
        </div>
      )}
      {buttonType === 'right' && (
        <div
          className={buttonClass}
          onClick={() => scrollOffset(isMobile ? 50 : 150)}
        >
          <ChevronRight size={24} color={iconColor} />
        </div>
      )}
    </>
  );
};

export default ScrollBarArrowButton;
