import { IStreamContainerDims } from '../../../utils';

export const MAX_STREAMS_PER_PAGE = 3;

export const MAX_STREAMS_PER_PAGE_FOR_MOB = 2;

export const getMaxStreamsToSlide = (
  streamsCount: number,
  isMobile: boolean,
) => {
  if (isMobile) {
    return streamsCount > MAX_STREAMS_PER_PAGE_FOR_MOB
      ? MAX_STREAMS_PER_PAGE_FOR_MOB
      : streamsCount;
  }

  return streamsCount > MAX_STREAMS_PER_PAGE
    ? MAX_STREAMS_PER_PAGE
    : streamsCount;
};

export const getRowsAndColumns = (streamsCount: number, isMobile: boolean) => {
  const _streamsCount = getMaxStreamsToSlide(streamsCount, isMobile);

  switch (_streamsCount) {
    case 1:
      if (isMobile) {
        return { rows: 1, cols: 1 };
      }
      return { rows: 1, cols: 1 };
    case 2:
      if (isMobile) {
        return { rows: 1, cols: 2 };
      }
      return { rows: 2, cols: 1 };
    case 3:
      // Does not apply
      if (isMobile) {
        return { rows: 1, cols: 2 };
      }
      return { rows: 3, cols: 1 };
    case 4:
      // Does not apply
      if (isMobile) {
        return { rows: 1, cols: 2 };
      }
      return { rows: 4, cols: 1 };
    default:
      return { rows: 4, cols: 4 };
  }
};

export const getOtherStreamsContainerHeight = (
  parentHeight: number,
  isMobile: boolean,
  isTablet: boolean,
  isIPad: boolean,
  areSlidingArrowsVisible: boolean,
  streamsCount: number,
) => {
  // Account for address bar, floating A/V controls, sliding dots on phones
  const mobileParentHeight = parentHeight * 0.8;
  if (isMobile) {
    return 0.472 * mobileParentHeight;
  }

  if (isTablet) {
    const safariTabsHeight = 48;
    return (
      parentHeight -
      (isIPad ? safariTabsHeight : 0) -
      (areSlidingArrowsVisible
        ? (1 / 5) * parentHeight
        : (1 / 7) * parentHeight)
    );
  }

  // Handle stretch of one stream
  if (streamsCount === 1) {
    return 0.4 * parentHeight;
  }
  return (
    parentHeight -
    (areSlidingArrowsVisible ? (1 / 5) * parentHeight : (1 / 7) * parentHeight)
  );
};

export const getOtherStreamsContainerWidth = (
  parentWidth: number,
  isTablet: boolean,
  isMobile: boolean,
  isSidePanelOpen: boolean,
) => {
  // While sharing, mobile has a horizontal sliding mode
  if (isMobile) {
    return parentWidth;
  }

  if (isTablet) {
    return isSidePanelOpen ? 0.25 * parentWidth : 0.215 * parentWidth;
  }

  // ~25% of available space for other streams
  return isSidePanelOpen ? 0.24 * parentWidth : 0.22 * parentWidth;
};

export const getStreamDims = (config: IStreamContainerDims) => {
  const { width, height, numRows: rows = 1, numCols: cols = 1 } = config;

  const newWidth = Math.floor(width) / cols;
  const newHeight = Math.floor(height) / rows;

  return {
    width: newWidth,
    height: newHeight,
  };
};
